<template>
  <div class="my-package">
    <div class="my-package__header"></div>
    <div v-if="!isLoading" class="my-package__content mt-4">
      <div v-if="data && data.length" class="row">
        <template v-for="(item, index) in data">
          <div class="col-md-12 col-lg-6 col-xl-4 col-xxl-3 col-12">
            <div
              :key="index"
              class="item-shop"
              @click="
                $router.push(`/package-detail?PackageId=${item.PackageId}`)
              "
            >
              <div class="img-shop">
                <img :src="$apiImageUrl + item.ImagePath" alt="shop-default" />
              </div>
              <div class="information">
                <span class="name">{{ item.PackageName }}</span>
                <span class="money">{{ item.Price | toCurrency(true) }}</span>
              </div>
              <div class="action">
                <span class="title">Activated date:</span>
                <span class="time">{{ item.UsedRedeemCodeDate | formatDateTime }} </span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-else class="row">
        <div class="col-md-12 empty-data">
          <span>{{ $t("no-package") }}</span>
        </div>
      </div>
    </div>
    <div v-else class="container position-relative mt-4">
      <BaseLoading v-if="isLoading" class="loading" :loading="isLoading" />
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@apis/repositoryFactory";
const ShopRepository = RepositoryFactory.get("shop");
import moment from "moment";
import BaseLoading from "@components/Inputs/BaseLoading";
export default {
  components: {
    BaseLoading
  },
  data() {
    return {
      packageItems: [],
      data: [],
      keyActive: "Affiliate",
      carts: [],
      numberCart: 0,
      isLoading: false
    };
  },
  async mounted() {
    try {
      if (
        this.$store.state.Auth.currentUser &&
        Object.keys(this.$store.state.Auth.currentUser).length !== 0
      ) {
        this.isLoading = true
        this.packageItems = await ShopRepository.getMyPackage()
        this.data = this.packageItems
        this.isLoading = false
      }
    } catch (error) {}
  }
};
</script>

<style scoped lang="scss">
.my-package {
  &__header {
    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #43464e;
      margin: 24px 0;
      display: inline-block;
    }
  }
  &__content {
    margin-bottom: 40px;
    .empty-data {
      height: 315px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-weight: 700;
        font-size: 44px;
        line-height: 50px;
        text-align: left;
        color: #141822;
      }
    }
    @media only screen and (max-width: 992px) {
      margin: 20px;
    }
  }
  .item-shop {
    background: #f7f7f7;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 24px;
    height: 92%;
    .img-shop {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-top: 68%;
      margin-bottom: 10px;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .information {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #141822;
        white-space: nowrap;
        max-width: 155px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .money {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #dbac35;
      }
    }
    .action {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #43464e;
        @media only screen and (min-width: 1199px) and (max-width: 1400px) {
          font-size: 12px;
        }
      }
      .time {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #43464e;
        @media only screen and (min-width: 1199px) and (max-width: 1400px) {
          font-size: 12px;
        }
      }
    }
  }
  @media (min-width: 1600px) {
    .col-xxl-3 {
      -webkit-box-flex: 0;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}
</style>
