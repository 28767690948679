<template>
  <div class="banner">
    <div
      v-swiper:myDirectiveSwiper="swiperOptions"
      class="swiper-banner wrapper-swiper"
    >
      <div class="swiper-wrapper">
        <div
          v-for="image in bannerIsUsing"
          :key="image.id"
          class="swiper-slide"
          :class="{'hover-banner': image.Link}"
        >
          <img
            :class="{'pointer': image.Link}"
            :src="$apiImageUrl + image.ImagePath"
            alt="banner"
            @click="openLink(image)"
            class="banner-item"
          />
        </div>
      </div>
      <div
        slot="pagination"
        class="swiper-pagination banner__swiper-pagination"
      />
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@apis/repositoryFactory";
const AssetRepository = RepositoryFactory.get("asset");
import { IMAGE_TYPE } from "@/constants";
import "swiper/css/swiper.css";
export default {
  data() {
    return {
      banners: [],
      swiperOptions: {
        slidesPerView: 1,
        loop: false,
        lazy: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      },
    };
  },
  async created() {
    try {
      this.banners = await AssetRepository.getBanners(IMAGE_TYPE.BANNER);
    } catch (error) {}
  },
  computed: {
    bannerIsUsing() {
      const lang = this.$i18n.locale.toUpperCase();
      return this.banners.filter((i) => i.Language.toUpperCase() == lang);
    },
  },
  methods: {
    openLink (item) {
      if(!item.Link) return;
      const newtab = window.open("", "_blank");
      newtab.location.href = item.Link;
    },
    // imageUrlAlt (event, image) {
    //   event.target.src = this.$apiImageUrl + image
    // }
  }
};
</script>

<style scoped lang="scss">
.banner {
  position: relative;
  min-height: 375px;
  // height: 65vh;
  overflow: hidden;
  .swiper-slide {
    position: relative;
    padding-top: 43%;
    .banner-item {
      position: absolute;
      width: 100%;
      height: 100%;
      // height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
    &.hover-banner {
      cursor: pointer;
    }
  }
  .swiper-pagination {
    bottom: 40px;
    @media only screen and (max-width: 992px) {
      bottom: 10px;
    }
  }
  /deep/ .swiper-pagination-bullet {
    width: 22px;
    height: 4px;
    border-radius: 8px;
    display: inline-block;
    background: #000;
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background: #ffffff;
    }
  }
  @media only screen and (max-width: 992px) {
    height: auto;
    min-height: initial;
  }
}
</style>
