<template>
  <BaseModal :config="config" @hidden="resetData">
    <template #content>
      <form class="google-verification" @submit.prevent="submit">
        <div class="google-verification__title">
          {{ $t('security.disable-google')}}
        </div>
        <div class="google-verification__input">
          <BaseInputCustom
            class-name="google-verification__verification-code theme-light"
            ref="verificationCode"
            v-model="googleVerificationCode"
            :placeholder="$t('validate.verification-code', { 0: 6})"
            type="tel"
            name="code"
            required
            :min-length="6"
            :limit-input="6"
            :label="$t('google.step-4.code')"
          />
        </div>
        <button class="btn btn-primary w-100" @click="submit" :class="{'button--loading': loading}">
          <span>{{$t('action.submit')}}</span>
        </button>
      </form>
    </template>
  </BaseModal>
</template>

<script>
import {MODAL} from "@/constants";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import { RepositoryFactory } from "@apis/repositoryFactory";
const AuthRepository = RepositoryFactory.get('auth')
import validateMixin from "@/core/mixins/validate";

export default {
  components: {
    BaseInputCustom
  },
  mixins: [validateMixin],
  data() {
    return {
      config: {
        id: MODAL.GOOGLE_VERIFICATION_CONFIRMATION,
        class: 'modal--google-verification',
        noCloseOnBackDrop: false,
        centered: true,
        theme: 'light'
      },
      googleVerificationCode: '',
      loading: false
    }
  },
  methods: {
    resetData () {
      this.googleVerificationCode =  ''
       this.loading = false
    },
    async submit () {
      const refs = ['verificationCode']
      const valid = this.$_validateMixin_refs(refs)
      if (valid) {
        this.loading = true
        try {
          await AuthRepository.disable2FA({Code: this.googleVerificationCode, Type: "google"})
          this.$alertMsg('success', this.$t('swal.success.saved'))
          this.$emit("updated")
          this.$bvModal.hide(MODAL.GOOGLE_VERIFICATION_CONFIRMATION)

        } catch (e) {
          this.$alertMsg('fail', e.response.data.message)
        }
        this.loading = false
      }
    }
  },
}
</script>

<style scoped lang="scss">
.google-verification {
  font-family: 'Overpass';
  font-style: normal;
  padding-bottom: 32px;
  margin-top: 16px;
  &__title {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #141822;
    margin-bottom: 32px;
  }
  &__input {
    position: relative;
  }
  &__time {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    text-transform: capitalize;
  }
}
</style>
