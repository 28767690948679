<template>
    <div class="wallet-detail">
      <div class="wallet-detail__title">
        <h2  class="text-white">{{ $t("wallet.wallet-detail") }}</h2>
      </div>
      <div class="wallet-detail__table">
        <b-card>
          <Breadcrumb link="/wallet" :label="$t('wallet.back-wallet')" />
          <p class="wallet-detail__table--title my-3 text-capitalize">
            {{ $t("history.transaction") }}
          </p>
          <div class="list">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t("fields.DateTime") }}</th>
                  <th>{{ $t("fields.Amount") }}</th>
                  <th>{{ $t("fields.Status") }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in data" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.ConvertDate | formatDateTime }}</td>
                  <td>{{ item.VmmAmount | toCurrency }}</td>
                  <td>{{ item.Status }}</td>
                  <td>
                    <router-link :to="`/wallet/cover-vmm-history/${item.Id}`" class="btn btn-outline-primary">
                      {{ $t('Release') }}
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="container position-relative" v-if="isLoading">
            <BaseLoading class="loading mt-0" :loading="isLoading" />
          </div>
          <div v-else-if="!data.length" class="empty-table">
            <img src="/img/empty-folder.png" alt="img empty" />
            <span>No data</span>
          </div>
        </b-card>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { RepositoryFactory } from "@apis/repositoryFactory";
  const WalletRepository = RepositoryFactory.get("wallet");
  import QrcodeVue from "qrcode.vue";
  import BaseInput from "@/components/Inputs/BaseInput.vue";
  import Breadcrumb from "@/containers/Wallet/breadcrumb.vue";
  import globalMixin from "@/core/mixins/global";
  import BaseLoading from "@components/Inputs/BaseLoading";
  
  export default {
    components: {
      QrcodeVue,
      BaseInput,
      Breadcrumb,
      BaseLoading
    },
    mixins: [globalMixin],
  
    data() {
      return {
        data: [],
        listAirdrop: [],
        PageNumber: 1,
        PageSize: 10,
        TotalCount: 0,
        TotalPages: 0,
        HasPrevious: false,
        HasNext: false,
        isActive: false
      };
    },
    computed: {
      ...mapGetters({
        walletValue: "getWallet",
      })
    },
    async created() {
      this.data = await WalletRepository.getReleaseVmmHistories();
    },
  };
  </script>
  
  <style scoped lang="scss">
  .wallet-detail {
    margin: 24px;
  
    @media only screen and (max-width: 500px) {
      margin: 16px;
    }
  
    &__title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
  
      h2 {
        color: #141822;
        font-family: "Overpass";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 12px;
      }
  
      .btn {
        min-width: 240px;
        height: 40px;
      }
  
      @media only screen and (max-width: 980px) {
        flex-direction: column;
      }
  
      @media only screen and (max-width: 830px) {
        flex-direction: column;
  
        .wallet-detail__action {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
  
          .btn {
            width: 100%;
  
            &:first-child {
              margin-right: 0;
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  
    &__btn-withdraw {
      margin-left: 1rem;
      color: #007bff;
      background: #ffffff;
      border: 1px solid #007bff;
  
      &:hover {
        background: #007bff;
        color: #ffffff;
      }
  
      @media only screen and (max-width: 768px) {
        margin-left: 0;
        margin-bottom: 1rem;
      }
    }
  
    &__table {
      overflow-y: hidden;
      overflow-x: auto;
      min-height: 600px;
  
      .card {
        min-height: 70vh;
        margin-bottom: 24px;
  
        .list {
          overflow-x: auto;
          overflow-y: hidden;
        }
      }
  
      &--title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: #43464e;
      }
  
      &--info {
        display: flex;
        justify-content: space-between;
        padding-bottom: 28px;
        border-bottom: solid 1px #eaeaea;
  
        .info-left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
  
          img {
            width: 120px;
          }
  
          .amount {
            display: flex;
            flex-direction: column;
            margin-left: 16px;
            align-items: flex-start;
  
            .token-name {
              font-weight: 700;
              font-size: 32px;
              line-height: 44px;
              color: #898b91;
  
              .btn-certificate {
                padding: 9px 24px;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #007bff;
                background: rgba(0, 123, 255, 0.2);
                border-radius: 8px;
                cursor: pointer;
              }
            }
  
            .token-value {
              font-weight: 700;
              font-size: 24px;
              line-height: 34px;
              display: flex;
              align-items: center;
              color: #141822;
  
              .equivalent {
                margin: 0 5px;
  
                &.hidden-number {
                  margin: -5px 5px 0 5px;
                }
              }
            }
          }
        }
  
        .info-right {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
  
          .form-group {
            margin-bottom: 6px;
          }
  
          .qrcode {
            cursor: pointer;
          }
  
          .address {
            margin-right: 1rem;
            min-width: 450px;
            max-width: 100%;
  
            p {
              font-weight: 500;
              font-size: 20px;
              line-height: 28px;
              color: #43464e;
            }
  
            /deep/ {
              .form-control:disabled {
                background: #F7F7F7;
                border: 1px solid #E8E8E9;
                border-radius: 8px 0 0 8px;
                font-size: 16px;
                line-height: 24px;
                color: #43464E;
                border-right: none;
              }
  
              .input-group-text {
                background: #F7F7F7;
                border: 1px solid #E8E8E9;
                border-left: none;
                border-radius: 0 8px 8px 0;
              }
  
            }
  
            .link-ref {
              &:hover {
                cursor: pointer;
              }
            }
          }
  
          .info-air-drop {
            position: relative;
  
            .tooltip-air-drop {
              position: absolute;
              right: -10px;
              top: 40px;
              width: 280px;
  
              .content {
                position: relative;
                background: #FFFFFF;
                border-radius: 4px;
                padding: 16px;
                box-shadow: 0px 0px 20px rgba(2, 13, 36, 0.25);
  
                &:before {
                  position: absolute;
                  content: "";
                  width: 0;
                  height: 0;
                  border-left: 15px solid transparent;
                  border-right: 15px solid transparent;
                  border-bottom: 15px solid #ffffff;
                  top: -10px;
                  right: 8px;
                }
              }
            }
          }
        }
  
        @media only screen and (max-width: 1376px) {
          .info-right .address {
            min-width: 300px;
          }
        }
  
        @media only screen and (max-width: 1160px) {
          flex-direction: column;
          justify-content: flex-start;
  
          .info-left {
            margin-bottom: 20px;
            justify-content: center;
          }
  
          .info-right {
            justify-content: center;
  
            .address {
              min-width: auto;
              width: 80%;
            }
          }
        }
  
        @media only screen and (max-width: 500px) {
          flex-direction: column;
  
          .info-left {
            img {
              width: 70px;
            }
  
            .amount {
              .token-name {
                font-size: 20px;
                line-height: 32px;
              }
  
              .token-value {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
  
          .info-right {
            flex-direction: column;
            justify-content: center;
            align-items: center;
  
            .address {
              align-items: center;
              margin-bottom: 1rem;
              min-width: auto;
              width: 100%;
            }
          }
        }
  
        &.air-drop {
          border-bottom: none;
  
          .info-right {
            align-items: center;
          }
        }
      }
  
      table {
        width: 100%;
  
        thead {
          th {
            padding: 10px;
            background: rgba(173, 174, 178, 0.2);
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #898b91;
            vertical-align: middle;
            white-space: nowrap;
          }
        }
  
        td {
          padding: 15px 10px;
          border-bottom: 1px solid #eaeaea;
          vertical-align: middle;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #141822;
          max-width: 300px;
  
          &.in-progress {
            color: #67b231;
            text-transform: capitalize;
          }
  
          &.active-date,
          &.status {
            text-transform: capitalize;
          }
        }
      }
  
      .empty-table {
        margin-top: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;
  
        span {
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          color: #adaeb2;
        }
      }
  
      .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 200px;
        flex-direction: column;
      }
    }
  
    &__description {
      .description-content {
        overflow: hidden;
        text-overflow: ellipsis;
        height: 50px;
        padding: 0;
  
        /deep/ ul {
          padding-left: 20px !important;
        }
  
        &.collapsed {
          overflow: auto;
          display: inline-block;
          height: auto;
        }
      }
  
      a {
        color: #007bff;
        font-style: italic;
  
        &:hover {
          cursor: pointer;
        }
      }
  
      border-bottom: solid 1px #eaeaea;
      padding-bottom: 24px;
    }
  
    .page-navigation {
      margin-top: 12px;
  
      ul {
        margin-bottom: 0;
  
        .page-item {
          cursor: pointer;
  
          .page-link {
            border: none;
          }
        }
      }
    }
  }
  </style>
  