<template>
  <div class="payment-wallet">
    <div class="payment-wallet__header">
      <img src="/img/arrow-back.svg" alt="arrow-back" class="arrow-back" @click="cancel()">
      <span>{{ $t('action.back-to') }} {{ $t('payments.methods.index') }}</span>
    </div>
    <div class="payment-wallet__header-mobile">
      <img src="/img/arrow-back.svg" alt="arrow-back" class="arrow-back" @click="cancel()">
      <span class="title">{{ $t('action.back-to') }} {{ $t('payments.methods.index') }}</span>
      <span />
    </div>
    <div class="payment-wallet__content">
    <div class="text-center my-2" v-if="loading">
      <BaseLoading v-if="loading" class="loading" :loading="loading" />
    </div>
    <template v-else>
      <p class="title">{{ $t('wallet.usdt-address') }}</p>
      <div class="input-wallet">
        <input
          type="text"
          v-model="cryptoInfo.ReceiveAddress"
        >
        <span
          type="button"
          class="content-item__copy"
          :class="{'copied':copyWalletAddress}"
          @click="onCopyWalletAddress(cryptoInfo.ReceiveAddress)"
        >
          {{ copyWalletAddress ? $t('action.copied') : $t('action.copy') }}
        </span>
      </div>
      <p v-if="cryptoInfo.CryptoType" class="title">{{ $t('wallet.value') }}: {{ cryptoInfo.CryptoType.toUpperCase() }} (TRC20)</p>
      <div class="input-wallet">
        <input
          type="text"
          disabled
          :value="$options.filters.toCryptocurrency(cryptoInfo.AmountRequested)"
        >
        <span
          type="button"
          class="content-item__copy"
          :class="{'copied':copyAmount}"
          @click="onCopyAmount(cryptoInfo.AmountRequested)"
        >
          {{ copyAmount ? $t('action.copied') : $t('action.copy') }}
        </span>
      </div>
      <div class="qr-code">
        <span>{{ $t('google.step-2.index') }}</span>
        <qrcode-vue
          id="qrimage"
          :value="cryptoInfo.ReceiveAddress"
          :size="200"
          level="M"
        ></qrcode-vue>
      </div>
    </template>
      <div class="small text-danger">
        <p>{{ $t('wallet.usdt-address-note') }}</p>
      </div>
      <div class="action">
        <div
          @click="transfer()"
          class="transfer"
          :class="{'button--loading':isLoading || loading}"
        >
          <span>{{ $t('action.transferred') }}</span>
        </div>
        <span
          @click="cancel()"
          class="cancel">
          {{ $t('action.cancel') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from '@apis/repositoryFactory'
const CartRepository = RepositoryFactory.get('cart')
import QrcodeVue from 'qrcode.vue'
import {NOTIFICATION_ICON} from "@/constants";
import BaseLoading from "@components/Inputs/BaseLoading";
export default {
  name: "payment-wallet",
  components: {
    QrcodeVue,
    BaseLoading
  },
  data () {
    return {
      copyAmount: false,
      copyWalletAddress: false,
      isLoading: false,
      inforCart: [],
      message: 'Copy These Text'
    }
  },
  props: {
    cartId: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    cryptoInfo: {
      type: Object,
      default: {}
    }
  },
  methods: {
    async onCopyAmount (value) {
      this.copyAmount = true
      await navigator.clipboard.writeText(value)
      setTimeout(() => {
        this.copyAmount = false
      }, 5000)
    },
    async onCopyWalletAddress (value) {
      this.copyWalletAddress = true
      await navigator.clipboard.writeText(value)
      setTimeout(() => {
        this.copyWalletAddress = false
      }, 5000)
    },
    async transfer() {
      this.isLoading = true
      try {
        await CartRepository.checkoutByCrypto(this.cartId + '')
        this.isLoading = false
        this.showAlertSuccess()
      } catch (error) {
        this.isLoading = false
        this.$alertMsg('false', error)
      }
    },
    cancel () {
      this.$emit('cancel', false)
    },
    viewOrderHistory () {
      window.location = '/shop?tab=OrderHistory'
    },
    showAlertSuccess () {
      this.isLoading = false
      this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
        this.$t('swal.title.success'),
        this.$t('payments.success'),
        {
          action1Text: 'OK',
          didCloseFunc: this.redirectPage,
          action1Func: this.redirectPage
        },
        'dashboard'
      )
    },
    redirectPage () {
      window.location = '/shop?tab=OrderHistory'
    },
  }
}
</script>

<style scoped lang="scss">
.payment-wallet {
  background: #FFFFFF;
  padding: 16px 24px 24px 24px;
  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 14px;
    border-bottom: 1px solid #EAEAEA;
    .arrow-back { margin-right: 20px}
    span {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #43464E;
    }
  }
  &__content {
    .title {
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      color: #141822;
      margin-top: 24px;
    }
    .input-wallet {
      position: relative;
      input {
        width: 100%;
        height: 44px;
        border: 1px solid #DADADA;
        border-radius: 4px;
        color: #141822;
        padding: 0 10px;
      }
      span {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #007BFF;
        background: #fff;
        padding-left: 7px;
        &.copied {
          color: #898B91;
          pointer-events: none;
        }
      }
    }
    .qr-code {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: 24px 0;
      span {
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        color: #141822;
        display: inline-block;
        margin-bottom: 14px;
      }
    }
    .action {
      .transfer {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #007BFF;
        border-radius: 8px;
        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #FFFFFF;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .cancel {
        display: block;
        margin-top: 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #007BFF;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  &__header-mobile {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    overflow-y: auto;
    padding: 0;
    z-index: 999;
    .payment-wallet__header {
      display: none;
    }
    .payment-wallet__header-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      padding: 0 20px;
      background: #FFFFFF;
      box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
      position: relative;
      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #141822;
        margin-bottom: 0;
      }
    }
    .payment-wallet__content {
      padding: 0 16px;
      .title {
        margin-top: 16px;
      }
      .action {
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        right: 0;
        width: 95%;
        .transfer {
          height: 40px;
        }
        .cancel {
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
