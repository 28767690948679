<template>
  <div class="cart">
    <ConfirmCart
      :cart-id.sync="cartId"
      :carts-list="cartsList"
      :selected-cart-item-ids.sync="listId"
      :total-amount="totalAmount"
      @updateCartItem="updateCartItem"
      @deleteCartItem="deleteCartItem"
    />
  </div>
</template>

<script>
import ShoppingCart from '@containers/Shopping-Cart/cart/shopping-cart.vue'
import ConfirmCart from "@containers/Shopping-Cart/cart/confirm-cart";
import {RepositoryFactory} from "@apis/repositoryFactory";
import {NOTIFICATION_ICON} from "@/constants";
import {mapActions} from "vuex";

const CartRepository = RepositoryFactory.get("cart");
const CartUpdateRepository = RepositoryFactory.put("cart");
const CartDeleteRepository = RepositoryFactory.put("cart");
export default {
  name: "index",
  components: {
    ConfirmCart,
    ShoppingCart
  },
  data () {
    return {
      carts: [],
      cartsList: [],
      numberCart: 0,
      total: 0,
      listId: [],
      cartId: '',
      loading: false,
    }
  },
  computed: {
    totalAmount () {
      return this.total || 0
    }
  },
  watch: {
    listId () {
      this.getMyCart()
      this.getListIdSpit()
    },
  },
  async created() {
    try {
      if (this.$store.state.Auth.currentUser && Object.keys(this.$store.state.Auth.currentUser).length !== 0) {
        await this.fetchWallet()
      }
      await this.getMyCart()
      this.listId = this.cartsList.map(item => item.Id)
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    ...mapActions(["fetchWallet"]),
    backToCart () {
      this.getMyCart()
    },
    async getMyCart () {
      this.carts = await CartRepository.getMyCart()
      this.cartsList = this.carts.CartItems
      this.numberCart = this.carts.CartItems.reduce( function(a, b){
        return a + b['Quantity'];
      }, 0 )
      this.total = this.carts.CartItems.filter(data => this.listId.includes(data.Id)).reduce((accumulator, object) => {
        return accumulator + object.Amount;
      }, 0)
      this.cartId = this.carts.Id
      this.$store.commit('setMyCart', this.numberCart)
    },
    async updateCartItem (params) {
      try {
        await CartUpdateRepository.updateItem(params)
        await this.getMyCart()
      } catch (error) {
        console.log(error)
        this.$alertMsg('fail', error.response.data.message)
      }
    },
    async deleteCartItem (id) {
      try {
        let ids = !id ? this.listId : id
        ids = Array.isArray(ids) ? ids : [ids]

        await CartDeleteRepository.removeItem('loser', ids)
        await this.getMyCart()
        this.listId = id ? this.listId.filter(e => e !== id) : []
        this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
          this.$t('swal.title.success'),
          this.$t('swal.success.saved'),
          {
            action1Text: 'OK',
            action1Func: () => { }
          },
          'dashboard'
        )
      } catch (error) {
        this.$notifyPopup(NOTIFICATION_ICON.ERROR,
          this.$t('swal.title.ops'),
          error,
          {
            action1Text: this.$t("action.try"),
            action1Func: this.getMyCart
          },
          'dashboard'
        )
      }
    },
    getListIdSpit () {
      this.$store.commit('setListIdSplit', this.listId)
    },
  }
}
</script>

<style scoped lang="scss">
</style>
