<template>
  <div class="search">
    <div class="search__type">
      <BaseSelect
        ref="type"
        :input-value="type"
        name="type"
        :items="options"
        :is-show-default="false"
        key-loop="index"
        text-field="label"
        value-field="value"
        class-name="theme-light"
        :placeholder="$t('account.search-placeholder')"
        @change="type = $event"
      />
    </div>
    <div class="search__input">
      <v-select
        v-model="q"
        :reduce="(member) => member.UserId"
        label="Name"
        @search="onSearch"
        :options="members"
        :placeholder="$t('account.search-placeholder')"
      >
        <template #no-options>{{ $t("no-options") }}</template>
      </v-select>
    </div>
    <div class="search__checkbox first">
      <b-form-checkbox
        :checked="true"
        class="itemCheck mb-0"
        v-model="hasRanking"
      />
      <span>{{ $t("network-tree.has-ranking") }}</span>
    </div>
    <div class="search__checkbox last">
      <b-form-checkbox
        :checked="true"
        class="itemCheck mb-0"
        v-model="hasRedeem"
      />
      <span>{{ $t("network-tree.redeemed") }}</span>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import debounce from "lodash.debounce";
import { RepositoryFactory } from "@/apis/repositoryFactory";
const UserRepository = RepositoryFactory.get("user");
import BaseSelect from "@/components/Inputs/BaseSelect.vue";
export default {
  data() {
    return {
      hasRanking: false,
      hasRedeem: false,
      options: [
        {
          label: this.$t("users.infor.username"),
          value: "username",
        },
        {
          label: this.$t("users.infor.phonenumber"),
          value: "phone",
        },
        {
          label: this.$t("users.infor.email"),
          value: "email",
        },
      ],
      members: [],
      type: "username",
      q: "",
      items: [],
    };
  },
  components: {
    "v-select": vSelect,
    BaseSelect,
  },
  watch: {
    q(value) {
      this.$emit("updatetree", value, this.hasRanking, this.hasRedeem);
    },
    hasRanking(value) {
      this.$emit("updatetree", this.q, value, this.hasRedeem);
    },
    hasRedeem(value) {
      this.$emit("updatetree", this.q, this.hasRanking, value);
    },
  },
  async created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.items = await UserRepository.getChildAccounts();
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce(async (loading, search, vm) => {
      try {
        const data = await UserRepository.searchOnNetwork({
          type: vm.type,
          q: search,
          hasRanking: vm.hasRanking,
          hasRedeem: vm.hasRedeem,
        });
        vm.members = data;
      } catch (error) {
        vm.members = [];
      } finally {
        loading(false);
      }
    }, 350),
  },
};
</script>
<style lang="scss" scoped>
/deep/ .vs__search {
  &::placeholder {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #adaeb2;
  }
}
/deep/ .v-select {
  background: #f7f7f7;
  border: 1px solid #e8e8e9;
  border-radius: 8px;
}
.search {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
  &__type {
    width: 220px;
    margin-right: 40px;
    @media only screen and (max-width: 768px) {
      margin-right: 0;
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  &__input {
    width: 480px;
    @media screen and (max-width: 991px) {
      margin-bottom: 10px;
    }
  }
  &__checkbox {
    margin-left: 40px;
    display: flex;
    align-items: center;
    /deep/ .itemCheck {
      label {
        &::before,
        &::after {
          width: 24px;
          height: 24px;
          top: unset;
        }
      }
    }
    span {
      font-family: "Overpass";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #43464e;
      margin-left: 12px;
    }
    @media screen and (max-width: 500px) {
      &.first {
        margin-left: 0;
      }
      &.last {
        margin-left: 5px;
      }
    }
  }
}
</style>
