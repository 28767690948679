<template>
  <div class="mb-4 shop" :class="{ 'dashboard': isDashboard }">
    <div class="pb-2 pt-3" :class="{ 'row': !isDashboard }">
      <div class="align-items-center w-100" :class="{ 'text-center d-flex': !isDashboard }">
        <template v-if="!isDashboard">
          <div class="line-gradient" />
          <span class="title text-gradient font-weight-normal text-nowrap px-3">{{ $t("menu-items.partners") }}</span>
          <div class="line-gradient" />
        </template>
        <template v-else>
          <span class="title text-gradient font-weight-normal text-nowrap px-3">{{ $t("menu-items.partners")
          }}</span>
          <div class="line-gradient mt-3 border-radius-none"></div>
        </template>
      </div>
    </div>
    <div class="business" :class="{ 'mw-100 w-100': isDashboard }">
      <div v-swiper:myDirectiveSwiper="swiperOptions" class="swiper-banner wrapper-swiper">
        <div class="swiper-wrapper">
          <div v-for="image in banners" :key="image.id" class="swiper-slide">
            <div>
              <a href="javascript:void(0)" @click="openLink(image.Id)" class="banner-item">
                <img :src="$apiImageUrl + image.BusinessLogo" :alt="image.BusinessName"
                  onerror="this.onerror=null;this.src='/assets/Default.jpg';" class="banner-item" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@apis/repositoryFactory";
const AssetRepository = RepositoryFactory.get("user");
import globalMixin from "@/core/mixins/global";
import "swiper/css/swiper.css";
export default {
  props: {
    isDashboard: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      banners: [],
      swiperOptions: {
        spaceBetween: 10,
        slidesPerView: 'auto',
        loop: false,
        lazy: true,
        // autoplay: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        // breakpoints: {
        //   320: {
        //     slidesPerView: 1,
        //     spaceBetween: 20,
        //   },
        //   640: {
        //     slidesPerView: 2,
        //     spaceBetween: 20,
        //   },
        //   768: {
        //     slidesPerView: 4,
        //     spaceBetween: 40,
        //   },
        //   1024: {
        //     slidesPerView: 5,
        //     spaceBetween: 50,
        //   },
        // },
      },
    };
  },
  mixins: [globalMixin],
  async created() {
    try {
      this.banners = await AssetRepository.getOurPartnerAvailable();
    } catch (error) { }
  },
  computed: {
    bannerIsUsing() {
      const lang = this.$i18n.locale.toUpperCase();
      return this.banners.filter((i) => i.Language.toUpperCase() == lang);
    },
  },
  methods: {
    openLink(id) {
      if (this.isLogged) {
        this.$router.push(`/details-partner?id=${id}`);
        return;
      }
      this.$router.push(`/partner?id=${id}`);
    },
  },
};
</script>

<style scoped lang="scss">
.shop {
  .row {
    max-width: 1160px;
    margin: 0 auto;
  }

  .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    text-align: left;
    color: #ffffff;
  }
}

.business {
  // display: flex;
  margin: auto;
  max-width: 1120px;
  height: 85px;
  // background: #202531;
  // border: 1px solid #393e4a;
  // border-radius: 8px;
  z-index: 0;
  position: relative;

  .swiper-slide {
    max-width: 200px;
    position: relative;
    padding-top: 7.5%;
    border: 2px solid rgba(238, 213, 137, 0.4);
    border-radius: 8px;
    background: radial-gradient(circle,
        rgb(163, 120, 58) 0%,
        rgb(238, 213, 137) 20%,
        rgb(250, 248, 218) 30%,
        rgb(238, 213, 137) 40%,
        rgb(238, 213, 137) 60%,
        rgb(249, 247, 218) 70%,
        rgb(238, 213, 137) 80%,
        rgb(164, 121, 58) 100%);

    .banner-item {
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: contain;
        border-radius: 7px;
        background: #141822;
      }

      &:hover {
        cursor: pointer;
      }
    }

    @media only screen and (max-width: 992px) {
      padding-top: 14%;
    }

    // @media only screen and (max-width: 768px) {
    //   padding-top: 23%;
    // }

    @media only screen and (max-width: 512px) {
      padding-top: 20%;
    }
  }

  @media only screen and (max-width: 992px) {
    width: 100%;
    // margin: 15px;
    height: 120px;
    padding: 0 15px;
  }

  @media only screen and (max-width: 768px) {
    height: 90px;
  }
}
</style>
