<template>
  <BaseModal :config="config">
    <template slot="content">
      <div class="withdraw__title">Withdraw crypto</div>
      <div class="withdraw__content">
        <BaseInputCustom
          class-name="theme-light"
          ref="address"
          v-model="formData.ToAddress"
          type="text"
          name="address"
          required
          label="Address"
          error-required="Please enter Address"
        />
        <BaseInputCustom
          class-name="theme-light"
          ref="quantity"
          v-model="formData.OfsAmount"
          type="text"
          name="quantity"
          required
          label="Quantity of token"
          error-required="Please enter Quantity of token"
        />
        <BaseInputCustom
          class-name="theme-light"
          ref="pin"
          v-model="formData.Pin"
          is-pin
          name="pin"
          inputmode="numeric"
          required
          label="Security PIN"
          error-required="Please enter Security PIN"
        />
        <p class="text-note">
          {{ $t("stock.pin.first")
          }}<router-link
            tag="a"
            :to="'/profile/security?tab=pin'"
            target="_blank"
            style="color: #007bff; font-weight: bold"
            >{{ $t("stock.pin.second") }}</router-link
          >{{ $t("stock.pin.third") }}
        </p>
      </div>
      <div class="withdraw__action">
        <button
          class="btn btn-primary btn-round withdraw__btn-cancel"
          @click="cancel"
        >
          Cancel
        </button>
        <button class="btn btn-primary withdraw__btn-create" @click="submit">
          Create
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { MODAL, NOTIFICATION_ICON } from "@/constants";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import validateMixin from "@/core/mixins/validate";
import { RepositoryFactory } from "@apis/repositoryFactory";
const WalletRepository = RepositoryFactory.get("wallet");

export default {
  name: "ModalWithdraw",
  components: {
    BaseInputCustom,
  },
  props: {},
  mixins: [validateMixin],
  data() {
    return {
      config: {
        id: MODAL.WITHDRAW,
        class: "modal--withdraw",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light",
      },
      formData: {
        OfsAmount: "",
        ToAddress: "",
        Pin: "",
      },
    };
  },
  computed: {},
  methods: {
    cancel() {
      this.$bvModal.hide(this.config.id);
    },
    async submit() {
      const refs = ["address", "quantity", "pin"];
      const refsValid = this.$_validateMixin_refs(refs);
      if (refsValid) {
        try {
          const response = await WalletRepository.withdrawVLG(this.formData);
          if (response && Object.keys(response).length) {
            this.$notifyPopup(
              NOTIFICATION_ICON.SUCCESS,
              this.$t("swal.title.success"),
              "Withdraw Successful",
              {
                action1Text: "OK",
                action1Func: () => {},
              }
            );
          } else {
            this.$notifyPopup(
              NOTIFICATION_ICON.ERROR,
              this.$t("swal.title.fail"),
              response.message,
              {
                action1Text: "OK",
                action1Func: () => {},
                action2Text: this.$t("action.try"),
                action2Func: () => {},
              }
            );
          }
        } catch (error) {
          this.$notifyPopup(
            NOTIFICATION_ICON.ERROR,
            this.$t("swal.title.fail"),
            error.response.data.message,
            {
              action1Text: this.$t("action.try"),
              action1Func: () => {},
            }
          );
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.withdraw {
  width: 568px;
  color: #ffffff;
  margin-top: 32px;
  &__title {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #141822;
  }
  &__password {
    margin-top: 15px;
  }
  &__btn {
    height: 40px;
  }
  &__action {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
  }
  &__btn-cancel {
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;
    &:hover {
      background: #007bff;
      color: #ffffff;
    }
  }
}
::v-deep .eye {
  color: #43464e !important;
}
::v-deep .base-select {
  margin-bottom: 20px;
  &__label {
    color: #43464e;
  }
}
::v-deep .base-select__wrap {
  background: #f7f7f7;
  .arrow-down {
    color: #43464e;
  }
}
::v-deep .base-select__inner {
  p {
    color: #898b91;
  }
}
</style>
