<template>
  <div class="security">
    <div class="security__title mt-4">{{ $t("subMenuProfile.security") }}</div>
    <div class="mt-4 security__content">
      <div
        class="
          row
          d-flex
          align-items-center
          border-gray border-bottom
          py-4
          pt-0
        "
      >
        <div class="col-md-6 col-sm-12 d-flex align-items-center">
          <img src="/img/security/google-authen.png" class="logo" />
          <div class="ml-4">
            <div class="name">{{ $t("security.google") }}</div>
            <div class="description">{{ $t("security.using-1") }}</div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 d-flex align-items-center justify-content-between">
          <div
            class="d-flex align-items-center"
            v-if="!GoogleAuth"
          >
            <i class="fa fa-times-circle icon" />
            <span> {{ $t("security.not-link") }}</span>
          </div>
          <div class="d-flex align-items-center" v-else>
            <i class="fa fa-check-circle icon ticked-icon" />
            <span> {{ $t("security.linked") }}</span>
          </div>
          <div class="text-right">
            <button
              class="btn cta"
              v-if="!GoogleAuth"
              @click="openGoogleAuthModal"
            >
              {{ $t("action.activate") }}
            </button>
            <button
              class="btn cta cta--red"
              v-else
              @click="openGoogleVerificationModal"
            >
              {{ $t("action.deactivate") }}
            </button>
          </div>
        </div>
      </div>
      <div class="row d-flex align-items-center border-gray border-bottom py-4">
        <div class="col-md-6 col-sm-12 d-flex align-items-center">
          <img src="/img/security/gmail.png" class="logo" />
          <div class="ml-4">
            <div class="name">{{ $t("security.mail") }}</div>
            <div class="description">{{ $t("security.using-1") }}</div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center verify-email">
            <i class="fa fa-times-circle icon" v-if="!EmailAuth" />
            <i class="fa fa-check-circle icon ticked-icon" v-else />
            <span> {{ currentUser.Email }} </span>
          </div>
          <div class="">
            <button
              class="btn cta"
              v-if="!EmailAuth"
              @click="handleActivateEmail"
            >
              {{ $t("action.activate") }}
            </button>
            <button
              class="btn cta cta--red"
              v-else
              @click="handleDeactivateEmail"
            >
              {{ $t("action.deactivate") }}
            </button>
          </div>
        </div>
      </div>
      <div class="row d-flex align-items-center py-4">
        <div class="col-md-6 col-sm-12 d-flex align-items-center">
          <img src="/img/security/pin.png" class="logo" />
          <div class="ml-4">
            <div class="name">{{ $t("security.pin") }}</div>
            <div class="description">{{ $t("security.using-2") }}</div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12"></div>
        <div class="col-md-3 col-sm-12 text-right">
          <button class="btn cta" @click="openPinSecurityModal">
            {{ $t("security.create-pin") }}
          </button>
        </div>
      </div>
    </div>
    <GoogleAuthModal @activated="get2FAStatus" />
    <EmailVerificationConfirmationModal
      @updated="get2FAStatus"
      :enabled="this.EmailAuth"
    />
    <GoogleVerificationModal @updated="get2FAStatus" />
    <PinSecurityModal />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RepositoryFactory } from "@apis/repositoryFactory";
import GoogleAuthModal from "@containers/Profile/Security/GoogleAuthModal.vue";
import { MODAL } from "@/constants";
import EmailVerificationConfirmationModal from "@containers/Profile/Security/EmailVerificationConfirmationModal";
import PinSecurityModal from "@containers/Profile/Security/PinSecurityModal";
import GoogleVerificationModal from "@containers/Profile/Security/GoogleVerificationModal";
const UserRepository = RepositoryFactory.get("user");
const AuthRepository = RepositoryFactory.get("auth");

export default {
  name: "Security",
  components: {
    GoogleVerificationModal,
    EmailVerificationConfirmationModal,
    GoogleAuthModal,
    PinSecurityModal,
  },
  computed: {
    ...mapGetters("Auth", { currentUser: "currentUser" }),
  },
  data() {
    return {
      GoogleAuth: false,
      EmailAuth: false,
      SmsAuth: false,
    };
  },
  watch: {
    "$route.query.tab"(value) {
      if (value === "pin") {
        this.openPinSecurityModal();
      }
    },
  },
  methods: {
    openGoogleAuthModal() {
      this.$bvModal.show(MODAL.GOOGLE_AUTHENTICATION);
    },
    openPinSecurityModal() {
      this.$bvModal.show(MODAL.PIN_SECURITY_CONFIRMATION);
    },
    openGoogleVerificationModal() {
      this.$bvModal.show(MODAL.GOOGLE_VERIFICATION_CONFIRMATION);
    },
    async handleActivateEmail() {
      try {
        await AuthRepository.getCodeEnable2FAForEmail();
        this.$alertMsg("success", this.$t("security-email.sent"));
        this.$bvModal.show(MODAL.EMAIL_VERIFICATION_CONFIRMATION);
      } catch (e) {
        this.$alertMsg("fail", e.response.data.message);
      }
    },
    async handleDeactivateEmail() {
      await this.deactivateEmailAuth();
    },
    async deactivateEmailAuth() {
      try {
        await AuthRepository.sendCodeDisable2FAForEmail();
        this.$alertMsg("success", this.$t("security-email.sent"));
        this.$bvModal.show(MODAL.EMAIL_VERIFICATION_CONFIRMATION);
      } catch (e) {
        this.$alertMsg("fail", e.response.data.message);
      }
    },
    async get2FAStatus() {
      try {
        const data = await UserRepository.get2FAStatus();
        this.EmailAuth = data.EmailAuth;
        this.GoogleAuth = data.GoogleAuth;
        this.SmsAuth = data.SmsAuth;
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    this.get2FAStatus();
    if (this.$route.query.tab === "pin") {
      this.openPinSecurityModal();
    }
  },
};
</script>

<style scoped lang="scss">
.security {
  font-family: "Overpass";
  color: #141822;
  max-width: 1012px;
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #43464e;
  }
  &__content {
    .logo {
      width: 80px;
      height: 80px;
    }
    .icon {
      font-size: 24px;
      color: #b8bdc5;
      margin-right: 8px;
      &.ticked-icon {
        color: #67b231;
      }
    }
    .cta {
      padding: 8px 16px;
      background: #f5f7fa;
      color: #007bff;
      border-radius: 8px;
      &--red {
        color: #f44336;
      }
    }
    .name {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
    }
    .description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #898b91;
    }
  }
  .verify-email {
    span {
      @media(max-width:767px) {
        max-width: 120px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
      }
    }
  }
}
</style>
