<template>
  <NetworkTree />
</template>

<script>
import NetworkTree from '@containers/NetworkTree/index.vue'
export default {
  components: {
    NetworkTree
  }
}
</script>

<style>

</style>
