<template>
  <div class="network">
    <div class="network__header">
      <span class="network__title  text-white">{{ $t("menu-items.network") }}</span>
      <a @click="onClickCreate" class="btn-create">{{
        $t("action.create-new-account")
      }}</a>
    </div>
    <div class="network__content border-radius-2">
      <div class="list">
        <div class="tabs">
          <div
            class="item"
            :class="{ active: tab === 'OverView' }"
            @click="tab = 'OverView'"
          >
            <span>{{ $t("partners.detail.overview") }}</span>
          </div>
          <div
            class="item"
            :class="{ active: tab === 'UserRanking' }"
            @click="tab = 'UserRanking'"
          >
            <span>{{ $t("children-has-ranking") }}</span>
          </div>
          <div
            class="item"
            :class="{ active: tab === 'UserRedeemed' }"
            @click="tab = 'UserRedeemed'"
          >
            <span>{{ $t("children-has-redeem") }}</span>
          </div>
          <div
            class="item"
            :class="{ active: tab === 'Affliate' }"
            @click="tab = 'Affliate'"
          >
            <span>{{ $t("action.aff-link") }}</span>
          </div>
        </div>
      </div>
      <div v-if="!isLoading" class="tab-content">
        <Tree
          v-if="tab === 'OverView'"
          :data="tree"
          :loading-data="isLoading"
          @updatetree="updateTree"
          @upToRoot="updateTree"
        />
        <UserRanking v-if="tab === 'UserRanking'" />
        <UserRedeemed v-if="tab === 'UserRedeemed'" />
        <Affliate v-if="tab === 'Affliate'" />
      </div>
      <div v-else class="container position-relative mt-4" >
        <BaseLoading v-if="isLoading" class="loading" :loading="isLoading" />
      </div>
    </div>
    <ModalCreateAccount />
  </div>
</template>

<script>
import UserRanking from "@containers/NetworkTree/user-ranking.vue";
import UserRedeemed from "@containers/NetworkTree/user-redeemed.vue";
import Affliate from "@containers/NetworkTree/affliate.vue";
import ModalCreateAccount from "@/containers/NetworkTree/modal-create-account.vue";
import Tree from "@/containers/NetworkTree/tree.vue";
import { MODAL } from "@/constants";
import globalMixin from "@/core/mixins/global";
import helpers from "@/core/mixins/helpers";
import { RepositoryFactory } from "@/apis/repositoryFactory";
const UserRepository = RepositoryFactory.get("user");
import BaseLoading from "@components/Inputs/BaseLoading";
export default {
  components: {
    BaseLoading,
    UserRanking,
    UserRedeemed,
    Affliate,
    ModalCreateAccount,
    Tree,
  },
  mixins: [globalMixin, helpers],

  data() {
    return {
      tab: "OverView",
      tree: {},
      currentUserId: this.$store.state.Auth.currentUser.Id || "",
      page: 1,
    };
  },
  async created() {
    await this.updateTree();
  },
  methods: {
    onClickCreate() {
      this.$bvModal.show(MODAL.CREATE_ACCOUNT);
    },
    async updateTree(
      userId = "",
      hasRanking = false,
      hasRedeem = false,
      page = 1
    ) {
      try {
        this.$_loading();
        this.currentUserId = !userId ? this.currentUserId : userId;
        userId = !userId ? this.currentUserId : userId;
        this.tree = await UserRepository.getNetwork(
          userId,
          hasRanking,
          hasRedeem,
          page
        );
        this.tree.HasRanking = hasRanking;
        this.tree.HasRedeem = hasRedeem;
        this.tree.Page = page;
      } catch (error) {
      } finally {
        this.$_load();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.network {
  padding: 24px;
  min-height: 70vh;
  &__header {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    .btn-create {
      background: #007bff;
      border-radius: 8px;
      padding: 8px 34px;
      cursor: pointer;
      text-decoration: inherit;
      text-align: center;
      color: #ffffff;
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: #141822;
    @media only screen and (max-width: 768px) {
      margin-bottom: 16px;
    }
  }
  &__content {
    background: #ffffff;
    padding: 16px;
    .list {
      overflow-y: hidden;
      overflow-x: auto;
      &::-webkit-scrollbar {
        opacity: 0;
        visibility: hidden;
      }
      .tabs {
        width: max-content;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #eaeaea;
        .item {
          padding: 0 15px 15px 15px;
          &:first-child {
            padding-left: 0;
          }
          span {
            display: inline-block;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #43464e;
            position: relative;
          }
          &.active,
          &:hover {
            cursor: pointer;
            span {
              color: #007bff;
              &:before {
                content: "";
                width: 100%;
                background: #007bff;
                position: absolute;
                bottom: -15px;
                left: 0;
                height: 1px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
