<template>
  <div class="shop mt-5">
    <div class="shop__header">
      <div class="row">
        <div class="col-12 text-center align-items-center d-flex w-100">
          <div class="line-gradient" />
          <span class="title mt-0 text-gradient font-weight-normal text-nowrap px-3">{{ $t("home.title.shop") }}</span>
          <div class="line-gradient" />
        </div>
      </div>
    </div>



    <div class="pre-order__content  my-0">
      <div v-if="shopItems['PRE-ORDER'] && shopItems['PRE-ORDER'].length" class="row">

        <div class="col-12 justify-content-center align-items-center right mt-4 mb-5">
          <div class="navigation">
            <div v-for="(item, indx) in dataTab" :key="item" :ref="item" class="item w-auto px-4" :class="{ 'active': 'PRE-ORDER' === item }">
              <span @click="goto(item, indx)">{{ item === 'PRE-ORDER' ? 'Pre Order' : getItemName(item) }}</span>
            </div>
          </div>
        </div>

        <div v-for="(item, index) in shopItems['PRE-ORDER']" :key="index" class="col-md-6 col-lg-4 col-xl-3 col-12">
          <div class="item-pre-order" :class="{ 'sold-out': !item.RemainingPackage }">
            <div class="btn-details">
              <img src="/img/icon-reduce.svg" alt="icon-info">
            </div>
            <div class="img-shop" @click="openDetails(item.Id)">
              <img :src="$apiImageUrl + item.ImagePath" alt="shop-default">
            </div>
            <div class="bottom">
              <div class="information">
                <a class="link" href="javascript:void(0)" @click="openDetails(item.Id)">
                  <span class="name">{{ item.Name }}</span>
                </a>
              </div>
              <span class="number">({{ $t('table.quantity') }} {{ item.RemainingPackage }} {{ $t('pre-order.packages')
              }})</span>
              <span class="reduce" style=" text-decoration: line-through; color: #E96058; font-size: 16px;">
                {{ $t('dashboards.price') }}
                {{ (item.VlgFullAmount / 0.8) * 12.75 | toCurrency(true, 0) }}</span>
              <span class="reduce">
                {{ $t('pre-order.sale') }} {{ (item.VlgFullAmount * 12.75) | toCurrency(true, 0) }}
              </span>
              <div class="quality d-flex justify-content-start align-items-center mt-3 mb-3">
                <span class="title mr-4">{{ $t('table.quantity') }}</span>
                <SumQualityPreOrder :remaining="item.RemainingPackage" :packageId="item.Id" :key="item.Id"
                  @updateItem="updateValuePre" />
              </div>
              <div class="action">
                <button class="buy" @click="orderNow(item)">
                  <span>{{ $t('menu-items.subShop.pre-order') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div v-for="(items, index) in noPreOrder" class="shop__content my-0" :key="index">
      <div class="row">

        <div class="col-12 justify-content-center align-items-center right mt-4 mb-5 flex-column">
          <div class="navigation">
            <div v-for="(item, indx) in dataTab" :key="item" :ref="item" class="item w-auto px-4" :class="{ 'active': index === item }">
              <span @click="goto(item, indx)">{{ getItemName(item) }}</span>
            </div>
          </div>
          <div class="line-gradient w-25 mt-2" />
        </div>

        <div v-for="(item, idx) in items" class="col-md-6 col-lg-4 col-xl-3 col-12" :key="idx">
          <div class="item-shop" :class="{ 'sold-out': listNameSoldOut.includes(item.Name) }">
            <img v-show="hotPackages.includes(item.Name)" src="/assets/New-03.png" class="mw-100 hot-packages">
            <div class="img-shop">
              <img :src="$apiImageUrl + item.ImagePath" alt="shop-default">
            </div>
            <div class="label-sold-out" v-if="listNameSoldOut.includes(item.Name)">
              <img src="/img/sold-out-home.svg" alt="icon-info" />
            </div>
            <div class="btn-details">
              <img src="/img/icon-info.svg" alt="icon-info" @click="openDetail(item.Id)" v-b-tooltip.hover
                :title="$t('action.view-more-benefit')">
            </div>
            <div class="information">
              <span class="name text-uppercase">{{ item.Name }}</span>
              <span class="money">{{ item.Price | toCurrency(true) }}</span>
            </div>
            <div class="quality d-flex justify-content-between align-items-center mt-3 mb-3">
              <span class="title mr-3">{{ $t('table.quantity') }}</span>
              <SumQuality :key="item.Id" @updateItem="updateValue" />
            </div>
            <div class="action">
              <!--                <span class="detail" @click="openDetail(item.Id)">{{ $t('action.details')}}</span>-->
              <button class="add" @click="addItem(item)">
                <span>{{ $t('action.addCart') }}</span>
              </button>
            </div>
          </div>
        </div>
        <!-- <div v-if="index == 'BUSINESS'" class="col-md-6 col-lg-4 col-xl-3 col-12">
          <div class="item-shop sold-out">
            <div class="img-shop">
              <img src="/assets/vmm-555.png" alt="shop-default">
            </div>
            <div class="information">
              <span class="name">{{ 'VMM 555' }}</span>
              <span class="money">{{ 5555 | toCurrency(true) }}</span>
            </div>
            <div>
              <img class="mw-100" src="/assets/coming-soon-text-label.png" alt="">
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <BaseModal :config="config">
      <template slot="content">
        <ModalPinSecurity @submit="finalConfirmWithPin" :loading="loading" :name-package="namePackage"
          :price-package="pricePackage" />
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { RepositoryFactory } from "@apis/repositoryFactory";
import SumQuality from '@/containers/Home/sum-quality.vue'
import SumQualityPreOrder from '@/containers/Shop/pre-order/sum-quality.vue'
import { MODAL, NOTIFICATION_ICON } from "@/constants";
import { dreamUsaPackageName } from "@/constants/packages";
const ShopRepository = RepositoryFactory.get('shop');
const CartRepository = RepositoryFactory.post('cart');
const NumberCartRepository = RepositoryFactory.get('cart');
import globalMixin from "@/core/mixins/global";
import isEmpty from "lodash/isEmpty"
import ModalPinSecurity from "@/containers/Shop/pre-order/ModalPinSecurity.vue";
export default {
  components: {
    SumQuality,
    SumQualityPreOrder,
    ModalPinSecurity
  },
  data() {
    return {
      noPreOrder: {},
      shopItemNew: {},
      shopItems: {},
      data: [],
      keyActive: '',
      carts: [],
      numberCart: 0,
      dataTab: [],
      namePackage: '',
      pricePackage: '',
      listNameNew: [
        '3EB',
        '5EB',
        '1L'
      ],
      listNameSoldOut: [
        'VLINK MERCHANT META'
      ],
      hotPackages: [
        'Vlinkmeta Mall Founders',
        'VLINK MERCHANT META 555'
      ],
      form: {
        Quantity: 1
      },
      loading: false,
      pinCode: "",
      config: {
        id: MODAL.PIN_VERIFICATION,
        class: "modal--pin-security-confirmation",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light"
      }
    };
  },
  mixins: [globalMixin],
  async mounted() {
    try {
      this.shopItems = await ShopRepository.getAll()
      delete this.shopItems['EVENT_TICKET']
      Object.keys(this.shopItems).forEach(i => {
        if (!this.shopItems[i].length) delete this.shopItems[i]
      })
      if (this.shopItems['AFFILIATE'] && this.shopItems['AFFILIATE'].length) {
        this.itemNews = this.shopItems['AFFILIATE'].filter(data => this.listNameNew.includes(data.Name))
        this.shopItems['AFFILIATE'] = this.shopItems['AFFILIATE'].filter(data => !this.listNameNew.includes(data.Name))
      }
      this.dataTab = Object.keys(this.shopItems)
      this.data = this.shopItems[this.dataTab[0]]
      this.keyActive = this.dataTab[0]
      this.noPreOrder = { ...this.shopItems }
      delete this.noPreOrder['PRE-ORDER']
    } catch (error) {

    }
  },
  methods: {
    goto(item, ind) {
      this.$refs[item][ind].scrollIntoView({ behavior: "smooth" })
    },
    openDetails(id) {
      this.$router.push(`/preorder-detail?PackageId=${id}`)
    },
    updateValue(value) {
      this.form.Quantity = value
    },
    openDetail(id) {
      this.$router.push(`/package-detail?PackageId=${id}`)
    },
    closePopup() {
      this.$bvModal.hide(MODAL.PIN_VERIFICATION);
    },
    async addItem(item) {
      if (isEmpty(this.$store.getters["Auth/currentUser"])) {
        this.$bvModal.show(MODAL.LOGIN)
      } else {
        const params = {
          PackageId: item.Id,
          Quantity: this.form.Quantity
        }
        const response = await CartRepository.addToCart(params)
        if (response && response.Id) {
          this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
            this.$t('swal.title.success'),
            this.$t('action.added-package'),
            {
              action1Text: 'OK',
              action1Func: 'OK',
              action2Text: this.$t('check-out'),
              action2Func: () => { this.$router.push("/shopping-cart?confirmCart=true") }
            }
          )
          // this.$alertMsg('true', 'Added package to shopping cart')
          this.carts = await NumberCartRepository.getMyCart()
          this.numberCart = this.carts.CartItems.reduce(function (a, b) {
            return a + b['Quantity'];
          }, 0)
          this.$store.commit('setMyCart', this.numberCart)
        } else {
          this.$notifyPopup(NOTIFICATION_ICON.ERROR,
            this.$t('swal.title.fail'),
            response.message,
            {
              action1Text: this.$t("action.try"),
              action1Func: () => { }
            }
          )
        }
      }
    },
    filterData(key) {
      this.keyActive = key
      this.data = this.shopItems[key]
    },
    hideShowTab(key) {
      return this.shopItems[key] && Object.keys(this.shopItems[key]).length
    },
    getItemName(item) {
      if (item === 'AFFILIATE') {
        return this.$t('fields.affilite-package')
      } else if (item === 'KYC') {
        return this.$t('fields.kyc-package')
      } else if (item === 'BUSINESS') {
        return this.$t('fields.merchant-package')
      } else return item.toLowerCase()
    },
    orderNow(value) {
      if (isEmpty(this.$store.getters["Auth/currentUser"])) {
        this.$bvModal.show(MODAL.LOGIN)
        return
      }
      if (!this.form.Quantity || this.form.Quantity < 1) {
        this.$notifyPopup(NOTIFICATION_ICON.ERROR,
          this.$t('swal.title.fail'),
          this.$t('swal.min', [0]),
          {
            action1Text: 'OK',
            action1Func: 'OK'
          },
          'dashboard'
        )
        return
      }
      this.PackageId = value.Id
      this.namePackage = value.Name
      this.pricePackage = value.Price
      this.$bvModal.show(MODAL.PIN_VERIFICATION);
    },
    updateValuePre(value) {
      this.form.Quantity = value
    },
    async fetchItem() {
      try {
        const foundResult = await Promise.all([
          ShopRepository.getAll()
        ])
        Object.keys(foundResult).forEach(i => {
          if (!foundResult[i].length) delete foundResult[i]
        })
        const items = foundResult[0]
        this.items = items['PRE-ORDER']
      } catch (error) {
        console.log(error);
      } finally {
      }
    },
    async finalConfirmWithPin(pinCode) {
      this.loading = true;
      this.pinCode = pinCode;
      const result = await this.onSubmit();
      this.loading = false;
      if (result) {
        this.$bvModal.hide(MODAL.PIN_VERIFICATION);
      }
    },
    closeModal() {
      this.$bvModal.hide(MODAL.PIN_VERIFICATION)
      window.location = '/shop?tab=OrderHistory'
    },
    async onSubmit() {
      try {
        const data = {
          PackageId: this.PackageId,
          Quantity: this.form.Quantity,
          Pin: this.pinCode
        };
        await ShopRepository.preOrder(data);
        this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
          this.$t("swal.title.congratulations"),
          this.$t("pre-order.success"),
          {
            action1Text: 'OK',
            action1Func: this.closeModal
          },
          'dashboard'
        )
        return true;
      } catch (error) {
        const res = JSON.parse(error.request.response)
        this.$notifyPopup(NOTIFICATION_ICON.ERROR,
          '',
          res.message + '',
          {
            action1Text: 'OK',
            action1Func: this.closePopup
          },
          'dashboard'
        )
        return false
      }
    },
  }
};
</script>

<style scoped lang="scss">
.shop {
  &__header {
    .col-md-3 {
      @media only screen and (max-width: 992px) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px 0;
      }
    }
  }

  &__content {
    margin-top: 43px;
    margin-bottom: 40px;

    .empty-data {
      height: 315px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-weight: 700;
        font-size: 44px;
        line-height: 50px;
        text-align: left;
        color: #FFFFFF;
      }
    }

    @media only screen and (max-width: 992px) {
      margin: 20px;
    }
  }

  .row {
    max-width: 1160px;
    margin: 0 auto;

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media only screen and (max-width: 992px) {
        justify-content: center;
      }
    }
  }

  .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    text-align: left;
    color: #FFFFFF;

    @media only screen and (max-width: 992px) {
      text-align: center;
      // margin: 20px 0;
    }
  }

  .navigation {
    height: 40px;
    // background: #43464E;
    // border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .item {
      width: 130px;
      text-align: center;
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      position: relative;

      &:not(:last-child) {
        &:after {
          content: '|';
          position: absolute;
          right: 0px;
          color: rgb(143, 156, 191);
        }
      }

      span {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: rgb(143, 156, 191);
        text-transform: capitalize;
        white-space: nowrap;

        @media only screen and (max-width: 992px) {
          font-size: 14px;
          line-height: 22px;
        }
      }

      &:hover,
      &.active {
        // background: rgba(0, 123, 255, 0.1);
        // border-radius: 20px;

        span {
          color: #FFF;
        }
      }

      @media only screen and (max-width: 768px) {
        padding: 0 12px !important;
        width: 85px;

        span {
          font-size: 12px;
        }
      }
      @media only screen and (max-width: 580px) {
        padding: 0 6px !important;
      }
    }
  }

  .item-shop {
    background: rgba(33, 36, 45, 1);;
    border: 1px solid rgba(134, 136, 138, 1);
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
    height: 92%;
    position: relative;

    .label-sold-out {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }

    .btn-details {
      position: absolute;
      right: 12px;
      top: 12px;
      z-index: 2;

      &:hover {
        cursor: pointer;
      }
    }

    .img-shop {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-top: 68%;
      margin-bottom: 10px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .information {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #FFFFFF;
        white-space: nowrap;
        max-width: 155px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .money {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #DBAC35;
      }
    }

    .quality {
      .title {
        font-size: 18px;
        line-height: 26px;
        color: #898B91;
      }

      .sum-quality {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #E8E8E9;
          height: 38px;

          .input-number {
            border: none;
            padding-left: 14px;
            width: 40px;
            color: #E8E8E9;
            background: transparent;

            &:focus,
            &:focus-visible,
            &:hover {
              border: none;
              outline: none;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }

          span {
            color: #141822;
            font-size: 20px;
            line-height: 20px;
            display: inline-block;
            padding: 10px 15px;
          }

          &:first-child,
          &:last-child {
            span {
              color: #ADAEB2;
              font-size: 20px;
              line-height: 20px;
              display: inline-block;
              padding: 10px;
            }
          }

          &:first-child {
            border: 1px solid #E8E8E9;
            border-radius: 8px 0px 0px 8px;
          }

          &:last-child {
            border: 1px solid #E8E8E9;
            border-radius: 0px 8px 8px 0px;
          }

          &.disable {
            pointer-events: none;
            opacity: 0.5;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .action {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .add {
        width: 100%;
        height: 40px;
        background: transparent;
        border: 1px solid #007BFF;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          color: #007BFF;
          padding-top: 3px;
        }

        &:hover {
          background: #007BFF;

          span {
            color: #FFFFFF;
          }
        }
      }
    }

    &.sold-out {
      .sum-quality {
        pointer-events: none;
        opacity: 0.4;
      }

      .action {
        pointer-events: none;

        .add {
          border: 1px solid #ADAEB2;

          span {
            color: #ADAEB2;
          }
        }
      }
    }
  }
}

.pre-order {
  &__content {
    margin-top: 43px;
    margin-bottom: 40px;

    .empty-data {
      height: 315px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-weight: 700;
        font-size: 44px;
        line-height: 50px;
        text-align: left;
        color: #FFFFFF;
      }
    }

    .item-pre-order {
      background: #20242D;
      border: 1px solid #43464E;
      border-radius: 8px;
      margin-bottom: 24px;
      height: 92%;
      position: relative;

      .btn-details {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3;
      }

      .img-shop {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 50%;
        margin-bottom: 10px;

        img {
          position: absolute;
          top: -1px;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .bottom {
        padding: 16px;

        .information {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .name {
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: #FFFFFF;
            max-width: 210px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;

          }

          .link {
            &:hover {
              cursor: pointer;

              .name {
                color: #1d62f0;
              }
            }
          }
        }

        .number {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ADAEB2;
          display: block;
        }

        .reduce {
          font-weight: 700;
          font-size: 20px;
          line-height: 28px;
          color: red;
          display: block;
        }

        .quality {
          display: flex;
          justify-content: flex-start;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          color: #43464E;

          .title {
            font-size: 18px;
            line-height: 26px;
            color: #ADAEB2;
          }

          .pre-order-sum {
            .item {
              border: 1px solid #E8E8E9 !important;
            }

            .input-number {
              color: #FFFFFF;
            }
          }
        }

        .action {
          margin-top: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .buy {
            width: 100%;
            height: 40px;
            background: transparent;
            border: 1px solid #007BFF;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              font-weight: 400;
              font-size: 18px;
              line-height: 26px;
              color: #007BFF;
              padding-top: 3px;
            }

            &:hover {
              background: #007BFF;

              span {
                color: #FFFFFF;
              }
            }
          }
        }
      }

      &.sold-out {
        .sum-quality {
          pointer-events: none;
          opacity: 0.5;
        }

        .buy {
          pointer-events: none;
          border: 1px solid #ADAEB2 !important;

          span {
            color: #ADAEB2 !important;
          }
        }
      }
    }

    @media only screen and (max-width: 992px) {
      margin: 20px;
    }
  }
}
</style>
