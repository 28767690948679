<template>
  <div class="direct">
    <div class="direct__table">
      <table>
        <thead>
          <tr>
            <th>{{ $t("fields.VLGCashAmount") }}</th>
            <th>{{ $t("fields.VLGOFSAmount") }}</th>
            <th>{{ $t("fields.VLGPlusAmount") }}</th>
            <th>{{ $t("fields.VTPBonusAmount") }}</th>
            <th>{{ $t("fields.Username") }}</th>
            <th>{{ $t("fields.BonusPercent") }}</th>
            <th>{{ $t("fields.DateTime") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="index">
            <td>{{ item.VLGCashAmount | toCurrency(true) }}</td>
            <td>{{ item.VLGOFSAmount | toCryptocurrency() }}</td>
            <td>{{ item.VLGPlusAmount | toCryptocurrency() }}</td>
            <td>{{ item.VTPBonusAmount | toCryptocurrency() }}</td>
            <td>{{ item.Username }}</td>
            <td>{{ item.BonusPercent }}%</td>
            <td>{{ $options.filters.formatDateTime(item.DateTime) }}</td>
          </tr>
        </tbody>
      </table>
      <BaseLoading v-if="isLoading" class="loading" :loading="isLoading" />
      <div v-else-if="!data.length" class="empty-table">
        <img src="/img/empty-folder.png" alt="img empty" />
        <span>No data</span>
      </div>
    </div>
    <nav aria-label="page-navigation" class="page-navigation" v-if="TotalPages">
      <ul class="pagination justify-content-end" v-if="TotalPages < 5">
        <li class="page-item" :class="{ disabled: !HasPrevious }">
          <a class="page-link" @click="onChangePage(PageNumber - 1)"
            >Previous</a
          >
        </li>
        <li
          class="page-item"
          v-for="page in TotalPages"
          :key="page"
          :class="{ active: page === PageNumber }"
        >
          <a class="page-link" @click="onChangePage(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: !HasNext }">
          <a class="page-link" @click="onChangePage(PageNumber + 1)">Next</a>
        </li>
      </ul>
      <ul class="pagination justify-content-end" v-else>
        <li class="page-item" :class="{ disabled: !HasPrevious }">
          <a class="page-link" @click="onChangePage(PageNumber - 1)"
            >Previous</a
          >
        </li>
        <li class="page-item">
          <a class="page-link" v-if="PageNumber > 2">...</a>
        </li>
        <li class="page-item">
          <a
            class="page-link"
            @click="onChangePage(PageNumber - 1)"
            v-if="PageNumber > 1"
            >{{ PageNumber - 1 }}</a
          >
        </li>
        <li class="page-item active">
          <a class="page-link">{{ PageNumber }}</a>
        </li>
        <li class="page-item" v-if="PageNumber < TotalPages">
          <a class="page-link" @click="onChangePage(PageNumber + 1)">{{
            PageNumber + 1
          }}</a>
        </li>
        <li class="page-item" v-if="PageNumber < TotalPages - 1">
          <a class="page-link">...</a>
        </li>
        <li class="page-item" :class="{ disabled: !HasNext }">
          <a class="page-link" @click="onChangePage(PageNumber + 1)">Next</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { RepositoryFactory } from "@apis/repositoryFactory";
const NetworkRepository = RepositoryFactory.get("network");
import moment from "moment";
import BaseLoading from "@components/Inputs/BaseLoading";
import globalMixin from "@/core/mixins/global";

export default {
  components: {
    BaseLoading,
  },
  mixins: [globalMixin],

  data() {
    return {
      data: [],
      PageNumber: 1,
      PageSize: 10,
      TotalCount: 0,
      TotalPages: 0,
      HasPrevious: false,
      HasNext: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const params = {
        PageNumber: this.PageNumber,
      };
      try {
        this.data = [];
        this.$_loading();
        const result = await NetworkRepository.getDirectHistory(params);
        this.$_load();
        this.PageNumber = result.CurrentPage;
        this.TotalCount = result.TotalCount;
        this.TotalPages = result.TotalPages;
        this.PageSize = result.PageSize;
        this.HasPrevious = result.HasPrevious;
        this.HasNext = result.HasNext;
        this.data = result.Result;
      } catch (error) {}
    },
    onChangePage(page) {
      if (page === this.PageNumber) {
        return;
      }
      this.PageNumber = page;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.direct {
  margin-top: 24px;
  min-height: 70vh;
  &__table {
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 600px;
    table {
      width: 100%;
      thead {
        th {
          padding: 10px;
          background: rgba(173, 174, 178, 0.2);
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #898b91;
          vertical-align: middle;
          white-space: nowrap;
        }
      }
      td {
        padding: 15px 10px;
        border-bottom: 1px solid #eaeaea;
        vertical-align: middle;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #141822;
        white-space: nowrap;
        &.in-progress {
          color: #67b231;
          text-transform: capitalize;
        }
        &.active-date,
        &.status {
          text-transform: capitalize;
        }
      }
    }
    .empty-table {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex-direction: column;
      padding: 100px 0;
      @media only screen and (max-width: 768px) {
        padding: 50px 0;
      }
      span {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #adaeb2;
      }
    }
    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 200px;
      flex-direction: column;
    }
  }
  .page-navigation {
    margin-top: 12px;
    ul {
      .page-item {
        cursor: pointer;
        .page-link {
          border: none;
        }
      }
    }
  }
}
</style>
