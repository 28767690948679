<template>
  <BaseModal :config="config" @hidden="resetData">
    <template #content>
      <form class="email" @submit.prevent="submit">
        <div class="email__title">
          {{ enabled ? $t('security-email.disabled') : $t('security-email.enabled')}}
        </div>
        <div class="email__input">
          <BaseInputCustom
            class-name="email__verification-code theme-light"
            ref="verificationCode"
            v-model="emailVerificationCode"
            type="tel"
            name="code"
            required
            :limit-input="6"
            :placeholder="$t('validate.verification-code', { 0: 6})"
            :error-required="$t('security-email.required')"
            :label="$t('security-email.code')"
          />
          <div class="email__time">
            <a
              v-if="!counting"
              href="javascript:void(0)"
              @click="resendEmail">
              {{$t('security-email.re-send')}}
            </a>
            <countdown :time="59 * 1000" v-else @end="counting = false" class="text-right">
              <template slot-scope="props">{{ `${$t('security-email.time')}: ${props.seconds}` }} s</template>
            </countdown>
          </div>
        </div>
        <button
          class="btn btn-primary w-100"
          @click="submit"
          :class="{'button--loading': loading}"
          :disabled="emailVerificationCode.length < 6"
        >
          <span>{{$t('action.submit')}}</span>
        </button>
      </form>
    </template>
  </BaseModal>
</template>

<script>
import {MODAL} from "@/constants";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import { RepositoryFactory } from "@apis/repositoryFactory";
const AuthRepository = RepositoryFactory.get('auth')
import validateMixin from "@/core/mixins/validate";

export default {
  components: {
    BaseInputCustom
  },
  mixins: [validateMixin],
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      config: {
        id: MODAL.EMAIL_VERIFICATION_CONFIRMATION,
        class: 'modal--email-verification-confirmation',
        noCloseOnBackDrop: false,
        centered: true,
        theme: 'light'
      },
      emailVerificationCode: '',
      counting: true,
      loading: false
    }
  },
  methods: {
    resetData () {
      this.emailVerificationCode =  ''
       this.counting = true
    },
    async resendEmail () {
      try {
        if(this.enabled) {
          await AuthRepository.sendCodeDisable2FAForEmail()
        } else {
          await AuthRepository.getCodeEnable2FAForEmail()
        }
        this.counting = true
        this.$alertMsg('success', this.$t('security-email.sent'))
      } catch (e) {
        this.$alertMsg('fail', e.response.data.message)
      }
    },
    async submit () {
      const refs = ['verificationCode']
      const valid = this.$_validateMixin_refs(refs)
      if (valid) {
        this.loading = true
        try {
          if (!this.enabled) {
            await AuthRepository.emailAuth2FAConfirm(this.emailVerificationCode)
          } else {
            await AuthRepository.disable2FA({Code: this.emailVerificationCode, Type: "email"})
          }
          this.$alertMsg("success", this.$t('swal.success.saved'))
          this.$emit("updated")
          this.$bvModal.hide(MODAL.EMAIL_VERIFICATION_CONFIRMATION)
        } catch (e) {
          this.$alertMsg('fail', e.response.data.message || this.$t("pages.error-title"))
        }
        this.loading = false
      }
    }
  },
}
</script>

<style scoped lang="scss">
.email {
  font-family: 'Overpass';
  font-style: normal;
  padding-bottom: 32px;
  margin-top: 16px;
  &__title {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #141822;
    margin-bottom: 32px;
  }
  &__input {
    position: relative;
  }
  &__time {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    text-transform: capitalize;
  }
}
</style>
