<template>
  <div class="credit-lending">
    <div class="container">
      <h2 class="credit-lending__title">{{ $t("credits-docs.title") }}</h2>
      <div class="credit-lending__content">
        <b-colxx xxs="12" class="mb-4 py-5 bg-common text-white">
          <ul>
            <li>
              <p class="h6 font-italic font-weight-bold">
                {{ $t("credits-docs.I.title") }}
              </p>
              <p v-html="$t('credits-docs.I.description')" />
            </li>
            <li>
              <p class="h6 font-italic font-weight-bold">
                {{ $t("credits-docs.II.title") }}
              </p>
              <p>{{ $t("credits-docs.II.description") }}</p>
            </li>
            <li>
              <p>
                <span class="h6 font-italic font-weight-bold">{{
                  $t("credits-docs.III.title")
                }}</span>
                {{ $t("credits-docs.III.description") }}
              </p>
            </li>
            <li>
              <p class="h6 font-italic font-weight-bold">
                {{ $t("credits-docs.IV.title") }}
              </p>
              <p
                class="text-capitalize"
                v-html="$t('credits-docs.IV.description')"
              />
            </li>
            <li>
              <p class="h6 font-italic font-weight-bold">
                {{ $t("credits-docs.V.title") }}
              </p>
              <p v-html="$t('credits-docs.V.description')" />
            </li>
            <li>
              <p class="h6 font-italic font-weight-bold">
                {{ $t("credits-docs.VI.title") }}
              </p>
              <p v-html="$t('credits-docs.VI.description')" />
            </li>
          </ul>
        </b-colxx>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.credit-lending {
  min-height: 100vh;
  position: relative;
  .container {
    padding: 64px 0;
    text-align: center;
  }
  &__title {
    color: #ffffff;
    font-family: "Overpass";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    margin: 0;
    margin-bottom: 40px;
  }
  &__content {
    text-align: left;
  }
  @media only screen and (max-width: 992px) {
    padding: 12px;
  }
}
</style>
