<template>
  <div class="shopping-cart">
    <div class="shopping-cart__header">
      <p class="title">{{ $t('menu-items.shopping') }}</p>
    </div>
    <div class="shopping-cart__content">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8">
          <div class="content-center">
            <div class="select-all">
              <div class="left" @click="$router.push('/shop')">
                  <img src="/img/arrow-back.svg" alt="arrow-back" class="arrow-back">
                  <span>{{ $t('menu-items.back-to-shop') }}</span>
                </div>
              <div class="right">
                <div :class="{'disable': !selectedCartItemIds.length}" @click="deleteCartItem(null)" class="delete-all">
                  <span class="icon-delete"></span>
                  <span class="text">{{ $t('action.delete') }}</span>
                </div>
              </div>
            </div>
            <div v-if="cartsList && cartsList.length" class="select-list">
              <div
                v-for="(item, index) in cartsList"
                :key="index"
                class="item py-3"
              >
                <div class="information">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      :value="item.Id"
                      :id="item.Id"
                      v-model="listId"
                    >
                  </div>
                  <div class="image">
                    <img :src="$apiImageUrl + item.ImagePath" alt="">
                  </div>
                  <div class="des">
                    <span class="name">{{ item.PackageName }}</span>
                  </div>
                </div>
                <div class="action flex-row align-items-center">
                  <span style="width: 125px;" class="price mb-0 pb-0">{{ item.Price | toCurrency(true) }}</span>
                  <span v-b-tooltip.hover :title="$t('remove-package')" class="price icon-delete ml-3" @click="deleteCartItem(item.Id)" />
                </div>
                <div class="sum-quality">
                  <SumQuality
                    :idCart="item.Id"
                    :package-id="item.PackageId"
                    :numberCart="item.Quantity"
                    :key="item.Id"
                    @updateItem="updateValue" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4">
          <div class="content-left">
            <p class="title">{{ $t('menu-items.order-summary') }}</p>
            <div class="summary">
              <span class="total">{{ $t('menu-items.total') }} {{ numOfSelectedItems }} ({{ $tc('menu-items.item', numOfSelectedItems < 2) }})</span>
              <span class="money">{{ totalAmount | toCurrency(true) }}</span>
            </div>
            <div class="action" :class="{'disable': !selectedCartItemIds.length}">
              <button class="button" @click="confirmCartAction()">
                <span>{{ $t('menu-items.confirm-cart') }} ({{ numOfSelectedItems }})</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SumQuality from '@containers/Shopping-Cart/cart/sum-quality.vue'
export default {
  components: {
    SumQuality
  },
  name: "shopping-cart",
  props: {
    cartsList: {
      type: Array,
      default: () => []
    },
    selectedCartItemIds: {
      type: Array,
      default: () => []
    },
    totalAmount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    listId : {
      get () {
        return this.selectedCartItemIds
      },
      set (val) {
        this.$emit('update:selectedCartItemIds', val)
      }
    },
    numOfSelectedItems () {
      if (this.listId && this.listId.length) {
        const selectedItems = this.cartsList.filter(item => this.listId.includes(item.Id))
        return selectedItems.reduce((a, b) => { return a + b['Quantity'] }, 0 )
      }
      return 0
    }
  },
  methods: {
    updateValue (value) {
      const data = {
        Id: value.Id,
        PackageId: value.PackageId,
        Quantity: value.Quantity
      }
      this.$emit('updateCartItem', data)
    },
    deleteCartItem (id) {
      this.$emit('deleteCartItem', id)
    },
    confirmCartAction () {
      this.$emit('confirmCart')
    },
  },

}
</script>

<style scoped lang="scss">
.shopping-cart {
  padding: 24px;
  @media only screen and (max-width: 768px) {
    min-height: 100vh;
    padding: 0;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      color: #141822;
      margin-bottom: 0;
    }
    padding-bottom: 26px;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  &__content {
    .content-center {
      background: #FFFFFF;
      border-radius: 8px;
      padding: 16px 24px;
      .select-all {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        margin-bottom: 15px;
        border-bottom: 1px solid #EAEAEA;
        .left {
          .form-check {
            display: flex;
            align-items: center;
            padding-left: 0;
            input {
              width: 24px;
              height: 24px;
              position: initial;
              margin: 0;
            }
            label {
              padding-left: 20px;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: #43464E;
            }
          }
        }
        .right {
          .delete-all {
            display: flex;
            align-items: center;
            .icon-delete {
              margin-right: 15px;
            }
            .text {
              text-transform: uppercase;
              font-size: 16px;
              line-height: 20px;
              color: #43464E;
            }
            &:hover {
              cursor: pointer;
              .text {
                color: #E96058;
              }
              .icon-delete {
                &:before {
                  color: #E96058;
                }
              }
            }
          }
        }
      }
      .select-list {
        .item {
          display: flex;
          justify-content: space-between;
          .information {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 300px;
            @media (max-width: 140px) {
              width: 250px;
            }
            .form-check {
              display: flex;
              align-items: center;
              padding-left: 0;
              margin-right: 20px;
              input {
                width: 24px;
                height: 24px;
                position: initial;
                margin: 0;
              }
            }
            .image {
              margin-right: 10px;
              img {
                //height: 112px;
                width: 100px;
                height: auto;
                @media (max-width: 1400px) {
                  width: 60px;
                }
              }
            }
            .des {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              flex-direction: column;
              padding-right: 20px;
              .name {
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                color: #141822;
                @media (max-width: 1400px) {
                  font-size: 17px;
                }
              }
              .description {
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                text-align: left;
                color: #141822;
              }
            }
          }
          .action {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            margin-right: 10px;
            .price {
              font-weight: 700;
              font-size: 20px;
              line-height: 23px;
              color: #E96058;
              margin-bottom: 2px;
            }
            .icon-delete {
              &:hover {
                cursor: pointer;
                &:before {
                  color: #E96058;
                }
              }
            }
          }
          .sum-quality {
            display: flex;
            align-items: center;
            width: 130px;
            justify-content: flex-end;
            @media (max-width: 1400px) {
              width: 115px;
            }
            .item-quality {
              width: 50px;
            }
            .item {
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #E8E8E9;
              span {
                color: #141822;
                font-size: 20px;
                line-height: 20px;
                display: inline-block;
                padding: 10px 15px;
              }
              &:first-child, &:last-child {
                span {
                  color: #ADAEB2;
                  font-size: 20px;
                  line-height: 20px;
                  display: inline-block;
                  padding: 10px;
                }
              }
              &:first-child {
                border: 1px solid #E8E8E9;
                border-radius: 8px 0px 0px 8px;
              }
              &:last-child {
                border: 1px solid #E8E8E9;
                border-radius: 0px 8px 8px 0px;
              }
              &.disable {
                pointer-events: none;
                opacity: 0.5;
              }
              &:hover {
                cursor: pointer;
              }
            }
            .sum {
              white-space: nowrap;
            }
          }
        }
      }
      .no-cart {
        display: flex;
        justify-content: center;
        align-items: center;
        // height: 500px;
        span {
          display: inline-block;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #007BFF;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .content-left {
      background: #FFFFFF;
      border-radius: 8px;
      padding: 18px 24px 24px 24px;
      .title {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #141822;
        padding-bottom: 14px;
        border-bottom: 1px solid #EAEAEA;
      }
      .summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px 0;
        .total {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #141822;
        }
        .money {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #E96058;
        }
      }
      .action {
        .button {
          background: #007BFF;
          border-radius: 8px;
          border: none;
          width: 100%;
          span {
            display: inline-block;
            padding: 12px 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  &__content-mobile {
    display: none;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      padding: 0 20px;
      background: #FFFFFF;
      box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
      position: relative;
      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #141822;
        padding-right: 20px;
      }
      .icon-delete {
        font-size: 20px;
      }
    }
    .list {
      .select-list {
        background: #ffffff;
        padding-left: 16px;
        border-bottom: 1px solid #ddd7d7;
        .item-cart {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 16px 16px 16px 0;
          border-bottom: 1px solid #F8F8F8;
          &:last-child {
            border-bottom: 0;
          }
          .form-check {
            display: flex;
            align-items: center;
            padding-left: 0;
            margin-right: 12px;
            input {
              width: 20px;
              height: 20px;
              position: initial;
              margin: 0;
            }
          }
          .image {
            img {
              width: 80px;
              height: auto;
              margin-right: 12px;
            }
          }
          .des {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            .name {
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              color: #141822;
            }
            .description {
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              color: #141822;
            }
            .action {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              padding-top: 4px;
              .left {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .price {
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 16px;
                  color: #E96058;
                  margin-right: 12px;
                  width: 83px;
                }
              }
              .sum-quality {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .item {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  span {
                    color: #141822;
                    font-size: 20px;
                    line-height: 20px;
                    display: inline-block;
                    padding: 10px 15px;
                  }
                  &:first-child, &:last-child {
                    span {
                      color: #ADAEB2;
                      font-size: 15px;
                      line-height: 15px;
                      display: inline-block;
                      padding: 3px 10px 5px 10px;
                    }
                  }
                  &:first-child {
                    border: 1px solid #E8E8E9;
                  }
                  &:last-child {
                    border: 1px solid #E8E8E9;
                  }
                  &.disable {
                    pointer-events: none;
                    opacity: 0.5;
                  }
                }
                .sum {
                  white-space: nowrap;
                }
              }
            }
          }
        }
        .no-cart {
          margin-top: 12px;
          margin-bottom: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          // height: 500px;
          span {
            display: inline-block;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #007BFF;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .confirm-cart {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 55px;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 79px;
        padding: 0 10px 0 16px;
        background: #FFFFFF;
        .form-check {
          display: flex;
          align-items: center;
          padding-left: 0;
          margin-right: 12px;
          input {
            width: 20px;
            height: 20px;
            position: initial;
            margin: 0;
          }
          &-label {
            margin-left: 10px;
            text-transform: capitalize;
          }
        }
        .sum {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: flex-start;
          .title {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #141822;
          }
          .money {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #E96058;
          }
        }
        .action {
          .button {
            background: #007BFF;
            border-radius: 20px;
            width: 120px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            span {
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;
              color: #FFFFFF;
              white-space: nowrap;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      display: block;
    }
  }
  .disable {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
