<template>
  <BaseModal :config="config" @hidden="resetData">
    <template #content>
      <template v-if="hasOldPin">
        <form class="pin" @submit.prevent="submit" ref="form">
          <div class="pin__title">
            {{$t('pin.generate')}}
          </div>
          <div class="pin__input">
            <BaseInputCustom class-name="pin__old theme-light" ref="oldPin" v-model="formData.OldPin" v-if="hasOldPin"
              is-pin name="old" required :max-length="6" :limit-input="6" :error-required="$t('security-pin.required')"
              :error-min-length="$t('security-pin.min-length')" :label="$t('security-pin.old')" />
            <BaseInputCustom class-name="pin__new theme-light" ref="newPin" v-model="formData.NewPin" is-pin name="new"
              required :max-length="6" :limit-input="6" :error-required="$t('security-pin.required-new')"
              :error-min-length="$t('security-pin.min-length')" :label="$t('security-pin.new')" />
            <p class="small text-danger"><i>{{$t('pin.guide')}}</i> </p>
          </div>
          <div class="pin__reset-pin">
            <span :class="{'disable': loadingForgot}" @click="onForgotPin">{{$t('security-pin.Forgot')}}</span>
          </div>
          <button class="btn btn-primary w-100" :class="{'button--loading': loading}">
            <span>{{$t('action.submit')}}</span>
          </button>
        </form>
      </template>
      <template v-else>
        <div class="pin__title">
          {{$t('action.getpin')}}
        </div>
        <p> {{$t('pin.get1')}}<a href="javascript:void(0)" @click="getPin">{{$t('pin.get2')}}</a> {{$t('pin.get3')}}</p>
        <button @click="getPin" class="btn btn-primary w-100" :class="{'button--loading': loading}">
          <span>{{$t('action.getpin')}}</span>
        </button>
      </template>
    </template>
  </BaseModal>
</template>

<script>
import { MODAL } from "@/constants";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import { RepositoryFactory } from "@apis/repositoryFactory";
const UserRepository = RepositoryFactory.get('user')
import validateMixin from "@/core/mixins/validate";
import { mapGetters } from "vuex";
import { NOTIFICATION_ICON } from "@/constants";

export default {
  components: {
    BaseInputCustom
  },
  mixins: [validateMixin],
  computed: {
    ...mapGetters("Auth", { currentUser: "currentUser" }),
    hasOldPin() {
      return !!this.currentUser.ExistPin
    }
  },
  data() {
    return {
      config: {
        id: MODAL.PIN_SECURITY_CONFIRMATION,
        class: 'modal--pin-security-confirmation',
        noCloseOnBackDrop: false,
        centered: true,
        theme: 'light'
      },
      formData: {
        OldPin: '',
        NewPin: ''
      },
      loading: false,
      loadingForgot: false
    }
  },
  methods: {
    async submit() {
      await this.hasOldPin ? this.updatePin() : this.addNewPin()
    },
    async getPin() {
      try {
        this.loading = true
        await UserRepository.addPin()
        this.$notifyPopup(
          NOTIFICATION_ICON.SUCCESS,
          this.$t("swal.title.success"),
          this.$t("pin.success"),
          {
            action1Func: "OK",
            action1Text: "OK"
          }
        )
        this.$bvModal.hide(MODAL.PIN_SECURITY_CONFIRMATION)
        this.$store.commit("Auth/setUser", { ...this.currentUser, ExistPin: true })
      } catch (e) {
        this.$alertMsg('fail', e.response.data.message)
      } finally {
        this.loading = false
      }
    },
    async onForgotPin() {
      try {
        this.loadingForgot = true
        const data = await UserRepository.recoverPin()
        this.$notifyPopup(
          NOTIFICATION_ICON.SUCCESS,
          this.$t("swal.title.success"),
          data.message, {
          action1Func: "OK",
          action1Text: "OK"
        }
        )
        this.loadingForgot = false
      } catch (e) {
        this.$alertMsg('fail', e.response.data.message)
      }
    },
    async addNewPin() {
      const refs = ['newPin']
      const valid = this.$_validateMixin_refs(refs)
      if (valid) {
        this.loading = true
        try {
          await UserRepository.addPin(this.formData.NewPin)
          this.$alertMsg("success", this.$t('security-pin.ready'))
          this.$bvModal.hide(MODAL.PIN_SECURITY_CONFIRMATION)
          this.$store.commit("Auth/setUser", { ...this.currentUser, ExistPin: true })
        } catch (e) {
          this.$alertMsg('fail', e.response.data.message)
        }
        this.loading = false
      }
    },
    async updatePin() {
      const refs = ['oldPin', 'newPin']
      const valid = this.$_validateMixin_refs(refs)
      if (valid) {
        this.loading = true
        try {
          await UserRepository.updatePin(this.formData)
          this.$alertMsg("success", this.$t('security-pin.ready'))
          this.$emit("updated")
          this.$bvModal.hide(MODAL.PIN_SECURITY_CONFIRMATION)
        } catch (e) {
          this.$alertMsg('fail', e.response.data.message)
        }
        this.loading = false
      }
    },
    resetData() {
      this.formData = {
        OldPin: '',
        NewPin: ''
      }
    }
  },
}
</script>

<style scoped lang="scss">
.pin {
  font-family: 'Overpass';
  font-style: normal;
  padding-bottom: 32px;
  margin-top: 16px;

  &__title {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #141822;
    margin-bottom: 32px;
  }

  &__input {
    position: relative;
  }

  &__reset-pin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    margin-bottom: 32px;

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
      color: #007BFF;
      cursor: pointer;

      &.disable {
        pointer-events: none;
        opacity: 0.6;
      }
    }
  }

  &__time {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
