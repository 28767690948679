<template>
  <div
    class="item"
     :class="{'active' : payByVlg, 'disable': disabled}"
     @click="toggleVlg"
  >
    <div class="d-flex justify-content-center">
      <img :src="item.logo" alt="crypto">
    </div>
    <div class="information mt-2">
      <div class="name">{{ item.label }}</div>
      <span class="balance" v-if="item.showEquivalentBalance">
        {{ $t('payments.methods.cash.balance')}}: {{ wallet[item.value].Amount | toCurrency }}
             ≈ {{ wallet[item.value].Amount *  formatCurrentPrice(wallet[item.value].CurrentPrice || 1) | toCurrency(true) }}
      </span>
    </div>
    <div class="item__checkbox">
      <label class="switch">
        <input type="checkbox" v-model="payByVlg">
        <span class="slider round"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'input',
    event: 'change'
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    input: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    wallet: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    payByVlg: {
      set(val) {
        this.$emit('change', val)
      },
      get() {
        return this.input
      }
    }
  },
  methods: {
    formatCurrentPrice(value) {
      if (value) {
        return value
      }
      return 1
    },
    toggleVlg () {
      this.payByVlg = !this.payByVlg
    }
  }
}
</script>

<style scoped lang="scss">
  .item {
    position: relative;
    padding: 24px 10px 16px 10px;
    margin-bottom: 14px;
    cursor: pointer;
    width: 150px;
    height: 150px;
    border: 1px solid #DADADA;
    border-radius: 8px;

    &.disable {
      pointer-events: none;
      cursor: default;
      background: #dadada;
    }
    &.active {
      border: 1px solid #007BFF;
    }
    img {
      width: 32px;
      height: 32px;
    }
    .information {
      width: 100%;
      .name {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #141822;
        text-align: center;
      }
      .balance {
        display: flex;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #898B91;
        b {
          color: #141822;
        }
      }
    }
    &__checkbox {
      position : absolute;
      left: 8px;
      top: 8px;
      .switch {
        position: relative;
        display: inline-block;
        width: 2.5rem;
        height: calc(1rem + 4px);
        pointer-events: none;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 1rem;
        width: 1rem;
        left: 4px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }

      input:checked + .slider {
        background-color: #2196F3;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(1rem);
        -ms-transform: translateX(1rem);
        transform: translateX(1rem);
      }

      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }
  }
</style>
