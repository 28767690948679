<template>
  <div class="order-history">
    <div v-if="!isLoading" class="order-history__content mt-4">
      <div  class="row">
        <template v-if="orderPreItems && orderPreItems.length" >
          <div v-for="(item, index) in orderPreItems" class="col-md-12 col-lg-6 col-xl-4 col-xxl-3 col-12">
            <div :key="index" class="item-shop">
              <p
                v-if="checkClass(item.Status)"
                :class="checkClass(item.Status)"
                class="approved"
              >{{$t(`pre-order.status.${item.Status}`)}}</p>
              <div class="img-shop">
                <img :src="$apiImageUrl + item.ImagePath" alt="shop-default" />
              </div>
              <div class="information">
                <span class="name-pre-order">{{ item.PackageName }}</span>
              </div>
              <div class="action">
                <span class="title">{{ $t("pre-order.from") }}:</span>
                <span class="time">{{
                      ((item.RemainingAmount + item.DepositAmount)/0.8)*12.75 | toCurrency(true)
                  }}</span>
              </div>
              <div class="action">
                <span class="title">{{ $t("pre-order.sale") }}:</span>
                <span class="time">{{
                    (((item.RemainingAmount + item.DepositAmount)/0.8)*12.75) -
                    ((((item.RemainingAmount + item.DepositAmount)/0.8)*12.75) * 20) / 100 | toCurrency(true)
                  }}</span>
              </div>
              <div class="action">
                <span class="title">{{ $t("pre-order.deposited") }}:</span>
                <span class="time">{{
                    item.DepositAmount
                  }} VLG</span>
              </div>
              <div class="action">
                <span class="title">{{ $t("pre-order.remain") }}:</span>
                <span class="time">{{
                    item.RemainingAmount | toCurrency
                  }} VLG</span>
              </div>
              <div class="action">
                <button
                  v-if="item.Status !== 'WON_PRIZE'"
                        class="order"
                        @click="payments(item)"
                        :class="{'won-prize': item.Status === 'WON_PRIZE'}"
                >
                  <span>{{ $t('borrow.payment') }} </span>
                </button>
                <template
                  v-else class="order"
                  :class="{
                    'won-prize': item.Status === 'WON_PRIZE',
                  }">
                    <Countdown :date="item.PaymentDeadline"  @click="payments(item)"/>
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-if="orderItems && orderItems.length">
          <div v-for="(item, index) in orderItems" class="col-md-12 col-lg-6 col-xl-4 col-xxl-3 col-12">
            <div :key="index" class="item-shop">
              <img
                :src="checkImage(item.Status)"
                :alt="item.Status"
                class="approved"
              />
              <div class="img-shop">
                <img :src="$apiImageUrl + item.ImagePath" alt="shop-default" />
              </div>
              <div class="information">
                <span class="name">{{ item.PackageName }}</span>
                <span class="money">${{ item.Price | toCurrency }}</span>
              </div>
              <div class="action">
                <span class="title">{{ $t("redeem") }}:</span>
                <span v-if="item.RedeemCode" class="time">
                  <a
                    @click="copyLink(item.RedeemCode)"
                    href="javascript:void(0)"
                  >
                    {{ item.RedeemCode }}<i class="fa fa-copy"></i>
                  </a>
                </span>
              </div>
              <div class="action" v-if="item.RedeemCode">
                <span class="title">{{ $t("used-by") }}:</span>
                <span class="time">{{
                    item.UsernameUsedRedeemCode
                      ? item.UsernameUsedRedeemCode
                      : "Available"
                  }}</span>
              </div>
              <div class="action" v-if="item.CheckOutDate">
                <span class="title">{{ $t("checkout-date") }}:</span>
                <span class="time">{{
                    item.CheckOutDate | formatDateTime
                  }}</span>
              </div>
              <div class="action">
                <span class="title">{{ $t("active-date") }}:</span>
                <span v-if="item.UsedRedeemCodeDate" class="time">{{
                    item.UsedRedeemCodeDate | formatDateTime
                  }}</span>
                <span v-else class="time">{{ $t("not-active") }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="!orderPreItems.length && !orderItems.length" class="row">
        <div class="col-md-12 empty-data">
          <span>{{ $t("no-package") }}</span>
        </div>
      </div>
    </div>
    <div v-else class="container position-relative mt-4">
      <BaseLoading v-if="isLoading" class="loading" :loading="isLoading" />
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@apis/repositoryFactory";
const ShopRepository = RepositoryFactory.get("shop");
import moment from "moment";
import { copyToClipboard } from "@/core/utils";
import BaseLoading from "@components/Inputs/BaseLoading";
import Swal from "sweetalert2";
import {NOTIFICATION_ICON} from "@/constants";
import Countdown from '@/containers/Shop/pre-order/countdown'
export default {
  components: {
    BaseLoading,
    Countdown
  },
  filters: {
    translate (value) {
      switch (value) {
        case "APPROVED":
          return "/img/approved.png";
        case "PENDING":
          return "/img/pending.png";
        case "USED":
          return "/img/used.png";
        case "PROCESSING":
          return "/img/processing.png";
        case "REJECTED":
          return "/img/reject.png";
        case "DELETED":
          return "/img/deleted.png";
        default:
          return "";
      }
    }
  },
  data() {
    return {
      isLoading: false,
      orderItems: [],
      orderPreItems: [],
      data: [],
      keyActive: "Affiliate",
      carts: [],
      numberCart: 0,
      finish: false
    };
  },
  async mounted() {
    try {
      if (
        this.$store.state.Auth.currentUser &&
        Object.keys(this.$store.state.Auth.currentUser).length !== 0
      ) {
        this.isLoading = true
        this.orderItems = await ShopRepository.getMyPackageHistory()
        this.orderPreItems = await ShopRepository.getPackagesPreOrder()
        this.isLoading = false
      }
    } catch (error) {}
  },
  methods: {
    paymentExpire() {
      console.log(this.finish)
      // this.finish = true
    },
    async payments (item) {
      try {
        Swal.fire({
          title: this.$t("check-out"),
          text: this.$t('pre-order.payment', [this.$options.filters.toCurrency(item.RemainingAmount, false)]),
          icon: 'info',
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonText: this.$t('action.cancel'),
          confirmButtonText: this.$t('borrow.payment'),
          customClass: {
            title: 'ticket'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.submit(item.Id)
          }
        });
      } catch (error) {
      }
    },
    async getDatePreOrder () {
      try {
        this.orderPreItems = await ShopRepository.getPackagesPreOrder()
      } catch (e) {
        console.log(e)
      }
    },
    async submit (params) {
      try {
        await ShopRepository.paymentPreOrder(params)
        this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
          this.$t('swal.title.success'),
          this.$t('pre-order.success'),
          {
            action1Text: 'OK',
            action1Func: this.getDatePreOrder
          },
          'dashboard'
        )
      } catch (error) {
        const res = JSON.parse(error.request.response)
        this.$notifyPopup(NOTIFICATION_ICON.ERROR,
          this.$t('swal.title.fail'),
          res.message,
          {
            action1Text: 'OK',
            action1Func: 'OK'
          },
          'dashboard'
        )
      }
    },
    async copyLink(contents) {
      await copyToClipboard(contents);
      await this.$alertMsg("success", this.$t("action.copied-text"));
    },
    checkImage(status) {
      switch (status) {
        case "APPROVED":
          return "/img/approved.png";
        case "PENDING":
          return "/img/pending.png";
        case "USED":
          return "/img/used.png";
        case "PROCESSING":
          return "/img/processing.png";
        case "REJECTED":
          return "/img/reject.png";
        case "DELETED":
          return "/img/deleted.png";
        default:
          return "";
      }
    },
    checkClass(status) {
      switch (status) {
        case "DEPOSITED":
          return "deposited";
        case "REFUNDED":
          return "refunded";
        case "WON_PRIZE":
          return "won_prize";
        case "PAID":
          return "paid";
        case "NO_SHOW":
          return "no_show";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.order-history {
  &__header {
    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #43464e;
      margin: 24px 0;
      display: inline-block;
    }
  }
  &__content {
    margin-bottom: 40px;
    .empty-data {
      height: 315px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-weight: 700;
        font-size: 44px;
        line-height: 50px;
        text-align: left;
        color: #141822;
      }
    }
    @media only screen and (max-width: 992px) {
      margin: 20px;
    }
  }
  .item-shop {
    background: #f7f7f7;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 24px;
    height: 97%;
    position: relative;
    .img-shop {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-top: 68%;
      margin-bottom: 10px;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .information {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #141822;
        white-space: nowrap;
        max-width: 155px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .name-pre-order {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #141822;
      }
      .money {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #dbac35;
      }
    }
    .action {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #43464e;
        @media only screen and (min-width: 1199px) and (max-width: 1400px) {
          font-size: 12px;
        }
      }
      .time {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #43464e;
        @media only screen and (min-width: 1199px) and (max-width: 1400px) {
          font-size: 12px;
        }
        a {
          color: #43464e;
          display: inline-block;
          &:hover {
            color: #1d62f0;
          }
          .fa-copy {
            margin-left: 5px;
          }
        }
      }
      .buy {
        width: 100%;
        height: 40px;
        background: #007BFF;
        border: 1px solid #007BFF;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          color: #FFFFFF;
          white-space: nowrap;
          padding-top: 3px;
        }
        &:hover {
          background: #007BFF;
          span {
            color: #FFFFFF;
          }
        }
      }
      .order {
        width: 100%;
        height: 40px;
        pointer-events: none;
        background: #ADAEB2;
        border: 1px solid #ADAEB2;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          color: #FFFFFF;
          white-space: nowrap;
          padding-top: 3px;
        }
        &.won-prize {
          pointer-events: auto !important;
          background: #007BFF;
          border: 1px solid #007BFF;
          &:hover {
            cursor: pointer;
          }
        }
        &.finish {
          pointer-events: none !important;
          background: #ADAEB2;
          border: 1px solid #ADAEB2;
        }
      }
    }
    .approved {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      z-index: 3;
      &.deposited, &.refunded, &.won_prize, &.no_show,  &.paid {
        border-radius: 8px 0;
        width: 136px;
        text-align: center;
        color: #ffffff;
      }
      &.deposited {
        background: #E89339;
      }
      &.refunded {
        background: #BA1E1E;
      }
      &.won_prize {
        background: #508558;
      }
      &.no_show {
        background: #898B91;
      }
      &.paid {
        background: #007BFF;
      }
    }
  }
  @media (min-width: 1600px) {
    .col-xxl-3 {
      -webkit-box-flex: 0;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}
</style>
