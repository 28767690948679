<template>
  <div class="wallet-detail">
    <div class="wallet-detail__title">
      <h2 class="text-white">{{ $t("wallet.wallet-detail") }}</h2>
    </div>
    <div class="wallet-detail__table">
      <b-card>
        <Breadcrumb link="/wallet" :label="$t('wallet.back-wallet')" />
        <div class="wallet-detail__table--info">
          <div class="info-left">
            <img src="/assets/img/icon-wallet/tron.png" alt="" />
            <div class="amount">
              <span class="token-name">Meta Tron </span>
            </div>
          </div>
          <div class="info-right">
            <div class="address">
              <p>{{ $t("wallet.address") }}</p>
              <a @click="onClickCopy">
                <BaseInput
                  :tooltip="$t('copy-link-tron')"
                  disabled
                  :value="address"
                  addonRightIcon="icon-icon-copy"
                  class="link-ref"
                />
              </a>
            </div>
            <img id="qrimg" :src="qrCode" size="109" level="M" />
          </div>
        </div>
        <p class="wallet-detail__table--title mt-3">
          {{ $t("history.transaction") }}
        </p>
        <div class="list">
          <table>
            <thead>
              <tr>
                <th>{{ $t("fields.DateTime") }}</th>
                <th>{{ $t("wallet.from-address") }}</th>
                <th>{{ $t("fields.Balance") }}</th>
                <th>{{ $t("wallet.to-address") }}</th>
                <th>{{ $t("wallet.status") }}</th>
                <th>{{ $t("wallet.message") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="index">
                <td>{{ item.DateTime | formatDateTime}}</td>
                <td>{{ item.FromAddress }}</td>
                <td>{{ item.Amount | toCurrency }}</td>
                <td>{{ item.ToAddress }}</td>
                <td>{{ item.Success }}</td>
                <td>{{ item.Reason }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <BaseLoading v-if="isLoading" class="loading" :loading="isLoading" />
        <div v-else-if="!data.length" class="empty-table">
          <img src="/img/empty-folder.png" alt="img empty" />
          <span>No data</span>
        </div>
      </b-card>
    </div>
    <ModalTransferTron />
  </div>
</template>

<script>
import { RepositoryFactory } from "@apis/repositoryFactory";
const WalletRepository = RepositoryFactory.get("wallet");
import moment from "moment";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import Breadcrumb from "./breadcrumb.vue";
import globalMixin from "@/core/mixins/global";
import BaseLoading from "@components/Inputs/BaseLoading";
import ModalTransferTron from "@/containers/Wallet/modal-transfer-home.vue";
import { MODAL } from "@/constants";

export default {
  components: {
    BaseInput,
    Breadcrumb,
    BaseLoading,
    ModalTransferTron,
  },
  mixins: [globalMixin],

  data() {
    return {
      data: [],
      qrCode: "",
      address: "",
    };
  },
  mounted() {},
  computed: {},
  async created() {
    this.fetchTronWallet();
    this.fetchLogTronWallet();
  },
  methods: {
    onClickCopy() {
      navigator.clipboard.writeText(this.address);
      this.$alertMsg("success", this.$t("action.copied-text"));
    },
    async fetchTronWallet() {
      try {
        this.$_loading();
        const result = await WalletRepository.getTronWallet();
        this.$_load();
        this.qrCode = result.QrCode;
        this.address = result.Address;
      } catch (error) {}
    },
    async fetchLogTronWallet() {
      try {
        this.$_loading();
        const result = await WalletRepository.getLogTronWallet();

        this.$_load();
        this.data = result.Result;
      } catch (error) {}
    },
    onTransfer() {
      this.$bvModal.show(MODAL.TRANSFER_HOME);
    },
  },
};
</script>

<style scoped lang="scss">
.wallet-detail {
  margin: 24px;
  @media only screen and (max-width: 500px) {
    margin: 16px;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    h2 {
      color: #141822;
      font-family: "Overpass";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 12px;
    }
    .btn {
      width: 240px;
      height: 40px;
    }
    @media only screen and (max-width: 980px) {
      flex-direction: column;
    }
    @media only screen and (max-width: 830px) {
      flex-direction: column;
      .wallet-detail__action {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .btn {
          width: 100%;
          &:first-child {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  &__btn-withdraw {
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;
    margin-right: 16px;
    &:hover {
      background: #007bff;
      color: #ffffff;
    }
  }
  &__table {
    .card {
      min-height: 70vh;
      .list {
        overflow-x: auto;
        overflow-y: hidden;
      }
    }
    &--title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #43464e;
    }
    &--info {
      display: flex;
      justify-content: space-between;
      padding-bottom: 28px;
      border-bottom: solid 1px #eaeaea;
      .info-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          width: 120px;
        }
        .amount {
          display: flex;
          flex-direction: column;
          margin-left: 16px;
          .token-name {
            font-weight: 700;
            font-size: 32px;
            line-height: 44px;
            color: #898b91;
            .btn-certificate {
              padding: 9px 24px;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #007bff;
              background: rgba(0, 123, 255, 0.2);
              border-radius: 8px;
              cursor: pointer;
            }
          }
          .token-value {
            font-weight: 700;
            font-size: 24px;
            line-height: 34px;
            display: flex;
            align-items: center;
            color: #141822;
          }
        }
      }
      .info-right {
        img {
          width: 120px;
          border: 1px solid #adaeb2;
          border-radius: 8px;
        }
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .form-group {
          margin-bottom: 6px;
        }
        .address {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-right: 30px;
          p {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #43464e;
          }
          /deep/ input {
            width: auto;
          }
          .link-ref {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      @media only screen and (max-width: 1160px) {
        flex-direction: column;
        justify-content: flex-start;
        .info-left {
          margin-bottom: 20px;
          justify-content: center;
        }
        .info-right {
          justify-content: center;
        }
      }
      @media only screen and (max-width: 500px) {
        flex-direction: column;
        .info-left {
          img {
            width: 70px;
          }
          .amount {
            .token-name {
              font-size: 20px;
              line-height: 32px;
            }
            .token-value {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
        .info-right {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .address {
            align-items: center;
            margin-bottom: 1rem;
          }
        }
      }
    }
    table {
      width: 100%;
      thead {
        th {
          padding: 10px;
          background: rgba(173, 174, 178, 0.2);
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #898b91;
          vertical-align: middle;
          white-space: nowrap;
        }
      }
      td {
        padding: 15px 10px;
        border-bottom: 1px solid #eaeaea;
        vertical-align: middle;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #141822;
        max-width: 250px;
        &.in-progress {
          color: #67b231;
          text-transform: capitalize;
        }
        &.active-date,
        &.status {
          text-transform: capitalize;
        }
      }
    }
    .empty-table {
      margin-top: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex-direction: column;
      span {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #adaeb2;
      }
    }
    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 200px;
      flex-direction: column;
    }
  }
}
</style>
