<template>
  <div class="list-request">
    <div class="list-request__header d-flex justify-content-between mb-2 flex-column flex-md-row">
      <p class="font-weight-bold">{{ $t('link-kyc.request') }}:</p>
      <p v-if="userType == LINK_KYC_TYPE.APPROVED">
        <span>
          <img class="icon_button__note" src="/assets/img/icon/tick.svg" alt="">
          <span class="ml-2">{{ $t('action.accept') }}</span>
        </span>
        <span class="ml-3">
          <img class="icon_button__note" src="/assets/img/icon/x.svg" alt="">
          <span class="ml-2">{{ $t('action.reject') }}</span>
        </span>
      </p>
      <button v-else class="btn btn-info btn-icon d-flex align-content-center" @click="showModalRequest">
        <i class="fa fa-brands fa-telegram fa-2x text-white"></i>
        <span class="text ml-1">{{ $t('action.request') }}</span>
      </button>
    </div>
    <b-table responsive bordered outlined hover  :items="items" :fields="fields" :busy.sync="isLoading" show-empty>
      <template #empty>
        <p class="text-center mb-0">{{ $t('no-data') }}</p>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <BaseLoading v-if="isLoading" class="loading" :loading="isLoading" />
        </div>
      </template>
      <template #cell(Reason)="row">
        <p class="text-elipssis" @click.prevent="loadMoreMessage(row.item.Reason)">{{ row.item.Reason }}</p>
      </template>
      <template #cell(Actions)="row">
        <p class="d-flex justify-content-around"
          v-if="userType == LINK_KYC_TYPE.APPROVED && row.item.Status == 'REVIEW'">
          <span class="pointer" @click="handleAction(row.item, actions.APPROVED)">
            <img class="icon_button" src="/assets/img/icon/tick.svg" alt="">
          </span>
          <span class="pointer" @click="handleAction(row.item, actions.REJECT)">
            <img class="icon_button" src="/assets/img/icon/x.svg" alt="">
          </span>
        </p>
        <template v-if="userType == LINK_KYC_TYPE.LINKING && row.item.Status == 'REJECTED'">
        <button class="btn btn-outline-primary mr-2" @click="resend(row.item)">
          {{ $t('link-kyc.re-send') }}
        </button>
        <button class="btn btn-primary" @click="rejectMessage(row.item)">
          {{ $t('fields.Reason') }}
        </button>
      </template>
      </template>
    </b-table>
    <modal-request ref="vlinkId" :vlinkId="vlinkId" :data="data" @refresh="refresh" />
    <BaseModal :config="config">
      <template slot="content">
        <ModalPinSecurity @submit="finalConfirmWithPin" :isShowReason="action === actions.REJECT" />
      </template>
    </BaseModal>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { MODAL, NOTIFICATION_ICON } from "@/constants"
import { RepositoryFactory } from "@apis/repositoryFactory";
const KYCRepository = RepositoryFactory.get('kyc')
import ModalPinSecurity from "./ModalPinSecurity.vue";
import { LINK_KYC_TYPE } from '@/constants'
import BaseLoading from "@components/Inputs/BaseLoading";

export default {
  components: {
    BaseLoading,
    ModalPinSecurity,
    modalRequest: () => import("./modal-request.vue")
  },
  props: {
    userType: {
      type: String,
      default: LINK_KYC_TYPE.UNAVAILABLE
    },
    LinkKYCRemain: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      LINK_KYC_TYPE,
      config: {
        id: MODAL.PIN_VERIFICATION,
        class: "modal--pin-security-confirmation",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light"
      },
      action: '',
      actions: {
        REJECT: "reject",
        APPROVED: "approved"
      },
      vlinkId: '',
      isLinkingUser: false,
      isLoading: false,
      data: {},
      items: []
    }
  },
  created() {
    this.vlinkId = this.$route.query.vlinkId
  },
  computed: {
    fields() {
      return [
        {
          key: this.userType == LINK_KYC_TYPE.APPROVED ? "LinkingUsername" : "SponsorUserName",
          label: this.$t('fields.Username'),
        },
        {
          key: "CreateAt",
          label: this.$t("fields.DateTime"),
          formatter: (value) => this.$options.filters.formatDateTime(value),
        },
        {
          key: "Message",
          label: this.$t("fields.Reason"),
        },
        {
          key: "Status",
          label: this.$t("fields.Status"),
        },
        {
          key: "Actions",
          label: this.$t("fields.Actions"),
        },
      ]
    }
  },
  watch: {
    userType(val) {
      this.fetchData(val)
    }
  },
  async mounted() {
    try {
      setTimeout(() => {
        if (this.vlinkId && this.userType == LINK_KYC_TYPE.LINKING) {
          this.$bvModal.show(MODAL.REQUEST_LINKKYC);
        }
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    rejectMessage (item) {
      const descHtml = `<div class="transaction-sucess_detail">
            <p class="d-flex justify-content-between">
              <span class="text-note text-nowrap">${this.$t('fields.Username')}: </span>
              <span class="text-note-2 text-nowrap text-address">${item.SponsorUserName}</span>
            </p>

            <p class="d-flex justify-content-between">
              <span class="text-note text-nowrap">${this.$t("fields.DateTime")}:</span>
              <span class="text-note-2">${this.$options.filters.formatDateTime(item.CreateAt)}</span>
            </p>
            <p class="d-flex justify-content-between mb-0">
              <span class="text-note text-nowrap">${this.$t("link-kyc.reason")}:</span>
              <span
              style="text-align: right;"
                class="text-note-2">${item.RejectReason}</span>
            </p>
          </div>`;
          this.$notifyPopup(
            NOTIFICATION_ICON.ERROR,
            this.$t('fields.Reason'),
            "",
            {
              action1Text: this.$t("transfer.close"),
              action1Func: () => ({}),
            },
            "light-theme",
            descHtml,
            "px-0",
            "light"
          );

    },
    refresh () {
      this.fetchData(this.userType)
    },
    async fetchData(val) {
      try {
        this.isLoading = true;
        let data = []
        if (val == LINK_KYC_TYPE.APPROVED) {
          data = await KYCRepository.getMyLinkRequest()
        } else {
          data = await KYCRepository.getMyLinked()
        }
        this.items = data
      } catch (error) {

      } finally {
        this.isLoading = false;
      }
    },
    resend (item) {
      this.vlinkId = 'item'
      this.$refs.vlinkId.trigger(item)
      this.$bvModal.show(MODAL.REQUEST_LINKKYC)
    },
    setData (item) {
      this.data = item
      this.$bvModal.show(MODAL.REQUEST_LINKKYC);
    },
    async finalConfirmWithPin(e) {
      try {
        const payload = {
          LinkKYCId: this.itemSelected.LinkKYCId,
          Pin: e.pin,
          Reason: e.reason,
        }
        if (this.action == this.actions.APPROVED) {
          const data = await KYCRepository.approveLinkKyc(payload)
          this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
            this.$t("swal.title.success"),
            this.$t("link-kyc.success"),
            {
              action1Text: 'OK',
              action1Func: () => ({})
            },
            'dashboard'
          )
        }
        if (this.action == this.actions.REJECT) {
          const data = await KYCRepository.rejectLinkKyc(payload)
          this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
            this.$t("swal.title.success"),
            this.$t("link-kyc.reject"),
            {
              action1Text: 'OK',
              action1Func: () => ({})
            },
            'dashboard'
          )
        }
        this.$emit('fetchTree')
        this.fetchData(this.userType)
      } catch (error) {
        const res = JSON.parse(error.request.response)
        this.$notifyPopup(NOTIFICATION_ICON.ERROR,
          '',
          res.message + '',
          {
            action1Text: 'OK',
            action1Func: () => ({})
          },
          'dashboard'
        )
        return false
      } finally {
        this.$bvModal.hide(MODAL.PIN_VERIFICATION);
      }
    },

    loadMoreMessage(text) {
      Swal.fire({ text, showCloseButton: true, showConfirmButton: false })
    },
    handleAction(item, action) {
      this.itemSelected = item
      this.action = action
      this.$bvModal.show(MODAL.PIN_VERIFICATION);
    },
    showModalRequest() {
      this.$refs.vlinkId.trigger(false)
      this.$bvModal.show(MODAL.REQUEST_LINKKYC);
    }
  }
}
</script>

<style scoped lang="scss">
.icon_button {
  width: 35px;

  &__note {
    width: 25px;
  }
}

.btn-icon {
  span.text {
    line-height: 32px;
  }
}
</style>