<template>
  <div class="convert">
    <div class="convert__title">{{ $t("borrow.payment") }}</div>
    <div class="convert__content">
      <BaseInputCustom
        class-name="theme-light"
        ref="code"
        v-model="code"
        type="text"
        name="code"
        required
        :label="$t('borrow.codepay')"
      />
      <BaseSelect
        ref="type"
        :input-value="type"
        name="type"
        :label="$t('borrow.fromAmount')"
        :items="WALLET_TRANSFER"
        text-field="label"
        value-field="value"
        :is-show-default="false"
        required
        key-loop="index"
        @change="type = $event"
        class-name="theme-light"
      />
    </div>
    <div class="convert__action">
      <button
        class="btn btn-primary btn-round convert__btn-cancel"
        @click="$emit('cancel')"
      >
        {{ $t("action.cancel") }}
      </button>
      <button
        class="btn btn-primary"
        @click="submit"
        :class="{ 'button--loading': loading }"
      >
        <span>{{ $t("borrow.payment") }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { NOTIFICATION_ICON } from "@/constants";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import validateMixin from "@/core/mixins/validate";
import { RepositoryFactory } from "@apis/repositoryFactory";
const lendingRepository = RepositoryFactory.get("lending");
import { mapGetters } from "vuex";
import { WALLET_TRANSFER } from "@/constants";
import BaseSelect from "@/components/Inputs/BaseSelect.vue";

export default {
  components: {
    BaseInputCustom,
    BaseSelect,
  },
  mixins: [validateMixin],
  data() {
    return {
      code: "",
      loading: false,
      WALLET_TRANSFER,
      type: "",
    };
  },
  mounted() {},
  methods: {
    async submit() {
      const refs = ["code", "type"];
      const refsValid = this.$_validateMixin_refs(refs);
      if (refsValid) {
        this.loading = true;
        try {
          const WalletType = this.type;
          const PaymentCode = this.code;
          const response = await lendingRepository.refundLendingOther({
            PaymentCode,
            WalletType,
          });
          if (response) {
            this.$notifyPopup(
              NOTIFICATION_ICON.SUCCESS,
              this.$t("swal.title.success"),
              this.$t("borrow.paysuccess", [""]),
              {
                action1Text: "OK",
                action1Func: () => {
                  this.$emit("onRefresh");
                },
              }
            );
          } else {
            this.$notifyPopup(
              NOTIFICATION_ICON.ERROR,
              this.$t("swal.title.fail"),
              response.message,
              {
                action1Text: "OK",
                action1Func: () => {},
                action2Text: this.$t("action.try"),
                action2Func: () => {},
              }
            );
          }
        } catch (error) {
          this.$notifyPopup(
            NOTIFICATION_ICON.ERROR,
            this.$t("swal.title.fail"),
            error.response.data.message || "Error",
            {
              action1Text: this.$t("action.try"),
              action1Func: () => {},
            }
          );
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.convert {
  &__title {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #141822;
    margin-bottom: 32px;
  }
  &__password {
    margin-top: 15px;
  }
  &__btn {
    height: 40px;
  }
  &__action {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
  }
  &__btn-cancel {
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;
    &:hover {
      background: #007bff;
      color: #ffffff;
    }
  }
  .text-note {
    font-size: 14px;
    line-height: 20px;
    color: #898b91;
  }
}
::v-deep .eye {
  color: #43464e !important;
}
::v-deep .base-select {
  margin-bottom: 20px;
  &__label {
    color: #43464e;
  }
}
::v-deep .base-select__wrap {
  background: #f7f7f7;
  .arrow-down {
    color: #43464e;
  }
}
::v-deep .base-select__inner {
  p {
    color: #898b91;
  }
}
</style>
