<template>
  <BaseModal :config="config">
    <template slot="content">
      <ModalContent @cancel="cancel" :item="item" @onRefresh="$emit('onRefresh')"/>
    </template>
  </BaseModal>
</template>

<script>
import ModalContent from "./ModalContent/payment-detail.vue";
import { MODAL } from "@/constants";

export default {
  components: {
    ModalContent,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      config: {
        id: MODAL.REPAY_DETAIL,
        class: "modal--convert-to-cash",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light",
      },
    };
  },
  methods: {
    cancel() {
      this.$bvModal.hide(this.config.id);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
