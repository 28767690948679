<template>
  <BaseModal :config="config" @hidden="cancel">
    <template slot="content">
      <div class="create-account__title">
        {{ $t("action.create-new-account") }}
      </div>
      <div class="create-account__content">
        <BaseInputCustom
          class-name="theme-light"
          ref="firstName"
          v-model="formData.firstName"
          type="text"
          name="firstName"
          required
          :label="$t('users.infor.first-name')"
          error-min-length="Your firstname must be minimum 6 characters"
        />
        <BaseInputCustom
          class-name="theme-light"
          ref="lastName"
          v-model="formData.lastName"
          type="text"
          name="lastName"
          required
          :label="$t('users.infor.last-name')"
          error-min-length="Your last name or Username must be minimum 6 characters"
        />
        <BaseInputCustom
          class-name="theme-light"
          ref="username"
          v-model="formData.username"
          type="text"
          name="username"
          required
          :label="$t('users.infor.username')"
          username-validate
        />
        <BaseInputCustom
          class-name="theme-light"
          ref="email"
          v-model="formData.email"
          type="email"
          name="email"
          required
          :label="$t('users.infor.email')"
          :min-length="6"
          error-min-length="Your email or Username must be minimum 6 characters"
        />
        <BaseSelect
          ref="type"
          :input-value="formData.Type"
          name="type"
          :label="$t('users.infor.account-type')"
          :items="Types"
          text-field="label"
          value-field="value"
          :is-show-default="false"
          required
          key-loop="index"
          class="theme-light"
          @change="formData.Type = $event"
        />
        <BaseInputCustom
          class-name="theme-light"
          ref="password"
          v-model="formData.password"
          type="password"
          name="password"
          required
          :label="$t('users.infor.password')"
          :max-length="50"
          :min-length="6"
          :limit-input="50"
        />
      </div>
      <div class="create-account__action">
        <button
          class="btn btn-primary btn-round create-account__btn-cancel"
          @click="cancel"
        >
          {{ $t("action.cancel") }}
        </button>
        <button
          class="btn btn-primary create-account__btn-create"
          :class="{ 'button--loading': loading }"
          @click="submit"
        >
          <span>{{ $t("forms.submit") }}</span>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { MODAL, NOTIFICATION_ICON } from "@/constants";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import validateMixin from "@/core/mixins/validate";
import BaseSelect from "@/components/Inputs/BaseSelect.vue";
import { RepositoryFactory } from "@apis/repositoryFactory";
const NetworkRepository = RepositoryFactory.get("network");

export default {
  name: "ModalCreateAccount",
  components: {
    BaseInputCustom,
    BaseSelect,
  },
  mixins: [validateMixin],
  data() {
    return {
      config: {
        id: MODAL.CREATE_ACCOUNT,
        class: "modal--create-account",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light",
      },
      formData: {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        Type: "AFFILIATE",
        password: "",
      },
      isValid: false,
      loading: false,
      Types: [
        {
          label: "AFFILIATE",
          value: "AFFILIATE",
        },
        {
          label: "CUSTOMER",
          value: "CUSTOMER",
        },
        {
          label: "BUSINESS",
          value: "BUSINESS",
        },
      ],
    };
  },
  computed: {
    isFullFill() {
      return (
        !Object.values(this.formData).some((x) => x === null || x === "") ||
        this.isValid
      );
    },
  },
  methods: {
    cancel() {
      (this.formData = {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        Type: "AFFILIATE",
        password: "",
      }),
        this.$bvModal.hide(this.config.id);
    },
    async submit() {
      const refs = [
        "username",
        "password",
        "email",
        "firstName",
        "lastName",
        "Type",
      ];
      const refsValid = this.$_validateMixin_refs(refs);
      if (refsValid) {
        try {
          this.loading = true;
          const response = await NetworkRepository.createMember(this.formData);
          if (response && Object.keys(response).length) {
            this.$notifyPopup(
              NOTIFICATION_ICON.SUCCESS,
              this.$t("swal.title.congratulations"),
              this.$t("swal.title.create-member"),
              {
                action1Text: "OK",
                action1Func: () => {
                  this.cancel();
                },
              }
            );
            this.$emit("created");
          } else {
            this.$alertMsg("fail", response.message);
            this.$notifyPopup(
              NOTIFICATION_ICON.ERROR,
              "Failure",
              response.message,
              {
                action1Text: "OK",
                action1Func: () => {},
                action2Text: this.$t("action.try"),
                action2Func: () => {},
              }
            );
          }
        } catch (error) {
          this.$notifyPopup(
            NOTIFICATION_ICON.ERROR,
            "Failure",
            error.response.data.message,
            {
              action1Text: this.$t("action.try"),
              action1Func: () => {},
            }
          );
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.create-account {
  width: 568px;
  color: #ffffff;
  margin-top: 32px;
  &__title {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #141822;
  }
  &__password {
    margin-top: 15px;
  }
  &__btn {
    height: 40px;
  }
  &__action {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
  }
  &__btn-cancel {
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;
    &:hover {
      background: #007bff;
      color: #ffffff;
    }
  }
}
::v-deep .eye {
  color: #43464e !important;
}
::v-deep .base-select {
  margin-bottom: 20px;
  &__label {
    color: #43464e;
  }
}
::v-deep .base-select__wrap {
  background: #f7f7f7;
  .arrow-down {
    color: #43464e;
  }
}
::v-deep .base-select__inner {
  p {
    color: #898b91;
  }
}
</style>
