<template>
  <BaseModal :config="config" @hidden="resetForm" @show="this.getGoogleAuthCode">
    <template #content>
      <div  class="google-auth">
        <div class="google-auth__title">
          {{$t('security.enable-google')}}
        </div>
        <div class="google-auth__step ">
          <StepProgress :steps="steps"
                        :current-step="currentStep"
                        :active-thickness="1"
                        :passive-thickness="1"
                        :line-thickness="2"
                        active-color="blue"
          />
        </div>
        <template v-if="currentStep === 0">
          <div class="step-title">{{$t('google.step-1.title')}}</div>
          <div class="d-flex align-items-center justify-content-center">
            <div class="device mr-5">
              <div class="d-flex justify-content-center">
                <img src="/img/security/ico-apple-store.svg">
              </div>
              <div class="mb-2"> {{$t('google.step-1.download-from')}}</div>
              <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank"> {{$t('google.step-1.apple-store')}}</a>
            </div>
            <div class="device">
              <div class="d-flex justify-content-center">
                <img src="/img/security/ico-google-play.svg">
              </div>
              <div class="mb-2"> {{$t('google.step-1.download-from')}}</div>
              <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank"> {{$t('google.step-1.google-play')}}</a>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button class="btn btn-primary mt-5 w-50 text-center" @click="next"> {{$t('action.next')}}</button>
          </div>
        </template>
        <template v-if="currentStep === 1">
          <div class="step-title">{{$t('google.step-2.title')}}</div>
          <div class="key mt-2">
            <div class="d-flex justify-content-center">
              <img :src="QrCodeSetupImageUrl" alt="qrcode" />
            </div>
            <div class="d-flex justify-content-center mt-3">
              <div class="manual-key">
              {{ManualEntryKey}}
              <i class="fa fa-copy"
                v-clipboard:copy="ManualEntryKey"
                v-clipboard:success="copied"
              />
            </div>
            </div>
            <div class="d-flex justify-content-center mt-2">
              <div class="description">{{$t('google.step-2.description')}}</div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button class="btn btn-primary mt-4 w-50 text-center" @click="next"> {{$t('action.next')}}</button>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <a class="text-center" href="javascript:void(0)" @click="previous"> {{$t('action.previous')}}</a>
          </div>
        </template>
        <template v-if="currentStep === 2">
          <div class="step-title">{{$t('google.step-3.title')}}</div>
          <div class="key">
            <div class="d-flex justify-content-center">
              <img src="/img/security/key.png" alt="key" />
            </div>
            <div class="d-flex justify-content-center">
              <div class="manual-key">
              {{ManualEntryKey}}
              <i class="fa fa-copy"
                 v-clipboard:copy="ManualEntryKey"
                 v-clipboard:success="copied"
              />
            </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="description">{{`${$t('google.step-3.subtitle')} ${$t('google.step-3.description')}` }}</div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button class="btn btn-primary mt-4 w-50 text-center" @click="next"> {{$t('action.next')}}</button>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <a class="text-center" href="javascript:void(0)" @click="previous"> {{$t('action.previous')}}</a>
          </div>
        </template>
        <template v-if="currentStep === 3">
        <div class="step-title">{{$t('google.step-4.title')}}</div>
        <div class="form d-flex justify-content-center mt-3">
          <BaseInputCustom
            class-name="theme-light"
            :class="$device.isDesktop ? 'w-50' : 'w-100'"
            ref="googleVerifyCode"
            :placeholder="$t('validate.verification-code', { 0: 6})"
            :min-length="6"
            :limit-input="6"
            v-model="googleVerifyCode"
            type="tel"
            name="googleVerifyCode"
            required
            :label="$t('google.step-4.code')"
            :error-min-length="$t('google.step-4.validate')"
          />
        </div>
        <div class="d-flex justify-content-center">
          <button class="btn btn-primary mt-4 w-50 text-center" @click="submit" :class="{'button--loading': loading}">
            <span>{{$t('action.submit')}}</span>
          </button>
        </div>
        <div class="d-flex justify-content-center mt-3">
            <a class="text-center" href="javascript:void(0)" @click="previous"> {{$t('action.previous')}}</a>
        </div>
      </template>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import {MODAL} from "@/constants";
import StepProgress from 'vue-step-progress';
import 'src/assets/sass/step-progress.scss';
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import { RepositoryFactory } from "@apis/repositoryFactory";
const AuthRepository = RepositoryFactory.get('auth')
import validateMixin from "@/core/mixins/validate";
import {NOTIFICATION_ICON} from "@/constants";

export default {
  name: "GoogleAuthModal",
  components: {
    StepProgress,
    BaseInputCustom
  },
  mixins: [validateMixin],
  computed: {
    steps() {
      return [
        this.$t("google.step-1.index"),
        this.$t("google.step-2.index"),
        this.$t("google.step-3.index"),
        this.$t("google.step-4.index")
      ]
    }
  },
  data() {
    return {
      currentStep: 0,
      config: {
        id: MODAL.GOOGLE_AUTHENTICATION,
        class: 'modal--google-auth',
        noCloseOnBackDrop: false,
        centered: true,
        theme: 'light',
        size: 'lg'
      },
      ManualEntryKey: '',
      QrCodeSetupImageUrl: null,
      googleVerifyCode: '',
      loading: false
    }
  },
  mounted() {
    this.getGoogleAuthCode()
  },
  methods: {
    next() {
      if (this.currentStep < this.steps.length) {
        this.currentStep++
      }
    },
    previous() {
      if (this.currentStep > 0) {
        this.currentStep--
      }
    },
    copied () {
      this.$alertMsg('success', this.$t('action.copied-text'))
    },
    async getGoogleAuthCode () {
      try {
        const data = await AuthRepository.googleAuth2FA()
        this.ManualEntryKey = data.ManualEntryKey
        this.QrCodeSetupImageUrl = data.QrCodeSetupImageUrl
      } catch (e) {
        console.error(e)
      }
    },
    resetForm() {
      this.ManualEntryKey = '',
      this.QrCodeSetupImageUrl = null,
      this.googleVerifyCode = ''
      this.currentStep = 0
    },
    async submit () {
      const refs = ['googleVerifyCode']
      const valid = this.$_validateMixin_refs(refs)
      if (valid) {
        this.loading = true
        try {
          await AuthRepository.googleAuth2FAConfirm(this.googleVerifyCode)
          this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
            this.$t('google.done.title'),
            this.$t('google.done.index'),
            {action1Func: "OK", action1Text: this.$t('google.done.back')}
          )
          this.$emit("activated")
          this.$bvModal.hide(MODAL.GOOGLE_AUTHENTICATION)
        } catch (e) {
          this.$alertMsg('fail', e.response.data.message)
        }
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.google-auth {
  font-family: 'Overpass';
  font-style: normal;
  padding-bottom: 32px;
  margin-top: 16px;
  &__title {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #141822;
    text-transform: capitalize;
  }
  &__step {
    &::v-deep .step-progress__wrapper {
      max-width: 500px;
    }
    @media screen and (max-width: 769px) {
      &::v-deep .step-progress__bar {
        margin-bottom: 8px;
      }
      &::v-deep .step-progress__step-label {
        display: none;
      }
    }
  }
  .step-title {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #141822;
  }
  .device {
    margin-top: 32px;
    img {
      width: 32px;
      height: 32px;
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
    }
    a {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #007BFF;
    }
  }
  .key {
    margin: 0 auto;
    img {
      width: 110px;
      height: 110px;
    }
    .description {
      text-align: center;
      max-width: 377px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .manual-key {
      max-width: 300px;
      position: relative;
      word-break: break-all;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #141822;
      i {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
}
</style>
