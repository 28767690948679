<template>
  <div class="lending">
    <div class="lending__title">{{ $t("borrow.loan") }}</div>
    <div class="lending__loan" v-show="step == 1">
      <BaseInputCustom
        class-name="theme-light"
        ref="amount"
        v-model="amount"
        type="text"
        name="amount"
        placeholder="0"
        required
        :label="$t('borrow.needloan')"
        thousands-separator-symbol=","
        :minValue="1000"
      />
      <BaseCheckbox v-model="isValid" :inline="true" class="lending__agree">
        <span class="label"
          >{{ $t("titles.transferOFStoVGLBlock") }}
          <router-link tag="a" to="/credit-lending" target="_blank">
            {{ $t("titles.loanCondition") }}
          </router-link>
        </span>
      </BaseCheckbox>
      <p v-if="showErrorValid" class="lending__text-condition">
        {{ $t("validate.isconfirm") }}
      </p>
    </div>
    <div class="lending__content" v-show="step == 2">
      <div class="lending__content--desc">
        <p>{{ $t("borrow.time") }}</p>
        <p>
          {{ $t("borrow.Interest") }}
          {{ $options.filters.toCurrency(monthlyInterest, true) }}
        </p>
        <p>
          {{ $t("borrow.original") }}
          {{ $options.filters.toCurrency(monthlyPrincipal, true) }}
        </p>
        <p class="total">
          {{ $t("borrow.total") }}
          {{ $options.filters.toCurrency(monthlyTotal, true) }}
        </p>
      </div>
      <BaseInputCustom
        class-name="theme-light"
        ref="pin"
        v-model="pin"
        name="pin"
        :isPin="true"
        :label="$t('input.pin_uppercase')"
      />

		<p class="text-note">
			{{ $t("stock.pin.first") }}
			<router-link tag="a" :to="'/profile/security?tab=pin'" target="_blank" style="color: #007bff; font-weight: bold">
				{{ $t("stock.pin.second") }}
			</router-link>
			{{ $t("stock.pin.third") }}
		</p>
    </div>
    <div class="lending__action">
      <button
        class="btn btn-primary btn-round lending__btn-cancel"
        @click="$emit('cancel')"
      >
        {{ $t("action.cancel") }}
      </button>
      <button
        v-if="step === 1"
        class="btn btn-block btn-primary lending__btn-create"
        @click="submit"
        :class="{ 'button--loading': loading }"
      >
        <span> {{ $t("action.confirm") }} </span>
      </button>
      <button
        v-else-if="step === 2"
        class="btn btn-primary lending__btn-create"
        @click="confirm"
        :class="{ 'button--loading': loading }"
      >
        <span> {{ $t("action.confirm") }} </span>
      </button>
    </div>
  </div>
</template>

<script>
import { NOTIFICATION_ICON } from "@/constants";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import validateMixin from "@/core/mixins/validate";
import { RepositoryFactory } from "@apis/repositoryFactory";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue";
const WalletRepository = RepositoryFactory.get("wallet");
const LendingRepository = RepositoryFactory.get("lending");

export default {
  components: {
    BaseInputCustom,
    BaseCheckbox,
  },
  mixins: [validateMixin],
  data() {
    return {
      amount: "",
      pin: "",
      step: 1,
      monthlyInterest: "",
      monthlyPrincipal: "",
      monthlyTotal: "",
      termBorrow: "",
      isValid: false,
      showErrorValid: false,
      loading: false,
    };
  },
  computed: {},
  created() {
    this.step = 1;
  },
  methods: {
    async submit() {
      const refs = ["amount"];
      const refsValid = this.$_validateMixin_refs(refs);
      if (!this.isValid) {
        this.showErrorValid = true;
        return;
      }
      if (refsValid) {
        this.loading = true;
        try {
          const response = await WalletRepository.createLending(this.amount);
          if (response && Object.keys(response).length) {
            this.step = 2;
            this.monthlyInterest = response.MonthlyInterest;
            this.monthlyPrincipal = response.MonthlyPrincipal;
            this.monthlyTotal = response.MonthlyTotal;
            this.termBorrow = response.TermBorrow;
          } else {
            this.$notifyPopup(
              NOTIFICATION_ICON.ERROR,
              this.$t("swal.title.fail"),
              response.message,
              {
                action1Text: "OK",
                action1Func: () => {},
                action2Text: this.$t("action.try"),
                action2Func: () => {},
              }
            );
          }
        } catch (error) {
          this.$notifyPopup(
            NOTIFICATION_ICON.ERROR,
            this.$t("swal.title.fail"),
            error.response.data.message,
            {
              action1Text: this.$t("action.try"),
              action1Func: () => {},
            }
          );
        }
        this.loading = false;
      }
    },
    async confirm() {
      const refs = ["pin"];
      const refsValid = this.$_validateMixin_refs(refs);
      if (refsValid) {
        this.loading = false;
        const payload = {
          Amount: this.$options.filters.formatValueCurrency(this.amount),
          Pin: this.pin,
        };
        try {
          const response = await LendingRepository.lending(payload);
          if (response === true) {
            this.$notifyPopup(
              NOTIFICATION_ICON.SUCCESS,
              this.$t("swal.title.success"),
              this.$t("borrow.success", [payload.Amount]),
              {
                action1Text: "OK",
                action1Func: () => {
                  this.$emit("onRefresh");
                },
              }
            );
          } else {
            this.$notifyPopup(
              NOTIFICATION_ICON.ERROR,
              this.$t("swal.title.fail"),
              response.message,
              {
                action1Text: "OK",
                action1Func: "OK",
                action2Text: this.$t("action.try"),
                action2Func: () => {},
              }
            );
          }
        } catch (error) {
          this.$notifyPopup(
            NOTIFICATION_ICON.ERROR,
            this.$t("swal.title.fail"),
            error.response.data.message,
            {
              action1Text: this.$t("action.try"),
              action1Func: () => {},
            }
          );
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.lending {
  &__title {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #141822;
  }
  &__btn {
    height: 40px;
  }
  &__action {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
  }
  &__btn-cancel {
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;
    &:hover {
      background: #007bff;
      color: #ffffff;
    }
  }
  &__agree {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    &::v-deep {
      .form-check-label {
        .form-check-sign::before {
          font-size: 24px;
          background: #f7f7f7;
          height: 24px;
          width: 24px;
          border-radius: 4px;
          margin-left: -35px;
          color: #f7f7f7;
        }
        .form-check-sign::after {
          content: "\2713";
          color: #007bff;
          height: 24px;
          width: 24px;
          font-size: 24px;
          margin-left: -35px;
          margin-top: -13px;
        }
      }
    }
    span {
      font-size: 14px;
      line-height: 20px;
      color: #43464e;
    }
  }
  &__content {
    &--desc {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #43464e;
    }
    .total {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #43464e;
    }
  }
  &__text-condition {
    color: #ea290e;
    font-size: 12px;
    margin-bottom: 12px;
  }
  &::v-deep .eye {
    color: #43464e !important;
  }
}
</style>
