<template>
	<div class="information">
		<div class="mb-3">
			<div class="affliate__header mb-4">
				<p class="title mb-1 h6">{{ $t("link-kyc.how") }}</p>
				<iframe width="100%" height="300" src="https://www.youtube-nocookie.com/embed/vgukogzJurg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>
			<div class="affliate__header mb-4">
				<p class="title mb-1 h6">{{ $t("link-kyc.username") }}</p>
				<a class="pointer text-decoration-none" @click="onClickCopy(currentUser.Username)">
					<BaseInput disabled :value="currentUser.Username" addonRightIcon="icon-icon-copy" class="link-ref" />
				</a>
			</div>
			<div class="affliate__header mb-4">
				<p class="title mb-1 h6">{{ $t("link-kyc.link") }}</p>
				<a class="pointer text-decoration-none" @click="onClickCopy(kycLink)">
					<BaseInput disabled :value="kycLink" addonRightIcon="icon-icon-copy" class="link-ref" />
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import helpers from "@/core/mixins/helpers";
import { homeUrl } from "@/constants/config";

export default {
	components: {
		BaseInput
	},
	mixins: [helpers],
	computed: {
		currentUser() {
      return this.$store.state.Auth.currentUser
    },
		kycLink () {
			return homeUrl + '/profile/link-kyc?vlinkId=' + this.currentUser.VLinkId
		}
	},
	methods: {
		onClickCopy(text) {
			navigator.clipboard.writeText(text);
			this.$alertMsg("success", this.$t("action.copied-text"));
		},
	},
}
</script>

<style>

</style>