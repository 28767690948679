<template>
  <div class="package-detail">
    <div class="package-detail__header">
      <p class="title">{{ $t('menu-items.shop') }}</p>
    </div>
    <div class="package-detail__content" v-if="dataItem && Object.keys(dataItem).length">
      <div class="back-page mt-3 mb-3">
        <img src="/img/arrow-back.svg" alt="arrow-back" class="arrow-back" @click="$router.push('/shop?tab=PreOrder')">
        <span> {{ $t('menu-items.back-to-pre-order')}}</span>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="img-top">
            <img :src="$apiImageUrl + dataItem.ImageDetailPath" alt="shop-default" >
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
          <span class="name">{{ dataItem.Name }}</span>
          <span class="number">
            ({{ $t('table.quantity') }} {{ dataItem.RemainingPackage }} {{ $t('pre-order.packages') }})
          </span>
          <p class="price"  style=" text-decoration: line-through; color: #E96058; font-size: 16px;">
            {{ $t('dashboards.price') }}
            {{(dataItem.VlgFullAmount/0.8)*12.75 | toCurrency(true, 0) }}</p>
          <p class="price">
            {{ $t('pre-order.sale') }} {{ (dataItem.VlgFullAmount * 12.75) | toCurrency(true, 0) }}
            <img
              src="/img/icon-reduce-detail.svg"
              alt="icon-info">
          </p>
          <div class="description">
            <div
              style="display: inline-block; padding: 8px 0;"
              v-html=" $t(`pre-order.meta.${dataItem.Name}.description`)"
            >
            </div>
          </div>
          <div class="quality" :class="{'sold-out': !dataItem.RemainingPackage}">
            <div class="d-flex justify-content-start align-items-center m-3">
              <span class="title mr-3">{{ $t('table.quantity') }}</span>
              <SumQuality
                :remaining="dataItem.RemainingPackage"
                :packageId="dataItem.Id"
                class="pre-order-sum"
                :key="dataItem.Id"
                @updateItem="updateValue"/>
            </div>
            <div class="action">
              <button class="buy-now" @click="orderNow(dataItem.Id)">
                <span>{{ $t('menu-items.subShop.pre-order') }}</span>
              </button>
              <span class="amount">
                {{ $t('pre-order.deposit') }} {{ dataItem.Price }} VLG
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div v-if="history && history.length" class="scrollable docs-merchant col-12 text-dark p-0 mt-3 mb-3">
            <table>
              <thead>
              <tr class="head">
                <th class="col-1">#</th>
                <th class="col-3">{{$t('user.username')}}</th>
                <th class="col-4">{{$t('deposited-date')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in history" :key="index">
                <td class="col-1">{{ index + 1 }}</td>
                <td class="col-3">{{item.Username}}</td>
                <td class="col-4">{{item.DateTime | formatDateTime}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="quality mobile" :class="{'sold-out': !dataItem.RemainingPackage}">
            <div class="d-flex justify-content-start align-items-center m-3 sum-quality-mb">
              <span class="title mr-3">{{ $t('table.quantity') }}</span>
              <SumQuality
                :remaining="dataItem.RemainingPackage"
                :packageId="dataItem.Id"
                class="pre-order-sum"
                :key="dataItem.Id"
                @updateItem="updateValue"/>
            </div>
            <div class="action" >
              <button class="buy-now" @click="orderNow(dataItem.Id)">
                <span>{{ $t('menu-items.subShop.pre-order') }}</span>
              </button>
              <span class="amount">
                {{ $t('pre-order.deposit') }} {{ dataItem.Price }} VLG
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SumQuality from '@/containers/Shop/pre-order/sum-quality.vue'
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import validateMixin from '@/core/mixins/validate'
import { RepositoryFactory } from "@apis/repositoryFactory";
import {MODAL, NOTIFICATION_ICON} from "@/constants";
const ShopRepository = RepositoryFactory.get('shop');
const CartRepository = RepositoryFactory.post('cart');
const NumberCartRepository = RepositoryFactory.get('cart');
import isEmpty from "lodash/isEmpty"
export default {
  name: "package-detail",
  components: {
    BaseInputCustom,
    SumQuality
  },
  data () {
    return {
      isValid: true,
      dataItem: {},
      quality: 1,
      history: [],
      pinCode: "",
      config: {
        id: MODAL.PIN_VERIFICATION,
        class: "modal--pin-security-confirmation",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light"
      },
      loading: false
    }
  },
  mixins: [validateMixin],
  async created() {
    const packageId = this.$route.query.PackageId
    if(packageId && packageId.length) {
      this.dataItem = await ShopRepository.get(packageId)
    }
  },
  async mounted () {
    const response = await ShopRepository.getUsersPackagesPreOrder()
    this.history = response[this.$route.query.PackageId]
  },
  methods: {
    updateValue (value) {
      this.quantity = value
    },
    orderNow (value) {
      if (isEmpty(this.$store.getters["Auth/currentUser"])) {
        this.$bvModal.show(MODAL.LOGIN)
        return
      }
      if (!this.quantity || this.quantity < 1) {
        this.$notifyPopup(NOTIFICATION_ICON.ERROR,
          this.$t('swal.title.fail'),
          this.$t('swal.min', [0]),
          {
            action1Text: 'OK',
            action1Func: 'OK'
          },
          'dashboard'
        )
        return
      }
      this.PackageId = value
      this.$bvModal.show(MODAL.PIN_VERIFICATION);
    },
    async finalConfirmWithPin(pinCode) {
      this.loading = true;
      this.pinCode = pinCode;
      const result = await this.onSubmit();
      this.loading = false;
      if (result) {
        this.$bvModal.hide(MODAL.PIN_VERIFICATION);
      }
    },
    closeModal () {
      window.open('/shop?tab=OrderHistory', '_self')
    },
    closePopup() {
      this.$bvModal.hide(MODAL.PIN_VERIFICATION);
    },
    async onSubmit() {
      try {
        const data = {
          PackageId: this.PackageId,
          Quantity: this.form.Quantity,
          Pin: this.pinCode
        };
        await ShopRepository.preOrder(data);
        this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
          this.$t("swal.title.congratulations"),
          this.$t("pre-order.success"),
          {
            action1Text: 'OK',
            action1Func: this.closeModal
          },
          'dashboard'
        )
        return true;
      } catch (error) {
        const res = JSON.parse(error.request.response)
        this.$notifyPopup(NOTIFICATION_ICON.ERROR,
          '',
          res.message + '',
          {
            action1Text: 'OK',
            action1Func: this.closePopup
          },
          'dashboard'
        )
        return false;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.package-detail {
  padding: 24px;
  @media only screen and (max-width: 768px) {
    padding: 16px;
    padding-bottom: 76px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      color: #141822;
      margin-bottom: 0;
    }
    padding-bottom: 26px;
    .redeemCode {
      margin-bottom: 0;
      /deep/.base-input-custom__wrap {
        label {
          display: none;
        }
        input {
          background: #FFFFFF;
          border: 1px solid #E8E8E9;
          border-radius: 8px 0 0 8px;
          color: #000000;
        }
      }
      /deep/.error {
        position: absolute;
      }
    }
    .btn-primary {
      border-radius: 0 8px 8px 0;
      height: 40px;
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 15px;
      .redeemCode {
        width: 66%;
      }
    }
  }
  &__content {
    background: #FFFFFF;
    padding: 16px 24px;
    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #EAEAEA;
      border-top: 1px solid #EAEAEA;
      span.icon-package {
        color: #898B91;
        white-space: nowrap;
      }
      span.last {
        margin-left: 10px;
        white-space: nowrap;
      }
    }
    .back-page {
      img {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .img-top {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F7F7F7;
      border-radius: 8px;
      position: relative;
      img {
        width: 100%;
      }
      margin-bottom: 30px;
    }
    .img-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      .item {
        padding: 13px 40px;
        background: #F7F7F7;
        border-radius: 8px;
        width: calc((100% - 48px) / 3);
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: auto;
          height: 95px;
          @media only screen and (max-width: 768px) {
            height: 54px;
          }
        }
      }
    }
    .name {
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      color: #141822;
      display: block;
      margin-bottom: 5px;
      @media only screen and (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    /*---------- star rating ----------*/
    .stars {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 30px;
      .star {
        color: #FFC107;
        font-size: 45px;
        line-height: 40px;
        position: relative;
        &:before {
          content: '★';
          color: #FFC107;
          font-size: 45px;
          line-height: 40px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .sum {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #898B91;
        display: inline-block;
        margin-left: 10px;
        margin-bottom: -10px;
      }
    }
    /*---------- star rating ----------*/
    .number {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #898B91;
      margin-bottom: 10px;
      display: inline-block;
    }
    .price {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      display: block;
      color: red;
      margin-bottom: 16px;
      @media only screen and (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    .description {
      padding: 16px 0;
      border-bottom: 1px solid #EAEAEA;
      border-top: 1px solid #EAEAEA;
      max-height: 150px;
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        background: #ADAEB2;
        border-radius: 8px;
        width: 4px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: #EAEAEA;
        border-radius: 8px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #ADAEB2;
        border-radius: 8px;
      }
      @media only screen and (max-width: 768px) {
        max-height: 100%;
      }
    }
    .quality {
      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: #141822;
        display: inline-block;
        margin: 16px 0;
      }
      .sum-quality {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #E8E8E9;
          height: 45px;
          .input-number {
            border: none;
            padding-left: 18px;
            width: 50px;
            &:focus, &:focus-visible, &:hover {
              border: none;
              outline: none;
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
          span {
            color: #141822;
            font-size: 20px;
            line-height: 20px;
            display: inline-block;
            padding: 10px 15px;
          }
          &:first-child, &:last-child {
            span {
              color: #ADAEB2;
              font-size: 20px;
              line-height: 20px;
              display: inline-block;
              padding: 10px;
            }
          }
          &:first-child {
            border: 1px solid #E8E8E9;
            border-radius: 8px 0px 0px 8px;
          }
          &:last-child {
            border: 1px solid #E8E8E9;
            border-radius: 0px 8px 8px 0px;
          }
          &.disable {
            pointer-events: none;
            opacity: 0.5;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
      .action {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .add-cart, .buy-now {
          width: 200px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          border-radius: 8px;
          border: 1px solid #007BFF;
          @media only screen and (max-width: 768px) {
            width: 125px;
          }
        }
        .add-cart {
          background: transparent;
          margin-right: 24px;
          margin-bottom: 10px;
          span {
            display: inline-block;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #007BFF;
            white-space: nowrap;
          }
          &:hover {
            cursor: pointer;
            background: #007BFF;
            span {
              color: #FFFFFF;
            }
          }
        }
        .buy-now {
          background: #007BFF;
          span {
            display: inline-block;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
            white-space: nowrap;
          }
          &:hover {
            cursor: pointer;
          }
        }
        .amount {
          white-space: nowrap;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          text-align: right;
          color: #898B91;
        }
      }
      @media only screen and (max-width: 768px) {
        .action {
          display: none;
        }
      }
      &.mobile {
        display: none;
        position: fixed;
        bottom: 56px;
        background: #ffffff;
        left: 0;
        right: 0;
        @media only screen and (max-width: 768px) {
          display: block;
          .sum-quality-mb {
            display: none !important;
          }
          .action {
            display: flex;
            justify-content: space-between;
            padding: 0 16px;
          }
        }
        .amount {
          font-size: 14px;
          line-height: 16px;
        }
      }
      &.sold-out {
        .sum-quality {
          pointer-events: none;
          opacity: 0.7;
        }
        .buy-now {
          pointer-events: none;
          background: #ADAEB2;
          border: 1px solid #ADAEB2;
        }
      }
    }
  }
  .img-icon {
    max-width: 50px;
    margin-right: 10px;
  }
  .module-border-wrap {
    position: relative;
    padding: 4px;
    border: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(270deg,#f1d47d,#b2ff59);
    .module {
      background: radial-gradient(circle,#245c92 0,#418fc6 50%,rgba(36,92,146,.54) 100%);
      color: #FFFFFF;
    }
  }
}
.price-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
      margin-right: 8px;
    }
  }
}
.scrollable {
  max-height: 600px;
  overflow-y: auto;
}
table {
  width: 100%;
  text-align: center;
  thead {
    th {
      padding: 9px;
      background: #BCC8DA;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #43464E;
      vertical-align: middle;
      white-space: nowrap;
    }
  }
  td {
    padding: 15px 10px;
    border-bottom: 1px solid #EAEAEA;
    vertical-align: middle;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #141822;
    white-space: nowrap;
  }
}
</style>
