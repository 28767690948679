<template>
  <div class="kyc">
    <template v-if="userType === LINK_KYC_TYPE.UNAVAILABLE">
      <div class="mt-3 kyc__title">{{ $t('link-kyc.unavailable') }}</div>
    </template>
    <!-- <div class="mt-3 kyc__title">{{ $t('kyc.list')}}</div> -->
    <div class="alert mt-3 alert-primary" role="alert" v-show="needKyc">
      <i class="fa fa-info text-primary" />
      {{$t('link-kyc.kyc-need')}} </div>
    <div v-if="userType === LINK_KYC_TYPE.APPROVED" class="text-right py-2 mb-2 text-primary font-italic font-weight-bold">
      {{ $t('link-kyc.available') }}: {{ linkKYCRemain }}
    </div>
    <div class="link-kyc__content row">
      <div class="link-kyc__content_status col-12"></div>
      <div class="link-kyc__content_left col-12 col-lg-4">
        <Infor v-if="userType === LINK_KYC_TYPE.APPROVED" :user-type="userType" />
      </div>
      <div class="link-kyc__content_right col-12 col-lg-8" v-show="userType !== LINK_KYC_TYPE.UNAVAILABLE">
        <Request @fetchTree="fetchTree" :user-type="userType" />
      </div>
      <div class="link-kyc__content_tree col-12" v-if="userType === LINK_KYC_TYPE.APPROVED">
        <Tree ref="treeChart" />
      </div>
    </div>
  </div>
</template>

<script>
import Request from '@/containers/Profile/LinkKYC/request.vue';
import Infor from '@/containers/Profile/LinkKYC/infor.vue';
import Tree from '@/containers/Profile/LinkKYC/tree.vue';
import { LINK_KYC_TYPE } from '@/constants'
import { RepositoryFactory } from "@apis/repositoryFactory";
const KYCRepository = RepositoryFactory.get('kyc')

export default {
  components: { Request, Infor, Tree },
  data() {
    return {
      data: {},
      LINK_KYC_TYPE,
      linkKYCRemain: 0,
      userType: LINK_KYC_TYPE.UNAVAILABLE
    }
  },
  methods: {
    async fetchTree() {
      this.$refs.treeChart.fetchTree()
      const data = await KYCRepository.checkStatusLinkKyc()
      this.linkKYCRemain = data.LinkKYCRemain
    }
  },
  computed: {
    needKyc () {
      return this.linkKYCRemain > 0 && this.userType == LINK_KYC_TYPE.LINKING && !this.$store.state.Auth.currentUser.IsKYC
    }
  },
  async created() {
    try {
      this.data = await KYCRepository.checkStatusLinkKyc()
      this.linkKYCRemain = this.data.LinkKYCRemain
      switch (true) {
        case this.data.IsAllowLinkingKYC:
          this.userType = LINK_KYC_TYPE.LINKING
          break;
        case this.data.IsAllowApprovingKYC:
          this.userType = LINK_KYC_TYPE.APPROVED
          break;
        default:
          this.userType = LINK_KYC_TYPE.UNAVAILABLE
          break;
      }
    } catch (error) {
    }
  }
}
</script>

<style>

</style>