<template>
  <gmap-map
    id="map"
    :center="center"
    :zoom="13"
    :options="options"
    map-type-id="terrain"
  >
    <gmap-marker :position="markLocation" v-if="markLocation" />
  </gmap-map>
</template>
<script>
  import { googleServicesKey } from "@/constants/config"
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  Vue.use(VueGoogleMaps, {
    load: {
      key: googleServicesKey,
      libraries: "geometry,drawing,places",
      v: "3.exp",
    }
  })
  export default {
    props: {
    location: {
      type: Object,
      default: () => ({
        lat: 40.748817,
        lng: -73.985428
      })
    },
  },
  computed: {
    markLocation () {
      if (!Number(this.location.lat)  || !Number(this.location.lng)) {
        return null
      }
      return  {
        lat: Number(this.location.lat),
        lng: Number(this.location.lng)
      }
    },
    center () {
      if (!Number(this.location.lat)  || !Number(this.location.lng)) {
        return {
          lat: 40.748817,
          lng: -73.985428
        }
      }
      return  {
          lat: Number(this.location.lat),
          lng: Number(this.location.lng)
        }
    }
  },
    data () {
      return {
        options: {
          styles: [{
            'featureType': 'water',
            'stylers': [{'saturation': 43}, {'lightness': -11}, {'hue': '#0088ff'}]
          }, {
            'featureType': 'road',
            'elementType': 'geometry.fill',
            'stylers': [{'hue': '#ff0000'}, {'saturation': -100}, {'lightness': 99}]
          }, {
            'featureType': 'road',
            'elementType': 'geometry.stroke',
            'stylers': [{'color': '#808080'}, {'lightness': 54}]
          }, {
            'featureType': 'landscape.man_made',
            'elementType': 'geometry.fill',
            'stylers': [{'color': '#ece2d9'}]
          }, {
            'featureType': 'poi.park',
            'elementType': 'geometry.fill',
            'stylers': [{'color': '#ccdca1'}]
          }, {
            'featureType': 'road',
            'elementType': 'labels.text.fill',
            'stylers': [{'color': '#767676'}]
          }, {
            'featureType': 'road',
            'elementType': 'labels.text.stroke',
            'stylers': [{'color': '#ffffff'}]
          }, {'featureType': 'poi', 'stylers': [{'visibility': 'off'}]}, {
            'featureType': 'landscape.natural',
            'elementType': 'geometry.fill',
            'stylers': [{'visibility': 'on'}, {'color': '#b8cb93'}]
          }, {'featureType': 'poi.park', 'stylers': [{'visibility': 'on'}]}, {
            'featureType': 'poi.sports_complex',
            'stylers': [{'visibility': 'on'}]
          }, {'featureType': 'poi.medical', 'stylers': [{'visibility': 'on'}]}, {
            'featureType': 'poi.business',
            'stylers': [{'visibility': 'simplified'}]
          }]
        }
      }
    }
  }
</script>
<style>
  #map {
    /* min-height: calc(100vh - 123px); */
    min-height: 350px;
  }
</style>
