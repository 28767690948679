<template>
  <Dashboard />
</template>

<script>
import Dashboard from "@/containers/Dashboards/index.vue";
export default {
  components: {
    Dashboard
  }
}
</script>

<style>

</style>
