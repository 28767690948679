<template>
  <div class="base-datepicker">
    <DatePicker
      :value="value"
      :input-class="[inputClass, 'date-input']"
      :format="formatDate"
      :language="language"
      @selected="selectedDate"
      minimum-view="month"
      calendar-button
      calendar-button-icon="fa fa-calendar"
    />
  </div>
</template>
<script>
import { vi, en } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
export default {
  props: {
    value: {
      type: [Date, String],
      default: ''
    },
    inputClass: {
      type: String,
      default: 'input input--type-3'
    }
  },
  data () {
    return {
      language: this.currentLanguage === 'vi' ? vi : en,
      disabledDates: {},
      DATE_FORMAT_PICKET: 'MM/YY',
      SHORT_DATE_FORMAT: 'MM/YYYY'
    }
  },
  computed: {
    displayDate () {
      return this.value ? moment(this.value).format(this.SHORT_DATE_FORMAT) : new Date(moment().format(this.DATE_FORMAT_PICKET))
    }
  },
  methods: {
    disabledDate (e, dir) {
      this.disabledDates = {}
      this.disabledDates[dir] = new Date(moment(e).format(this.DATE_FORMAT_PICKET))
    },
    selectedDate (val) {
      this.$emit('input', val)
    },
    formatDate (val) {
      return moment(val).format(this.SHORT_DATE_FORMAT)
    }
  }
}
</script>

<style lang="scss" scoped >
$base-datepicker : 'base-datepicker';
$vdp-datepicker : 'vdp-datepicker';

.#{$base-datepicker} {
  position : relative;
  /deep/ .#{$vdp-datepicker} {
    input {
      border: none;
    }
    .date-input {
      font-style    : normal;
      font-weight   : 500;
      font-size: 18px;
      line-height: 26px;
      background: #F7F7F7;
      border-radius: 8px;
      color: #141822;
      padding: 8px 12px;
      height        : 40px;
      width         : 100%;
      &:focus {
        outline      : 0;
        border-color : #595239;
      }
      &::placeholder {
        color : transparent;
      }
      cursor        : pointer;
    }
    .#{$vdp-datepicker}__calendar {
      background: #F7F7F7;
      border-radius : 8px;
      color: #141822;
      font-weight   : 700;
      header {
        .day__month_btn {
          position : relative;
        }
        .prev,
        .up,
        .next {
          &:hover {
            background : transparent;
          }
        }
        .prev {
          &:after {
            border-right : 10px solid #141822;
          }
          &.disabled {
            &:after {
              border-right : 10px solid #8c8c8c;
            }
          }
        }
        .next {
          &:after {
            border-left : 10px solid #141822;
          }
          &.disabled {
            &:after {
              border-left : 10px solid #8c8c8c;
            }
          }
        }
      }
      .cell {
        &.selected {
          background: #007BFF;
        }
        &.day {
          border-radius : 50%;
        }
        &.disabled {
          color  : #717375;
          cursor : default;
        }
        &:hover,
        &:focus {
          &.day,
          &.month,
          &.year {
            background: #007BFF;;
          }
        }
        &.disabled, &.blank {
          &:hover,
          &:focus {
            &.day,
            &.month,
            &.year {
              background : transparent;
              border     : none;
            }
          }
        }
      }
    }
    .#{$vdp-datepicker}__calendar-button {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

</style>
