<template>
  <div class="shop">
    <div class="shop__header">
      <p class="title text-white">{{ $t("menu-items.shop") }}</p>
      <div v-if="$route.query.tab !== 'PreOrder'" class="form-inline my-2 my-lg-0">
        <BaseInputCustom
          class-name="redeemCode"
          ref="redeemCode"
          v-model="formData.redeemCode"
          type="text"
          name="redeemCode"
          required
          label=""
          :placeholder="$t('redeem-placeholder')"
          :error-required="$t('validate.required', [$t('redeem-code')])"
        />
        <button class="btn btn-primary" type="submit" @click="redeem()">
          {{ $t("redeem") }}
        </button>
      </div>

      <div v-else class="form-inline my-2 my-lg-0">
        <div
          class="base-input-custom"
        >
          <div
            class="base-input-custom__wrap"
            style="position: relative;"
          >
            <input
              class="redeemCode"
              ref="search"
              v-model="searchValue"
              type="text"
              name="search"
              required
              :placeholder="$t('pre-order.search')"
              @keyup="searchPre"
            />
            <img
              v-if="searchValue"
              src="/img/icons/icon-close-black.svg"
              alt="icon-close"
              class="icon-close"
              @click="[searchValue = '', keywords = '']"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="shop__content border-radius-2">
      <div class="list">
        <div class="tabs">
          <div
            v-for="(item, index) in tabs"
            :key="index"
            class="item"
            :class="{ active: tab === item.id }"
            @click="changeTab(item)"
          >
            <span>{{ $t(item.label) }}</span>
          </div>
        </div>
        <div class="event-info" v-if="tab === 'PreOrder'">
          <a href="/shop/pre-order/event-proposal">
            <span v-if="$device.isDesktop">{{ $t("pre-order.proposal") }}</span>
            <img src="/img/pre-order/icon-info.svg" alt="icon" />
          </a>
        </div>
      </div>
      <div class="tab-content">
        <PreOrder v-if="tab === 'PreOrder'" :keywords="keywords"/>
        <EventTicket v-if="tab === 'EventTicket'" />
        <BuyPackages v-if="tab === 'BuyPackages'" />
        <MyPackage v-if="tab === 'MyPackage'" />
        <OrderHistory v-if="tab === 'OrderHistory'" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import { validationMixin } from "vuelidate";
import BuyPackages from '@containers/Shop/buy-packages.vue'
import MyPackage from '@containers/Shop/my-package.vue'
import OrderHistory from '@containers/Shop/order-history.vue'
import PreOrder from '@/containers/Shop/pre-order/index.vue'
import EventTicket from '@/containers/Shop/event-ticket/index.vue'
import validateMixin from '@/core/mixins/validate'
import updateCountryMixin from '@/core/mixins/updateCountry'
import { RepositoryFactory } from "@apis/repositoryFactory";
import {MODAL, NOTIFICATION_ICON} from "@/constants";
import debounce from 'lodash/debounce'
import isEmpty from "lodash/isEmpty";
const CartRepository = RepositoryFactory.post("cart");
export default {
  components: {
    BuyPackages,
    MyPackage,
    OrderHistory,
    PreOrder,
    BaseInputCustom,
    EventTicket,
  },
  mixins: [validateMixin, validationMixin, updateCountryMixin],
  data () {
    return {
      searchValue: '',
      keywords: '',
      tab: "BuyPackages",
      formData: {
        redeemCode: "",
      },
      tabs: [
        {
          id: "PreOrder",
          requireLogin: false,
          component: PreOrder,
          label: "menu-items.subShop.pre-order-shop",
        },
        {
          id: 'EventTicket',
          component: EventTicket,
          label: 'menu-items.subShop.event-ticket'
        },
        {
          id: "BuyPackages",
          requireLogin: false,
          component: BuyPackages,
          label: "menu-items.subShop.buy",
        },
        {
          id: "MyPackage",
          requireLogin: true,
          component: MyPackage,
          label: "menu-items.subShop.my",
        },
        {
          id: "OrderHistory",
          requireLogin: true,
          component: OrderHistory,
          label: "menu-items.subShop.order",
        },
      ],
      isValid: true,
    };
  },
  mounted() {
    this.tab = this.$route.query.tab || "BuyPackages";
    const tab = this.tabs.find(i => i.id == this.tab)
    this.changeTab(tab)
  },
  watch: {
    $route () {
      this.resetForm()
    }
  },
  validations() {
    return {
      searchValue: {
        required: true
      }
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.Auth.currentUser
    }
  },
  methods: {
    changeTab (item) {
      if (item.requireLogin) {
        if (isEmpty(this.$store.getters["Auth/currentUser"])) {
          this.$bvModal.show(MODAL.LOGIN)
          return
        }
      }
      this.tab = item.id
      this.$router.push({ path: '/shop', query: { tab: item.id } })
    },
    searchPre ($event) {
      if ($event.keyCode == 13) {
        this.$refs.search.blur();
      }
      this.keywords =  this.searchValue
    },
    async redeem() {
      if (isEmpty(this.$store.getters["Auth/currentUser"])) {
        this.$bvModal.show(MODAL.LOGIN)
        return
      }
      const refs = ["redeemCode"];
      const refsValid = this.$_validateMixin_refs(refs);
      if (refsValid) {
        try {
          const response = await CartRepository.useRedeem(
            this.formData.redeemCode
          );
          if (response && Object.keys(response).length) {
            this.$store.commit("Auth/updateCurrentUser", response);
            if (response.NeedToUpdateCountry) {
              this.requireUpdate(this.currentUser.Id)
            } else {
            let action = {
              action1Text: "OK",
              action1Func: () => {}
            }
            if (response.RedirectUrl) {
              action = {
                action1Text: "OK",
                action1Func: () => {},
                didCloseFunc: () => window.open(response.RedirectUrl, '_self')
              }
            }
            this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
              this.$t('swal.title.success'),
              response.message,
              {...action},
              'dashboard'
            )
            }
          } else {
            this.$notifyPopup(
              NOTIFICATION_ICON.ERROR,
              this.$t("swal.title.ops"),
              response.message,
              {
                action1Text: "OK",
                action1Func: "OK",
              },
              "dashboard"
            );
          }
          this.resetForm();
        } catch (error) {
          this.$notifyPopup(
            NOTIFICATION_ICON.ERROR,
            this.$t("swal.title.ops"),
            error.response.data.message,
            {
              action1Text: "OK",
              action1Func: "OK",
            },
            "dashboard"
          );
        }
      }
    },
    resetForm() {
      const refs = ["redeemCode"];
      this.keywords = ''
      this.searchValue = ''
      this.formData = {
        redeemCode: "",
      };
      this.$_validateMixin_reset(refs);
    },
  },
};
</script>

<style scoped lang="scss">
$base-input-custom: "base-input-custom";
.#{$base-input-custom} {
  position: relative;
  margin-bottom: 20px;
  &::v-deep {
    .deposit-icon-verify {
      position: absolute;
      top: 52%;
      font-size: 14px;
      transform: translateY(-50%);
      right: 50px;
    }
  }
  &__wrap {
    display: flex;
    flex-flow: column;
    position: relative;
    &.input-error {
      input {
        padding-right: 45px;
        border: 1px solid #ff5631;
      }
    }
    .icon {
      position: absolute;
      top: 65%;
      transform: translateY(-50%);
      right: 20px;
      font-size: 20px;
      transition: all 0.2s;
      cursor: pointer;
      width: 20px;
      height: 20px;
      &.eye {
        color: #fffafa;
      }
      &.search {
        color: #898b91;
        top: 60%;
        transform: translateY(-50%);
        right: 8px;
        width: 24px;
        height: 24px;
      }
    }
    &--reverse {
      flex-flow: column;
      label {
        transform: unset;
      }
    }
  }
  &__show-error {
    position: absolute;
    top: 50%;
    right: 20px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJBSURBVHgBvVZdThtBDLZnE8RL1eQE3UTwnHICKBdoBATx1pYLVOoBaHOBVj1B27cogZJeoMAF+HkGsXuDRDwumjX2ZrNswk/GIfBJo8xkxuux/dkeBAcEW1sr1tq6AVgGRJ//KqVbfSQ6tQBn5HndxVbrcNK38LHNi0bjo0H8ylMf3BDwB5uVdvsPaBSyRT5Zu8/WvIXpEGAUrVa63XB8w9w5ubn5geL45AnKBBWamzu+WF+vj2+MWCgHjOftw+xArOBT3sWZheJGVvZr0hdsHL+rttsowxqzOuE4EsCPoF737yhkNx7ALfseRMHzXoMOZSoW/44oFDaCIxPZwvJwjtY6yQgfhBuZwpT6zwlxbaLDnHNSg3ue8WXRz80nhiAH/3xjY8UYriAwPTTx5Pvhe8O0rcELgUO3bLQJbojeZAsijUsFvpBGK5RBGUNBySgFgNlWhiegwKMPGisRa2neiktrvAYF+gUWCpVxlDh8TpWr4i+907CLjjRCfL5Z7XSWZMhcIyuN2sREXY3QVRR9G85NFP10l2RfEv0zi7u7h7wIXaUwT5r5eQ1LQ9GVsJStdHbNq2Lxu7QbHiWW23EUk77YTC88wGWjccwkWILnQcD9syqTLA/R89b4pwezR0/eN8NFprDSaoXsoi+QEHFmoNja7fxjaqTSLHQ6v/nArCztsQHbC3t7I1nw8DMxjv/DoE+qSkkCohO8vl6775no8hDeUSie7iE8jqRTc/NMeuegnA1zsS+lUaqVFJA0px/FDbn81xpfXEHfAAAAAElFTkSuQmCC");
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    background-size: contain;
    transform: translateY(-50%);
  }
  input {
    height: 40px;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid #e8e8e9;
    color: #000000;
    padding: 8px 12px;
    width: 100%;
    font-size: 18px;
    line-height: 26px;
    &:focus {
      outline: 0;
    }
    &::placeholder {
      //color: transparent;
      font-size: 16px;
      line-height: 26px;
    }
  }
  input[type="password"]:not(:placeholder-shown) {
    letter-spacing: 0;
  }
  label {
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    margin-bottom: 8px;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  .error {
    margin-top: 5px;
    color: #ea290e;
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    margin-bottom: 0;
    &--inline {
      display: inline;
      margin-top: 0;
      position: absolute;
      right: 10px;
      top: 50%;
      color: #ffffff;
      transform: translateY(-50%);
      line-height: 1;
      background: #ff4069;
      padding: 3px;
      border-radius: 2px;
      font-weight: 700;
      z-index: 9;
      margin-right: 10px;
    }
    &--outline {
      font-size: 12px;
      display: block;
      margin-top: 5px;
      color: #bb3817;
      font-style: italic;
    }
  }
  &.theme-light {
    label {
      color: #43464e;
    }
    input {
      background: #f7f7f7;
      color: #141822;
    }
    .base-input-custom__wrap > .eye {
      color: #898b91;
    }
  }
  .mobile {
    .error {
      margin-top: px2vw(5px);
      font-size: px2vw(12px);
      line-height: px2vw(20px);
      &--inline {
        right: px2vw(10px);
        padding: px2vw(3px);
        margin-right: px2vw(10px);
      }
      &--outline {
        font-size: px2vw(12px);
        margin-top: px2vw(5px);
      }
    }
  }
  .error {
    @media (max-width: 1024px) {
      font-size: px2vw(12px);
      line-height: px2vw(20px);
    }
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &--password {
    input {
      padding-right: 2.2rem !important;
    }
  }
  &.required {
    input {
      &::placeholder {
        &:after {
          //content: '*';
          color: #de5656;
          padding-left: 5px;
        }
      }
    }
    label {
      &:after {
        color: #de5656;
        padding-left: 5px;
      }
    }
  }

  &.card-number {
    input {
      text-align: center;
      padding-top: 0;
    }

    .value-increase,
    .value-decrease {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
      padding: 2px;
      color: #000;
      //background-color: #969EBB;
      text-align: center;
      border-radius: 3px;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      cursor: pointer;
      font-size: 14px;
      /deep/span {
        font-size: 20px;
        margin-bottom: 6px;
      }
      &.disable {
        //background: #969EBB;
        opacity: 0.6;
      }
    }

    .value-decrease {
      left: auto;
      right: 10px;
      /deep/span {
        font-size: 20px;
        margin-bottom: 8px;
      }
    }
  }
}
.shop {
  padding: 16px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 26px;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      color: #141822;
      margin-bottom: 0;
    }
    .redeemCode {
      white-space: nowrap;
      margin-bottom: 0;
      /deep/.base-input-custom__wrap {
        label {
          display: none;
        }
        input {
          background: #ffffff;
          border: 1px solid #e8e8e9;
          border-radius: 8px 0 0 8px;
          color: #000000;
        }
      }
      /deep/.error {
        position: absolute;
      }
    }
    .btn-primary {
      border-radius: 0 8px 8px 0;
      height: 40px;
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__content {
    background: #ffffff;
    padding: 16px 24px;
    .list {
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      justify-content: space-between;
      &::-webkit-scrollbar {
        opacity: 0;
        visibility: hidden;
      }
      .tabs {
        width: max-content;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #eaeaea;
        .item {
          padding: 0 15px 15px 15px;
          &:first-child {
            padding-left: 0;
          }
          span {
            display: inline-block;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #43464e;
            position: relative;
          }
          &.active{
            cursor: pointer;
            span {
              color: #007bff;
              &:before {
                content: "";
                width: 100%;
                background: #007bff;
                position: absolute;
                bottom: -15px;
                left: 0;
                height: 1px;
              }
            }
          }
          &:hover {
            cursor: pointer;
            span {
              color: #007bff;
            }
          }
        }
        @media only screen and (max-width: 768px) {
          overflow-x: scroll;
          overflow-y: auto;
          span {
            white-space: nowrap;
          }
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      .event-info {
        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #007bff;
          margin-right: 8px;
        }
        a {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      padding: 16px 13px;
    }
  }
  .icon-close {
    position: absolute;
    right: 5px;
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    &:hover {
      cursor: pointer;
    }
  }
}

</style>
