<template>
  <div class="sum-quality">
    <div
      class="item"
      id="item-first"
      :class="{'disable': form.Quantity === 1}"
      @click="[form.Quantity--, updateItem()]"
    >
      <span>-</span>
    </div>
    <div class="item">
      <input
        class="input-number"
        type="number"
        min="1"
        v-model="form.Quantity"
        onkeyup="this.value=this.value.replace(/[^\d]/,'')"
        @input="updateItem()"
      >
    </div>
    <div
      class="item"
      @click="[form.Quantity++, updateItem()]"
    >
      <span>+</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "sum-quality",
  data() {
    return {
      form: {
        PackageId: '',
        Quantity: 1
      },
    }
  },
  watch: {
    'form.Quantity' (value) {
      if (value === '' || value <= 1) {
        setTimeout(() => {
          document.getElementById('item-first').classList.add('disable')
        }, 200)
      }
    }
  },
  methods: {
    updateItem () {
      this.$emit('updateItem', this.form.Quantity)
    }
  }
}
</script>

<style scoped lang="scss">
.sum-quality {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E8E8E9;
    height: 38px;
    .input-number {
      border: none;
      padding-left: 14px;
      width: 40px;
      color: #E8E8E9;
      background: transparent;
      &:focus, &:focus-visible, &:hover {
        border: none;
        outline: none;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    span {
      color: #141822;
      font-size: 20px;
      line-height: 20px;
      display: inline-block;
      padding: 10px 15px;
    }
    &:first-child, &:last-child {
      span {
        color: #ADAEB2;
        font-size: 20px;
        line-height: 20px;
        display: inline-block;
        padding: 10px;
      }
    }
    &:first-child {
      border: 1px solid #E8E8E9;
      border-radius: 8px 0px 0px 8px;
    }
    &:last-child {
      border: 1px solid #E8E8E9;
      border-radius: 0px 8px 8px 0px;
    }
    &.disable {
      pointer-events: none;
      opacity: 0.5;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
