<template>
  <div class="wallet-detail">
    <div class="wallet-detail__title">
      <h2  class="text-white">{{ $t("wallet.wallet-detail") }}</h2>
      <div class="wallet-detail__action">
        <template v-if="walletType === 'VLGCash' || isVLGToken || isVTP">
          <button class="btn btn-primary wallet-detail__btn-transfer" @click="openModal(MODAL.TRANSFER_HOME)">
            {{ $t("action.transfer") }}
          </button>
        </template>
        <template v-if="walletType === 'VLGPlus'">
          <button v-if="isBussinessType" class="btn btn-primary btn-round wallet-detail__btn-withdraw"
            @click="openModal(MODAL.CONVERT_TO_CASH)">
            {{ $t("action.convert") }} VUSD
          </button>
          <button :class="!$device.isDesktop ? '' : 'ml-3'" class="btn btn-primary wallet-detail__btn-transfer"
            @click="$router.push('/wallet/loan')">
            {{ $t("dashboards.lending") }}
          </button>
        </template>
        <button v-if="isVLGShare" class="btn btn-primary wallet-detail__btn-transfer"
          @click="openModal(MODAL.CERTIFICATE)">
          {{ $t("view-cer") }}
        </button>
        <button v-if="walletType == 'VmmBlock' || walletType == 'VmmBurn'" class="btn btn-primary wallet-detail__btn-transfer"
          @click="$router.push('/wallet/cover-vmm-history')">
          {{ $t("convert-his") }}
        </button>
      </div>
    </div>
    <div class="wallet-detail__table">
      <b-card>
        <Breadcrumb link="/wallet" :label="$t('wallet.back-wallet')" />
        <div class="wallet-detail__table--info" :class="{ 'air-drop': $route.query.code === 'Airdrop' }">
          <div class="info-left">
            <img :src="getInfoToken.icon" alt="" />
            <div class="amount">
              <p>
                <span class="token-name">{{ getInfoToken.label }} </span>
                <i :class="[
                  isShowWallet ? 'fa fa-eye' : 'fa fa-eye-slash',
                  'icon eye',
                ]" @click="showHideWallet"></i>
              </p>
              <span class="token-value justify-content-end">
                {{
                    isShowWallet
                      ? $options.filters.toCryptocurrency(
                        walletValue[walletType].Amount,
                        getInfoToken.symbolDetail
                      )
                      : "********"
                }}
                <i v-if="
                  getInfoToken.value !== 'MoongleWallet' &&
                  getInfoToken.value !== 'VLGLifetimePoint' &&
                  getInfoToken.value !== 'VLGAffiliatesPoint'
                " :class="{ 'hidden-number': !isShowWallet }" class="equivalent">≈</i>
                <i v-if="
                  getInfoToken.value !== 'MoongleWallet' &&
                  getInfoToken.value !== 'VLGLifetimePoint' &&
                  getInfoToken.value !== 'VLGAffiliatesPoint'
                " class="text-right small">
                  {{
                      isShowWallet
                        ? $options.filters.toCurrency(
                          walletValue[walletType].Amount *
                          (walletValue[walletType].CurrentPrice || 1),
                          true
                        )
                        : "********"
                  }}</i>
              </span>
            </div>
          </div>
          <div class="info-right">
            <template v-if="pools && Object.keys(pools).length > 0">
              <div>
                <p class="font-weight-semibold mb-3" v-if="isBussinessType">
                  {{ $t("Merchant AP Sharing Pool") }}:
                  {{ pools.MerchantPoint | toCurrency(true) }}
                </p>
                <p class="font-weight-semibold mb-3">
                  Package AP sharing Pool:
                  {{ pools.AffiliatePoint | toCurrency(true) }}
                </p>
                <p class="font-weight-semibold mb-3">
                  Total Team Volume AP: {{ pools.totalAp | toCurrency(true) }}
                </p>
                <p class="font-weight-semibold mb-0" v-if="PersonalSale">
                  Personal Sale this Quarter:
                  {{ pools.personalSaleInQuarter | toCurrency(true) }}
                </p>
              </div>
            </template>
            <template v-if="getInfoToken.transferAvailable">
              <div class="address">
                <p>{{ $t("wallet.address") }}</p>
                <a @click="onClickCopy">
                  <BaseInput disabled :tooltip="$t('copy-link', [getInfoToken.label])" :value="walletAddress"
                    addonRightIcon="icon-icon-copy" class="link-ref" />
                </a>
              </div>
              <div @click="zoomQRcode" class="qrcode">
                <qrcode-vue id="qrimg" :value="walletQrCode" size="109" level="M"
                  class="border-radius-2 border-2 p-1 d-flex" />
              </div>
            </template>
            <template v-if="$route.query.code === 'Airdrop' && walletValue['AirdropBlock'].Amount">
              <div class="info-air-drop">
                <a href="javascript:void(0)" @click.prevent="fetchAirdropBlock">{{ $t('wallet.block.title', {
                    0: walletValue['AirdropBlock'].Amount
                  })
                }}</a>
                <img v-click-outside="hideTooltip" @click="isActive = !isActive" style="margin-left: 8px"
                  src="/img/icon-info.svg" alt="icon-info" title="show">
                <div class="tooltip-air-drop" v-if="isActive">
                  <div class="content">
                    {{ $t('wallet.block.content', [walletValue['AirdropBlock'].Amount]) }}
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="wallet-detail__description" v-if="$route.query.code === 'Airdrop'">
          <div class="description-content" :class="{ collapsed: isCollapsible }"
            v-html="$t('wallet.description.air-drop')"></div>
          <a @click="isCollapsible = !isCollapsible">{{ isCollapsible ? `${$t(`action.see-less`)}` :
              `${$t(`action.see-more`)}`
          }}</a>
        </div>
        <div class="wallet-detail__description" v-if="$route.query.code === 'VmmBlock'">
          <div class="description-content" :class="{ collapsed: isCollapsible }"
            v-html="$t('wallet.description.VmmBlock')"></div>
          <a @click="isCollapsible = !isCollapsible">{{ isCollapsible ? `${$t(`action.see-less`)}` :
              `${$t(`action.see-more`)}`
          }}</a>
        </div>
        <div class="wallet-detail__description" v-if="$route.query.code === 'VmmBurn'">
          <div class="description-content" :class="{ collapsed: isCollapsible }"
            v-html="$t('wallet.description.VmmBurn')"></div>
          <a @click="isCollapsible = !isCollapsible">{{ isCollapsible ? `${$t(`action.see-less`)}` :
              `${$t(`action.see-more`)}`
          }}</a>
        </div>
        <p class="wallet-detail__table--title my-3 text-capitalize">
          {{ $t("history.transaction") }}
        </p>
        <div class="list">
          <table>
            <thead>
              <tr>
                <th>{{ $t("fields.DateTime") }}</th>
                <th>{{ $t("fields.Amount") }}</th>
                <th>{{ $t("fields.Balance") }}</th>
                <th>{{ $t("fields.TransactionId") }}</th>
                <th>{{ $t("fields.Reason") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="index">
                <td>{{ item.DateTime | formatDateTime }}</td>
                <td>{{ item.Amount | toCurrency }}</td>
                <td>{{ item.Balance | toCurrency }}</td>
                <td>{{ item.TransactionId }}</td>
                <td>{{ item.Reason }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container position-relative" v-if="isLoading">
          <BaseLoading class="loading mt-0" :loading="isLoading" />
        </div>
        <div v-else-if="!data.length" class="empty-table">
          <img src="/img/empty-folder.png" alt="img empty" />
          <span>No data</span>
        </div>
        <nav aria-label="page-navigation" class="page-navigation" v-if="TotalPages">
          <ul class="pagination justify-content-end" v-if="TotalPages < 5">
            <li class="page-item" :class="{ disabled: !HasPrevious }">
              <a class="page-link" @click="onChangePage(PageNumber - 1)">Previous</a>
            </li>
            <li class="page-item" v-for="page in TotalPages" :key="page" :class="{ active: page === PageNumber }">
              <a class="page-link" @click="onChangePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: !HasNext }">
              <a class="page-link" @click="onChangePage(PageNumber + 1)">Next</a>
            </li>
          </ul>
          <ul class="pagination justify-content-end" v-else>
            <li class="page-item" :class="{ disabled: !HasPrevious }">
              <a class="page-link" @click="onChangePage(PageNumber - 1)">Previous</a>
            </li>
            <li class="page-item">
              <a class="page-link" v-if="PageNumber > 2">...</a>
            </li>
            <li class="page-item">
              <a class="page-link" @click="onChangePage(PageNumber - 1)" v-if="PageNumber > 1">{{ PageNumber - 1 }}</a>
            </li>
            <li class="page-item active">
              <a class="page-link">{{ PageNumber }}</a>
            </li>
            <li class="page-item" v-if="PageNumber < TotalPages">
              <a class="page-link" @click="onChangePage(PageNumber + 1)">{{
                  PageNumber + 1
              }}</a>
            </li>
            <li class="page-item" v-if="PageNumber < TotalPages - 1">
              <a class="page-link">...</a>
            </li>
            <li class="page-item" :class="{ disabled: !HasNext }">
              <a class="page-link" @click="onChangePage(PageNumber + 1)">Next</a>
            </li>
          </ul>
        </nav>
      </b-card>
    </div>
    <ModalWithdraw />
    <ModalCertificate :show.sync="showPopup" />
    <ModalConvertToCash @refresh="getData()" />
    <ModalTransfer :walletType="walletType" @refresh="getData()" />
    <b-modal hide-footer id="modal-lg" size="lg" :title="$t('airdrop-history')">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('fields.DateTime') }}</th>
            <th scope="col">{{ $t('fields.Amount') }}</th>
            <th scope="col">{{ $t('fields.Reason') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i, index) in listAirdrop" :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ i.DateTime | formatDateTime }}</td>
            <td>{{ i.Amount }} VLG</td>
            <td>{{ i.Message }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { RepositoryFactory } from "@apis/repositoryFactory";
const WalletRepository = RepositoryFactory.get("wallet");
import wallet from "@/constants/wallet";
import QrcodeVue from "qrcode.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import Breadcrumb from "./breadcrumb.vue";
import globalMixin from "@/core/mixins/global";
import BaseLoading from "@components/Inputs/BaseLoading";
import ModalWithdraw from "@/containers/Wallet/modal-withdraw.vue";
import { MODAL, NOTIFICATION_ICON } from "@/constants";
import ModalCertificate from "@/containers/Wallet/modal-certificate.vue";
import ModalConvertToCash from "@/containers/Wallet/modal-convert-to-cash.vue";
// import ModalConvertToVMM from "@/containers/Wallet/modal-convert-to-cash.vue";
import Swal from "sweetalert2";
import QRCode from "qrcode";
import { homeUrl } from "@/constants/config";

export default {
  components: {
    QrcodeVue,
    BaseInput,
    Breadcrumb,
    BaseLoading,
    ModalWithdraw,
    ModalCertificate,
    ModalConvertToCash,
    ModalTransfer: () => import("@/containers/Wallet/modal-transfer-home.vue"),
  },
  mixins: [globalMixin],

  data() {
    return {
      data: [],
      listAirdrop: [],
      MODAL,
      modalId: "",
      pools: {},
      walletType: "",
      PageNumber: 1,
      PageSize: 10,
      TotalCount: 0,
      TotalPages: 0,
      HasPrevious: false,
      HasNext: false,
      isShowWallet: false,
      showPopup: false,
      isCollapsible: false,
      isActive: false
    };
  },
  watch: {
    '$route.query.code': {
      handler: function (newVal) {
        this.getData()
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      walletValue: "getWallet",
    }),
    walletQrCode() {
      if (this.walletValue && this.walletValue[this.walletType] && this.walletValue[this.walletType].WalletAddress) {
        const data = {
          token: this.walletType,
          address: this.walletValue[this.walletType].WalletAddress
        }
        const searchParams = new URLSearchParams(data);
        return homeUrl + '/app/wallets/transfers?' + searchParams
        // return JSON.stringify(data)
      }
      return ""
    },
    getInfoToken() {
      return wallet.find((e) => {
        return e.value === this.$route.query.code;
      });
    },
    isBussinessType() {
      return this.$store.state.Auth.currentUser.Type === "BUSINESS";
    },
    isVLGToken() {
      return this.$route.query.code === "VLGTokenAvailable";
    },
    isVTP() {
      return this.$route.query.code === "VLGPlus";
    },
    isVLGShare() {
      return this.$route.query.code === "VLGShare";
    },
    PersonalSale() {
      const { Title } = this.$store.state.Auth.currentUser;
      return (
        (Title && Title.toLowerCase() == "agent") ||
        (Title && Title.toLowerCase() == "supervisor") ||
        (Title && Title.toLowerCase() == "manager")
      );
    },
    walletAddress() {
      return this.walletValue && this.walletValue[this.walletType] && this.walletValue[this.walletType].WalletAddress
        ? this.walletValue[this.walletType].WalletAddress
        : ""
    }
  },
  async created() {
    this.getData();
    this.fetchWallet();
    this.walletType = this.$route.query.code || null;
    if (this.walletType == "VLGAffiliatesPoint") {
      this.pools = await WalletRepository.getPools();
    }
    if (localStorage.getItem("showWallet")) {
      this.isShowWallet = true;
    }
  },
  methods: {
    async fetchAirdropBlock() {
      try {
        this.listAirdrop = await WalletRepository.fetchAirdropBlock();
        this.$bvModal.show('modal-lg')
      } catch (error) {
        console.log(error);
      }
    },
    hideTooltip() {
      this.isActive = false;
    },
    showHideWallet() {
      if (this.isShowWallet) {
        this.isShowWallet = false;
        localStorage.removeItem("showWallet");
      } else {
        this.isShowWallet = true;
        localStorage.setItem("showWallet", true);
      }
    },
    ...mapActions(["fetchWallet"]),
    async getData() {
      this.fetchWallet();
      try {
        const params = {
          PageNumber: this.PageNumber,
        };
        const type = this.$route.query.code;
        this.$_loading();
        const result = await WalletRepository.getHistoryByType(type, params);
        this.PageNumber = result.CurrentPage;
        this.TotalCount = result.TotalCount;
        this.TotalPages = result.TotalPages;
        this.PageSize = result.PageSize;
        this.HasPrevious = result.HasPrevious;
        this.HasNext = result.HasNext;
        this.data = result.Result;
        this.$_load();
        this.data = result.Result;
      } catch (error) { }
    },
    onClickCopy() {
      navigator.clipboard.writeText(this.walletAddress);
      this.$alertMsg("success", this.$t("action.copied-text"));
    },
    openModal(modalId) {
      this.showPopup = modalId == MODAL.CERTIFICATE;
      this.$bvModal.show(modalId);
    },
    onChangePage(page) {
      if (page === this.PageNumber) {
        return;
      }
      this.PageNumber = page;
      this.getData();
    },
    async zoomQRcode() {
      const opts = {
        errorCorrectionLevel: "M",
        width: 400,
      };
      const image = await QRCode.toDataURL(this.walletQrCode, opts);
      const descHtml = `<div class="card-with-title">
      <h5 class="text-center">QR Code</h5><div class="input-group mb-3 align-items-center justify-content-center">
      <img src="${image}" class="mw-100 min-w-320px" />
      </div></div></div>`;
      Swal.fire({ html: descHtml, customClass: { content: "px-0" } });
    },
  },
};
</script>

<style scoped lang="scss">
.wallet-detail {
  margin: 24px;

  @media only screen and (max-width: 500px) {
    margin: 16px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    h2 {
      color: #141822;
      font-family: "Overpass";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 12px;
    }

    .btn {
      min-width: 240px;
      height: 40px;
    }

    @media only screen and (max-width: 980px) {
      flex-direction: column;
    }

    @media only screen and (max-width: 830px) {
      flex-direction: column;

      .wallet-detail__action {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .btn {
          width: 100%;

          &:first-child {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  &__btn-withdraw {
    margin-left: 1rem;
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;

    &:hover {
      background: #007bff;
      color: #ffffff;
    }

    @media only screen and (max-width: 768px) {
      margin-left: 0;
      margin-bottom: 1rem;
    }
  }

  &__table {
    overflow-y: hidden;
    overflow-x: auto;
    min-height: 600px;

    .card {
      min-height: 70vh;
      margin-bottom: 24px;

      .list {
        overflow-x: auto;
        overflow-y: hidden;
      }
    }

    &--title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #43464e;
    }

    &--info {
      display: flex;
      justify-content: space-between;
      padding-bottom: 28px;
      border-bottom: solid 1px #eaeaea;

      .info-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 120px;
        }

        .amount {
          display: flex;
          flex-direction: column;
          margin-left: 16px;
          align-items: flex-start;

          .token-name {
            font-weight: 700;
            font-size: 32px;
            line-height: 44px;
            color: #898b91;

            .btn-certificate {
              padding: 9px 24px;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #007bff;
              background: rgba(0, 123, 255, 0.2);
              border-radius: 8px;
              cursor: pointer;
            }
          }

          .token-value {
            font-weight: 700;
            font-size: 24px;
            line-height: 34px;
            display: flex;
            align-items: center;
            color: #141822;

            .equivalent {
              margin: 0 5px;

              &.hidden-number {
                margin: -5px 5px 0 5px;
              }
            }
          }
        }
      }

      .info-right {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        .form-group {
          margin-bottom: 6px;
        }

        .qrcode {
          cursor: pointer;
        }

        .address {
          margin-right: 1rem;
          min-width: 450px;
          max-width: 100%;

          p {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #43464e;
          }

          /deep/ {
            .form-control:disabled {
              background: #F7F7F7;
              border: 1px solid #E8E8E9;
              border-radius: 8px 0 0 8px;
              font-size: 16px;
              line-height: 24px;
              color: #43464E;
              border-right: none;
            }

            .input-group-text {
              background: #F7F7F7;
              border: 1px solid #E8E8E9;
              border-left: none;
              border-radius: 0 8px 8px 0;
            }

          }

          .link-ref {
            &:hover {
              cursor: pointer;
            }
          }
        }

        .info-air-drop {
          position: relative;

          .tooltip-air-drop {
            position: absolute;
            right: -10px;
            top: 40px;
            width: 280px;

            .content {
              position: relative;
              background: #FFFFFF;
              border-radius: 4px;
              padding: 16px;
              box-shadow: 0px 0px 20px rgba(2, 13, 36, 0.25);

              &:before {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid #ffffff;
                top: -10px;
                right: 8px;
              }
            }
          }
        }
      }

      @media only screen and (max-width: 1376px) {
        .info-right .address {
          min-width: 300px;
        }
      }

      @media only screen and (max-width: 1160px) {
        flex-direction: column;
        justify-content: flex-start;

        .info-left {
          margin-bottom: 20px;
          justify-content: center;
        }

        .info-right {
          justify-content: center;

          .address {
            min-width: auto;
            width: 80%;
          }
        }
      }

      @media only screen and (max-width: 500px) {
        flex-direction: column;

        .info-left {
          img {
            width: 70px;
          }

          .amount {
            .token-name {
              font-size: 20px;
              line-height: 32px;
            }

            .token-value {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }

        .info-right {
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .address {
            align-items: center;
            margin-bottom: 1rem;
            min-width: auto;
            width: 100%;
          }
        }
      }

      &.air-drop {
        border-bottom: none;

        .info-right {
          align-items: center;
        }
      }
    }

    table {
      width: 100%;

      thead {
        th {
          padding: 10px;
          background: rgba(173, 174, 178, 0.2);
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #898b91;
          vertical-align: middle;
          white-space: nowrap;
        }
      }

      td {
        padding: 15px 10px;
        border-bottom: 1px solid #eaeaea;
        vertical-align: middle;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #141822;
        max-width: 300px;

        &.in-progress {
          color: #67b231;
          text-transform: capitalize;
        }

        &.active-date,
        &.status {
          text-transform: capitalize;
        }
      }
    }

    .empty-table {
      margin-top: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex-direction: column;

      span {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #adaeb2;
      }
    }

    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 200px;
      flex-direction: column;
    }
  }

  &__description {
    .description-content {
      overflow: hidden;
      text-overflow: ellipsis;
      height: 50px;
      padding: 0;

      /deep/ ul {
        padding-left: 20px !important;
      }

      &.collapsed {
        overflow: auto;
        display: inline-block;
        height: auto;
      }
    }

    a {
      color: #007bff;
      font-style: italic;

      &:hover {
        cursor: pointer;
      }
    }

    border-bottom: solid 1px #eaeaea;
    padding-bottom: 24px;
  }

  .page-navigation {
    margin-top: 12px;

    ul {
      margin-bottom: 0;

      .page-item {
        cursor: pointer;

        .page-link {
          border: none;
        }
      }
    }
  }
}
</style>
