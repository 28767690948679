<template>
  <b-modal
    centered
    hide-header
    content-class="package"
    hide-footer
    v-model="showGive"
    @hide="onClose()"
  >
    <b-form @submit.prevent="submitForm" class="text-dark">
      <h3 class="text-dark text-center mb-2">
        {{ $t('event.give')}}
      </h3>
      <b-form-group :label="$t('event.toUser')" class="text-dark">
        <v-select
          v-model="$v.formData.ToUserId.$model"
          :reduce="(member) => member.ValueField"
          label="DisplayField"
          @search="onSearch"
          :options="members"
        />
        <div
          :class="{
            'invalid-feedback': true,
            'd-block':
              $v.formData.ToUserId.$error && !$v.formData.ToUserId.required,
          }"
        >
          {{ $t('validate.required', [$t('contact.Username')]) }}
        </div>
      </b-form-group>
      <b-form-group class="text-dark" :label="$t('input.pin')">
        <b-form-input
          type="tel"
          pattern="[0-9.]+"
          class="custom-password input-pin-code"
          :maxLength="6"
          onkeyup="this.value=this.value.replace(/[^\d]/,'')"
          v-model="$v.formData.Pin.$model"
        />
        <b-form-invalid-feedback
          class="d-block border-0 shadow-none"
          v-if="!$v.formData.Pin.required && $v.formData.Pin.$dirty"
        >
          {{ $t("title.required", [$t("input.pin")]) }}
        </b-form-invalid-feedback>
        <p style="padding-top: 5px">
          {{ $t("stock.pin.first")
          }}<router-link
          tag="a"
          :to="'/app/profile/security-pin'"
          target="_blank"
          style="color: #007bff; font-weight: bold"
        >{{ $t("stock.pin.second") }}</router-link
        >
          {{ $t("stock.pin.third") }}
        </p>
      </b-form-group>
      <div class="d-flex justify-content-center mt-2">
        <b-button @click="onClose()" variant="light default">{{
          $t("action.cancel")
        }}</b-button>
        <b-button class="ml-2" @click="submitForm()" variant="primary default">{{
            $t('action.confirm')
          }}</b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import vSelect from "vue-select";
import debounce from "lodash.debounce";
import {RepositoryFactory} from "@/apis/repositoryFactory";
import Swal from "sweetalert2";
const EventRepository = RepositoryFactory.get("event");
const UserRepository = RepositoryFactory.get("user");
export default {
  name: "modal-give-ticket",
  props: {
    showGive: {
      type: Boolean,
      default: false
    },
    ticketId: {
      type: String,
      default: ''
    }
  },
  components: {
    vSelect
  },
  mixins: [validationMixin],
  data () {
    return {
      members: [],
      isShowGive: false,
      toUser: '',
      formData: {
        TicketId: this.ticketId,
        ToUserId: '',
        Pin: ''
      }
    }
  },
  validations: {
    formData: {
      ToUserId: { required },
      Pin: { required }
    }
  },
  watch: {
    showGive(value) {
      this.isShowGive = value
    }
  },
  methods: {
    onClose () {
      this.isShowGive = false
      this.$v.formData.$reset()
      this.$emit('onClose', false)
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce(async (loading, search, vm) => {
      try {
        vm.members = await UserRepository.searchUser(search);
      } catch (error) {
        vm.members = [];
      } finally {
        loading(false);
      }
    }, 350),
    async submitForm() {
      try {
        this.$v.formData.$touch();
        if (!this.$v.formData.$anyError) {
          this.isLoading = true;
          await EventRepository.transferEventTicket(this.formData);
          this.onClose()
          Swal.fire({
            title: this.$t("swal.title.congratulations"),
            text: this.$t('event.success.first') + this.members[0].DisplayField + this.$t('event.success.second'),
            icon: "success",
            confirmButtonText: 'OK',
            className: 'dashboard'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$emit('handleData', true)
            }
          });
        }
      } catch (error) {
        const res = JSON.parse(error.request.response)
        await Swal.fire({
          // title: this.$t("pages.error-title"),
          text: res.message + '',
          icon: "error",
          confirmButtonText: 'OK',
          className: 'dashboard'
        })
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.v-select {
  color: #3a3a3a;
  outline: initial !important;
  box-shadow: none !important;

  .vs__search {
    margin: 2px !important;
    line-height: 1.5rem !important;
    appearance: none;
    background: none;
    border: 1px solid transparent;
    border-left: none;
    box-shadow: none;
    flex-grow: 1;
    max-width: 100%;
    outline: none;
    padding: 0 7px;
    width: 0;
    z-index: 1;
  }
  .vs__actions {
    color: #8f8f8f;
    display: flex;
    padding: 0 10px 0 3px;
  }

  .vs__selected-options {
    outline: initial !important;
    box-shadow: none !important;
    padding: 0.4rem !important;
    background: #FFFFFF !important;
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    flex-wrap: wrap;
    position: relative;
  }

  .vs__no-options {
    padding: 5px !important;
  }

  .vs__dropdown-menu {
    padding-bottom: 0;
    padding-top: 0;
    overflow: auto !important;
    box-shadow: initial !important;
    border-radius: initial !important;
    background: #FFFFFF;
    border-color: rgba(#900604, 0.6) !important;

    .vs__dropdown-option {
      transition: initial;
      background: #FFFFFF !important;
      color: #3a3a3a !important;
      padding: 8px 12px !important;

      &.vs__dropdown-option--highlight {
        background: rgba(#900604, 0.6) !important;
        color: #FFFFFF !important;
      }
      &.vs__dropdown-option--selected {
        display: none;
      }
    }
  }

  .vs__dropdown-toggle::after {
    display: none;
  }

  .vs__dropdown-toggle {
    border-radius: 8px !important;
    border: 1px solid #d7d7d7 !important;
    background: #FFFFFF !important;
    outline: initial !important;
    box-shadow: none !important;
    padding: 0 !important;
    appearance: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .vs__open-indicator {
    display: flex;
    align-items: center;
    cursor: pointer;
    pointer-events: all;
    opacity: 1;
    width: 12px;

    path {
      display: none;
    }
  }
  .vs__open-indicator,
  .vs__open-indicator {
    transition: all 0.15s cubic-bezier(1, -0.115, 0.975, 0.855);
    transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
  }
  .vs__open-indicator {
    border-color: #d7d7d7 !important;
    border-width: 2px 2px 0 0 !important;
    height: 6px !important;
    width: 6px !important;
    border-style: solid;
    content: "";
    display: inline-block;
    vertical-align: text-top;
    transform: rotate(133deg);
    box-sizing: inherit;
  }
  &.vs--open .vs__open-indicator {
    transform: rotate(315deg);
  }
  &.vs--loading .vs__open-indicator {
    opacity: 0;
  }

  .vs__actions .vs__clear {
    display: none;
  }

  .vs__actions svg {
    width: 15px;
    height: 15px;
  }

  &.vs--open {
    border: none;
    .vs__dropdown-toggle {
      border-color: rgba(#900604, 0.6) !important;
    }
  }

  .vs__actions,
  &.vs--open .vs__actions {
    outline: initial;
    box-shadow: initial;
    &:active,
    &:focus,
    &:hover {
      color: #900604 !important;
    }
  }

  &.vs--single .vs__selected {
    padding: 6px 5px !important;
    background-color: transparent !important;
    border-color: transparent !important;
    color: #3a3a3a !important;
    font-size: inherit !important;
    position: initial !important;
  }
}

</style>
