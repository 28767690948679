<template>
  <b-modal
    centered
    hide-header
    content-class="package"
    hide-footer
    v-model="isShowUpdate"
    @hide="onClose()"
  >
    <b-form @submit.prevent="submitForm" class="text-dark">
      <h3 class="text-dark text-center mb-2">
        {{ $t('event.update')}}
      </h3>
      <b-form-group class="text-dark" :label="$t('contact.full-name')">
        <input
          type="text"
          class="form-control"
          v-model="$v.formData.FullName.$model"
        />
        <div
          :class="{
                'invalid-feedback position-relative mt-0': true,
                'd-block':
                  $v.formData.FullName.$error && !$v.formData.FullName.required,
              }"
        >
          {{ $t("validate.required", [$t("contact.full-name")]) }}
        </div>
      </b-form-group>
      <b-form-group class="text-dark" :label="$t('users.infor.phonenumber')">
        <input
            type="tel"
            pattern="[0-9.]+"
            v-model="$v.formData.PhoneNumber.$model"
            onkeyup="this.value=this.value.replace(/[^\d]/,'')"
            class="input-phone-number form-control"
            maxlength="11" />
        <div :class="{ 'invalid-feedback':true,
            'd-block':$v.formData.PhoneNumber.$error && !$v.formData.PhoneNumber.required }" >
          {{ $t('validate.required', [$t('users.infor.phonenumber')]) }}
        </div>
      </b-form-group>
      <div class="d-flex justify-content-center mt-3">
        <b-button @click="onClose()" variant="light default">{{
            $t("action.cancel")
          }}</b-button>
        <b-button class="ml-2" @click="submitForm()" variant="primary default">{{
            $t('action.confirm')
          }}</b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import {RepositoryFactory} from "@/apis/repositoryFactory";
const { required } = require("vuelidate/lib/validators");
const EventRepository = RepositoryFactory.get("event");
import globalMixin from "@/core/mixins/global";
import Swal from "sweetalert2";
export default {
  name: "modal-update-infor-ticket",
  props: {
    showUpdate: {
      type: Boolean,
      default: false
    },
    ticketId: {
      type: String,
      default: ''
    },
    fullName: {
      type: String,
      default: ''
    },
    phoneNumber: {
      type: String,
      default: ''
    },
  },
  mixins: [validationMixin, globalMixin],
  data () {
    return {
      isShowUpdate: false,
      formData: {
        TicketId: this.ticketId,
        FullName: this.fullName,
        PhoneNumber: this.phoneNumber
      }
    }
  },
  validations: {
    formData: {
      FullName: { required },
      PhoneNumber: { required }
    }
  },
  watch: {
    showUpdate(value) {
      this.isShowUpdate = value
    }
  },
  methods: {
    onClose () {
      this.isShowUpdate = false
      this.$v.formData.$reset()
      this.$emit('onClose', false)
    },
    async submitForm() {
      try {
        this.$v.formData.$touch();
        if (!this.$v.formData.$anyError) {
          this.isLoading = true;
          await EventRepository.updateEventTicket(this.formData);
          this.onClose()
          Swal.fire({
            title: this.$t("swal.title.congratulations"),
            text: this.$t('event.success-update.saved'),
            icon: "success",
            confirmButtonText: 'OK',
            className: 'dashboard'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$emit('handleData', true)
            }
          });
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
