<template>
  <form class="verification" @submit.prevent="submit" autocomplete="off">
    <div class="verification__title">
      {{ $t('pre-order.order-confirmation') }}
    </div>
    <div class="verification__description">
      <p style="text-align: center">{{ $t('pre-order.description.step-1') }} <b>{{ namePackage }}</b> <br />
        {{ $t('pre-order.description.step-2') }} <b>{{ pricePackage }} VLG?</b></p>
    </div>
    <div class="verification__input">
      <BaseInputCustom
        v-model="pin"
        class-name="pin__old theme-light"
        ref="pin"
        name="pin"
        required
        type="tel"
        is-pin
        inputmode="number"
        :min-length="6"
        :limit-input="6"
        :error-required="$t('security-pin.required')"
        :error-min-length="$t('security-pin.min-length')"
        :placeholder="$t('payments.pin')"
        :label="$t('transfer.pin')"
      />
    </div>
    <p class="verification__note">
      {{ $t('stock.pin.first')}} <a href="/profile/security?tab=pin" target="_blank">{{ $t('stock.pin.second')}}</a> {{ $t('stock.pin.third')}}
    </p>
    <p class="verification__proposal">
      {{ $t('pre-order.condition') }}
    </p>
    <BaseCheckbox
      v-model="isValid"
      :inline="true"
      class="verification__agree"
    >
        <span class="label">{{ $t('pre-order.term') }}
          <a href="/shop/pre-order/event-proposal" target="_blank">{{$t('pre-order.proposal')}}</a>
        </span>
    </BaseCheckbox>
    <p v-if="!isValid" class="error">
      {{ $t('pre-order.validate-proposal') }}
    </p>
    <div class="verification__action mt-4">
      <a
        href="javascript:void(0)"
        @click.prevent="closeModal"
        class="btn btn-cancel">
        <span>{{$t("action.cancel")}}</span>
      </a>
      <button
        class="btn btn-primary"
        :class="{'button--loading': loading}"
      >
        <span>{{$t('action.submit')}}</span>
      </button>
    </div>
  </form>
</template>

<script>
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import validateMixin from "@/core/mixins/validate";
import {MODAL} from "@/constants";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue"
export default {
  components: {
    BaseCheckbox,
    BaseInputCustom
  },
  mixins: [validateMixin],
  data() {
    return {
      pin: '',
      isValid: true
    }
  },
  props: {
    namePackage: {
      type: String,
      default: ''
    },
    pricePackage: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    closeModal () {
      this.isValid = true
      this.$bvModal.hide(MODAL.PIN_VERIFICATION)
    },
    resetData () {
      this.pin =  ''
    },
    async submit () {
      let refs = ['pin']
      const valid = this.$_validateMixin_refs(refs)
      if (valid && this.isValid) {
        this.$emit("submit", this.pin)
      }
    }
  },
}
</script>

<style scoped lang="scss">
.verification {
  font-family: 'Overpass';
  font-style: normal;
  padding-bottom: 32px;
  margin-top: 16px;
  &__title {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #141822;
    margin-bottom: 16px;
  }
  &__input {
    position: relative;
  }
  &__note {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #898B91;
  }
  &__agree {
    &.form-check {
      padding-left: 0;
      &::v-deep {
        .form-check-label {
          .form-check-sign::before {
            color: #F7F7F7;
            font-size: 24px;
            background: #F7F7F7;
            border-radius: 4px;
            height        : 24px;
            width         : 24px;
            margin-left: -35px;
            margin-top: -18px;
            border: none;
          }
          .form-check-sign::after {
            content         : '\2713';
            color: #007BFF;
            height        : 24px;
            width         : 24px;
            font-size: 24px;
            margin-left: -35px;
            margin-top: -18px;
          }
        }
      }

    }
    .label {
      color: #000000;
    }
  }
  .error {
    margin-top: 5px;
    color: #ea290e;
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    margin-bottom: 0;
  }
  &__action {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
    .btn-cancel {
      color: #007bff;
      background: #ffffff;
      border: 1px solid #007bff;
      &:hover {
        background: #007bff;
        color: #ffffff;
      }
    }
  }
}
</style>
