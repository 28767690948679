<template>
  <div class="row">
    <b-colxx xxs="12" md="6" class="thumb-mobile">
      <div
        class="
          d-flex
          flex-row
          cursor
          flex-column
          align-items-center
        "
      >
        <div class="d-block position-relative">
            <img
              alt="img"
              :src="images"
              class="list-thumbnail border-0 mw-100"
              @click="onThumbClick(0)"
            />
            <LightGallery
              :images="[images]"
              :index="photoIndex"
              :disable-scroll="true"
              @close="handleHide()"
            />
        </div>
      </div>
    </b-colxx>
    <b-colxx xxs="12" md="6" class="">
      <div class="pl-3 w-100">
        <p class="text-dark font-weight-bold text-uppercase" style="font-size: 24px;color: #3A3A3A;line-height: 24px">{{ $t('event.name') }}</p>
        <p class="text-dark" >{{ $t('event.time')  }}</p>
        <p class="text-dark" >{{ $t('event.address')  }}</p>
        <p class="text-dark" >{{ $t('event.price')  }}: <b>10 VLG / {{ $t('event.ticket')  }}</b></p>
        <p class="text-dark" >{{ $t('event.remain')  }}: <b>{{ item.RemainingPackage }} {{ $t('event.ticket')  }}</b></p>
      </div>
      <b-form
        @submit.prevent="onSubmit"
        class="
          d-flex
          justify-content-around
          align-items-center
          mb-3
          mx-3 mx-md-0
        "
      >
        <b-input-group class="" style="width: 45%">
          <b-form-input
            type="number"
            min="1"
            v-model="$v.form.Quantity.$model"
            :state="!$v.form.Quantity.$error"
          />
          <input type="hidden" :value="item.Id" />
        </b-input-group>
        <b-button
          :disabled="!item.RemainingPackage"
          variant="default"
          @click="showPopupBuy"
          style="width: 45%"
          class="btn-info bg-primary br-1 text-uppercase px-0 font-weight-bold btn-buy-ticket"
        > {{ $t('dashboards.buyNow') }} </b-button>
      </b-form>
    </b-colxx>
    <b-modal
      hide-header
      content-class="bg-white"
      hide-footer
      v-model="isShow"
      @hide="onClose()"
    >
      <b-form @submit.prevent="onSubmit" class="text-dark">
        <h3 class="text-dark">
          {{ $t('event.buy') }} ?
        </h3>
        <b-row class="overflow-auto">
          <b-colxx xxs="12">
            <b-form-group :label="$t('input.pin')">
              <b-form-input
                :type="inputType"
                inputmode="numeric"
                class="custom-password"
                :maxLength="6"
                :placeholder="$t('input.pin')"
                v-model="$v.Pin.$model"
                onkeyup="this.value=this.value.replace(/[^\d]/,'')"
              />
              <i
                :class="[isShowPassword ? 'fa fa-eye' : 'fa fa-eye-slash', 'icon eye']"
                @click="togglePassword()"
                style="position: absolute; top: 42px; right: 25px;"
              />
              <b-form-invalid-feedback
                class="d-block border-0 shadow-none text-danger"
                v-if="!$v.Pin.required && $v.Pin.$dirty"
              >
                {{ $t("security-pin.required") }}
              </b-form-invalid-feedback>
              <p style="padding-top: 5px">
                {{ $t("stock.pin.first")
                }}<router-link
                  tag="a"
                  :to="'/profile/security?tab=pin'"
                  target="_blank"
                  style="color: #007bff; font-weight: bold"
                  >{{ $t("stock.pin.second") }}</router-link
                >
                {{ $t("stock.pin.third") }}
              </p>
            </b-form-group>
            <div class="d-flex justify-content-end mt-5">
              <b-button @click="onClose()" variant="light default">{{
                $t("action.cancel")
              }}</b-button>
              <b-button @click="onSubmit()" variant="primary default">{{
                  $t('action.confirm')
                }}</b-button>
            </div>
          </b-colxx>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { RepositoryFactory } from "@/apis/repositoryFactory";
import { mapActions } from "vuex";
import { LightGallery } from "vue-light-gallery";
import globalMixin from "@/core/mixins/global";
const { required } = require("vuelidate/lib/validators");
import { apiImageUrl,  } from "@/constants/config";
const ShopRepository = RepositoryFactory.get("shop");
import Swal from "sweetalert2";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [validationMixin, globalMixin],
  components: { LightGallery },
  data() {
    return {
      apiImageUrl,
      isLoading: false,
      form: {
        PackageId: this.item.Id,
        Quantity: 1,
      },
      photoIndex: null,
      Pin: null,
      isShow: false,
      isShowPassword: false,
      inputType: 'password',
      eventTickets: []
    };
  },
  computed: {
    images () {
      const lang = this.$i18n.locale.toUpperCase();
      return lang === "VI" ? require('./6-8.vi.jpg') : require('./6-8.en.jpg')
    }
  },
  watch: {
    "form.Quantity": function (newQuestion) {
      if (!newQuestion) {
      }
    },
  },
  validations: {
    form: {
      PackageId: { required },
      Quantity: { required },
    },
    Pin: { required },
  },
  methods: {
    togglePassword() {
      this.isShowPassword = !this.isShowPassword;
      this.inputType = this.isShowPassword ? "text" : "password";
    },
    showPopupBuy () {
      if (!Number(this.form.Quantity)) {
        this.isShow = false
        return
      }
      if (!this.isLogged) {
        Swal.fire({
          icon: 'info',
          title: 'Oops...',
          text: this.$t('action.login-before')
        })
        return
      }
      this.isShow = true
    },
    onClose () {
      this.Pin = ""
      this.isShow = false
    },
    fetchData () {
      this.$emit('reloadTicket', true)()
    },
    onThumbClick(index) {
      this.photoIndex = index;
    },
    handleHide() {
      this.photoIndex = null;
    },
    async onSubmit() {
      try {
        const data = {
          PackageId: this.form.PackageId,
          Pin: this.Pin,
          Quantity: this.form.Quantity
        };
        this.$v.$touch();
        if (!this.$v.$anyError) {
          this.isLoading = true;
          await ShopRepository.buyEventTicket(data);
          this.isShow = false
          Swal.fire({
            title: this.$t("swal.title.congratulations"),
            text: this.$t('payments.success-ticket'),
            icon: "success",
            confirmButtonText: 'OK',
            customClass: {
              title: 'ticket'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              this.fetchData()
            }
          });
        }
      } catch (error) {
        const res = JSON.parse(error.request.response)
        Swal.fire({
          title: '',
          text: res.message + '',
          icon: "error",
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            this.fetchData()
          }
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.list-thumbnail {
  height: auto !important;
}
.pe-none {
  pointer-events: none !important;
}
.thumb-mobile {
  @media screen and (max-width: 768px){
    margin-bottom: 20px !important;
  }
}
</style>
