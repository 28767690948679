<template>
  <form class="verification" @submit.prevent="submit" autocomplete="off">
    <div class="verification__title">
      {{ $t('payments.pin') }}
    </div>
    <div class="verification__input">
      <BaseInputCustom v-model="pin" class-name="pin__old theme-light" ref="pin" name="pin" required type="tel" is-pin
        inputmode="number" :min-length="6" :limit-input="6" :error-required="$t('security-pin.required')"
        :error-min-length="$t('security-pin.min-length')" :placeholder="$t('payments.pin')"
        :label="$t('transfer.pin')" />
    </div>
    <p class="verification__note">
      {{ $t('stock.pin.first')}} <a href="/profile/security?tab=pin" target="_blank">{{ $t('stock.pin.second')}}</a> {{
      $t('stock.pin.third')}}
    </p>
    <div class="d-flex justify-content-around">
      <button class="btn btn-primary px-5" :class="{'button--loading': loading}">
        <span>{{$t('action.submit')}}</span>
      </button>

      <button class="btn btn-outline-primary px-5" @click.prevent="closeModal">
        <span>{{$t('action.cancel')}}</span>
      </button>
    </div>

  </form>
</template>

<script>
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import validateMixin from "@/core/mixins/validate";
import { MODAL } from "@/constants";

export default {
  components: {
    BaseInputCustom
  },
  mixins: [validateMixin],
  data() {
    return {
      pin: ''
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(MODAL.PIN_VERIFICATION)
    },
    resetData() {
      this.pin = ''
    },
    async submit() {
      let refs = ['pin']
      const valid = this.$_validateMixin_refs(refs)
      if (valid) {
        this.$emit("submit", this.pin)
      }
    }
  },
}
</script>

<style scoped lang="scss">
.verification {
  font-family: 'Overpass';
  font-style: normal;
  padding-bottom: 32px;
  margin-top: 16px;

  &__title {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    text-transform: uppercase;
    color: #141822;
    margin-bottom: 32px;
  }

  &__input {
    position: relative;
  }

  &__note {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #898B91;
  }
}
</style>
