<template>
  <div class="profile">
    <div class="profile__header">
      <p class="title text-white">{{ $t('menu-items.profile') }}</p>
      <a v-if="routeName === 'Accounts'" @click="openCreateMemberModal" class="btn-create">{{$t('action.create-new-account')}}</a>
      <a v-if="routeName === 'KYC'" @click="handleUploadDocuments" class="btn-create">{{$t('action.upload-documents')}}</a>
    </div>
    <div class="profile__content border-radius-2">
      <div class="list" v-if="$route.name !== 'KYC Detail'">
        <div class="tabs">
          <div
            v-for="item in tabs"
            :key="item.name"
            class="item"
            :class="{'active': routeName === item.name }"
            @click="$router.push(item.path)"
          >
            <span>{{ item.label }}</span>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Info from "../../pages/App/Profile/Info"
import KYC from "../../pages/App/Profile/KYC"
import Password from "../../pages/App/Profile/Password"
import Security from "../../pages/App/Profile/Security"
import ShareBonus from "../../pages/App/Profile/Accounts"
import { MODAL, NOTIFICATION_ICON } from "@/constants";
import {mapGetters} from "vuex";

export default {
  components: {
    Info,
    KYC,
    Password,
    Security,
    ShareBonus,
  },
  data() {
    return {
      tabs: [
        {
          name: 'Overview',
          path: '/profile/overview',
          label: this.$t('menu-items.profiles.overview')
        },
        {
          name: 'KYC',
          path: '/profile/kyc',
          label: this.$t('menu-items.profiles.kyc')
        },
        {
          name: 'Link KYC',
          path: '/profile/link-kyc',
          label: this.$t('menu-items.profiles.link-kyc')
        },
        {
          name: 'Password',
          path: '/profile/password',
          label: this.$t('menu-items.profiles.password')
        },
        {
          name: 'Security',
          path: '/profile/security',
          label: this.$t('menu-items.profiles.security')
        },
        {
          name: 'Accounts',
          path: '/profile/accounts',
          label: this.$t('menu-items.profiles.add-account')
        }
      ]
    }
  },
  computed: {
    ...mapGetters("Auth", { currentUser: "currentUser" }),
    ...mapGetters("user", { KYC: "KYC" }),
    routeName() {
      return this.$route.name
    }
  },
  methods: {
    openCreateMemberModal() {
      this.$bvModal.show(MODAL.CREATE_ACCOUNT)
    },
    handleUploadDocuments() {
      if (this.KYC && this.KYC.KYCReview && this.KYC.KYCReview.length) {
        this.$notifyPopup(
          NOTIFICATION_ICON.ERROR,
          this.$t("swal.title.ops"),
          this.$t("notify.kyc.submitted"),
          {action1Text: "OK", action1Func: ()=> {}}
        )
        return
      }
      if (this.KYC && this.KYC.KYCApproved && this.KYC.KYCApproved.length) {
        this.$notifyPopup(
          NOTIFICATION_ICON.ERROR,
          this.$t("swal.title.ops"),
          this.$t("notify.kyc.successfully"),
          {action1Text: "OK", action1Func: ()=> {}}
        )
        return
      }
      this.$bvModal.show(MODAL.UPLOAD_DOCUMENTS)
    }
  },

}
</script>

<style scoped lang="scss">
  .profile {
    padding: 24px;
    // background: rgb(245, 247, 250);
    // max-width: 1500px;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 26px;
      .btn-create {
        background: #007BFF;
        border-radius: 8px;
        padding: 8px 34px;
        cursor: pointer;
        text-decoration: inherit;
        text-align: center;
        color: #FFFFFF;
      }
      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        color: #141822;
        margin-bottom: 0;
      }
    }
    &__content {
      background: #FFFFFF;
      padding: 16px 24px;
      // max-width: 1500px;
      .list {
        overflow-y: hidden;
        overflow-x: auto;
        &::-webkit-scrollbar {
          opacity: 0;
          visibility: hidden;
        }
        .tabs {
          width: max-content;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-bottom: 1px solid #EAEAEA;

          .item {
            padding: 0 15px 15px 15px;

            &:first-child {
              padding-left: 0;
            }

            span {
              display: inline-block;
              font-weight: 400;
              font-size: 20px;
              line-height: 28px;
              color: #43464E;
              position: relative;
            }

            &.active, &:hover {
              cursor: pointer;

              span {
                color: #007BFF;

                &:before {
                  content: '';
                  width: 100%;
                  background: #007BFF;
                  position: absolute;
                  bottom: -15px;
                  left: 0;
                  height: 1px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
