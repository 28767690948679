<template>
  <div class="event-detail">
    <div class="event-detail__container">
      <div class="cta-back">
        <a @click="$router.push('/shop?tab=PreOrder')">
          <img src="/img/wallet/icon-back.svg" alt="" />
          <span>Quay lại đặt hàng trước</span>
        </a>
      </div>
      <img class="mw-100 banner-pre" src="/img/pre-order/banner-event-vi.webp" alt="banner" />
      <img class="mw-100 mt-4 banner-pre" src="/img/pre-order/banner-2-vi.jpg" alt="banner" />
      <b-colxx xxs="12 px-0">
        <ol class="main-list mt-5 px-3">
          <li class="main-list_li">
            <span> Thời gian chương trình: </span>
            <div>
              <ul>
                <li>Từ nay đến 31/12/2022</li>
              </ul>
            </div>
          </li>
          <li class="main-list_li">
            <span> Đối tượng khách hàng: </span>
            <div>
              <ul>
                <li>Tất cả người dùng trong hệ thống VLINKMETA.</li>
              </ul>
            </div>
          </li>
          <li class="main-list_li">
            <span> Cơ cấu giải thưởng: </span>
            <table class="table table-product table-responsive d-block d-lg-table">
              <thead>
                <td></td>
                <td>Tên sản phẩm</td>
                <td>Số lượng</td>
                <td>Giá niêm yết</td>
                <td>Giá tri ân (Giảm 20%)</td>
                <td>Giá trị đặt cọc (VLG)</td>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="item.name">
                  <td>{{ index }}</td>
                  <td>{{ item.name }}<span class="text-bold d-inline"></span></td>
                  <td>{{ item.qty }}</td>
                  <td>{{ $t("pre-order.from") }} {{ item.price | toCurrency(true, 0) }}<span class="text-bold d-inline">*</span></td>
                  <td>{{ item.sale | toCurrency(true, 0) }}</td>
                  <td>{{ item.deposit }}</td>
                </tr>
              </tbody>
            </table>
            <p>
              (*) Trị giá của giải thưởng là giá thị trường niêm yết bởi nhà
              cung cấp tại thời điểm tháng 9/2022. Trị giá thực tế của giải
              thưởng có thể thay đổi theo giá thị trường tại thời điểm diễn ra
              chương trình.
            </p>
          </li>
          <li class="main-list_li">
            <span> Hình thức quay số: </span>
            <div>
              <ul>
                <li>Quay số trực tiếp tại sự kiện</li>
              </ul>
            </div>
          </li>
          <li class="main-list_li">
            <span>
              Điều kiện, cách thức tiến hành và thủ tục tham gia chương trình:
            </span>
            <div>
              <ul>
                <li>Khách hàng đang là người dùng trong hệ thống VLINKMETA.</li>
                <li>Các vật phẩm chỉ được thanh toán bằng VLG token.</li>
                <li>
                  Mỗi khách hàng tham gia có thể đặt cọc mua nhiều vật phẩm.
                </li>
                <li>
                  Không được thay đổi cũng như hủy sản phẩm vì bất cứ lý do gì.
                </li>
                <li>
                  Đơn đặt cọc sẽ được đóng trước 1 tiếng đối với từng lượt quay
                  số.
                </li>
                <li>Số VLG đã đặt cọc cho các vật phẩm sẽ được hoàn trả lại nguyên vẹn nếu người tham gia không được
                  quay trúng vật phẩm tương ứng, việc hoàn trả sẽ diễn ra trong vòng 24h kể từ khi kết thúc quay số.
                </li>
              </ul>
            </div>
          </li>
          <li class="main-list_li">
            <span> Quy định về cách thức xác định trúng thưởng: </span>
            <div>
              <ul>
                <li>
                  15 loại vật phẩm sẽ được quay thành 15 đợt (mỗi đợt sẽ quay số
                  lần tương ứng với số lượng vật phẩm) để xác định người tham
                  gia may mắn.
                </li>
                <li>
                  Các khách hàng đã trúng lần quay trước sẽ không được quay vào
                  lần sau.
                </li>
              </ul>
            </div>
          </li>
          <li class="main-list_li pb-5">
            <span> Cách thức trao thưởng: </span>
            <div>
              <ul>
                <li>
                  Chương trình quay số sẽ được thực hiện trực tiếp tại sự kiện
                  và danh sách người may mắn sẽ được hiển thị trực tiếp để đảm
                  bảo tính minh bạch của chương trình.
                </li>
                <li>
                  Sau khi quay số kết thúc, kết quả quay số sẽ được công bố trên
                  trang chủ của VlinkMeta.
                </li>
                <li>
                  Trong vòng 24h kể từ khi danh sách khách hàng may mắn được
                  công bố trên trang chủ VlinkMeta, quý khách hàng vui lòng
                  thanh toán khoản tiền cần phải trả còn lại của vật phẩm trong
                  Lịch sử giao dịch.
                </li>
                <li>
                  Trong trường hợp quá thời hạn 24h mà khoản thanh toán chưa
                  được hoàn thành thì quý khách hàng sẽ mất phần đặt cọc và bị
                  huỷ quyền mua sản phẩm.
                </li>
                <li>
                  Sản phẩm không được quy đổi sang tiền mặt hoặc bất cứ tài sản
                  hiện kim nào khác.
                </li>
              </ul>
            </div>
          </li>
        </ol>
      </b-colxx>
    </div>
  </div>
</template>

<script>
import { preOrder } from "@/constants/packages"; 

export default {
  name: "EventProposal",
  data() {
    return {
      items: preOrder
    }
  }
};
</script>

<style lang="scss">
.event-detail {
  margin: 24px;

  @media only screen and (max-width: 768px) {
    margin: 0;
  }

  &__container {
    padding: 24px;

    @media only screen and (max-width: 768px) {
      padding: 12px;
    }

    background: #ffffff;
    border-radius: 8px;

    .cta-back {
      margin-bottom: 17px;

      a {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
      }

      img {
        width: 29px;
        margin-right: 16px;
      }

      span {
        font-size: 20px;
        line-height: 28px;
        color: #43464e;
      }
    }
  }

  .banner-pre {
    border-radius: 8px;
  }
}

.main-list {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #43464e;

  p {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: #43464e;
  }

  &_li {
    margin-bottom: 2rem;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #141822;
      display: block;
      margin-bottom: 8px;
    }

    ul li {
      list-style-type: disc;
    }
  }

  .table-product {
    text-align: center;
    // border-radius: 8px;
    border-collapse: separate;
    font-size: 0.9em;
    // overflow: hidden;
    border: 1px solid #132239;
    border-spacing: 0;

    thead {
      background: #132239;
      color: #ffffff;

      td {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }

    tbody {
      border: 2px solid #132239 !important;
    }

    td {
      border: 1px solid #132239 !important;
      border-color: #132239 !important;
      white-space: nowrap;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #43464e;
    }
  }

  .text-bold {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #43464e;
  }
}
</style>
