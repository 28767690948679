<template>
 <ShoppingCart />
</template>

<script>
import ShoppingCart from '@containers/Shopping-Cart/index.vue'
export default {
  name: "index",
  components: {
    ShoppingCart
  }
}
</script>

<style scoped>

</style>
