<template>
  <div class="mt-3 w-100">
    <b-card
      @click.prevent="toggleItem($event, data.id)"
      class="item-ticket"
      :class="{ 'd-flex flex-row border-radius-10  flex-column flex-sm-row position-relative': true }"
      no-body
    >
      <div class="d-flex justify-content-center align-items-center p-3" :class="{'zoom-in': isZoom}">
        <img
          alt="img"
          :src="images"
          class="list-thumbnail border-0"
          @click="onThumbClick(0)"
        />
        <LightGallery
          :images="[images]"
          :index="photoIndex"
          :disable-scroll="true"
          @close="handleHide()"
        />
      </div>
      <div class="pl-1 d-flex flex-grow-1 min-width-zero">
        <div
          class="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center"
        >
          <div class="d-flex justify-content-center mt-1 mb-2 flex-column">
            <p class="list-item-heading mb-2 truncate font-weight-bold">
              {{ $t('event.text.first') }}
              {{ $t('event.text.second') }} <br>
            </p>
            <!-- <p
              style="font-weight: 500; color: gray; width: 250px;"
              class="list-item-heading mb-2 truncate">
              {{$t('contact.full-name')}}: {{ data.FullName }}
            </p> -->
            <p
              style="font-weight: 500; color: gray; width: 250px;"
              class="list-item-heading mb-2 truncate">
              {{$t('users.infor.phonenumber')}}: {{ data.PhoneNumber }}
            </p>
            <p
              style="font-weight: 500; color: gray; width: 250px;"
              class="list-item-heading mb-2 truncate">
              Email: {{ data.Email }}
            </p>
            <!-- <p
              v-if="!data.PhoneNumber || !data.FullName"
              style="text-decoration: underline;"
              class="list-item-heading mb-1 text-danger noted-mb"
            >
              {{ $t('event.noted') }}
            </p>
            <button
              type="button"
              class="btn btn-info bg-primary br-1 text-uppercase px-0 font-weight-bold btn-buy-ticket btn-default"
              @click="showUpdate = true"
            >{{ $t('action.update') }}</button> -->
          </div>
          <div
            class="d-flex justify-content-center flex-column align-items-center">
            <p
              class="mb-0 text-muted w-sm-100 font-weight-bold text-center"
              style="margin-bottom: 15px !important;"
            >
              {{ data.Code }}<i class="iconsminds-file-copy" @click="copyLink(data.Code)"></i>
            </p>
            <div
              :id="data.Code"
              @click="showQRcode(data.Code)">
              <QrcodeVue
                class-name="mb-2 qr-code-ticket"
                :id="data.Code"
                :value="data.Code"
                :size="isZoomCode ? 300 : 100"
                level="L"
              ></QrcodeVue>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <a style="text-decoration: underline;" class="ml-2" href="javascript:void(0)" @click="downloadQR(data.Code, data.Code)">
                {{ $t("action.download")}}
              </a>
              <span style="padding: 0 10px;">|</span>
              <a style="text-decoration: underline;" href="javascript:void(0)" @click="showGive = true">
                {{ $t('event.give') }}
              </a>
            </div>
          </div>
          <!-- <div class="text-muted w-20-sm-100 my-2 ml-2 mr-2">
            <p v-if="data.BuyDate" class="buy-date">
              <span> {{ $t('checkout-date') }}:</span>
              <b class="font-weight-bold">{{ data.BuyDate | formatDateTime }}</b>
            </p>
            <p v-else class="buy-date">
              <span> {{ $t('checkout-date') }}:</span>
              <b class="font-weight-bold">{{ $t('not-activated') }}</b>
            </p>
            <p v-if="data.CheckInDate" class="buy-date">
              <span>{{ $t('activate-date') }}:</span>
              <b class="font-weight-bold">{{ data.CheckInDate | formatDateTime }}</b>
            </p>
            <p v-else class="buy-date">
              <span>{{ $t('activate-date') }}:</span>
              <b class="font-weight-bold">{{ $t('not-activated') }}</b>
            </p>
          </div> -->

          <div class="w-10 w-sm-100 d-flex justify-content-center">
            <b-badge pill :variant="STATUS_COLOR[data.Status]">{{
                data.Status
              }}</b-badge>
          </div>
        </div>
      </div>
      <p
        v-if="!data.PhoneNumber || !data.FullName"
        style="text-decoration: underline;"
        class="list-item-heading mb-1 text-danger item-noted"
      >
        {{ $t('event.noted') }}
      </p>
    </b-card>
    <ModalGiveTicket
      :showGive="showGive"
      :ticketId="data.Id"
      @onClose="onClosePopupGive"
      @handleData="$emit('handleData', true)"
    />
    <ModalUpdateInforTicket
      :showUpdate="showUpdate"
      :ticketId="data.Id"
      :fullName="data.FullName"
      :phoneNumber="data.PhoneNumber"
      @onClose="onClosePopupUpdate"
      @handleData="$emit('handleData', true)"
    />
  </div>
</template>

<script>
import { STATUS_COLOR } from "@/constants";
import helpers from "@/core/mixins/helpers"
const { required } = require("vuelidate/lib/validators");
import QrcodeVue from "qrcode.vue";
import {copyToClipboard} from "@/core/utils";
import { LightGallery } from "vue-light-gallery";
import ModalGiveTicket from '@/containers/Shop/event-ticket/modal-give-ticket.vue'
import ModalUpdateInforTicket from '@/containers/Shop/event-ticket/modal-update-infor-ticket'
import QRCode from 'qrcode'
import Swal from "sweetalert2";
export default {
  components: {
    QrcodeVue,
    LightGallery,
    ModalGiveTicket,
    ModalUpdateInforTicket,
  },
  data: () => ({
    isZoom: false,
    isZoomCode: false,
    STATUS_COLOR,
    photoIndex: null,
    showUpdate: false,
    showGive: false
  }),
  props: ["data", "selectedItems"],
  mixins: [helpers],
  computed: {
    images () {
      const lang = this.$i18n.locale.toUpperCase();
      return lang === "VI" ? require('./6-8.vi.jpg') : require('./6-8.en.jpg')
    }
  },
  methods: {
    isIpadOS() {
      return navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform);
    },
    onClosePopupUpdate (value) {
      this.showUpdate = value
    },
    onClosePopupGive (value) {
      this.showGive = value
    },
    toggleItem(event, itemId) {
      this.$emit("toggle-item", event, itemId);
    },
    copyLink(contents) {
      copyToClipboard(contents)
      this.$notify('success', this.$t('action.copied-text'), '')
    },
    downloadQR(nameFile = 'qrcode-for-scan.png', id = null) {
      const opts = {
        errorCorrectionLevel: 'L',
        width: 300,
      };
      QRCode.toDataURL(id, opts, function (err, url) {
      if (err) throw err
      let link = document.createElement('a')
      link.href = url
      link.download = nameFile
      link.click()
    })
      // let imageWrapper = document
      // if (id) {
      //   imageWrapper = document.getElementById(id)
      // }
      // let image = imageWrapper.getElementsByTagName('canvas')[0].toDataURL()
      // let link = document.createElement('a')
      // link.href = image
      // link.download = nameFile
      // link.click()
    },
    onThumbClick(index) {
      this.photoIndex = index;
    },
    handleHide() {
      this.photoIndex = null;
    },
    async showQRcode (id = null) {
      let imageWrapper = document
      if (id) {
        imageWrapper = document.getElementById(id)
      }
      const image = imageWrapper.getElementsByTagName('canvas')[0].toDataURL();
      const html = `<div class="card-with-title">
      <h5 class="text-center">QR Code:</h5><div class="input-group mb-3 align-items-center justify-content-center">
      <img src="${image}" class="mw-300 border-radius-1" style="width: var(--particular-ad);" />
      </div></div></div>`;
      await Swal.fire({
        customClass: {
          popup: 'bg-white',
        },
        html: html
      })
    }
  },
};
</script>
<style scoped lang="scss">
@media screen and (min-width: 768px) {
  :root { --particular-ad: 50%; }
}
.zoom-in {
  @media screen and (min-width: 768px) {
    transform: scale(3.5);
    position: absolute;
    left: 38%;
    z-index: 2;
  }
}
.item-noted {
  position: absolute;
  bottom: 5px;
  left: 15px;
  @media screen and (max-width: 768px){
    display: none;
  }
}
.noted-mb {
  display: none;
  @media screen and (max-width: 768px){
    display: block;
  }
}
.btn-buy-ticket {
  width: 45%;
  margin: 10px 0;
  @media screen and (max-width: 992px){
    margin: 10px auto;
  }
}
.buy-date {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 !important;
  padding: 15px 0;
  @media screen and (max-width: 768px){
    flex-direction: row;
  }
}
.item-ticket {
  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column !important;
  }
}
.list-thumbnail {
  width: 275px;
  @media screen and (max-width: 1000px) {
    height: auto;
  }
}
canvas {
  @media screen and (max-width: 1000px) {
    width: 300px !important;
    height: 300px !important;
  }
}
.iconsminds-file-copy:before { content: '\e8aa'; }
</style>
