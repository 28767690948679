<template>
  <PackageDetails />
</template>

<script>
import PackageDetails from '@containers/Shop/package-detail.vue'
export default {
  name: "index",
  components: {
    PackageDetails
  }
}
</script>

<style scoped>

</style>
