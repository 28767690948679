<template>
  <div v-if="selectCredit" class="credit">
    <div class="img-arrow" @click="setSelectCredit()">
      <img src="/img/arrow-back.svg" alt="arrow-back" class="arrow-back">
    </div>
    <div class="header">
      <img src="/img/arrow-back.svg" alt="arrow-back" class="arrow-back" @click="setSelectCredit()">
      <span class="title" v-if="!hasInfo">{{ $t('action.new-card') }}</span>
      <span class="title" v-else>{{ $t('action.edit-card') }}</span>
      <span />
    </div>
    <div class="content">
      <span class="title" v-if="!hasInfo">{{ $t('action.new-card') }}</span>
      <span class="title" v-else>{{ $t('action.edit-card') }}</span>
      <p class="text-protect">
        <img src="/img/payment/protect.svg" alt="">
        {{ $t('pay-protected') }}
      </p>
      <div class="form-credit">
        <BaseInputCustom class-name="credit-input" ref="CardNumber" v-model="formData.CardNumber" type="tel"
          name="CardNumber" required inputmode="numeric" :label="$t('payments.methods.creadit.number')" :limitInput="20"
          :markCustom="markCustom" :error-required="$t('validate.required', [$t('payments.methods.creadit.number')])" />
        <div class="name-card">
          <BaseInputCustom class-name="credit-input w-49" ref="FirstName" v-model="formData.FirstName" type="text"
            name="FirstName" required :label="$t('users.infor.first-name')"
            :error-required="$t('validate.required', [$t('users.infor.first-name')])" />
          <BaseInputCustom class-name="credit-input w-49" ref="LastName" v-model="formData.LastName" type="text"
            name="LastName" required :label="$t('users.infor.last-name')"
            :error-required="$t('validate.required', [$t('users.infor.last-name')])" />
        </div>
        <BaseInputCustom class-name="credit-input" ref="Address" v-model="formData.Address" type="text" name="Address"
          required :label="$t('users.infor.address')"
          :error-required="$t('validate.required', [$t('users.infor.address')])" />
        <BaseInputCustom class-name="credit-input" ref="City" v-model="formData.City" type="text" name="City" required
          :label="$t('users.infor.city')" :error-required="$t('validate.required', [$t('users.infor.city')])" />
        <div class="time-expiration">
          <span class="title">{{ $t('payments.methods.creadit.expir') }}</span>
          <BaseDatePicker id="ExpirationDate" ref="ExpirationDate" :value="formData.ExpirationDate"
            @input="formData.ExpirationDate = $event" />
          <p v-if="errorExpiration" class="error">{{ $t('validate.required',
          [$t('payments.methods.creadit.ExpirationDate')]) }}</p>
        </div>
        <div class="code-credit">
          <BaseInputCustom class-name="credit-input w-49" ref="CardCode" v-model="formData.CardCode" type="tel"
            :limit-input="3"
            name="CardCode" required inputmode="numeric" label="CVV"
            :error-required="$t('validate.required', ['CVV'])" />
          <BaseInputCustom class-name="credit-input w-49" ref="Zip" v-model="formData.Zip" type="text" name="Zip"
            required :label="$t('payments.methods.creadit.zip-code')"
            :error-required=" $t('validate.required', [$t('payments.methods.creadit.zip-code')])" />
        </div>
      </div>
      <div class="action">
        <button @click.prevent="submit()">
          <span>{{ $t('action.next') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import validateMixin from '@/core/mixins/validate'
import BaseDatePicker from "@components/Inputs/BaseDatePickerCredit.vue"
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import moment from "moment";
export default {
name: "form-credit",
components: {
BaseInputCustom,
BaseDatePicker
},
mixins: [validateMixin],
props: {
cartId: {
type: String,
default: ''
},
selectCredit: {
type: Boolean,
default: false
},
hasInfo: {
type: Boolean,
default: false
},
},
data() {
return {
errorExpiration: false,
formData: {
Address: '',
CardCode: '',
CardNumber: '',
City: '',
ExpirationDate: '',
FirstName: '',
LastName: '',
Zip: '',
},
markCustom: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]
}
},
watch: {
'formData.ExpirationDate'(value) {
value ? this.errorExpiration = false : this.errorExpiration = true
}
},
methods: {
submit() {
const refs = ['Address', 'CardCode', 'CardNumber',
'City', 'FirstName', 'LastName', 'Zip']
const refsValid = this.$_validateMixin_refs(refs)
this.errorExpiration = true
if (this.formData.ExpirationDate) {
this.errorExpiration = false
this.formData.ExpirationDate
}
if (refsValid && !this.errorExpiration) {
this.$emit('setDataForm', this.formData)
this.$emit('selectCreditCard', true)
}
},
setSelectCredit() {
this.$emit('selectCreditCard', false)
},
resetForm() {
const refs = ['Address', 'CardCode', 'CardNumber', 'City', 'FirstName', 'LastName', 'Zip']
this.errorExpiration = false
this.formData = {
Address: '',
CardCode: '',
CardNumber: '',
City: '',
ExpirationDate: '',
FirstName: '',
LastName: '',
Zip: '',
}
this.$_validateMixin_reset(refs)
}
}
}
</script>

<style scoped lang="scss">
.credit {
  background: #FFFFFF;
  padding: 24px;

  .img-arrow {
    margin-bottom: 32px;
    display: block;

    &:hover {
      cursor: pointer;
    }
  }

  .header {
    display: none;
  }

  .content {
    .title {
      display: block;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: #141822;
      margin-bottom: 14px;
      text-align: center;
    }

    .text-protect {
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #009962;
    }

    .form-credit {
      background: #F5F7FA;
      border-radius: 8px;
      padding: 24px 16px;
      margin-bottom: 32px;

      .name-card,
      .code-credit {
        display: flex;
        justify-content: space-between;
        align-content: center;
      }

      .time-expiration {
        margin-bottom: 20px;

        span.title {
          font-weight: 400;
          font-size: 20px;
          line-height: 25px;
          color: #141822;
          display: block;
          text-align: left;
        }

        /deep/.base-datepicker {
          input {
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #E8E8E9;
            // border-radius: 8px 0 0 8px;
            color: #000000;
          }
        }

        .error {
          margin-top: 5px;
          color: #EA290E;
          display: inline-block;
          font-size: 12px;
          line-height: 16px;
          font-weight: 500;
          margin-bottom: 0;
        }
      }

      .credit-input {
        margin-bottom: 15px;

        /deep/.base-input-custom__wrap {
          label {
            font-weight: 400;
            font-size: 18px;
            white-space: nowrap;
            line-height: 25px;
            color: #141822;
          }

          input {
            background: #FFFFFF;
            border: 1px solid #E8E8E9;
            // border-radius: 8px 0 0 8px;
            color: #000000;
          }
        }
      }
    }

    .action {
      button {
        background: #007BFF;
        border-radius: 8px;
        width: 100%;
        border: none;

        span {
          padding: 12px 0;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          display: inline-block;
          color: #FFFFFF;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    overflow-y: auto;
    padding: 0;
    z-index: 999;

    .img-arrow {
      display: none;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      padding: 0 20px;
      background: #FFFFFF;
      box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
      position: relative;

      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #141822;
        margin-bottom: 0;
      }
    }

    .content {
      padding: 16px;

      .title {
        display: none;
      }

      .text-protect {
        font-size: 14px;
        line-height: 20px;
      }

      .form-credit {
        background: transparent;
        padding: 0;
      }
    }
  }
}
</style>
