<template>
  <div class="position-relative">
    <title-marquee class="text-white py-2 h5 text-marquee position-absolute" />
    <div class="statistics">
      <div class="row justify-content-center text-center">
        <div class="col-6 col-md-2">
          <p class="text-white my-4">
            ${{ tokenPrice }} <br />
            <span>{{ 'VLG TOKEN' }}</span>
          </p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-white my-4">
            356 <br />
            <span>{{ $t("menu-items.partners") }}</span>
          </p>
        </div>
        <div class="col-6 col-md-2 d-md-block d-none">
          <div class="text-white position-relative">
            <div class="nk-circle-animation nk-df-center fast" />
            <img src="/img/vlg-01.png" class="img-responsive mw-100" />
          </div>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-white my-4">
            100+ <br />
            <span>{{ $t("countries") }}</span>
          </p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-white my-4">
            ${{ tokenPrice }}{{ $t("unitCap") }}<br />
            <span>{{ $t("cap") }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const HomeRepository = RepositoryFactory.get("home");
import { RepositoryFactory } from "@apis/repositoryFactory";
import TitleMarquee from '@components/title-marquee.vue';

export default {
  components: {
    TitleMarquee
  },
  data() {
    return {
      tokenPrice: 0
    };
  },
  computed: {
    ...mapGetters("Auth", { staticUser: "staticUser" }),
  },
  mounted() {
    this["Auth/getStatisticUser"]();
  },
  async created() {
    try {
      const prices = await HomeRepository.getPrice();
      this.tokenPrice = prices.TokenPrice
    } catch (error) { }
  },
  methods: {
    ...mapActions(["Auth/getStatisticUser"]),
  },
};
</script>

<style scoped lang="scss">
.text-marquee {
  background: rgb(8,83,177);
  top: -82px;
  left: 0;
  right: 0;
  z-index: 2;
  margin: 0;
  background: linear-gradient(90deg, rgba(8,83,177,1) 0%, rgba(8,83,177,0.7665660014005602) 51%, rgba(8,83,177,1) 100%);
}
.statistics {
  background: linear-gradient(90.17deg, #000869 0.79%, #000553 7.94%, #00054e 19.17%, #000347 33.04%, #001d37 48.9%, #001a35 53.13%, #001b36 56.79%, #000553 86.07%, #000869 99.37%);

  img {
    position: absolute;
    left: 50%;
    top: -6px;
    transform: translate(-50%, -15%);
    z-index: 1;
    width: 200px;
    max-height: 200px;
  }

  p {
    font-size: 36px;
    line-height: 44px;

    span {
      color: #ADAEB2;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 32px;
      line-height: 40px;

      span {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .nk-circle-animation {
    height: 175px;
    width: 175px;
    z-index: 2;
    overflow: hidden;

    &:before,
    &:after {
      position: absolute;
      content: '';
      border: 3px solid rgba(151, 175, 213, 0.3);
      border-radius: 50%;
    }

    &:before {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-top-color: #ffc107;
      border-right-color: #ffc107;
      animation: rotatecw 15s linear infinite 0s;
    }

    &:after {
      top: 10px;
      left: 10px;
      bottom: 10px;
      right: 10px;
      border-top-color: #ffc107;
      border-bottom-color: #ffc107;
      animation: rotateacw 10s linear infinite 0s;
    }
  }

  .nk-df-center {
    position: absolute !important;
    top: 62px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes rotatecw {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotateacw {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
</style>
