<template>
  <div v-if="loading" class="loading-page">
    <div class='container'>
      <div class='loader'>
        <div class='loader--dot'></div>
        <div class='loader--dot'></div>
        <div class='loader--dot'></div>
        <div class='loader--dot'></div>
        <div class='loader--dot'></div>
        <div class='loader--dot'></div>
        <div class='loader--text'></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style
  scoped
  lang="scss"
>
//.loading-page {
//  display: flex;
//  z-index: 9;
//  padding: 1rem;
//  align-items: center;
//  justify-content: center;
//  .sk-text {
//    color: #141822;
//    font-size: 24px;
//    line-height: 20px;
//    text-align: center;
//    text-align: center;
//    text-transform: uppercase;
//  }
//  .sk-circle {
//    display: inline-block;
//    margin-right: 10px;
//    width: 40px;
//    height: 40px;
//    position: relative;
//    > .sk-child {
//      width: 100%;
//      height: 100%;
//      position: absolute;
//      left: 0;
//      top: 0;
//      &:before {
//        content: '';
//        display: block;
//        margin: 0 auto;
//        width: 20%;
//        height: 20%;
//        background-color: #141822;
//        border-radius: 100%;
//        -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
//        animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
//      }
//    }
//    > .sk-circle2 {
//      -webkit-transform: rotate(30deg);
//      -ms-transform: rotate(30deg);
//      transform: rotate(30deg);
//      &:before {
//        -webkit-animation-delay: -1.1s;
//        animation-delay: -1.1s;
//      }
//    }
//    > .sk-circle3 {
//      -webkit-transform: rotate(60deg);
//      -ms-transform: rotate(60deg);
//      transform: rotate(60deg);
//      &:before {
//        -webkit-animation-delay: -1s;
//        animation-delay: -1s;
//      }
//    }
//    > .sk-circle4 {
//      -webkit-transform: rotate(90deg);
//      -ms-transform: rotate(90deg);
//      transform: rotate(90deg);
//      &:before {
//        -webkit-animation-delay: -0.9s;
//        animation-delay: -0.9s;
//      }
//    }
//    > .sk-circle5 {
//      -webkit-transform: rotate(120deg);
//      -ms-transform: rotate(120deg);
//      transform: rotate(120deg);
//      &:before {
//        -webkit-animation-delay: -0.8s;
//        animation-delay: -0.8s;
//      }
//    }
//    > .sk-circle6 {
//      -webkit-transform: rotate(150deg);
//      -ms-transform: rotate(150deg);
//      transform: rotate(150deg);
//      &:before {
//        -webkit-animation-delay: -0.7s;
//        animation-delay: -0.7s;
//      }
//    }
//    > .sk-circle7 {
//      -webkit-transform: rotate(180deg);
//      -ms-transform: rotate(180deg);
//      transform: rotate(180deg);
//      &:before {
//        -webkit-animation-delay: -0.6s;
//        animation-delay: -0.6s;
//      }
//    }
//    .sk-circle8 {
//      -webkit-transform: rotate(210deg);
//      -ms-transform: rotate(210deg);
//      transform: rotate(210deg);
//      &:before {
//        -webkit-animation-delay: -0.5s;
//        animation-delay: -0.5s;
//      }
//    }
//    > .sk-circle9 {
//      -webkit-transform: rotate(240deg);
//      -ms-transform: rotate(240deg);
//      transform: rotate(240deg);
//      &:before {
//        -webkit-animation-delay: -0.4s;
//        animation-delay: -0.4s;
//      }
//    }
//    > .sk-circle10 {
//      -webkit-transform: rotate(270deg);
//      -ms-transform: rotate(270deg);
//      transform: rotate(270deg);
//      &:before {
//        -webkit-animation-delay: -0.3s;
//        animation-delay: -0.3s;
//      }
//    }
//    > .sk-circle11 {
//      -webkit-transform: rotate(300deg);
//      -ms-transform: rotate(300deg);
//      transform: rotate(300deg);
//      &:before {
//        -webkit-animation-delay: -0.2s;
//        animation-delay: -0.2s;
//      }
//    }
//    > .sk-circle12 {
//      -webkit-transform: rotate(330deg);
//      -ms-transform: rotate(330deg);
//      transform: rotate(330deg);
//      &:before {
//        -webkit-animation-delay: -0.1s;
//        animation-delay: -0.1s;
//      }
//    }
//  }
//}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.loading-page {
  height: 300px;
}

.loader {
  height: 20px;
  width: 200px;
  position: absolute;
  top: 30%;
  /* bottom: 0; */
  left: 50%;
  transform: translateX(-50%);
  /* right: 0; */
  margin: auto;
}
.loader--dot {
  animation-name: loader;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;
}
.loader--dot:first-child {
  background-color: #8cc759;
  animation-delay: 0.5s;
}
.loader--dot:nth-child(2) {
  background-color: #8c6daf;
  animation-delay: 0.4s;
}
.loader--dot:nth-child(3) {
  background-color: #ef5d74;
  animation-delay: 0.3s;
}
.loader--dot:nth-child(4) {
  background-color: #f9a74b;
  animation-delay: 0.2s;
}
.loader--dot:nth-child(5) {
  background-color: #60beeb;
  animation-delay: 0.1s;
}
.loader--dot:nth-child(6) {
  background-color: #fbef5a;
  animation-delay: 0s;
}
.loader--text {
  position: absolute;
  top: 200%;
  left: 50%;
  right: 0;
  /* width: 4rem; */
  margin: auto;
  transform: translateX(-50%);
}
.loader--text:after {
  content: "Loading";
  font-weight: bold;
  animation-name: loading-text;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
}
@keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}
</style>
