<template >
  <button class="countdown" v-if="!finish" @click="$emit('click')">
    <span style="padding-right: 5px;">{{ $t('borrow.payment') }} </span>
    <span>({{ days | twoDigits }}D:{{ hours | twoDigits }}H:{{ minutes | twoDigits }}M)</span>
  </button>
  <button class="countdown finish" v-else >
    {{ $t('pre-order.finishPayment') }}
  </button>
</template>
<script>
export default {
  props: {
    date: null,
  },
  data() {
    let offsetTime = (new Date().getTimezoneOffset() - (5 * 60)) * 60000;
    return {
      now: Math.trunc((new Date().getTime() + offsetTime) / 1000),
      event: this.date,
      finish: true
    }
  },
  mounted() {
    this.finish = (this.calculatedDate - this.now) <= 0
    const _self = this
    window.setInterval(() => {
      let offsetTime = (new Date().getTimezoneOffset() - (5 * 60)) * 60000;
      this.now = Math.trunc((new Date().getTime() + offsetTime) / 1000)
      if (!this.finish && this.calculatedDate - this.now <= 0) {
        _self.finish = true
        _self.$emit('onFinish')
      }
    }, 1000)
  },
  created() {
    if ((this.calculatedDate - this.now) <= 0) {
      this.$emit('onFinish')
    }
  },
  computed: {
    secondCount() {
      return this.calculatedDate - this.now
    },
    calculatedDate() {
      return Math.trunc(Date.parse(this.event) / 1000)
    },
    seconds() {
      if (this.secondCount < 0) return 0
      return this.secondCount % 60
    },
    minutes() {
      if (this.secondCount < 0) return 0
      return Math.trunc(this.secondCount / 60) % 60
    },
    hours() {
      if (this.secondCount < 0) return 0
      return Math.trunc(this.secondCount / 60 / 60) % 24
    },
    days() {
      if (this.secondCount < 0) return 0
      return Math.trunc(this.secondCount / 60 / 60 / 24)
    },
  },
  filters: {
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    },
  },
}
</script>
<style lang="scss">
.countdown {
  width: 100%;
  height: 40px;
  background: #007BFF;
  border: 1px solid #007BFF;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #FFFFFF;
    white-space: nowrap;
    padding-top: 3px;
  }
  &.finish {
    pointer-events: none !important;
    background: #ADAEB2;
    border: 1px solid #ADAEB2;
  }
}
</style>
