<template>
  <div class="convert">
    <div class="convert__title">{{ $t('action.convert') }} VUSD</div>
    <div class="convert__content">
      <BaseInputCustom
        class-name="theme-light"
        ref="amount"
        v-model="amount"
        type="text"
        name="amount"
        required
        placeholder="0.00"
        :label="$t('input.vtp')"
        thousands-separator-symbol=","
        :balance="$options.filters.formatNumber(walletValue.VLGPlus.Amount)"
        :maxValue="$options.filters.formatNumber(walletValue.VLGPlus.Amount)"
        :error-required="$t('validate.required', ['VTP'])"
      />
    </div>
    <p class="text-note">{{ $t("Business") }}</p>
    <p class="text-note">{{ $t("Business-2") }}</p>
    <div class="convert__action">
      <button
        class="btn btn-primary btn-round convert__btn-cancel"
        @click="$emit('cancel')"
      >
        {{ $t('action.cancel') }}
      </button>
      <button
        class="btn btn-primary"
        @click="submit"
        :class="{ 'button--loading': loading }"
      >
        <span>{{$t('action.convert')}}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { NOTIFICATION_ICON } from "@/constants";
import BaseInputCustom from "@components/Inputs/BaseInputCustomWallet";
import validateMixin from "@/core/mixins/validate";
import { RepositoryFactory } from "@apis/repositoryFactory";
const WalletRepository = RepositoryFactory.get("wallet");
import { mapGetters } from "vuex";

export default {
  components: {
    BaseInputCustom,
  },
  mixins: [validateMixin],
  data() {
    return {
      amount: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      walletValue: "getWallet",
    }),
  },
  mounted() {},
  methods: {
    async submit() {
      const refs = ["amount"];
      const refsValid = this.$_validateMixin_refs(refs);
      if (refsValid) {
        this.loading = true;
        try {
          const response = await WalletRepository.convertVTPToCash(this.amount);
          if (response && Object.keys(response).length) {
            this.$notifyPopup(
              NOTIFICATION_ICON.SUCCESS,
              this.$t("swal.title.success"),
              this.$t("conver-cash-success"),
              {
                action1Text: "OK",
                action1Func: () => ({}),
              }
            );
            this.$emit("refresh")
          } else {
            this.$notifyPopup(
              NOTIFICATION_ICON.ERROR,
              this.$t("swal.title.fail"),
              response.message,
              {
                action1Text: this.$t('action.try'),
                action1Func: () => ({}),
              }
            );
          }
        } catch (error) {
          this.$notifyPopup(
            NOTIFICATION_ICON.ERROR,
            this.$t("swal.title.fail"),
            error.response.data.message,
            {
              action1Text: this.$t('action.try'),
              action1Func: () => ({}),
            }
          );
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.convert {
  &__title {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #141822;
    margin-bottom: 32px;
  }
  &__password {
    margin-top: 15px;
  }
  &__btn {
    height: 40px;
  }
  &__action {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
  }
  &__btn-cancel {
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;
    &:hover {
      background: #007bff;
      color: #ffffff;
    }
  }
  .text-note {
    font-size: 14px;
    line-height: 20px;
    color: #898b91;
  }
}
::v-deep .eye {
  color: #43464e !important;
}
::v-deep .base-select {
  margin-bottom: 20px;
  &__label {
    color: #43464e;
  }
}
::v-deep .base-select__wrap {
  background: #f7f7f7;
  .arrow-down {
    color: #43464e;
  }
}
::v-deep .base-select__inner {
  p {
    color: #898b91;
  }
}
</style>
