<template>
  <div class="loan-detail">
    <div class="loan-detail__title">
      <h2>{{ $t("borrow.loandetail") }}</h2>
    </div>
    <div class="loan-detail__table">
      <b-card>
        <Breadcrumb
          :label="$t('borrow.back-credit-lending')"
          @onBack="$emit('onBack')"
        />
        <p class="loan-detail__table--title my-3 text-capitalize">
          {{ $t("borrow.invoice") }}
        </p>
        <div class="list">
          <table>
            <thead>
              <tr>
                <th>{{ $t("borrow.end") }}</th>
                <th>{{ $t("borrow.status") }}</th>
                <th>{{ $t("borrow.needpay") }}</th>
                <th>{{ $t("borrow.codepay") }}</th>
                <th>{{ $t("borrow.action") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in list" :key="index">
                <td>{{ item.DateTime | formatDate(formatValue) }}</td>
                <td>{{ item.Status }}</td>
                <td>{{ item.Amount | toCurrency(true) }}</td>
                <td>{{ item.PaymentCode }}</td>
                <td>
                  <a
                    @click="onPayment(item)"
                    :class="{ disabled: item.Status === 'Paid' }"
                    >{{ $t("borrow.payment") }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="!list.length" class="empty-table">
          <img src="/img/empty-folder.png" alt="img empty" />
          <span>No data</span>
        </div>
      </b-card>
    </div>
    <ModalRepayDetail :item="item" @onRefresh="$emit('onRefresh')" />
  </div>
</template>

<script>
import globalMixin from "@/core/mixins/global";
import { MODAL } from "@/constants";
import ModalRepayDetail from "@/containers/Wallet/modal-repay-detail.vue";
import Breadcrumb from "./breadcrumb.vue";

export default {
  components: {
    ModalRepayDetail,
    Breadcrumb,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [globalMixin],

  data() {
    return {
      MODAL,
      item: {},
    };
  },
  computed: {
    formatValue() {
      const lang = this.$i18n.locale.toUpperCase();
      return lang == "VI" ? "DD/MM/YYYY HH:mm" : "MM/DD/YYYY hh:mm A";
    },
  },
  methods: {
    openModal(modalId) {
      this.$bvModal.show(modalId);
    },
    onPayment(item) {
      this.item = item;
      this.openModal(MODAL.REPAY_DETAIL);
    },
  },
};
</script>

<style scoped lang="scss">
.loan-detail {
  margin: 24px;
  @media only screen and (max-width: 500px) {
    margin: 16px;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    h2 {
      color: #141822;
      font-family: "Overpass";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 12px;
    }
    .btn {
      min-width: 240px;
      height: 40px;
    }
    @media only screen and (max-width: 980px) {
      flex-direction: column;
    }
    @media only screen and (max-width: 830px) {
      flex-direction: column;
      .loan-detail__action {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .btn {
          width: 100%;
          &:first-child {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  &__btn-withdraw {
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;
    margin-right: 16px;
    &:hover {
      background: #007bff;
      color: #ffffff;
    }
  }
  &__table {
    overflow-y: hidden;
    overflow-x: auto;
    min-height: 600px;
    .card {
      min-height: 70vh;
      margin-bottom: 24px;
      .list {
        overflow-x: auto;
        overflow-y: hidden;
      }
    }
    &--title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #43464e;
    }
    &--info {
      display: flex;
      justify-content: space-between;
      padding-bottom: 28px;
      border-bottom: solid 1px #eaeaea;
      .info-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          width: 120px;
        }
        .amount {
          display: flex;
          flex-direction: column;
          margin-left: 16px;
          .token-name {
            font-weight: 700;
            font-size: 32px;
            line-height: 44px;
            color: #898b91;
            .btn-certificate {
              padding: 9px 24px;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #007bff;
              background: rgba(0, 123, 255, 0.2);
              border-radius: 8px;
              cursor: pointer;
            }
          }
          .token-value {
            font-weight: 700;
            font-size: 24px;
            line-height: 34px;
            display: flex;
            align-items: center;
            color: #141822;
          }
        }
      }
      .info-right {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .form-group {
          margin-bottom: 6px;
        }
        .address {
          text-align: center;
          p {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #43464e;
          }
        }
      }
      @media only screen and (max-width: 1160px) {
        flex-direction: column;
        justify-content: flex-start;
        .info-left {
          margin-bottom: 20px;
          justify-content: center;
        }
        .info-right {
          justify-content: center;
        }
      }
      @media only screen and (max-width: 500px) {
        flex-direction: column;
        .info-left {
          img {
            width: 70px;
          }
          .amount {
            .token-name {
              font-size: 20px;
              line-height: 32px;
            }
            .token-value {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
        .info-right {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .address {
            align-items: center;
            margin-bottom: 1rem;
          }
        }
      }
    }
    table {
      width: 100%;
      thead {
        th {
          padding: 10px;
          background: rgba(173, 174, 178, 0.2);
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #898b91;
          vertical-align: middle;
          white-space: nowrap;
        }
      }
      td {
        padding: 15px 10px;
        border-bottom: 1px solid #eaeaea;
        vertical-align: middle;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #141822;
        white-space: nowrap;
        &.in-progress {
          color: #67b231;
          text-transform: capitalize;
        }
        &.active-date,
        &.status {
          text-transform: capitalize;
        }
        a {
          color: #007bff;
          cursor: pointer;
          &.disabled {
            color: #898b91;
            pointer-events: none;
          }
        }
      }
    }
    .empty-table {
      margin-top: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex-direction: column;
      span {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #adaeb2;
      }
    }
    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 200px;
      flex-direction: column;
    }
  }
  .page-navigation {
    margin-top: 12px;
    ul {
      margin-bottom: 0;
      .page-item {
        cursor: pointer;
        .page-link {
          border: none;
        }
      }
    }
  }
}
</style>
