<template>
  <div class="pre-order mt-3">
    <div v-if="!isLoading" class="pre-order__content">
      <div v-if="items && items.length" class="row">
        <template v-for="(item, index) in items" >
          <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div :key="index" class="item-shop" :class="{'sold-out': !item.RemainingPackage}">
              <div class="btn-details">
                <img
                  src="/img/icon-reduce.svg"
                  alt="icon-info">
              </div>
              <div
                @click="$router.push(`/preorder-detail?PackageId=${item.Id}`)"
                class="img-shop"
              >
                <img :src="$apiImageUrl + item.ImagePath" alt="shop-default" >
              </div>
              <div class="bottom">
                <div class="information">
                  <a class="link" @click="$router.push(`/preorder-detail?PackageId=${item.Id}`)" href="javascript:void(0)">
                    <span class="name">{{ item.Name }}</span>
                  </a>
                </div>
                <span class="number">({{ $t('table.quantity') }} {{ item.RemainingPackage }} {{ $t('pre-order.packages') }})</span>
                <span class="reduce"  style=" text-decoration: line-through; color: #E96058; font-size: 16px;">
                  {{ $t('dashboards.price') }}
                  {{(item.VlgFullAmount /0.8)*12.75 | toCurrency(true, 0) }}</span>
                <span class="reduce">
                  {{ $t('pre-order.sale') }} {{ (item.VlgFullAmount * 12.75) | toCurrency(true, 0) }}
                </span>
                <div class="quality d-flex justify-content-start align-items-center mt-3 mb-3">
                  <span class="title mr-4">{{ $t('table.quantity') }}</span>
                  <SumQuality
                    :remaining="item.RemainingPackage"
                    :packageId="item.Id"
                    class="pre-order-sum"
                    :key="item.Id"
                    @updateItem="updateValue"/>
                </div>
                <div class="action">
                  <button class="buy" @click="orderNow(item)">
                    <span>{{ $t('menu-items.subShop.pre-order') }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-else class="row">
        <div class="col-md-12 empty-data">
          <span>{{ $t("pre-order.no-package") }}</span>
        </div>
      </div>
    </div>
    <div v-else class="container position-relative mt-4">
      <BaseLoading v-if="isLoading" class="loading" :loading="isLoading" />
    </div>
    <BaseModal :config="config">
      <template slot="content">
        <ModalPinSecurity
          @submit="finalConfirmWithPin"
          :loading="loading"
          :name-package="namePackage"
          :price-package="pricePackage"
        />
      </template>
    </BaseModal>
  </div>
</template>

<script>
import SumQuality from '@/containers/Shop/pre-order/sum-quality.vue'
import { RepositoryFactory } from "@/apis/repositoryFactory";
const ShopRepository = RepositoryFactory.get("shop");
import BaseLoading from "@components/Inputs/BaseLoading";
import {MODAL, NOTIFICATION_ICON} from "@/constants";
import ModalPinSecurity from "@/containers/Shop/pre-order/ModalPinSecurity.vue";
import isEmpty from "lodash/isEmpty"
export default {
  components: {
    BaseLoading,
    SumQuality,
    ModalPinSecurity
  },
  props: {
    keywords: {
      type: String,
      default: ''
    }
  },
  watch: {
    keywords (value) {
      const arr = this.itemsFilter
      if (value) {
        this.items = arr.filter(data => {
          return data.Name.toLowerCase().includes(value.toLowerCase())
        })
      } else {
        this.fetchItem();
      }
    }
  },
  data() {
    return {
      items: [],
      itemsFilter: [],
      pinCode: "",
      form: {
        Quantity: 1
      },
      config: {
        id: MODAL.PIN_VERIFICATION,
        class: "modal--pin-security-confirmation",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light"
      },
      loading: false,
      namePackage: '',
      pricePackage: ''
    };
  },
  async created() {
    await this.fetchItem();
  },
  methods: {
    orderNow (value) {
      if (isEmpty(this.$store.getters["Auth/currentUser"])) {
        this.$bvModal.show(MODAL.LOGIN)
        return
      }
      if (!this.form.Quantity || this.form.Quantity < 1) {
        this.$notifyPopup(NOTIFICATION_ICON.ERROR,
          this.$t('swal.title.fail'),
          this.$t('swal.min', [0]),
          {
            action1Text: 'OK',
            action1Func: 'OK'
          },
          'dashboard'
        )
        return
      }
      this.PackageId = value.Id
      this.namePackage = value.Name
      this.pricePackage = value.Price
      this.$bvModal.show(MODAL.PIN_VERIFICATION);
    },
    updateValue (value) {
      this.form.Quantity = value
    },
    async fetchItem() {
      this.isLoading = true
      try {
        const foundResult = await Promise.all([
            ShopRepository.getAll()
        ])
        Object.keys(foundResult).forEach(i => {
          if (!foundResult[i].length) delete foundResult[i]
        })
        const items = foundResult[0]
        this.items = items['PRE-ORDER']
        this.itemsFilter = items['PRE-ORDER']
        this.isLoading = false
      } catch (error) {
        console.log(error);
      } finally {
      }
      this.isLoading = false
    },
    async finalConfirmWithPin(pinCode) {
      this.loading = true;
      this.pinCode = pinCode;
      const result = await this.onSubmit();
      this.loading = false;
      if (result) {
        this.$bvModal.hide(MODAL.PIN_VERIFICATION);
      }
    },
    closeModal () {
      window.open('/shop?tab=OrderHistory', '_self')
    },
    openLink () {
      this.$bvModal.hide(MODAL.PIN_VERIFICATION);
      window.open('/shop?tab=OrderHistory', '_self')
    },
    closePopup() {
      this.$bvModal.hide(MODAL.PIN_VERIFICATION);
    },
    async fetchData () {
      await this.fetchItem()
      this.openLink()
    },
    async onSubmit() {
      try {
        const data = {
          PackageId: this.PackageId,
          Quantity: this.form.Quantity,
          Pin: this.pinCode
        };
        await ShopRepository.preOrder(data);
        this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
          this.$t("swal.title.congratulations"),
          this.$t("pre-order.success"),
          {
            action1Text: 'OK',
            action1Func: this.fetchData
          },
          'dashboard'
        )
        return true;
      } catch (error) {
        const res = JSON.parse(error.request.response)
        this.$notifyPopup(NOTIFICATION_ICON.ERROR,
          '',
          res.message + '',
          {
            action1Text: 'OK',
            action1Func: this.closePopup
          },
          'dashboard'
        )
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.pre-order {
  &__header {
    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #43464E;
      margin: 24px 0;
      display: inline-block;
    }
    .row {
      @media only screen and (max-width: 992px) {
        justify-content: center;
      }
    }
  }
  &__content {
    margin-top: 43px;
    margin-bottom: 40px;
    .empty-data {
      height: 315px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-weight: 700;
        font-size: 44px;
        line-height: 50px;
        text-align: left;
        color: #000000;
      }
    }
    @media only screen and (max-width: 992px) {
      margin: 20px;
    }
  }
  .list {
    overflow-y: hidden;
    overflow-x: auto;
  }
  .navigation {
    width: max-content;
    height: 40px;
    background: #F7F7F7;
    border: 1px solid #E8E8E9;
    border-radius: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .item {
      width: 130px;
      text-align: center;
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      span {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #898B91;
        text-transform: capitalize;
        @media only screen and (max-width: 992px) {
          font-size: 14px;
          line-height: 22px;
        }
      }
      &:hover, &.active {
        background: rgba(0, 123, 255, 0.1);
        border-radius: 20px;
        span {
          color: #007BFF;
        }
      }
    }
  }
  .item-shop {
    background: #F7F7F7;
    border-radius: 8px;
    margin-bottom: 24px;
    // height: 92%;
    position: relative;
    .btn-details {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
    }
    .img-shop {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-top: 50%;
      margin-bottom: 10px;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .bottom {
      padding: 16px;
      .information {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          font-weight: 700;
          font-size: 20px;
          line-height: 28px;
          color: #141822;
          max-width: 210px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: inline-block;
        }
        .link {
          &:hover {
            cursor: pointer;
            .name {
              color: #1d62f0;
            }
          }
        }
      }
      .number {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #898B91;
        display: block;
      }
      .reduce {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: red;
        display: block;
      }
      .quality {
        display: flex;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: #43464E;
        .title {
          font-size: 18px;
          line-height: 26px;
          color: #43464E;
        }
        .pre-order-sum {
          .item {
            border: 1px solid #ADAEB2 !important;
          }
          .input-number {
            color: #000000;
          }
        }
      }
      .action {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .add {
          width: 100%;
          height: 40px;
          background: transparent;
          border: 1px solid #007BFF;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: #007BFF;
            white-space: nowrap;
            padding-top: 3px;
          }
          &:hover {
            background: #007BFF;
            span {
              color: #FFFFFF;
            }
          }
        }
        .buy {
          width: 100%;
          height: 40px;
          background: #007BFF;
          border: 1px solid #007BFF;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: #FFFFFF;
            white-space: nowrap;
            padding-top: 3px;
          }
          &:hover {
            background: #007BFF;
            span {
              color: #FFFFFF;
            }
          }
        }
      }
    }
    &.sold-out {
      .sum-quality {
        pointer-events: none;
        opacity: 0.7;
      }
      .buy {
        pointer-events: none;
        background: #ADAEB2 !important;
        border: 1px solid #ADAEB2 !important;
      }
    }
  }
}
</style>
