<template>
  <PackageDetail />
</template>

<script>
import PackageDetail from '@containers/Shop/pre-order/package-detail.vue'
export default {
  name: "index",
  components: {
    PackageDetail
  }
}
</script>

<style scoped>

</style>
