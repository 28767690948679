<template>
  <div class="loan">
    <div v-if="loanDetailList.length">
      <LoanDetail
        :list="loanDetailList"
        @onRefresh="onRefresh"
        @onBack="onBack"
      />
    </div>
    <div v-else>
      <div class="loan__title">
        <h2>{{ $t("borrow.my") }}</h2>
        <div class="loan__action">
          <template>
            <button
              class="btn btn-primary btn-round loan__btn-withdraw"
              @click="openModal(MODAL.REPAY)"
            >
              {{ $t("borrow.repay") }}
            </button>
            <button
              class="btn btn-primary loan__btn-transfer"
              @click="openModal(MODAL.CREATE_LENDING)"
            >
              {{ $t("borrow.loan") }}
            </button>
          </template>
        </div>
      </div>
      <div class="loan__table">
        <b-card>
          <Breadcrumb
            link="/wallet/detail?code=VLGPlus"
            :label="$t('borrow.back-vtp')"
          />
          <p class="loan__table--title my-3 text-capitalize">
            {{ $t("borrow.invoice") }}
          </p>
          <div class="list">
            <table>
              <thead>
                <tr>
                  <th>{{ $t("borrow.amount") }}</th>
                  <th>{{ $t("borrow.code") }}</th>
                  <th>{{ $t("borrow.start") }}</th>
                  <th>{{ $t("borrow.end") }}</th>
                  <th>{{ $t("borrow.status") }}</th>
                  <th>{{ $t("borrow.action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in data" :key="index">
                  <td>{{ item.Amount | toCurrency }}</td>
                  <td>{{ item.Code }}</td>
                  <td>{{ item.StartTime | formatDate(formatValue) }}</td>
                  <td>{{ item.EndTime | formatDate(formatValue) }}</td>
                  <td>{{ item.Status }}</td>
                  <td>
                    <a
                      @click.prevent="
                        onViewDetail(item.Id, item.CreditLendings)
                      "
                      >{{ $t("borrow.detail") }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="container position-relative" v-if="isLoading">
            <BaseLoading class="loading mt-0" :loading="isLoading" />
          </div>
          <div v-else-if="!data.length" class="empty-table">
            <img src="/img/empty-folder.png" alt="img empty" />
            <span>No data</span>
          </div>
        </b-card>
      </div>
    </div>
    <ModalCreateLending @onRefresh="onRefresh" />
    <ModalRepay @onRefresh="onRefresh" />
  </div>
</template>

<script>
import { RepositoryFactory } from "@apis/repositoryFactory";
const LendingRepository = RepositoryFactory.get("lending");
import BaseInput from "@/components/Inputs/BaseInput.vue";
import Breadcrumb from "./breadcrumb.vue";
import globalMixin from "@/core/mixins/global";
import BaseLoading from "@components/Inputs/BaseLoading";
import { MODAL } from "@/constants";
import ModalCreateLending from "@/containers/Wallet/modal-create-lending.vue";
import ModalRepay from "@/containers/Wallet/modal-repay.vue";
import LoanDetail from "@/containers/Wallet/loan-detail.vue";

export default {
  components: {
    BaseInput,
    Breadcrumb,
    BaseLoading,
    ModalCreateLending,
    ModalRepay,
    LoanDetail,
  },
  mixins: [globalMixin],

  data() {
    return {
      data: [],
      MODAL,
      loanDetailList: [],
      loadActiveId: "",
    };
  },
  computed: {
    formatValue() {
      const lang = this.$i18n.locale.toUpperCase();
      return lang == "VI" ? "DD/MM/YYYY HH:mm" : "MM/DD/YYYY hh:mm A";
    },
  },
  async created() {
    this.getData();
  },
  methods: {
    onRefresh() {
      this.closeAllModal();
      this.getData();
    },
    closeAllModal() {
      const self = this;
      Object.values(this.MODAL).forEach(function (item) {
        self.$bvModal.hide(item);
      });
    },
    async getData() {
      try {
        this.$_loading();
        const result = await LendingRepository.getlending();
        this.$_load();
        this.data = result;
        if (this.loadActiveId) {
          this.loanDetailList =
            this.data.find((item) => item.Id === this.loadActiveId)
              .CreditLendings || [];
        }
      } catch (error) {}
    },
    openModal(modalId) {
      this.$bvModal.show(modalId);
    },
    onViewDetail(id, list) {
      this.loadActiveId = id;
      this.loanDetailList = list;
    },
    onBack() {
      this.loadActiveId = "";
      this.loanDetailList = "";
    },
  },
};
</script>

<style scoped lang="scss">
.loan {
  margin: 24px;
  @media only screen and (max-width: 500px) {
    margin: 16px;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    h2 {
      color: #141822;
      font-family: "Overpass";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 12px;
    }
    .btn {
      min-width: 240px;
      height: 40px;
    }
    @media only screen and (max-width: 980px) {
      flex-direction: column;
    }
    @media only screen and (max-width: 830px) {
      flex-direction: column;
      .loan__action {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .btn {
          width: 100%;
          &:first-child {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  &__btn-withdraw {
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;
    margin-right: 16px;
    &:hover {
      background: #007bff;
      color: #ffffff;
    }
  }
  &__table {
    overflow-y: hidden;
    overflow-x: auto;
    min-height: 600px;
    .card {
      min-height: 70vh;
      margin-bottom: 24px;
      .list {
        overflow-x: auto;
        overflow-y: hidden;
      }
    }
    &--title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #43464e;
    }
    &--info {
      display: flex;
      justify-content: space-between;
      padding-bottom: 28px;
      border-bottom: solid 1px #eaeaea;
      .info-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          width: 120px;
        }
        .amount {
          display: flex;
          flex-direction: column;
          margin-left: 16px;
          .token-name {
            font-weight: 700;
            font-size: 32px;
            line-height: 44px;
            color: #898b91;
            .btn-certificate {
              padding: 9px 24px;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #007bff;
              background: rgba(0, 123, 255, 0.2);
              border-radius: 8px;
              cursor: pointer;
            }
          }
          .token-value {
            font-weight: 700;
            font-size: 24px;
            line-height: 34px;
            display: flex;
            align-items: center;
            color: #141822;
          }
        }
      }
      .info-right {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .form-group {
          margin-bottom: 6px;
        }
        .address {
          text-align: center;
          p {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #43464e;
          }
        }
      }
      @media only screen and (max-width: 1160px) {
        flex-direction: column;
        justify-content: flex-start;
        .info-left {
          margin-bottom: 20px;
          justify-content: center;
        }
        .info-right {
          justify-content: center;
        }
      }
      @media only screen and (max-width: 500px) {
        flex-direction: column;
        .info-left {
          img {
            width: 70px;
          }
          .amount {
            .token-name {
              font-size: 20px;
              line-height: 32px;
            }
            .token-value {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
        .info-right {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .address {
            align-items: center;
            margin-bottom: 1rem;
          }
        }
      }
    }
    table {
      width: 100%;
      thead {
        th {
          padding: 10px;
          background: rgba(173, 174, 178, 0.2);
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #898b91;
          vertical-align: middle;
          white-space: nowrap;
        }
      }
      td {
        padding: 15px 10px;
        border-bottom: 1px solid #eaeaea;
        vertical-align: middle;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #141822;
        white-space: nowrap;
        &.in-progress {
          color: #67b231;
          text-transform: capitalize;
        }
        &.active-date,
        &.status {
          text-transform: capitalize;
        }
        a {
          color: #007bff;
          cursor: pointer;
        }
      }
    }
    .empty-table {
      margin-top: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex-direction: column;
      span {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #adaeb2;
      }
    }
    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 200px;
      flex-direction: column;
    }
  }
  .page-navigation {
    margin-top: 12px;
    ul {
      margin-bottom: 0;
      .page-item {
        cursor: pointer;
        .page-link {
          border: none;
        }
      }
    }
  }
}
</style>
