<template>
	<div>
		<p class=" h6">{{ $t('link-kyc.tree') }}</p>
		<template>
			<div class="col-12 tree">
				<template>
					<div class="position-relative">
						<vue-tree ref="scaleTree" style="width: 100%; height: 700px; border: 1px solid gray"
							:dataset="data" :config="treeConfig">
							<template v-slot:node="{ node, collapsed }">
								<div class="rich-media-node" :style="{ border: collapsed ? '2px solid grey' : '' }"
									v-show="node.Username">
									<div class="tree-avatar-wraper">
										<div class="container-network-tree-avatar p-0 pt-2"
											:class="{ 'justify-content-between': node.Layer == 1 }">
											<img v-if="node.PackageImage"
												class="vk-image-responsive tree-avatar-default tree-avatar logo"
												:src="$apiImageUrl + node.PackageImage" />
											<img v-else
												class=" vk-image-responsive tree-avatar-default tree-avatar logo"
												src="/img/default-avatar.png" />
											<p class="mb-0 mw-120px truncate">
												{{ node.FullName || node.Username }}
											</p>
											<p class="mb-0 small" v-show="node.Layer == 1 ">
												{{ $t('link-kyc.linked')}}
												<i>{{ node.LinkedDate | formatDate }}</i>
											</p>
											<div v-if="node.Layer == 1" @click="revokeUser(node)"
												class="d-flex justify-content-center align-items-center w-100 btn-revoke">
												<p class="mb-0">{{ $t('link-kyc.revoke')}}</p>
											</div>
										</div>
									</div>
								</div>
							</template>
						</vue-tree>
						<div class="tree-loading" v-if="loadingData">
							<div class="text-center my-2">
								<b-spinner class="align-middle mr-1"></b-spinner>
								<strong>Loading...</strong>
							</div>
						</div>
					</div>
				</template>
			</div>
		</template>
	</div>
</template>

<script>
import Swal from 'sweetalert2'
import { RepositoryFactory } from "@apis/repositoryFactory";
const KYCRepository = RepositoryFactory.get('kyc')
import helpers from "@/core/mixins/helpers";

export default {
	data() {
		return {
			data: [],
			loadingData: false,
			treeConfig: {
				nodeWidth: 230,
				nodeHeight: 230,
				levelHeight: 300,
			},
		}
	},
	mounted() {
		this.fetchTree()
	},
	mixins: [helpers],
	methods: {
		async revoke (item) {
			await KYCRepository.getMyLinkTree()
		},
		async fetchTree() {
			try {
				this.loadingData = true;
				const data = await KYCRepository.getMyLinkTree()
				console.log(data);
				this.data = data
			} catch (error) {
				console.log('====================================');
				console.log(error);
				console.log('====================================');
			} finally {
				this.loadingData = false
			}
		},
		revokeUser(item) {
			console.log(item);
			const text = `${item.FullName}${this.$t('link-kyc.text')}`
			Swal.fire({
				title: this.$t("link-kyc.sure"),
				text,
				icon: "info",
				confirmButtonText: 'OK',
				showCancelButton: true,
				reverseButtons: true,
				cancelButtonText: this.$t('action.cancel'),
				customClass: {
					title: 'text-body'
				}
			}).then(async (result) => {
				if (result.isConfirmed) {
					await KYCRepository.revokeLinkKyc(item.LinkKycId)
					this.fetchTree()
				}
			});
		}
	},
};
</script>


<style lang="scss">
.tree {
	margin-top: 24px;

	@media screen and (max-width: 991px) {
		padding: 0;
	}

	&__action {
		position: absolute;
		right: 24px;
		top: 24px;
		z-index: 2;
	}

	.container-network-tree-avatar {
		min-width: 180px;
	}
}

.logo {
	border-color: rgb(110, 189, 228);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
}

.tree-loading {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9;
	background: #0000001f;
}

.btn-revoke {
	background: #102b5f;
	color: #fff;
	text-transform: uppercase;
	font-size: 0.8rem;
	height: 36px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}
</style>
