<template>
  <Shop />
</template>

<script>
import Shop from '@containers/Shop/index.vue'
export default {
  components: {
    Shop
  }
}
</script>

<style>

</style>
