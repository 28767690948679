<template>
  <div class="breadcrumb-wallet">
    <a @click="link ? $router.push(link) : $emit('onBack')">
      <img src="/img/wallet/icon-back.svg" alt="" />
      <span>{{ label }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  components: {},
  props: {
    link: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.breadcrumb-wallet {
  margin-bottom: 27px;
  a {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
  img {
    width: 29px;
    margin-right: 16px;
  }
  span {
    font-size: 20px;
    line-height: 28px;
    color: #43464e;
  }
}
</style>
