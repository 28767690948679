<template>
  <div class="package-detail">
    <div class="package-detail__header">
      <p class="title text-white">{{ $t('pack-detail') }}</p>
      <div class="form-inline my-2 my-lg-0">
        <BaseInputCustom class-name="redeemCode" ref="redeemCode" v-model="formData.redeemCode" type="text"
          name="redeemCode" required :placeholder="$t('redeem-placeholder')" label=""
          :error-required="$t('validate.required', [$t('redeem-code')])" />
        <button class="btn btn-primary" type="submit" @click="redeem()">{{ $t('redeem') }}</button>
      </div>
    </div>
    <div class="package-detail__content" v-if="dataItem && Object.keys(dataItem).length">
      <div class="back-page mt-3 mb-3">
        <img src="/img/arrow-back.svg" alt="arrow-back" class="arrow-back" @click="$router.push('/shop')">
        <span> {{ $t('menu-items.back-to-shop')}}</span>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="img-top">
            <img :src="$apiImageUrl + dataItem.ImagePath" alt="shop-default">
            <div class="label-sold-out" v-if="listNameSoldOut.includes(dataItem.Name)">
              <img src="/img/sold-out-home.svg" alt="icon-info" />
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
          <span class="name">{{ dataItem.Name }}</span>
          <span class="price">{{ dataItem.Price | toCurrency(true) }}</span>
          <div class="description">
            <div style="display: inline-block; padding: 8px 0;" v-html="$t(`packages-detail.${namePackage}`)" />
          </div>
          <div class="quality">
            <div class="d-flex justify-content-start align-items-center m-3">
              <span class="title mr-3">{{ $t('table.quantity') }}</span>
              <div class="sum-quality" :class="{'sold-out': listNameSoldOut.includes(dataItem.Name)}">
                <div class="item" id="item-first" :class="{'disable': quality === 1}" @click="quality--">
                  <span>-</span>
                </div>
                <div class="item">
                  <input onkeyup="this.value=this.value.replace(/[^\d]/,'')" class="input-number" type="number"
                    v-model="quality" min="0">
                </div>
                <div class="item" @click="quality++">
                  <span>+</span>
                </div>
              </div>
            </div>
            <div class="action" :class="{'sold-out': listNameSoldOut.includes(dataItem.Name)}">
              <button class="add-cart" @click="addItem()">
                <span>{{ $t('action.addCart') }}</span>
              </button>
              <button class="buy-now" @click="buyNow()">
                <span>{{ $t('dashboards.buyNow') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="col-12 text-dark list-item" v-if="!listShowPrice.includes(dataItem.Name)">
            <p class="d-flex justify-content-between align-items-center text-dark mb-0">
              <span class="icon-package">
                <img :src="`/img/icons/affiliate-point.png`" alt="affiliate-point" class="img-icon" />
                {{ $t('packages-detail.aff-point') }}:
              </span>
              <span class="last">
                {{ dataItem.AffiliatesPoint | formatNumber }} AP
              </span>
            </p>
            <p class="d-flex justify-content-between align-items-center text-dark mb-0">
              <span class="icon-package">
                <img :src="`/img/icons/lifetime-point.png`" alt="lifetime-point" class="img-icon" />
                Lifetime Point:
              </span>
              <span class="last">
                {{ dataItem.LifetimePoint | formatNumber }} LP
              </span>
            </p>
            <p v-if="!listShowPrice.includes(dataItem.Name) &&
            packages.filter(data => data.name === dataItem.Name).shift().direct"
              class="d-flex justify-content-between align-items-center text-dark mb-0">
              <span class="icon-package">
                <img :src="`/img/icons/bonus.png`" alt="lifetime-point" class="img-icon" />
                {{ $t('direct') }}:
              </span>
              <span class="last">
                {{ packages.filter(data => data.name === dataItem.Name).shift().direct.toString() }}
              </span>
            </p>
          </div>
          <div class="docs-merchant col-12 text-dark p-0 mt-3 mb-3">
            <div class="scrollable" v-if="isMerchant">
              <table class="table-package">
                <thead>
                  <tr class="head">
                    <th>{{$t('Merchant.Quantities')}}</th>
                    <th>{{$t('Merchant.Dividend')}}</th>
                    <th>{{$t('Merchant.Price')}}</th>
                    <th>{{$t('Merchant.Promotion')}}</th>
                    <th>{{$t('Merchant.ap')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in merchantData" :key="item.no">
                    <td>{{item.no}}</td>
                    <td>{{item.dividend}}</td>
                    <td>{{item.price}}</td>
                    <td>{{item.promotion}}</td>
                    <td>{{item.ap}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="scrollable" v-if="isL1">
              <table class="table-package">
                <thead>
                  <tr class="head">
                    <th class="col-3">{{$t('Merchant.Quantities')}}</th>
                    <th class="col-3">{{$t('Merchant.Price')}}</th>
                    <th class="col-3">VLG Token</th>
                    <th class="col-3">{{$t('Merchant.ap')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in L1Data" :key="item.no">
                    <td class="col-3">{{item.no}}</td>
                    <td class="col-3">{{item.price}}</td>
                    <td class="col-3">{{item.promotion}}</td>
                    <td class="col-3">{{item.ap}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="scrollable" v-if="isEb3">
              <table class="table-package">
                <thead>
                  <tr class="head">
                    <th class="col-3">{{$t('Merchant.Quantities')}}</th>
                    <th class="col-3">{{$t('Merchant.Price')}}</th>
                    <th class="col-3">VLG Token</th>
                    <th class="col-3">{{$t('Merchant.ap')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in eb3Data" :key="item.no">
                    <td class="col-3">{{item.no}}</td>
                    <td class="col-3">{{item.price}}</td>
                    <td class="col-3">{{item.promotion}}</td>
                    <td class="col-3">{{item.ap}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="scrollable" v-if="isEb5">
              <table class="p-0 m-0 horizontal-scroll">
                <thead>
                  <tr class="head">
                    <th class="col-3">{{$t('Merchant.Quantities')}}</th>
                    <th class="col-3">{{$t('Merchant.Price')}}</th>
                    <th class="col-3">VLG Token</th>
                    <th class="col-3">{{$t('Merchant.ap')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in eb5Data" :key="item.no">
                    <td class="col-3">{{item.no}}</td>
                    <td class="col-3">{{item.price}}</td>
                    <td class="col-3">{{item.promotion}}</td>
                    <td class="col-3">{{item.ap}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="scrollable" v-if="isVmf">
              <table class="p-0 m-0 horizontal-scroll">
                <thead>
                  <tr class="head">
                    <th class="col-3">{{$t('Merchant.phase')}}</th>
                    <th class="col-3">{{$t('Merchant.Quantities')}}</th>
                    <th class="col-3">{{$t('Merchant.Price')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in VMFData" :key="item.no" :class="{'bg-light': item.price < dataItem.Price, 'bg-green-light': item.price == dataItem.Price}">
                    <td class="col-3">{{item.apply}}</td>
                    <td class="col-3">{{item.quantity | toCurrency(false, 0)}}</td>
                    <td class="col-3">{{item.price | toCurrency(true)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="history && history.length" class="scrollable docs-merchant col-12 text-dark p-0 mt-3 mb-3">
            <table>
              <thead>
                <tr class="head">
                  <th class="col-1">#</th>
                  <th class="col-4">{{$t('user.fullname')}}</th>
                  <th class="col-3">{{$t('user.username')}}</th>
                  <th class="col-4">{{$t('active-date')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in history" :key="index">
                  <td class="col-2">{{ index + 1 }}</td>
                  <td class="col-4">{{item.FirstName}} {{item.LastName}}</td>
                  <td class="col-3">{{item.Username}}</td>
                  <td class="col-4">{{item.DateTime | formatDateTime}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="quality mobile">
            <div class="d-flex justify-content-start align-items-center m-3 sum-quality-mb">
              <span class="title mr-3">{{ $t('table.quantity') }}</span>
              <div class="sum-quality" :class="{'sold-out': listNameSoldOut.includes(dataItem.Name)}">
                <div class="item" :class="{'disable': quality === 1}" @click="quality--">
                  <span>-</span>
                </div>
                <div class="item">
                  <input onkeyup="this.value=this.value.replace(/[^\d]/,'')" class="input-number" type="number"
                    v-model="quality" min="0">
                </div>
                <div class="item" @click="quality++">
                  <span>+</span>
                </div>
              </div>
            </div>
            <div class="action" :class="{'sold-out': listNameSoldOut.includes(dataItem.Name)}">
              <button class="add-cart" @click="addItem()">
                <span>{{ $t('action.addCart') }}</span>
              </button>
              <button class="buy-now" @click="buyNow()">
                <span>{{ $t('dashboards.buyNow') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import packages from "@/constants/packages";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import validateMixin from '@/core/mixins/validate'
import { RepositoryFactory } from "@apis/repositoryFactory";
import { MODAL, NOTIFICATION_ICON } from "@/constants";
const ShopRepository = RepositoryFactory.get('shop');
const CartRepository = RepositoryFactory.post('cart');
const NumberCartRepository = RepositoryFactory.get('cart');
import { EB5, EB3, L1, MERCHANT } from '@/constants'
import isEmpty from "lodash/isEmpty"
import VMFData from '@/constants/vmf-price.json'
import updateCountryMixin from '@/core/mixins/updateCountry'

export default {
  name: "package-detail",
  components: {
    BaseInputCustom
  },
  data() {
    return {
      packages,
      formData: {
        redeemCode: ''
      },
      isValid: true,
      dataItem: {},
      namePackage: 'vlg-1k-meta',
      quality: 1,
      carts: [],
      history: [],
      numberCart: 0,
      merchantData: MERCHANT,
      L1Data: L1,
      eb3Data: EB3,
      eb5Data: EB5,
      VMFData,
      listNameSoldOut: [
        'VLINK MERCHANT META'
      ],
      listShowPrice: [
        'VLINK MERCHANT META',
        '1L',
        '3EB',
        '5EB'
      ]
    }
  },
  mixins: [validateMixin, updateCountryMixin],
  computed: {
    isMerchant() {
      return this.dataItem.Name.includes('VLINK MERCHANT META 555')
    },
    is50k() {
      return this.dataItem.Name.includes('VUSD 50K')
    },
    isL1() {
      return this.dataItem.Name.includes('1L')
    },
    isEb3() {
      return this.dataItem.Name.includes('3EB')
    },
    isVmf() {
      return this.dataItem.Name.includes('Vlinkmeta Mall Founders')
    },
    isEb5() {
      return this.dataItem.Name.includes('5EB')
    }
  },
  async created() {
    const packageId = this.$route.query.PackageId
    if (packageId && packageId.length) {
      this.dataItem = await ShopRepository.get(packageId)
      this.namePackage = this.dataItem.Name.toLowerCase().replaceAll(' ', '-')
      try {
        if (this.isVmf || this.isMerchant) {
          this.history = []
        } else {
          this.history = await ShopRepository.getUserRedeemPackage(this.$route.query.PackageId)
        }
      } catch (error) {
      }
    }
  },
  watch: {
    quality(value) {
      if (value === '' || value <= 1) {
        setTimeout(() => {
          document.getElementById('item-first').classList.add('disable')
        }, 200)
      }
    }
  },
  methods: {
    async addItem() {
      if (isEmpty(this.$store.getters["Auth/currentUser"])) {
        this.$bvModal.show(MODAL.LOGIN)
        return
      }
      try {
        const params = {
          PackageId: this.dataItem.Id,
          Quantity: this.quality
        }
        const response = await CartRepository.addToCart(params)
        if (response && response.Id) {
          // this.$alertMsg('true', 'Added package to shopping cart')
          this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
            this.$t('swal.title.success'),
            this.$t('action.added-package'),
            {
              action1Text: 'OK',
              action1Func: 'OK',
              action2Text: this.$t('check-out'),
              action2Func: () => { this.$router.push("/shopping-cart?confirmCart=true") }
            },
            'dashboard'
          )
          this.carts = await NumberCartRepository.getMyCart()
          this.numberCart = this.carts.CartItems.reduce(function (a, b) {
            return a + b['Quantity'];
          }, 0)
          this.$store.commit('setMyCart', this.numberCart)
        } else {
          this.$alertMsg('false', response.message)
          this.$notifyPopup(NOTIFICATION_ICON.ERROR,
            this.$t('swal.title.fail'),
            response.message,
            {
              action1Text: this.$t("action.try"),
              action1Func: () => { }
            },
            'dashboard'
          )
        }
      } catch (error) {
        const res = JSON.parse(error.request.response)
        this.$notifyPopup(NOTIFICATION_ICON.ERROR,
          this.$t('swal.title.fail'),
          res.message,
          {
            action1Text: 'OK',
            action1Func: 'OK'
          },
          'dashboard'
        )
      }
    },
    async buyNow() {
      if (isEmpty(this.$store.getters["Auth/currentUser"])) {
        this.$bvModal.show(MODAL.LOGIN)
        return
      }
      try {
        const params = {
          PackageId: this.dataItem.Id,
          Quantity: this.quality
        }
        await CartRepository.addToCart(params)
        await this.$router.push('/shopping-cart')
      } catch (error) {
        const res = JSON.parse(error.request.response)
        this.$notifyPopup(NOTIFICATION_ICON.ERROR,
          this.$t('swal.title.fail'),
          res.message,
          {
            action1Text: 'OK',
            action1Func: 'OK'
          },
          'dashboard'
        )
      }
    },
    async redeem() {
      if (isEmpty(this.$store.getters["Auth/currentUser"])) {
        this.$bvModal.show(MODAL.LOGIN)
      }
      const refs = ['redeemCode']
      const refsValid = this.$_validateMixin_refs(refs)
      if (refsValid) {
        try {
          const response = await CartRepository.useRedeem(this.formData.redeemCode)
          if (response && Object.keys(response).length) {
            this.$store.commit("Auth/updateCurrentUser", response);
            if (response.NeedToUpdateCountry) {
              this.requireUpdate(this.currentUser.Id)
            } else {
              let action = {
                action1Text: "OK",
                action1Func: () => {}
              }
            if (response.RedirectUrl) {
              action = {
                action1Text: "OK",
                action1Func: () => {},
                didCloseFunc: () => window.open(response.RedirectUrl, '_self')
              }
            }
            this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
              this.$t('swal.title.success'),
              response.message,
              {...action},
              'dashboard'
            )
          }
          } else {
            this.$notifyPopup(NOTIFICATION_ICON.ERROR,
              this.$t('swal.title.fail'),
              response.message,
              {
                action2Text: this.$t("action.try"),
                action2Func: () => { }
              },
              'dashboard'
            )
          }
          this.resetForm()
        } catch (error) {
          this.$notifyPopup(NOTIFICATION_ICON.ERROR,
            this.$t('swal.title.fail'),
            error.response.data.message,
            {
              action1Text: this.$t("action.try"),
              action1Func: () => { }
            },
            'dashboard'
          )
        }
      }
    },
    resetForm() {
      const refs = ['redeemCode']
      this.formData = {
        redeemCode: ''
      }
      this.$_validateMixin_reset(refs)
    }
  }
}
</script>

<style scoped lang="scss">
.package-detail {
  padding: 24px;

  @media only screen and (max-width: 768px) {
    padding: 16px;
    padding-bottom: 76px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      color: #141822;
      margin-bottom: 0;
    }

    padding-bottom: 26px;

    .redeemCode {
      margin-bottom: 0;

      /deep/.base-input-custom__wrap {
        label {
          display: none;
        }

        input {
          background: #FFFFFF;
          border: 1px solid #E8E8E9;
          border-radius: 8px 0 0 8px;
          color: #000000;
        }
      }

      /deep/.error {
        position: absolute;
      }
    }

    .btn-primary {
      border-radius: 0 8px 8px 0;
      height: 40px;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 15px;

      .redeemCode {
        width: 66%;
      }
    }
  }

  &__content {
    background: #FFFFFF;
    padding: 16px 24px;

    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #EAEAEA;
      border-top: 1px solid #EAEAEA;

      span.icon-package {
        color: #898B91;
        white-space: nowrap;
      }

      span.last {
        margin-left: 10px;
        white-space: nowrap;
      }
    }

    .back-page {
      img {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .img-top {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F7F7F7;
      border-radius: 8px;
      padding: 40px;
      position: relative;

      .label-sold-out {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;

        img {
          width: auto;
          height: auto;
        }
      }

      img {
        width: 350px;
        height: auto;

        @media only screen and (max-width: 768px) {
          width: 200px;
        }
      }

      margin-bottom: 30px;
    }

    .img-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      .item {
        padding: 13px 40px;
        background: #F7F7F7;
        border-radius: 8px;
        width: calc((100% - 48px) / 3);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: auto;
          height: 95px;

          @media only screen and (max-width: 768px) {
            height: 54px;
          }
        }
      }
    }

    .name {
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      color: #141822;
      display: block;
      margin-bottom: 10px;
    }

    /*---------- star rating ----------*/
    .stars {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 30px;

      .star {
        color: #FFC107;
        font-size: 45px;
        line-height: 40px;
        position: relative;

        &:before {
          content: '★';
          color: #FFC107;
          font-size: 45px;
          line-height: 40px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .sum {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #898B91;
        display: inline-block;
        margin-left: 10px;
        margin-bottom: -10px;
      }
    }

    /*---------- star rating ----------*/
    .price {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      display: block;
      color: #E96058;
      margin-bottom: 16px;
    }

    .description {
      padding: 16px 0;
      border-bottom: 1px solid #EAEAEA;
      border-top: 1px solid #EAEAEA;
      max-height: 150px;
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        background: #ADAEB2;
        border-radius: 8px;
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #EAEAEA;
        border-radius: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #ADAEB2;
        border-radius: 8px;
      }

      @media only screen and (max-width: 768px) {
        max-height: 100%;
      }
    }

    .quality {
      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: #141822;
        display: inline-block;
        margin: 16px 0;
      }

      .sum-quality {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #E8E8E9;
          height: 45px;

          .input-number {
            border: none;
            padding-left: 18px;
            width: 50px;

            &:focus,
            &:focus-visible,
            &:hover {
              border: none;
              outline: none;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }

          span {
            color: #141822;
            font-size: 20px;
            line-height: 20px;
            display: inline-block;
            padding: 10px 15px;
          }

          &:first-child,
          &:last-child {
            span {
              color: #ADAEB2;
              font-size: 20px;
              line-height: 20px;
              display: inline-block;
              padding: 10px;
            }
          }

          &:first-child {
            border: 1px solid #E8E8E9;
            border-radius: 8px 0px 0px 8px;
          }

          &:last-child {
            border: 1px solid #E8E8E9;
            border-radius: 0px 8px 8px 0px;
          }

          &.disable {
            pointer-events: none;
            opacity: 0.5;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &.sold-out {
          pointer-events: none;
          opacity: 0.8;
        }
      }

      .action {
        margin-top: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .add-cart,
        .buy-now {
          width: 240px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          border-radius: 8px;
          border: 1px solid #007BFF;

          @media only screen and (max-width: 768px) {
            width: 125px;
          }
        }

        .add-cart {
          background: transparent;
          margin-right: 24px;
          margin-bottom: 10px;

          span {
            display: inline-block;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #007BFF;
            white-space: nowrap;
          }

          &:hover {
            cursor: pointer;
            background: #007BFF;

            span {
              color: #FFFFFF;
            }
          }
        }

        .buy-now {
          background: #007BFF;

          span {
            display: inline-block;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
            white-space: nowrap;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &.sold-out {
          .add-cart {
            pointer-events: none;
            border: 1px solid #ADAEB2;

            span {
              color: #ADAEB2;
            }
          }

          .buy-now {
            pointer-events: none;
            background: #ADAEB2;
            border: 1px solid #ADAEB2;
          }
        }
      }

      @media only screen and (max-width: 768px) {
        .action {
          display: none;
        }
      }

      &.mobile {
        display: none;
        position: fixed;
        bottom: 56px;
        background: #ffffff;
        left: 0;
        right: 0;

        @media only screen and (max-width: 768px) {
          display: block;

          .sum-quality-mb {
            display: none !important;
          }

          .action {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  .img-icon {
    max-width: 50px;
    margin-right: 10px;
  }

  .module-border-wrap {
    position: relative;
    padding: 4px;
    border: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(270deg, #f1d47d, #b2ff59);

    .module {
      background: radial-gradient(circle, #245c92 0, #418fc6 50%, rgba(36, 92, 146, .54) 100%);
      color: #FFFFFF;
    }
  }
}

.price-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      margin-right: 8px;
    }
  }
}

.scrollable {
  max-height: 600px;
  overflow-y: auto;
}

table {
  width: 100%;
  text-align: center;

  thead {
    th {
      padding: 9px;
      background: #BCC8DA;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #43464E;
      vertical-align: middle;
      white-space: nowrap;
    }
  }

  td {
    padding: 15px 10px;
    border-bottom: 1px solid #EAEAEA;
    vertical-align: middle;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    // color: #141822;
    white-space: nowrap;
  }
  tr.bg-light {
    color: #8d8d8d;
  }
  .bg-green-light {
  background-color: #aee8bb;
}
}
</style>
