<template>
  <div class="body">
    <credit />
  </div>
</template>

<script>
export default {
  components: {
    credit: () => import("@containers/Credit-lending/index.vue"),
  },
};
</script>

<style scoped lang="scss">
.body {
  background: #141822;
}
</style>
