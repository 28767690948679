<template>
  <div>
    <div class="text-center overflow-auto">
      <canvas
        id="myCanvas"
        data="personal"
        :width="1026"
        :height="1280"
      ></canvas>
      <div class="load-font-Uvn-Regular" style="visibility: hidden">.</div>
      <div class="load-font-avant-book" style="visibility: hidden">.</div>
      <div class="load-font-consola" style="visibility: hidden">.</div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/apis/repositoryFactory";
const WalletRepository = RepositoryFactory.get("wallet");

export default {
  name: "ModalCertificate",
  mixins: [],
  data() {
    return {
      certificateData: {},
      loading: false,
    };
  },
  computed: {},
  async mounted() {
    try {
      this.loading = true;
      this.certificateData = await WalletRepository.getShareCertificateData();
      var data = this.certificateData;
      var canvas = document.getElementById("myCanvas");
      var context = canvas.getContext("2d");
      var imageObj = new Image();
      if (data) {
        imageObj.onload = function () {
            context.drawImage(imageObj, 10, 10);
            context.textAlign = "center";
            context.font = "13pt consola";
            context.fillStyle = "white";
            context.fillText(data.VlinkId, 325, 303);
            var totalStock = Number(data.TotalStock).toLocaleString();
            context.fillText(totalStock, 730, 303);
            context.fillStyle = "white";
            context.font = "54pt Uvn-Regular";
            context.fillText(data.FullName, 521, 610);
            context.textAlign = "left";
            context.font = "12pt avant-book";
            context.fillText(data.DateConfirmed, 484, 767);
          };
      }
      imageObj.src = "/assets/img/vlg-e-certificate.png";
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
.certificate {
  color: #ffffff;
  margin-top: 32px;
  &__title {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #141822;
  }
  &__password {
    margin-top: 15px;
  }
  &__btn {
    height: 40px;
  }
  &__action {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
  }
  &__btn-cancel {
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;
    &:hover {
      background: #007bff;
      color: #ffffff;
    }
  }
}
::v-deep .eye {
  color: #43464e !important;
}
::v-deep .base-select {
  margin-bottom: 20px;
  &__label {
    color: #43464e;
  }
}
::v-deep .base-select__wrap {
  background: #f7f7f7;
  .arrow-down {
    color: #43464e;
  }
}
::v-deep .base-select__inner {
  p {
    color: #898b91;
  }
}
@media (max-width: 1200px) {
  #myCanvas {
    zoom: 85%;
  }
}
@media (max-width: 991px) {
  #myCanvas {
    zoom: 65%;
  }
}
@media (max-width: 768px) {
  #myCanvas {
    zoom: 45%;
  }
}
@media (max-width: 520px) {
  #myCanvas {
    zoom: 38%;
  }
}
</style>
