<template>
  <LoanDetail />
</template>

<script>
import LoanDetail from "@containers/Wallet/loan-detail.vue";

export default {
  name: "index",
  components: {
    LoanDetail,
  },
};
</script>

<style scoped>
</style>
