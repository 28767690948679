<template>
	<div v-if="dataCommission && dataCommission.length">
		<marquee onmouseover="this.stop();" onmouseout="this.start();"
			width="100%" direction="left">
			{{ $t('reached-title') }}:
			<span> {{ dataCommission.toString() }}</span>
		</marquee>
	</div>
</template>

<script>
import { RepositoryFactory } from "@/apis/repositoryFactory";
const CommissionRepository = RepositoryFactory.get("commission");
import globalMixin from "@/core/mixins/global";
export default {
	data() {
		return {
			dataCommission: []
		}
	},
	mixins: [globalMixin],
	async created() {
		try {
			if (this.isLogged) {
				this.dataCommission = await CommissionRepository.faststarthisweek()
			}
		} catch (error) { }
	},
}
</script>

<style>

</style>