<template>
  <div class="user-ranking">
    <div class="user-ranking__table">
      <div class="search">
        <input
          v-model="SearchString"
          type="search"
          id="form1"
          class="form-control"
          :placeholder="$t('account.search-placeholder')"
          aria-label="Search"
          @keyup="debounceInput"
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>{{ $t("users.infor.username") }}</th>
            <th>{{ $t("users.infor.email") }}</th>
            <th>{{ $t("users.infor.first-name") }}</th>
            <th>{{ $t("users.infor.last-name") }}</th>
            <th>{{ $t("pack-name") }}</th>
            <th>{{ $t("users.infor.referral-user") }}</th>
            <th>{{ $t("layer") }}</th>
            <th>{{ $t("profile.overview.Title") }}</th>
          </tr>
        </thead>
        <tbody v-if="!isLoading && data.length">
          <tr v-for="(item, index) in data" :key="index">
            <td>{{ item.Username }}</td>
            <td>{{ item.Email }}</td>
            <td>{{ item.FirstName }}</td>
            <td>{{ item.LastName }}</td>
            <td>{{ item.Name }}</td>
            <td>{{ item.ReferralUser }}</td>
            <td>{{ item.Layer }}</td>
            <td>{{ item.Ranking }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="container position-relative mt-4" v-if="isLoading">
      <BaseLoading v-if="isLoading" class="loading" :loading="isLoading" />
    </div>
    <div
      v-else-if="!data.length"
      class="
        empty-table
        d-flex
        justify-content-center
        align-items-center
        flex-column
      "
    >
      <img src="/img/empty-folder.png" alt="img empty" />
      <span>No data</span>
    </div>
    <nav aria-label="page-navigation" class="page-navigation" v-if="TotalPages">
      <ul class="pagination justify-content-end" v-if="TotalPages < 5">
        <li class="page-item" :class="{ disabled: !HasPrevious }">
          <a class="page-link" @click="onChangePage(PageNumber - 1)"
            >Previous</a
          >
        </li>
        <li
          class="page-item"
          v-for="page in TotalPages"
          :key="page"
          :class="{ active: page === PageNumber }"
        >
          <a class="page-link" @click="onChangePage(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: !HasNext }">
          <a class="page-link" @click="onChangePage(PageNumber + 1)">Next</a>
        </li>
      </ul>
      <ul class="pagination justify-content-end" v-else>
        <li class="page-item" :class="{ disabled: !HasPrevious }">
          <a class="page-link" @click="onChangePage(PageNumber - 1)"
            >Previous</a
          >
        </li>
        <li class="page-item">
          <a class="page-link" v-if="PageNumber > 2">...</a>
        </li>
        <li class="page-item">
          <a
            class="page-link"
            @click="onChangePage(PageNumber - 1)"
            v-if="PageNumber > 1"
            >{{ PageNumber - 1 }}</a
          >
        </li>
        <li class="page-item active">
          <a class="page-link">{{ PageNumber }}</a>
        </li>
        <li class="page-item" v-if="PageNumber < TotalPages">
          <a class="page-link" @click="onChangePage(PageNumber + 1)">{{
            PageNumber + 1
          }}</a>
        </li>
        <li class="page-item" v-if="PageNumber < TotalPages - 1">
          <a class="page-link">...</a>
        </li>
        <li class="page-item" :class="{ disabled: !HasNext }">
          <a class="page-link" @click="onChangePage(PageNumber + 1)">Next</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { RepositoryFactory } from "@apis/repositoryFactory";
const NetworkRepository = RepositoryFactory.get("network");
import moment from "moment";
import globalMixin from "@/core/mixins/global";
import BaseLoading from "@components/Inputs/BaseLoading";
import debounce from "lodash.debounce";

export default {
  components: {
    BaseLoading,
  },
  mixins: [globalMixin],
  data() {
    return {
      data: [],
      SearchField: "",
      SearchString: "",
      PageNumber: 1,
      PageSize: 10,
      TotalCount: 0,
      TotalPages: 0,
      HasPrevious: false,
      HasNext: false,
    };
  },
  watch: {
    SearchString(value) {
      if (value === "") {
        this.searchUserRanking();
      }
    },
  },
  async mounted() {
    await this.searchUserRanking();
  },
  methods: {
    async searchUserRanking() {
      try {
        const params = {
          SearchField: this.SearchField,
          SearchString: this.SearchString,
          PageNumber: this.PageNumber,
        };
        this.$_loading();
        const result = await NetworkRepository.getUserRanking(params);
        if (result) {
          this.data = result.Result;
          this.PageNumber = result.CurrentPage;
          this.TotalCount = result.TotalCount;
          this.TotalPages = result.TotalPages;
          this.PageSize = result.PageSize;
          this.HasPrevious = result.HasPrevious;
          this.HasNext = result.HasNext;
          this.$_load();
        }
      } catch (error) {
        console.log(error);
      }
    },
    debounceInput: debounce(function () {
      this.searchUserRanking();
    }, 500),
    onChangePage(page) {
      if (page === this.PageNumber) {
        return;
      }
      this.PageNumber = page;
      this.searchUserRanking();
    },
  },
};
</script>

<style scoped lang="scss">
.user-ranking {
  margin-top: 24px;
  min-height: 70vh;
  &__table {
    overflow-x: auto;
    overflow-y: hidden;
    .search {
      margin-bottom: 24px;
      input {
        width: 488px;
        height: 40px;
      }
    }
    table {
      width: 100%;
      thead {
        th {
          padding: 10px;
          background: rgba(173, 174, 178, 0.2);
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #898b91;
          vertical-align: middle;
          white-space: nowrap;
        }
      }
      td {
        padding: 15px 10px;
        border-bottom: 1px solid #eaeaea;
        vertical-align: middle;
        font-size: 16px;
        line-height: 24px;
        color: #141822;
        white-space: nowrap;
        &.in-progress {
          color: #67b231;
          text-transform: capitalize;
        }
        &.active-date,
        &.status {
          text-transform: capitalize;
        }
      }
    }
  }
  .loading {
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }
  .empty-table {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    span {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #adaeb2;
    }
  }
  .page-navigation {
    margin-top: 12px;
    ul {
      .page-item {
        cursor: pointer;
        .page-link {
          border: none;
        }
      }
    }
  }
}
</style>
