<template>
  <div class="upload-document">
    <div class="upload-document__title text-center">
      {{$t("kyc.title")}}
    </div>
    <div class="w-75 m-auto">
      <StepProgress :steps="steps"
                    :current-step="currentStep"
                    :active-thickness="1"
                    :passive-thickness="1"
                    :line-thickness="2"
                    active-color="#007BFF"
      />
    </div>

    <div class="upload-document__content">
      <div v-if="currentStep === 0" :key="currentStep">
        <div class="step-title text-center">
          {{$t("kyc.forms.YourAddress")}}
        </div>
        <div class="step-desc text-center mt-3">
          {{$t("kyc.address-document-description")}}
        </div>
        <BaseInputCustom
          class-name="theme-light mt-4"
          ref="fullAddress"
          v-model="formData.FullAddress"
          type="text"
          name="fullAddress"
          required
          :label="$t('kyc.forms.address.address')"
        />
        <div class="row">
          <BaseSelect
            class="col-sm-6"
            ref="country"
            :input-value="formData.CountryOfLiving"
            name="country"
            required
            :label="$t('kyc.forms.address.country')"
            :items="countries"
            text-field="name"
            value-field="name"
            :is-show-default="false"
            key-loop="index"
            class-name="theme-light"
            @change="countryChange"
          />
          <BaseInputCustom
            class-name="theme-light col-sm-6"
            ref="city"
            v-model="formData.City"
            type="text"
            name="text"
            required
            :label="$t('kyc.forms.address.city')"
          />
        </div>
        <div class="row">
          <BaseSelect
            class="col-sm-6 documents"
            ref="addressDocumentType"
            :input-value="formData.AddressDocumentType"
            name="addressDocumentType"
            :label="$t('kyc.forms.passport.type')"
            :items="addressDocumentTypes"
            required
            text-field="name"
            value-field="name"
            :is-show-default="false"
            key-loop="name"
            class-name="theme-light"
            @change="addressDocumentTypeChange"
          />
          <BaseInputCustom
            class-name="theme-light col-sm-6"
            ref="postalCode"
            v-model="formData.PostalCode"
            type="tel"
            name="postalCode"
            required
            :label="$t('kyc.forms.address.code')"
          />
        </div>
        <BaseInputFile
          :label="$t('kyc.forms.Bill') + ' ('+$t('validate.upload')+')'"
          id="utility-bill-img"
          name="utility-bill-img"
          ref="utilityBillImg"
          required
          :file-browse-text="$t('action.upload')"
          :accept-type="['.jpg', '.jpeg', '.png']"
          :limit-file-size="5242880"
          :placeholder="$t('input-groups.choose-file')"
          :validation-error-message="$t('kyc.note')"
          @change="handleChangeUtilityFile"
        />
      </div>
      <div v-if="currentStep === 1" :key="currentStep">
        <div class="step-title text-center">
          {{$t("kyc.forms.Personal")}}
        </div>
        <div class="step-desc text-center mt-3">
          {{$t("kyc.passport-document-description")}}
        </div>
        <div class="row mt-4">
          <BaseInputCustom
            class-name="theme-light col-sm-6"
            ref="documentNumber"
            v-model="formData.DocumentNumber"
            type="tel"
            name="documentNumber"
            required
            :label="$t('kyc.forms.passport.number')"
          />
          <div class="col-sm-6">
            <BaseDatePicker
              id="documentIssueDate"
              ref="documentIssueDate"
              class="theme-light"
              :label="$t('kyc.forms.passport.issue-date')"
              required
              :value="formData.DocumentIssueDate"
              @input="formData.DocumentIssueDate = $event"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <BaseDatePicker
              :label="$t('kyc.forms.passport.birthday')"
              id="dateOfBirth"
              ref="dateOfBirth"
              class="theme-light"
              required
              :value="formData.DateOfBirth"
              @input="formData.DateOfBirth = $event"
            />
          </div>
          <BaseSelect
            class="col-sm-6"
            ref="countryOfBirth"
            v-model="formData.CountryOfBirth"
            name="countryOfBirth"
            required
            :label="$t('kyc.forms.passport.country')"
            :items="countries"
            text-field="name"
            value-field="name"
            :is-show-default="false"
            key-loop="name"
            class-name="theme-light"
            @change="formData.CountryOfBirth = $event"
          />
        </div>
        <div class="row mt-3">
          <BaseInputCustom
            class-name="theme-light col-sm-6"
            ref="cityOfBirth"
            v-model="formData.CityOfBirth"
            type="text"
            name="cityOfBirth"
            required
            :label="$t('kyc.forms.passport.city')"
          />
          <BaseSelect
            class="col-sm-6"
            ref="passportDocumentType"
            :input-value="formData.PassportDocumentType"
            name="passportDocumentType"
            :label="$t('kyc.forms.passport.type')"
            :items="passportDocumentTypes"
            text-field="name"
            value-field="name"
            :is-show-default="false"
            key-loop="index"
            class-name="theme-light"
            required
            @change="formData.PassportDocumentType = $event"
          />
        </div>
        <BaseInputFile
          :label="$t('kyc.forms.CardFront') + ' ('+$t('validate.upload')+')'"
          id="card-front-img"
          name="card-front-img"
          ref="cardFront"
          :file-browse-text="$t('action.upload')"
          :accept-type="['.jpg', '.jpeg', '.png']"
          :limit-file-size="5242880"
          required
          :placeholder="$t('input-groups.choose-file')"
          :validation-error-message="$t('kyc.note')"
          @change="handleChangeFrontCardFile"
        />
        <BaseInputFile
            :label="$t('kyc.forms.CardBack') + ' ('+$t('validate.upload')+')'"
            class="mt-3"
            id="card-back-img"
            name="card-back-img"
            ref="cardBack"
            :file-browse-text="$t('action.upload')"
            :accept-type="['.jpg', '.jpeg', '.png']"
            :limit-file-size="5242880"
            required
            :placeholder="$t('input-groups.choose-file')"
            :validation-error-message="$t('kyc.note')"
            @change="handleChangeBackCardFile"
          />
      </div>
      <div v-if="currentStep === 2" :key="currentStep">
        <div class="step-title text-center">
          {{$t("profile.overview.Business")}}
        </div>
        <div class="step-desc text-center mt-3">
          {{$t("kyc.forms.business.desc")}}
        </div>
        <BaseInputCustom
          class-name="theme-light mt-3"
          ref="businessAddress"
          v-model="formData.BusinessAddress"
          type="text"
          name="documentNumber"
          required
          :label="$t('kyc.forms.business.address')"
        />
        <BaseInputFile
          :label="$t('kyc.forms.business.certificate') + ' ('+$t('validate.upload')+')'"
          id="business-certificate"
          name="business-certificate"
          ref="businessCertificate"
          :file-browse-text="$t('action.upload')"
          :accept-type="['.jpg', '.jpeg', '.png']"
          :limit-file-size="5242880"
          required
          :placeholder="$t('input-groups.choose-file')"
          :validation-error-message="$t('kyc.note')"
          @change="handleChangeBusinessCertificateFile"
        />
        <BaseInputFile
         :label="$t('kyc.forms.business.bank-statement') + ' ('+$t('validate.upload')+')'"
          class="mt-3"
          id="bank-statement"
          name="bank-statement"
          ref="bankStatement"
          :file-browse-text="$t('action.upload')"
          :accept-type="['.jpg', '.jpeg', '.png']"
          :limit-file-size="5242880"
          required
          :placeholder="$t('input-groups.choose-file')"
          :validation-error-message="$t('kyc.note')"
          @change="handleChangeBusinessBankStatementFile"
          />
      </div>
    </div>
    <div class="upload-document__action">
      <button
        v-if="!isEndStep"
        class="btn btn-primary w-100 mt-4"
        :class="{'button--loading': loading}"
        @click="next"
      >
        <span>{{$t('wizard.next')}}</span>
      </button>
      <button
        v-else
        class="btn btn-primary w-100 mt-4"
        :class="{'button--loading': loading}"
        @click="submit"
      >
        <span>{{$t('action.submit')}}</span>
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import countries from '@/constants/countries.json'
import {apiImageUrl} from "@/constants/config";
import BaseInputFile from '@/components/Inputs/BaseInputFile.vue'
import BaseInputCustom from "@/components/Inputs/BaseInputCustom.vue"
import BaseSelect from "@/components/Inputs/BaseSelect.vue"
import StepProgress from 'vue-step-progress';
import 'src/assets/sass/step-progress.scss';
import BaseDatePicker from "@/components/Inputs/BaseDatePicker.vue"
import validateMixin from '@/core/mixins/validate'
import {RepositoryFactory} from "@apis/repositoryFactory"
import {MODAL, NOTIFICATION_ICON} from "@/constants";
const KYCRepository = RepositoryFactory.get('kyc')
export default {
  components: {
    BaseInputFile,
    BaseInputCustom,
    BaseSelect,
    StepProgress,
    BaseDatePicker
  },
  mixins: [validateMixin],
  computed: {
    ...mapGetters('Auth', {currentUser: 'currentUser'}),
    isEndStep() {
      return this.currentStep === this.steps.length - 1
    },
    steps() {
      if (this.currentUser.Type === "BUSINESS") {
        return [this.$t("kyc.forms.YourAddress"), this.$t("kyc.forms.Personal"), this.$t("profile.overview.Business")]
      }
      return [this.$t("kyc.forms.YourAddress"), this.$t("kyc.forms.Personal")]
    },
    addressDocumentTypes() {
      return [
        {name: this.$t("kyc.forms.GasBill")},
        {name: this.$t("kyc.forms.WaterBill")},
        {name: this.$t("kyc.forms.ElectricityBill")},
        {name: this.$t("kyc.forms.BankStatement")}
      ]
    },
    passportDocumentTypes() {
      return [
        {name: this.$t("kyc.forms.passport.idCard")},
        {name: this.$t("kyc.forms.passport.passport")},
        {name: this.$t("kyc.forms.passport.driverLicense")},
      ]
    }
  },
  data() {
    return {
      currentStep: 0,
      countries,
      fieldValidationByStep: [
        ["fullAddress", "country", "city", "addressDocumentType", "postalCode", "utilityBillImg"],
        ["documentNumber", "documentIssueDate", "dateOfBirth", "countryOfBirth", "cityOfBirth", "passportDocumentType", "cardFront", "cardBack"],
        ["businessAddress", "bankStatement", "businessCertificate"]
      ],
      formData: {
        AddressDocumentType: "",
        BusinessAddress: "",
        Country: "",
        BusinessBankStatement: [],
        BusinessCertificate: [],
        City: "",
        CityOfBirth: "",
        CountryOfBirth: "",
        CountryOfLiving: "",
        DateOfBirth: "",
        DocumentIssueDate: "",
        DocumentNumber: "",
        FullAddress: "",
        IdentityCardBack: "",
        IdentityCardFront: "",
        PassportDocumentType: "",
        PostalCode: "",
        SocialSecurityCode: "",
        SocialSecurityImage: "",
        UtilityBill: "",
      },
      loading: false
    }
  },
  methods: {
    addressDocumentTypeChange(data) {
      this.formData.AddressDocumentType = data
    },
    countryChange(data) {
      this.formData.CountryOfLiving = data
    },
    getImagePath(id) {
      return apiImageUrl + id
    },
    handleChangeUtilityFile (val) {
      this.formData.UtilityBill = val;
    },
    handleChangeFrontCardFile (val) {
      this.formData.IdentityCardFront = val;
    },
    handleChangeBusinessCertificateFile (val) {
      this.formData.BusinessCertificate = [val];
    },
    handleChangeBackCardFile (val) {
      this.formData.IdentityCardBack = val;
    },
    handleChangeBusinessBankStatementFile (val) {
      this.formData.BusinessBankStatement = [val];
    },
    next () {
      const refs = this.fieldValidationByStep[this.currentStep]
      const refsValid = this.$_validateMixin_refs(refs)
      if (refsValid) {
        this.currentStep += 1
      }
    },
    async submit() {
      const refs = this.fieldValidationByStep[this.currentStep]
      const refsValid = this.$_validateMixin_refs(refs)
      if (refsValid) {
        try {
          await KYCRepository.uploadKYC(this.formData)
          this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
            this.$t("kyc.forms.completed"),
            this.$t("kyc.forms.desc"),
            {
              action1Text: 'OK',
              action1Func: () => {
              }
            }
          )
          this.$emit("submitted")
          this.$bvModal.hide(MODAL.UPLOAD_DOCUMENTS)
        } catch(e) {
          console.log(e)
          this.$alertMsg('fail', e.response.data.message)
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
.upload-document {
  font-style: normal;
  color: #141822;
  &__title {
     font-weight: 700;
     font-size: 32px;
     line-height: 44px;
  }
  .step-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
  }
  /deep/.documents {
    .base-select__value {
      p {
        width: 184px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
