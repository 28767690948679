<template>
  <div class="mt-4">
    <div v-if="isLoading" class="container position-relative mt-4">
      <BaseLoading class="loading" :loading="isLoading" />
    </div>
    <template v-else>
      <div class="row align-items-center page-title">
        <!-- <div class="col-12 col-md mb-2 mb-sm-0">
          <h5 class="mb-0">{{$t('menu-items.subShop.event-ticket')}}</h5>
        </div> -->
        <div class="col-12">
          <p class="text-secondary">
            {{ $t('event.text.first') }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 position-relative">
          <div class="card p-3 mb-4">
            <div class="card-body p-0">
              <div class="table-responsive">
                <table class="table table-borderless footable">
                  <thead>
                    <tr class="text-muted">
                      <th>Code</th>
                      <th>{{$t('contact.full-name')}}</th>
                      <th>{{$t('users.infor.phonenumber')}}</th>
                      <th>{{ $t('activate-date') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in tickets" :key="index" :data="item">
                      <td class="d-table-cell">#{{item.Code}}</td>
                      <td class="d-table-cell">{{item.FullName}}</td>
                      <td class="d-table-cell">{{item.PhoneNumber}}</td>
                      <td class="d-table-cell">{{ item.BuyDate | formatDateTime }}</td>
                    </tr><!---->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <b-row style="padding: 0 15px;">
        <event v-for="(itemElm, indx) in ticketEvents" :key="indx" :item="itemElm" @reloadTicket="handleData()" />
      </b-row>
      <div v-if="tickets.length > 0" class="scroll-ticket">
        <span
          v-scroll-to="{
                 el: '#element',
                 duration: 500,
                 lazy: false,
                 easing: 'linear',
                 offset: 0
             }"
        >
          {{ $t('event.scroll') }}
        </span>
        <div class="scroll-ticket-container"
           v-scroll-to="{
               el: '#element',
               duration: 500,
               lazy: false,
               easing: 'linear',
               offset: 0
           }"
        >
          <div class="chevron"></div>
          <div class="chevron"></div>
          <div class="chevron"></div>
        </div>
      </div>
      <b-row id="element" v-if="tickets.length > 0">
        <item v-for="(item, index) in tickets" :key="index" :data="item" @handleData="handleData()" />
      </b-row> -->
    </template>
  </div>
</template>

<script>
import Event from "@/containers/Shop/event-ticket/event-item.vue";
import { RepositoryFactory } from "@/apis/repositoryFactory";
import Item from "@/containers/Shop/event-ticket/item.vue";
import globalMixin from '@/core/mixins/global';
import BaseLoading from "@components/Inputs/BaseLoading";

const ShopRepository = RepositoryFactory.get("shop");
const EventRepository = RepositoryFactory.get("event");

export default {
  data: () => ({
    tickets: [],
    end: new Date("2022-07-18T10:00:00"),
    ticketEvents: [],
  }),
  mixins: [globalMixin],
  components: {
    BaseLoading,
    Item,
    Event
  },
  async created() {
    await this.fetchItem()
    await this.fetchTicket()
  },
  methods: {
    async handleData () {
      await this.fetchItem()
      await this.fetchTicket()
    },
    async fetchTicket () {
      try {
        this.$_loading();
        this.tickets = await EventRepository.getEventTicket();
      } catch (error) {
      } finally {
        this.$_load();
      }
    },
    async fetchItem() {
      try {
        this.$_loading()
        const items = await ShopRepository.getAll();
        this.ticketEvents = items['EVENT_TICKET']
        Object.keys(items).forEach(i => {
          if (!items[i].length) delete items[i]
        })
      } catch (error) {
        console.log(error);
      } finally {
        this.$_load()
      }
    }
  },
};
</script>

<style scoped lang="scss">
.scroll-ticket {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  flex-direction: column;
  span {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    @media screen and (max-width: 768px){
      display: block;
      font-size: 14px;
      line-height: 17px;
    }
    &:hover {
      cursor: pointer;
      color: #00a7e6;
    }
  }
}

.scroll-ticket-container {
  position: relative;
  width: 24px;
  height: 24px;
}

.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #000000;
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(10px);
  }
  67% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    transform: translateY(30px) scale3d(0.5, 0.5, 0.5);
  }
}

.text {
  display: block;
  margin-top: 75px;
  margin-left: -30px;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: .25;
  animation: pulse 2s linear alternate infinite;
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}

.text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb),var(--bs-text-opacity))!important;
}
.text-muted {
    opacity: .7;
    color: inherit!important;
}
.table>thead {
    vertical-align: bottom;
}
table.footable, table.footable-details {
    position: relative;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}
.table-responsive {
    overflow-x: auto;
}

.card .card-body:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
}
.card .card-body:first-child {
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem;
}
.card .card-body {
    background-color: rgba(var(--WinDOORS-theme-bg-rgb),.7);
}
.footable thead tr th {
    font-size: 14px;
    font-weight: 400;
    color: var(--WinDOORS-theme-text-secondary);
}
</style>
