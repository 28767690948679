<template>
  <div class="affliate">
    <div class="affliate__container">
      <div class="affliate__header">
        <p class="title">{{ $t("action.aff-link") }}</p>
        <a @click="onClickCopy">
          <BaseInput
            disabled
            :value="getAffLink"
            addonRightIcon="icon-icon-copy"
            class="link-ref"
          />
        </a>
      </div>
      <div class="affliate__content">
        <p class="title">{{ $t("qrcode.qr-code") }}</p>
        <div class="qr-img" id="AffLink">
          <qrcode-vue
            id="qrimage"
            :value="getAffLink"
            size="400"
            level="M"
          ></qrcode-vue>
        </div>
        <a
          type="button"
          class="btn-download"
          @click="downloadQR('AffLink', 'AffLink')"
          >{{ $t("qrcode.download") }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import QrcodeVue from "qrcode.vue";
import { homeUrl } from "@/constants/config";
import helpers from "@/core/mixins/helpers";

export default {
  name: "Affliate",
  components: {
    BaseInput,
    QrcodeVue,
  },
  data() {
    return {
      affLinkResource: homeUrl + "/user/register?vlinkId=",
    };
  },
  mixins: [helpers],
  computed: {
    getAffLink() {
      return this.affLinkResource + this.$store.state.Auth.currentUser.VLinkId;
    },
  },
  methods: {
    onClickCopy() {
      navigator.clipboard.writeText(this.getAffLink);
      this.$alertMsg("success", this.$t("action.copied-text"));
    },
    // downloadQR() {
    //     const nameFile = 'qrcode-for-scan.png'
    //   var image = document.getElementsByTagName('canvas')[0].toDataURL()
    //   var link = document.createElement('a')
    //   link.href = image
    //   link.download = nameFile
    //   link.click()
    // }
  },
};
</script>

<style scoped lang="scss">
.affliate {
  margin-top: 24px;
  min-height: 70vh;
  .title {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #43464e;
    margin-bottom: 12px;
  }
  &__container {
    max-width: 488px;
  }
  &__header {
    .link-ref {
      cursor: pointer;
    }
  }
  &__content {
    text-align: center;
    margin-top: 24px;
    .qr-img {
      border: 1px solid #adaeb2;
      border-radius: 8px;
      padding: 24px;
      /deep/ canvas {
        width: 100% !important;
        height: auto !important;
      }
    }
    .btn-download {
      border: 1px solid #007bff;
      border-radius: 8px;
      padding: 8px 34px;
      cursor: pointer;
      text-decoration: inherit;
      margin-top: 24px;
      &:hover {
        background: #007bff;
        color: #ffffff;
      }
    }
  }
}
</style>
