<template>
  <div v-if="payment" class="payment">
    <div class="payment__title">
      <span>{{ $t("payments.methods.index") }}</span>
    </div>
    <div class="payment__content">
      <div class="text-center my-2" v-if="isFetchPayment">
        <BaseLoading v-if="isFetchPayment" class="loading" :loading="isFetchPayment" />
      </div>
      <div class="choose-payment" v-else>
        <template v-for="(item, index) in paymentList">
          <VlgItem
            v-if="item.name === 'VLG' && !(paymentOptions.NotAllowPartialPayVlg && !paymentOptions.PaymentMethods.includes('VLG'))"
            v-model="PayByVlg"
            :item="item"
            :wallet="wallet"
            :disabled="payByVlgDisabled"
            :key="index"/>
          <PaymentItem
            v-model="paymentType"
            v-if="item.name !== 'VLG'"
            :item="item"
            :wallet="wallet"
            @selectCreditCard="setPaymentMethod('CreditCard')"
            @editCreditCard="setSelectCredit"
          :key="index"/>
        </template>
      </div>
      <p class="note" v-if="PayByVlg && numberCart > 0">
        {{ $t("payments.use-vlg.note") }}
      </p>
      <div class="summary">
        <template v-if="!paymentMethod || paymentMethod === 'CreditCard'">
          <div class="item">
            <span
              >{{ $t("payments.methods.subtotal") }} ({{ numberCart }}
              {{ $tc("menu-items.item", numberCart) }})</span
            >
            <span>{{ totalAmount | toCurrency(true) }}</span>
          </div>
          <div class="item">
            <span>{{ $t("payments.methods.fee") }}:</span>
            <span>{{ transactionFee() | toCurrency(true) }}</span>
          </div>
          <div class="item last">
            <span>{{ $t("payments.methods.total") }}:</span>
            <span>{{
              (totalAmount + transactionFee()) | toCurrency(true)
            }}</span>
          </div>
        </template>
        <template v-else>
          <div class="item">
            <span
              >{{ $t("payments.methods.subtotal") }} ({{ numberCart }}
              {{ $tc("menu-items.item", numberCart) }})</span
            >
            <span>{{
              (cartTotal.Amount + cartTotal.VlgAmount) | toCurrency(true)
            }}</span>
          </div>
          <div class="">
            <p class="d-flex justify-content-between small sub-text">
              <span>{{ currentSelected.label }}:</span>
              <span>
                {{ estimate.Amount | toCurrency }} {{ currentSelected.name }}
                <i class="d-block text-right"
                  >~{{ cartTotal.Amount | toCurrency(true) }}</i
                >
              </span>
            </p>
            <template v-if="PayByVlg">
              <p class="d-flex justify-content-between small sub-text">
                <span>VLG Token:</span>
                <span>
                  {{ estimate.VlgAmount | toCurrency }} VLG
                  <i class="d-block text-right"
                    >~{{ cartTotal.VlgAmount | toCurrency(true) }}</i
                  >
                </span>
              </p>
            </template>
          </div>

          <div class="item">
            <span>{{ $t("payments.methods.fee") }} </span>
            <span>{{
              (cartTotal.fee + cartTotal.VlgFee) | toCurrency(true)
            }}</span>
          </div>
          <div class="">
            <p class="d-flex justify-content-between small sub-text">
              <span
                >{{ currentSelected.label }} ({{
                  (cartTotal.fee * 100 / totalAmount) | toCurrency
                }}%):</span
              >
              <span>
                {{ estimate.Fee | toCurrency }} {{ currentSelected.name }}
                <i class="d-block text-right"
                  >~{{ cartTotal.fee | toCurrency(true) }}</i
                >
              </span>
            </p>
            <template v-if="PayByVlg">
              <p class="d-flex justify-content-between small sub-text">
                <span>VLG Token (10%):</span>
                <span>
                  {{ estimate.VlgFee | toCurrency }} VLG
                  <i class="d-block text-right"
                    >~{{ cartTotal.VlgFee | toCurrency(true) }}</i
                  >
                </span>
              </p>
            </template>
          </div>
          <div class="item last">
            <span>{{ $t("payments.methods.total") }}:</span>
            <span>{{ cartTotal.TotalAmount | toCurrency(true) }}</span>
          </div>
        </template>
        <button
          :class="{ 'button--loading': isLoading }"
          class="button"
          @click="submitPayment()"
          :disabled="!paymentMethod || !numberCart"
        >
          <span>{{ $t("payments.methods.process") }}</span>
        </button>
      </div>
    </div>
    <BaseModal :config="config">
      <template slot="content">
        <ModalPinSecurity @submit="finalConfirmWithPin" :loading="isLoading" />
      </template>
    </BaseModal>
  </div>
</template>

<script>
import validateMixin from "@/core/mixins/validate";
import { mapGetters } from "vuex";
import { MODAL, NOTIFICATION_ICON } from "@/constants";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import { RepositoryFactory } from "@apis/repositoryFactory";
import PaymentItem from "@containers/Shopping-Cart/payment/payment-item.vue";
import VlgItem from "@containers/Shopping-Cart/payment/vlg-item.vue";
import moment from "moment";
import isEmpty from "lodash/isEmpty";

const CartRepository = RepositoryFactory.post("cart");
import { PAYMENT_METHODS } from "@/constants";
import ModalPinSecurity from "@containers/Shopping-Cart/payment/ModalPinSecurity";
import BaseLoading from "@components/Inputs/BaseLoading";
export default {
  name: "payment",
  components: {
    ModalPinSecurity,
    BaseInputCustom,
    PaymentItem,
    VlgItem,
    BaseLoading
  },
  mixins: [validateMixin],
  props: {
    cartList: {
      type: Array,
      default: () => [],
    },
    payment: {
      type: Boolean,
      default: false,
    },
    isFetchPayment: {
      type: Boolean,
      default: true,
    },
    idCheckBox: {
      type: String,
      default: "",
    },
    paymentMethod: {
      type: String,
      default: "",
    },
    cartId: {
      type: String,
      default: "",
    },
    numberCart: {
      type: Number,
      default: 0,
    },
    totalAmount: {
      type: Number,
      default: 0,
    },
    formDataCredit: {
      type: Object,
      default: () => {},
    },
    paymentOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      config: {
        id: MODAL.PIN_VERIFICATION,
        class: "modal--pin-security-confirmation",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light",
      },
      PAYMENT_METHODS,
      isLoading: false,
      pinCode: "",
      PayByVlg: false,
      estimate: {
        Amount: 0,
        Fee: 0,
        TotalAmount: 0,
        TotalVlgAmount: 0,
        VlgAmount: 0,
        VlgFee: 0,
      },
      cartTotal: {},
      formData: {},
    };
  },
  computed: {
    ...mapGetters({
      wallet: "getWallet",
    }),
    paymentType: {
      set(value) {
        this.$emit("paymentMethod", value);
      },
      get() {
        return this.paymentMethod;
      },
    },
    currentSelected() {
      return this.paymentMethod
        ? PAYMENT_METHODS.find((item) => item.value === this.paymentMethod)
        : {};
    },
    paymentList() {
      return PAYMENT_METHODS.map((e) => {
        if (e.value === 'CreditCard') {
          return {
            ...e,
            allow: this.paymentOptions.PaymentMethods && this.paymentOptions.PaymentMethods.includes(e.name),
            hasInfo: !isEmpty(this.formDataCredit)
          };
        }
        return {
          ...e,
          allow:
            this.paymentOptions.PaymentMethods &&
            this.paymentOptions.PaymentMethods.includes(e.name),
        };
      });
    },
    payByVlgDisabled() {
      return (
        !this.paymentMethod ||
        this.paymentMethod === "CreditCard" ||
        this.paymentOptions.NotAllowPartialPayVlg
      );
    },
  },
  watch: {
    PayByVlg() {
      this.fetchValue();
    },
    paymentMethod() {
      if (this.paymentMethod !== "CreditCard") {
        this.fetchValue();
      }
    },
    numberCart() {
      this.fetchValue();
    },
  },
  created() {
    if (this.paymentMethod && this.paymentMethod !== "CreditCard") {
      this.fetchValue();
    }
  },
  methods: {
    async fetchValue() {
      this.isLoading = true;
      let paymentMethod = this.paymentMethod || "Usdt";

      const payload = {
        WalletType: this.paymentMethod
          ? this.paymentMethod.toUpperCase()
          : "Usdt",
        CartId: this.cartId,
        PayByVlg: this.PayByVlg,
        CartItemIds: this.$store.state.listIdSplit,
      };
      try {
        payload.WalletType = payload.WalletType === 'USDT_TRC20' ? 'USDT' : payload.WalletType.toUpperCase()
        this.estimate = await CartRepository.getTotalcart(payload);
        paymentMethod = paymentMethod == 'Usdt_trc20' ? 'Usdt' : paymentMethod
        this.cartTotal = {
          Amount:
            this.estimate.Amount *
            (this.wallet[paymentMethod].CurrentPrice || 1),
          fee:
            this.estimate.Fee * (this.wallet[paymentMethod].CurrentPrice || 1),
          VlgFee:
            this.estimate.VlgFee *
            this.wallet["VLGTokenAvailable"].CurrentPrice,
          VlgAmount:
            this.estimate.VlgAmount *
            this.wallet["VLGTokenAvailable"].CurrentPrice,
          TotalAmount:
            this.estimate.TotalAmount *
              (this.wallet[paymentMethod].CurrentPrice || 1) +
            this.estimate.TotalVlgAmount *
              this.wallet["VLGTokenAvailable"].CurrentPrice,
        };
      } catch (e) {
      } finally {
        this.isLoading = false;
      }
    },
    async submitPayment() {
      this.isLoading = true;
      if (this.paymentMethod !== "CreditCard") {
        if (this.paymentMethod === "Usdt_trc20") {
          this.$emit("paymentWallet", true, { PayByVlg: this.PayByVlg });
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.$bvModal.show(MODAL.PIN_VERIFICATION);
      } else {
        if (this.totalAmount > 1000) {
          this.$notifyPopup(
            NOTIFICATION_ICON.ERROR,
            this.$t("swal.title.fail"),
            this.$t("payments.orders-unders"),
            {
              action1Text: "OK",
              action1Func: () => {},
            },
            "dashboard"
          );
          this.isLoading = false;
          return;
        }
        await this.checkoutCreditCard();
      }
    },
    async finalConfirmWithPin(pinCode) {
      this.isLoading = true;
      this.pinCode = pinCode;
      const result = await this.checkoutCrypto();
      this.isLoading = false;
      if (result) {
        this.$bvModal.hide(MODAL.PIN_VERIFICATION);
      }
    },
    async checkoutCrypto() {
      const payload = {
        CartId: this.cartId,
        Pin: this.pinCode,
        PayByVlg: this.PayByVlg,
      };
      try {
        switch (this.paymentMethod) {
          case "VLGTokenAvailable":
            await CartRepository.checkOutByVlgToken(payload);
            this.showAlertSuccess();
            return true;
          case "VLGPlus":
            await CartRepository.checkOutByVlgPlus(payload);
            this.showAlertSuccess();
            return true;
          case "VLGCash":
            await CartRepository.checkOutByCash(payload);
            this.showAlertSuccess();
            return true;
          case "Usdt_trc20":
            await CartRepository.checkoutByCrypto(payload.CartId);
            this.showAlertSuccess();
            return true;
          case "Usdt":
            await CartRepository.checkoutByUsdt(payload);
            this.showAlertSuccess();
            return true;
          default:
            await CartRepository.checkOutByVlgGold(payload);
            this.showAlertSuccess();
            return true;
        }
      } catch (error) {
        this.isLoading = false;
        this.$notifyPopup(
          NOTIFICATION_ICON.ERROR,
          this.$t("swal.title.fail"),
          error.response.data.message,
          {
            action1Text: "OK",
            action1Func: "OK",
          },
          "dashboard"
        );
        return false;
      }
    },
    showAlertSuccess() {
      this.isLoading = false;
      this.$notifyPopup(
        NOTIFICATION_ICON.SUCCESS,
        this.$t("swal.title.success"),
        this.$t("payments.success"),
        {
          action1Text: "OK",
          didCloseFunc: this.redirectPage,
          action1Func: this.redirectPage,
        },
        "dashboard"
      );
    },
    redirectPage() {
      window.location = "/shop?tab=OrderHistory";
    },
    async checkoutCreditCard() {
      try {
        if (Object.keys(this.formDataCredit).length !== 0) {
          this.formData = { ...this.formDataCredit, CartId: this.cartId };
          this.formData.ExpirationDate = moment(
            this.formData.ExpirationDate
          ).format("MM/YY");
        }
        await CartRepository.checkoutByCreditCard(this.formData);
        this.showAlertSuccess();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$notifyPopup(
          NOTIFICATION_ICON.ERROR,
          this.$t("swal.title.fail"),
          error.response.data.message,
          {
            action1Text: "OK",
            action1Func: () => {},
          },
          "dashboard"
        );
      }
    },
    resetForm() {
      const refs = ["pinCode"];
      this.pinCode = "";
      this.$_validateMixin_reset(refs);
    },
    transactionFee() {
      if (!isEmpty(this.currentSelected)) {
        return (
          (this.totalAmount * Number(this.currentSelected.transaction)) / 100
        );
      }
      return 0;
    },
    formatCurrentPrice(value) {
      if (value) {
        return value;
      }
      return 1;
    },
    setPaymentMethod(value) {
      if (value === "CreditCard" && this.totalAmount > 1000) {
        this.$notifyPopup(
          NOTIFICATION_ICON.ERROR,
          this.$t("swal.title.fail"),
          this.$t("payments.orders-unders"),
          {
            action1Text: "OK",
            action1Func: () => {},
          },
          "dashboard"
        );
        this.isLoading = false;
        return;
      }
      if (
        Object.keys(this.formDataCredit).length === 0 &&
        value === "CreditCard"
      ) {
        this.setSelectCredit();
        return;
      }
      this.paymentType = value;
    },
    setSelectCredit() {
      this.$emit("selectCredit", true);
    },
  },
};
</script>

<style scoped lang="scss">
.payment {
  background: #ffffff;
  padding: 24px 16px;
  border-radius: 8px;
  &__title {
    display: flex;
    align-items: center;
    padding-bottom: 14px;
    border-bottom: 1px solid #eaeaea;
    span:first-child {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #141822;
      @media only screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    @media only screen and (max-width: 768px) {
      border-bottom: 0;
      padding-bottom: 10px;
    }
  }
  &__content {
    .choose-payment {
      margin-top: 24px;
      display: flex;
      column-gap: 16px;
      flex-wrap: wrap;
      justify-content: center;
      // justify-content: flex-start;
    }
    .summary {
      border-top: 1px solid #eaeaea;
      padding-top: 24px;
      .item {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 8px;
        span:first-child {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #141822;
        }
        span:last-child {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          color: #141822;
        }
        &.last {
          margin-top: 16px;
          margin-bottom: 24px;
          border-top: 1px solid #eaeaea;
          padding-top: 24px;
          span:first-child {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #141822;
          }
          span:last-child {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #e96058;
          }
        }
      }
      .button {
        background: #007bff;
        border-radius: 8px;
        border: none;
        width: 100%;
        display: flex;
        justify-content: center;
        &:disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        span {
          display: inline-block;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #ffffff;
          padding: 12px 0;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      .choose-payment {
        margin-top: 0;
        .item {
          min-height: 74px;
          padding: 8px 12px;
          .left {
            img {
              margin-right: 12px;
            }
            .information {
              .right__box {
                margin-top: 0;
              }
              .name {
                font-size: 16px;
                line-height: 20px;
              }
              .number-card {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                span {
                  font-size: 14px;
                  line-height: 18px;
                  color: #898b91;
                  margin-right: 10px;
                }
              }
              .number-card-show {
                span {
                  display: block;
                  font-size: 14px;
                  line-height: 18px;
                  color: #898b91;
                }
              }
              .transaction {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #e96058;
              }
            }
          }
          .right {
            margin-right: 0;
          }
        }
      }
      .pin-code {
        padding-top: 0;
        padding-bottom: 8px;
      }
      .summary {
        padding-top: 13px;
        .item {
          span:first-child {
            font-size: 14px;
            line-height: 20px;
          }
          span:last-child {
            font-size: 14px;
            line-height: 20px;
          }
          &.last {
            margin-top: 10px;
            margin-bottom: 14px;
            span:first-child {
              font-size: 16px;
              line-height: 24px;
            }
            span:last-child {
              font-size: 16px;
              line-height: 24px;
              font-weight: 700;
            }
          }
        }
        .button {
          span {
            padding: 8px 0;
          }
        }
      }
    }
  }
  .sub-text {
    color: #667085;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
