export default [
    {
        label: 'VUSD (VCR20)',
        symbol: 'VUSD',
        icon: '/assets/img/icon-wallet/vusd.png',
        value: 'VLGCash',
        toFiat: true,
        transferAvailable: true,
        actions: []
    },
    {
        label: 'VLG Token (VCR20)',
        symbol: 'VLG',
        icon: '/assets/img/icon-wallet/vlg.png',
        value: 'VLGTokenAvailable',
        transferAvailable: true,
        toFiat: true,
        actions: []
    },
    {
        label: 'VTP (VCR20)',
        symbol: 'VTP',
        icon: '/assets/img/icon-wallet/vtp.png',
        value: 'VLGPlus',
        transferAvailable: true,
        toFiat: true,
        actions: []
    },
    {
        label: 'VTP Bonus (VCR20)',
        symbol: 'VTP',
        icon: '/assets/img/icon-wallet/vtp-bonus.png',
        value: 'VtpBonus',
        toFiat: true,
        actions: []
    },
    {
        label: 'VLG Token Matching (VCR20)',
        symbol: 'VLG',
        icon: '/assets/img/icon-wallet/vlgtoken.png',
        value: 'VLGToken',
        toFiat: true,
        actions: []
    },
    {
        label: 'VLG Token Staking (VCR20)',
        symbol: 'VLG',
        icon: '/assets/img/icon-wallet/vlg-block.png',
        value: 'VLGTokenBlock',
        toFiat: true,
        actions: []
    },
    {
        label: 'VLG Share (VCR20)',
        symbol: 'VLG Share',
        icon: '/assets/img/icon-wallet/vlg-share.png',
        value: 'VLGShare',
        toFiat: true,
        actions: []
    },
    {
        label: 'VLG Gold (VCR20)',
        symbol: 'VLG Gold',
        icon: '/assets/img/icon-wallet/vlg-gold.png',
        value: 'VLGGold',
        toFiat: true,
        actions: []
    },
    {
        label: 'VLG Airdrop (VCR20)',
        symbol: 'VLG Airdrop',
        icon: '/assets/img/icon-wallet/Airdropvlg.png',
        value: 'Airdrop',
        toFiat: true,
        actions: []
    },
    {
        label: 'VLG Airdrop Block (VCR20)',
        symbol: 'VLG Airdrop Block',
        icon: '/assets/img/icon-wallet/AirdropvlgBlock.png',
        value: 'AirdropBlock',
        toFiat: true,
        actions: []
    },
    {
      label: 'VMM Token Release (VRC20)',
      symbol: '',
      symbolDetail: '',
      icon: '/assets/img/icon-wallet/vmm-icon_release.png?v=0.0.1',
      value: 'VmmRelease',
      toFiat: true,
      actions: []
    },
    {
        label: 'VMM Token (VRC20)',
        symbol: '',
        symbolDetail: '',
        icon: '/assets/img/icon-wallet/vmm-icon.png?v=0.0.1',
        value: 'VmmToken',
        transferAvailable: true,
        toFiat: true,
        actions: []
    },
    {
        label: 'VMM Token Bonus (VRC20)',
        symbol: '',
        symbolDetail: '',
        icon: '/assets/img/icon-wallet/vmm-icon_bonus.png?v=0.0.1',
        value: 'VmmBonus',
        toFiat: true,
        actions: []
    },
    {
        label: 'VMM Token Staking (VRC20)',
        symbol: '',
        symbolDetail: '',
        icon: '/assets/img/icon-wallet/vmm-icon_st.png?v=0.0.1',
        value: 'VmmStaking',
        toFiat: true,
        actions: []
    },
    {
        label: 'Lifetime Point',
        symbol: 'LP',
        icon: '/assets/img/icon-wallet/lifetime-point.png',
        value: 'VLGLifetimePoint',
        actions: []
    },
    {
        label: 'Affiliates Point',
        symbol: 'AP',
        icon: '/assets/img/icon-wallet/affiliate-point.png',
        value: 'VLGAffiliatesPoint',
        actions: []
    },
    {
        label: 'VMM Token Burn (VRC20)',
        symbol: '',
        icon: '/assets/img/icon-wallet/VMMburn.png',
        value: 'VmmBurn',
        toFiat: true,
        transferAvailable: false,
        actions: []
    },
    {
        label: 'VMM Token Block (VRC20)',
        symbol: '',
        icon: '/assets/img/icon-wallet/VMMblock.png',
        value: 'VmmBlock',
        toFiat: true,
        transferAvailable: false,
        actions: []
    },
    {
        label: 'USDT',
        symbol: 'USDT',
        icon: '/assets/img/icon-wallet/usdt.png',
        value: 'Usdt',
        transferAvailable: true,
        actions: []
    },
    {
        label: 'BTC',
        symbol: 'BTC',
        icon: '/assets/img/icon-wallet/btc.png',
        value: 'Btc',
        actions: []
    },
    {
        label: 'ETH',
        symbol: 'ETH',
        icon: '/assets/img/icon-wallet/eth.png',
        value: 'Eth',
        actions: []
    },
    {
        label: 'DOGE',
        symbol: 'DOGE',
        icon: '/assets/img/icon-wallet/doge.png',
        value: 'Doge',
        actions: []
    },
    {
        label: 'SHIBA',
        symbol: 'SHIBA',
        icon: '/assets/img/icon-wallet/shiba.png',
        value: 'Shiba',
        actions: []
    },
    {
      label: '1L',
      symbol: '1L',
      icon: '/assets/img/icon-wallet/1L.png',
      value: 'L1Wallet',
      toFiat: true,
      actions: []
    },
    {
      label: '3EB',
      symbol: '3EB',
      icon: '/assets/img/icon-wallet/3eb.png',
      value: 'Eb3Wallet',
      toFiat: true,
      actions: []
    },
    {
      label: '5EB',
      symbol: '5EB',
      icon: '/assets/img/icon-wallet/5eb.png',
      value: 'Eb5Wallet',
      toFiat: true,
      actions: []
    },
    {
        label: 'META TRON',
        symbol: 'TRX',
        icon: '/assets/img/icon-wallet/tron.png',
        value: 'tron',
        actions: []
    },
]
