<template>
  <div class="buy-packages">
    <div class="buy-packages__header mt-4">
      <div class="row">
        <div class="list">
          <div class="navigation">
            <div
              v-for="item in dataTab"
              class="item w-auto px-4"
              :class="{'active': keyActive === item}"
              @click="filterData(item)"
              v-if="hideShowTab(item)"
            >
              <span>{{ item === 'PRE-ORDER' ? 'Pre Order' : getItemName(item) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isLoading" class="buy-packages__content">
      <div v-if="data && data.length" class="row">
        <template v-for="(item, index) in data" >
          <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div :key="index" class="item-shop" :class="{'sold-out': listNameSoldOut.includes(item.Name)}">
              <img v-show="hotPackages.includes(item.Name)" src="/assets/New-03.png" class="mw-100 hot-packages">
              <div class="img-shop">
                <img :src="$apiImageUrl + item.ImagePath" alt="shop-default" >
              </div>
              <div class="label-sold-out" v-if="listNameSoldOut.includes(item.Name)">
                <img src="/img/sold-out-home.svg" alt="icon-info" />
              </div>
              <div class="btn-details">
                <img src="/img/icon-info.svg" alt="icon-info" @click="openDetail(item.Id)"  v-b-tooltip.hover :title="$t('action.view-more-benefit')">
              </div>
              <div class="information">
                <span class="name text-uppercase">{{ item.Name }}</span>
                <span class="money">{{ item.Price | toCurrency(true) }}</span>
              </div>
              <div class="quality d-flex justify-content-between align-items-center mt-3 mb-3">
                <span class="title mr-3">{{ $t('table.quantity') }}</span>
                <SumQuality :key="item.Id" @updateItem="updateValue" />
              </div>
              <div class="action">
                <button class="add" @click="handleAddItem(item)">
                  <span>{{ $t('action.addCart') }}</span>
                </button>
              </div>
              <div class="action">
                <button class="buy" @click="buyNow(item)">
                  <span>{{ $t('action.buy-now') }}</span>
                </button>
              </div>
            </div>
          </div>
        </template>
        <!-- <div class="col-md-6 col-lg-4 col-xl-3 col-12">
          <div :key="index" class="item-shop sold-out">
            <div class="img-shop">
              <img src="/assets/vmm-555.png" alt="shop-default" >
            </div>
            <div class="information">
              <span class="name">{{ 'VMM 555' }}</span>
              <span class="money">{{ 5555 | toCurrency(true) }}</span>
            </div>
            <div>
              <img class="mw-100" src="/assets/coming-soon-text-label.png" alt="">
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div v-else class="container position-relative mt-4">
      <BaseLoading v-if="isLoading" class="loading" :loading="isLoading" />
    </div>
  </div>
</template>

<script>
import SumQuality from '@/containers/Home/sum-quality.vue'
import { RepositoryFactory } from "@apis/repositoryFactory";
import {MODAL, NOTIFICATION_ICON} from "@/constants";
import { dreamUsaPackageName } from "@/constants/packages";
const ShopRepository = RepositoryFactory.get('shop');
const CartRepository = RepositoryFactory.post('cart');
const NumberCartRepository = RepositoryFactory.get('cart');
import isEmpty from "lodash/isEmpty"
import BaseLoading from "@components/Inputs/BaseLoading";
export default {
  components: {
    SumQuality,
    BaseLoading
  },
  data () {
    return {
      isLoading: false,
      shopItems: {},
      data: [],
      keyActive: 'KYC',
      carts: [],
      numberCart: 0,
      dataTab: [],
      listNameNew: [
        '3EB',
        '5EB',
        '1L'
      ],
      listNameSoldOut: [
        'VLINK MERCHANT META'
      ],
      hotPackages: [
        'Vlinkmeta Mall Founders',
        'VLINK MERCHANT META 555'
      ],
      form: {
        Quantity: 1
      }
    };
  },
  async mounted () {
    this.isLoading = true
    try {
      this.shopItems = await ShopRepository.getAll()
      delete this.shopItems['PRE-ORDER']
      delete this.shopItems['EVENT_TICKET']
      Object.keys(this.shopItems).forEach(i => {
        if (!this.shopItems[i].length) delete this.shopItems[i]
      })
      if (this.shopItems['AFFILIATE'] && this.shopItems['AFFILIATE'].length) {
        this.itemNews = this.shopItems['AFFILIATE'].filter(data => this.listNameNew.includes(data.Name))
        this.shopItems['AFFILIATE'] = this.shopItems['AFFILIATE'].filter(data => !this.listNameNew.includes(data.Name))
      }
      this.shopItems['PRE-ORDER'] = this.itemNews
      this.dataTab = Object.keys(this.shopItems)
      // this.data = this.shopItems[this.dataTab[0]]
      this.keyActive = this.$route.query.active || this.dataTab[0]
      this.data = this.shopItems[this.keyActive]
      this.isLoading = false
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    updateValue (value) {
      this.form.Quantity = value
    },
    openDetail (id) {
      this.$router.push(`/package-detail?PackageId=${id}`)
    },
    async handleAddItem (item) {
      const result = await this.addItem(item)
      if (result) {
        this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
          this.$t('swal.title.success'),
          this.$t('action.added-package'),
          {
            action1Text: 'OK',
            action1Func: 'OK',
            action2Text: this.$t('check-out'),
            action2Func: () => { this.$router.push("/shopping-cart?confirmCart=true") }
          },
          'dashboard'
        )
      }
    },
    async addItem (item) {
      if (isEmpty(this.$store.getters["Auth/currentUser"])) {
        this.$bvModal.show(MODAL.LOGIN)
      } else {
        try {
          const params = {
            PackageId: item.Id,
            Quantity: this.form.Quantity
          }
          const response = await CartRepository.addToCart(params)
          if (response && response.Id) {
            // this.$alertMsg('true', 'Added package to shopping cart')
            this.carts = await NumberCartRepository.getMyCart()
            this.numberCart = this.carts.CartItems.reduce( function(a, b){
              return a + b['Quantity'];
            }, 0 )
            this.$store.commit('setMyCart', this.numberCart)
            return true
          }
          return false
        } catch (error) {
          const res = JSON.parse(error.request.response)
          this.$notifyPopup(NOTIFICATION_ICON.ERROR,
            this.$t('swal.title.fail'),
            res.message,
            {
              action1Text: 'OK',
              action1Func: 'OK'
            },
            'dashboard'
          )
          return false
        }
      }
    },
    async buyNow (item) {
      const result = await this.addItem(item)
      if (result) {
        await this.$router.push("/shopping-cart")
      }
    },
    filterData(key) {
      this.keyActive = key
      this.data = this.shopItems[key]
      this.$router.push(`/shop?tab=BuyPackages&active=${key}`)
    },
    hideShowTab (key) {
      return this.shopItems[key] && Object.keys(this.shopItems[key]).length
    },
    getItemName(item) {
      if(item === 'AFFILIATE') {
        return this.$t('fields.affilite-package')
      } else if (item === 'KYC') {
        return this.$t('fields.kyc-package')
      }else if(item === 'BUSINESS') {
        return this.$t('fields.merchant-package')
      }else return item.toLowerCase()
    }
  }
};
</script>

<style scoped lang="scss">
.buy-packages {
  &__header {
    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #43464E;
      margin: 24px 0;
      display: inline-block;
    }
    .row {
      @media only screen and (max-width: 992px) {
        justify-content: center;
      }
    }
  }
  &__content {
    margin-top: 43px;
    margin-bottom: 40px;
    .empty-data {
      height: 315px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-weight: 700;
        font-size: 44px;
        line-height: 50px;
        text-align: left;
        color: #FFFFFF;
      }
    }
    @media only screen and (max-width: 992px) {
      margin: 20px;
    }
  }
  .list {
    overflow-y: hidden;
    overflow-x: auto;
  }
  .navigation {
    width: max-content;
    height: 40px;
    background: #F7F7F7;
    border: 1px solid #E8E8E9;
    border-radius: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .item {
      width: 130px;
      text-align: center;
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      span {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #898B91;
        text-transform: capitalize;
        @media only screen and (max-width: 992px) {
          font-size: 14px;
          line-height: 22px;
        }
      }
      &:hover, &.active {
        background: rgba(0, 123, 255, 0.1);
        border-radius: 20px;
        span {
          color: #007BFF;
        }
      }
    }
  }
  .item-shop {
    background: #F7F7F7;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
    height: 92%;
    position: relative;
    .label-sold-out {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }
    .btn-details {
      position: absolute;
      right: 12px;
      top: 12px;
      z-index: 2;
      &:hover {
        cursor: pointer;
      }
    }
    .img-shop {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-top: 68%;
      margin-bottom: 10px;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .information {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #141822;
        white-space: nowrap;
        max-width: 155px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .money {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #DBAC35;
      }
    }
    .quality {
      .title {
        font-size: 18px;
        line-height: 26px;
        color: #898B91;
      }
      /deep/.sum-quality {
        .input-number {
          color: #000000;
        }
      }
    }
    .action {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .add {
        width: 100%;
        height: 40px;
        background: transparent;
        border: 1px solid #007BFF;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          color: #007BFF;
          white-space: nowrap;
          padding-top: 3px;
        }
        &:hover {
          background: #007BFF;
          span {
            color: #FFFFFF;
          }
        }
      }
      .buy {
        width: 100%;
        height: 40px;
        background: #007BFF;
        border: 1px solid #007BFF;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          color: #FFFFFF;
          white-space: nowrap;
          padding-top: 3px;
        }
        &:hover {
          background: #007BFF;
          span {
            color: #FFFFFF;
          }
        }
      }
    }
    &.sold-out {
      .sum-quality {
        pointer-events: none;
        opacity: 0.5;
      }
      .action {
        .add {
          pointer-events: none;
          border: 1px solid #ADAEB2;
          span {
            color: #ADAEB2;
          }
        }
        .buy {
          pointer-events: none;
          background: #ADAEB2;
          border: 1px solid #ADAEB2;
        }
      }
    }
  }
}
</style>
