<template>
  <div class="kyc">
    <div class="mt-3 kyc__title">{{  $t('kyc.list')}}</div>
    <div class="kyc__table mt-4">
    <table>
      <thead>
      <tr>
        <th>{{ $t("fields.PassportDocumentType") }}</th>
        <th>{{ $t("fields.AddressDocumentType") }}</th>
        <th>{{ $t("fields.Status") }}</th>
        <th>{{ $t("fields.RejectReason") }}</th>
        <th>{{ $t("fields.Actions") }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in kycList" :key="index">
        <td>{{ item.PassportDocumentType}}</td>
        <td>{{ item.AddressDocumentType || "" }}</td>
        <td><span :class="['status', item.Status]">{{ item.Status }}</span></td>
        <td>{{ item.RejectReason || "" }}</td>
        <td><a href="javascript:void(0)" @click="moveToDetail(item)">{{$t("action.view-detail")}}</a></td>
      </tr>
      </tbody>
    </table>
    <BaseLoading v-if="isLoading" class="loading" :loading="isLoading" />
  </div>
    <BaseModal :config="uploadDocumentModalConfig">
      <template slot="content">
        <UploadDocument @submitted="getKYCStatus"/>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import {apiImageUrl} from "@/constants/config";
import {RepositoryFactory} from "@apis/repositoryFactory"
const AssetRepository = RepositoryFactory.get('asset')
const KYCRepository = RepositoryFactory.get('kyc')
import BaseLoading from "@/components/Inputs/BaseLoading";
import UploadDocument from "@/containers/Profile/KYC/UploadDocument";
import {MODAL} from "@/constants";

export default {
  components: {
    BaseLoading,
    UploadDocument
  },
  mounted () {
    this.getKYCStatus()
  },
  methods: {
    async getKYCStatus () {
      this.isLoading = true
      try {
        const data = await KYCRepository.getKYCSubmitted()
        this.$store.commit("user/setKYC", data)
        this.kycList = [...data.KYCApproved, ...data.KYCRejected, ...data.KYCReopen, ...data.KYCReview]
      } catch (e) {
        this.$alertMsg('fail', e.response.data.message)
        this.kycList = []
      }
      this.isLoading = false
    },
    moveToDetail(item) {
      this.$router.push({path: '/profile/kyc/' + item.Id, params: {...item}})
    },
    async uploadFrontId(e) {
      let uploadError = ''
      let file = null
      if (e.target && e.target.files && e.target.files[0]) {
        file = e.target.files[0]
      }
      if (file.size > (5 * 1024 * 1024)) {
        this.$alertMsg('fail', 'Kích thước ảnh không được lớn hơn 5MB!')
        return
      }
      this.frontId = await this.uploadFile(file)
    },
    async uploadBackId(e) {
      let uploadError = ''
      let file = null
      if (e.target && e.target.files && e.target.files[0]) {
        file = e.target.files[0]
      }
      if (file.size > (5 * 1024 * 1024)) {
        this.$alertMsg('fail', 'Kích thước ảnh không được lớn hơn 5MB!')
        return
      }
      this.backId = await this.uploadFile(file)
    },
    async uploadFile (file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const {Photo} = await AssetRepository.upload(formData)
        return Photo
      } catch (e) {
        this.$alertMsg('fail', e.response.data.message)
        return null
      }
    },
    imagePath(source) {
      return source ? apiImageUrl + source : ''
    },
    async submit () {
      try {
        const payload = {
          IdentityCardFront: this.frontId,
          IdentityCardBack: this.backId
        }
        await KYCRepository.uploadKYC(payload)
        this.$alertMsg('success',this.$t('notify.kyc.submitted'))
      } catch(e) {
        this.$alertMsg('fail', e.response.data.message)
      }
    }
  },
  computed: {
    canSubmit() {
      return !this.status || ["REOPEN", "REJECTED"].includes(this.status)
    }
  },
  data() {
    return {
      kycList: [],
      isLoading: false,
      uploadDocumentModalConfig: {
        id: MODAL.UPLOAD_DOCUMENTS,
        class: 'modal--upload-documents',
        noCloseOnBackDrop: false,
        centered: true,
        notShowCloseButton: false,
        theme: 'light'
      },
    }
  }
}
</script>

<style scoped lang="scss">
  .kyc {
    color: #43464E;
    &__title {
      font-family: 'Overpass';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
    &__table {
      overflow-y: hidden;
      overflow-x: auto;
      min-height: 600px;
      table {
        width: 100%;
        thead {
          th {
            padding: 10px;
            background: rgba(173, 174, 178, 0.2);
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #898b91;
            vertical-align: middle;
            white-space: nowrap;
            &:first-child {
              border-radius: 4px 0 0 4px;
            }
            &:last-child {
              border-radius: 0 4px 4px 0;
            }
          }

        }
        td {
          padding: 15px 10px;
          border-bottom: 1px solid #eaeaea;
          vertical-align: middle;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #141822;
          white-space: break-spaces;
          &.in-progress {
            color: #67b231;
            text-transform: capitalize;
          }
        }
        .status {
          text-transform: capitalize;
          background: #B91E1E;
          border-radius: 11px;
          color: #FFFFFF;
          padding: 5px 20px;
          font-size: 10px;
          line-height: 10px;
            &.REJECTED {
              background: #B91E1E;
            }
            &.REVIEW {
              background: #E79330;
            }
            &.APPROVED {
              background: #518555;
            }
            &.REOPEN {
              background: #0064D0;
            }
         }
      }
      .empty-table {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;
        padding: 100px 0;
        @media only screen and (max-width: 768px) {
          padding: 50px 0;
        }
        span {
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          color: #adaeb2;
        }
      }
      .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 200px;
        width: 100%;
        flex-direction: column;
      }
    }
    .alert {
      max-width: 756px;
      padding: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      i {
        font-size: 28px;
      }
    }
    &__upload-image {
      width: 366px;
      height: 240px;
      background: #F7F7F7;
      border-radius: 8px;
      color: #007BFF;
      position: relative;
      .upload-button {
        text-align: center;
        cursor: pointer;
        width: 100%;
        height: 100%;
        i {
          font-size: 30px;
          color: #007BFF;
        }
      }
      .btn-close {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 25px;
        color: #FFFFFF;
        z-index: 2;
      }
    }
    &__upload {
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        .first {
          margin-right: 0 !important;
          margin-bottom: 24px;
        }
      }
    }
  }
</style>
