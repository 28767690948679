<template>
  <BaseModal :config="config" @show="getBonuses" @hidden="closePopup">
      <template #content>
        <div  class="share-bonus">
          <div class="share-bonus__title">
            {{$t('setup-bonus.title')}}
          </div>
          <div class="share-bonus__sub-title" v-if="!formData.CoUsers || !formData.CoUsers.length">
            {{$t('setup-bonus.sub')}}
          </div>
          <div class="share-bonus__content mt-4" v-if="!formData.CoUsers || !formData.CoUsers.length">
            <div class="d-flex justify-content-between">
              <BaseCheckbox
                v-model="formData.ShareDirectBonus"
                :inline="true"
                class="share-bonus__option"
              >
              <span class="label">{{$t('setup-bonus.direct')}}</span>
              </BaseCheckbox>
              <BaseCheckbox
                v-model="formData.ShareAp"
                :inline="true"
                class="share-bonus__option"
              >
                <span class="label">{{$t('setup-bonus.ap')}}</span>
              </BaseCheckbox>
            </div>
            <div class="row justify-content-between mt-4">
              <div class="col-md-7">
                <p class="label">{{$t('setup-bonus.user')}} 1</p>
                <CoolSelect
                v-model="user1"
                :items="user1Items"
                :loading="loading1"
                :error-message="user1ValidateError"
                item-text="DisplayField"
                :placeholder="$t('setup-bonus.user-placeholder')"
                disable-filtering-by-search
                @search="handleUser1Search"
              >
                <template #no-data>
                  {{ $t('no-options')}}
                </template>
                <template #item="{ item }">
                  <div class="item">
                    <span class="item-name"> {{ item.DisplayField }} </span>
                  </div>
                </template>
                <template v-if="loading1" #input-end>
                  <img src="https://i.imgur.com/mTNe6tr.gif" class="loading-indicator">
                </template>
              </CoolSelect>
              </div>
              <div class="col-md-5">
                <BaseInputCustom
                  class-name="theme-light"
                  ref="percent1"
                  v-model="percent1"
                  type="text"
                  name="username"
                  inputmode="text"
                  :max-value="100-percent2"
                  :min-value="0"
                  required
                  :label="`% ${$t('setup-bonus.share')}`"
                />
              </div>
            </div>
            <div class="row justify-content-between mt-4">
              <div class="col-md-7">
                <p class="label">{{$t('setup-bonus.user')}} 2</p>
                <CoolSelect
                  v-model="user2"
                  :items="user2Items"
                  :loading="loading2"
                  :error-message="user2ValidateError"
                  item-text="DisplayField"
                  :placeholder="$t('setup-bonus.user-placeholder')"
                  disable-filtering-by-search
                  @search="handleUser2Search"
                >
                  <template #no-data>
                    {{ $t('no-options')}}
                  </template>
                  <template #item="{ item }">
                    <div class="item">
                      <span class="item-name"> {{ item.DisplayField }} </span>
                    </div>
                  </template>
                  <template v-if="loading2" #input-end>
                    <img src="https://i.imgur.com/mTNe6tr.gif" class="loading-indicator">
                  </template>
                </CoolSelect>
              </div>
              <div class="col-md-5">
                <BaseInputCustom
                  class-name="theme-light"
                  ref="percent2"
                  v-model="percent2"
                  type="tel"
                  name="username"
                  inputmode="text"
                  :max-value="100 - percent1"
                  :min-value="0"
                  required
                  :label="`% ${$t('setup-bonus.share')}`"
                />
              </div>
            </div>
            <div class="mt-4">
              {{$t('setup-bonus.receive')}}: {{100 - percent1 - percent2}}%
            </div>
            <div class="mt-4">
              {{$t('setup-bonus.user')}} 1: {{percent1}}%
            </div>
            <div class="mt-4">
              {{$t('setup-bonus.user')}} 2: {{percent2}}%
            </div>
            <div class="mt-3 note">
              {{$t('setup-bonus.note')}}
            </div>
            <div class="mt-4 d-flex justify-content-between">
              <button class="btn btn-outline-primary col-md-5" @click="closePopup">{{$t('action.cancel')}}</button>
              <button class="btn btn-primary col-md-6" @click="validateAndConfirm" :class="{'button--loading': loading}">{{$t('action.confirm')}}</button>
            </div>
          </div>
          <div class="share-bonus__setup mt-4" v-else>
            <div> {{$t('setup-bonus.shared', {0: bonusNames})}}</div>
            <div class="mt-3" v-for="user in formData.CoUsers" :key="user.UserId">
              {{$t('setup-bonus.user')}}: {{user.Username}}({{user.Percent}}%)
            </div>
          </div>
        </div>
      </template>
  </BaseModal>
</template>

<script>
import {MODAL} from "@/constants";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue"
import { CoolSelect } from 'vue-cool-select'
import {RepositoryFactory} from "@apis/repositoryFactory";
import globalMixin from '@/core/mixins/global';
const UserRepository = RepositoryFactory.get('user');
import isEmpty from "lodash/isEmpty"
export default {
  name: "ShareBonusModal",
  components: {
    BaseInputCustom,
    BaseCheckbox,
    CoolSelect
  },
  props: {
    id: {
      type: String,
      default: ''
    },
  },
  computed: {
    bonusNames() {
      let bonusNames = this.formData.ShareAp ? this.$t('setup-bonus.ap') + ', ' : ''
      bonusNames += this.formData.ShareDirectBonus ? this.$t('setup-bonus.direct') : ''
      return bonusNames
    },
    user1ValidateError () {
      if (isEmpty(this.user1) && this.percent1 && this.percent1 !== '0') {
        return this.$t('validate.isRequired')
      }
      return null
    },
    user2ValidateError () {
      if (isEmpty(this.user2) && this.percent2 && this.percent2 !== '0') {
        return this.$t('validate.isRequired')
      }
      return null
    }
  },
  mixins: [globalMixin],
  data() {
    return {
      config: {
        id: MODAL.SHARE_BONUS,
        class: 'modal--share-bonus',
        noCloseOnBackDrop: false,
        centered: true,
        theme: 'light'
      },
      formData: {
        CoUsers: [],
        ShareAp: true,
        ShareDirectBonus: true,
      },
      loading: false,
      user1Items: [],
      user2Items: [],
      user1: null,
      user2: null,
      percent1: '0',
      percent2: '0',
      loading1: false,
      loading2: false
    }
  },
  methods: {
    closePopup() {
      this.$bvModal.hide(MODAL.SHARE_BONUS)
      this.user1Items =  []
      this.user2Items = []
      this.user1 = null
      this.user2 = null
      this.percent1 = '0'
      this.percent2 = '0'
      this.loading1 = false
      this.loading2 = false
    },
    async searchUser(key) {
      try {
        return await UserRepository.searchUser(key)
      } catch (e) {
        return []
      }
    },
    async handleUser1Search(key) {
      this.loading1 = true
      this.user1Items = await this.searchUser(key)
      this.loading1 = false
    },
    async handleUser2Search(key) {
      this.loading2 = true
      this.user2Items = await this.searchUser(key)
      this.loading2 = false
    },
    async validateAndConfirm() {
      if (this.user1ValidateError || this.user2ValidateError) {
        return
      }
      this.showConfirmPopup()
    },
    async submit () {
      this.loading = true
      let payload = {
        ChildUserId: this.id,
        ShareAp: this.formData.ShareAp,
        ShareDirectBonus: this.formData.ShareDirectBonus,
        CoUsers: [
          {
            Percent: (100 - this.percent1 - this.percent2),
            UserId: this.currentUser.Id
          }
        ]
      }
      if (!isEmpty(this.user1)) {
        payload.CoUsers.push(
          {
            Percent: this.percent1,
            UserId: this.user1.ValueField
          }
        )
      }
      if (!isEmpty(this.user2)) {
        payload.CoUsers.push(
          {
            Percent: this.percent2,
            UserId: this.user2.ValueField
          }
        )
      }
      try {
        await UserRepository.shareBonuses(payload)
        this.$alertMsg('success', this.$t('notify.setup-success'))
        this.closePopup()
      } catch (e) {
        this.$alertMsg('fail', e.response.data.message)
      }
      this.loading = false
    },
    async getBonuses () {
      try {
        this.formData = await UserRepository.getBonuses(this.id)
        if (!this.formData.CoUsers.length) {
          this.formData.ShareDirectBonus = true
          this.formData.ShareAp = true
        }
      } catch (e) {
        this.$alertMsg('fail', e.response.data.message)
      }
    },
    showConfirmPopup() {
      let sharedBonusText = ''
      if (this.formData.ShareAp && this.formData.ShareDirectBonus) {
        sharedBonusText = this.$t("setup-bonus.all-bonus")
      } else {
        if (this.formData.ShareAp) {
          sharedBonusText = this.$t("setup-bonus.aff-bonus")
        }
        if (this.formData.ShareDirectBonus) {
          sharedBonusText = this.$t("setup-bonus.direct-bonus")
        }
      }
      const html = `
        <div>${this.$t("setup-bonus.confirm-text", {0: sharedBonusText})}</div>
        <div style="background: #F7F7F7; border-radius: 8px; padding: 12px; margin-top: 24px;">
          <div style="min-width: 450px; text-align: left">
             <span>${this.$t("setup-bonus.user")} 1: </span>
            <span><b>${this.user1 ? this.user1.DisplayField + '(' + this.percent1 + '%)' : ''}</b></span>
          </div>
          <div style="min-width: 450px; text-align: left">
            <span>${this.$t("setup-bonus.user")} 2: </span>
            <span><b>${this.user2 ? this.user2.DisplayField + '(' + this.percent2 + '%)' : ''}</b></span>
          </div>
        <div>`

      this.$notifyPopup('confirm', this.$t('setup-bonus.title'),
          null,
        {
          action1Text: this.$t("action.confirm"),
          action1Func: this.submit,
          action2Text: this.$t("action.cancel"),
          action2Func: () => {}
        },
        this.$device.isMobile ? 'share-bonus-mobile' : 'share-bonus',
        html
      )
    }
  }
}
</script>

<style scoped lang="scss">
.share-bonus {
  font-family: 'Overpass';
  font-style: normal;
  margin-top: 16px;

  &__title {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #141822;
  }
  &__sub-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #43464E;
  }
  &__content {
    color: #43464E;
    font-size: 20px;
    line-height: 28px;
    .note {
      font-size: 14px;
      line-height: 20px;
      color: #898B91;
    }
    .label {
      color: #43464E;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 8px;
    }
    .select {
      margin-bottom: 20px;
    }
    .item {
      display: flex;
      align-items: center;
    }
    .IZ-select__input {
      border-radius: 8px !important;
    }
    /deep/.IZ-select__menu-items > .IZ-select__no-data {
      font-size: 1rem !important;;
    }
    /deep/.loading-indicator {
      width: 20px;
      margin-right: 10px !important;
    }
  }
  &__option {
    &.form-check {
      padding-left: 0;
      &::v-deep {
        .form-check-label {
          .form-check-sign::before {
            color: #F7F7F7;
            font-size: 24px;
            background:   #F7F7F7;
            height        : 24px;
            width         : 24px;
            border-radius: 4px;
            margin-left: -35px;
            margin-top: -18px;
          }
          .form-check-sign::after {
            content         : '\2713';
            color: #007BFF;
            height        : 24px;
            width         : 24px;
            font-size: 24px;
            margin-left: -35px;
            margin-top: -18px;
          }
        }
      }

    }
    .label {
      color: #43464E;
    }
  }
}
</style>
