<template>
  <div>
    <OurPartner />
  </div>
</template>

<script>
import OurPartner from '@containers/OurPartners/index.vue'
export default {
  components: {
    OurPartner
  }
}
</script>

<style>

</style>
