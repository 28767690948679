<template>
  <div class="event-detail">
    <div class="event-detail__container">
      <div class="cta-back">
        <a @click="$router.push('/shop?tab=PreOrder')">
          <img src="/img/wallet/icon-back.svg" alt="" />
          <span>Back to Pre-order</span>
        </a>
      </div>
      <img class="mw-100 banner-pre" src="/img/pre-order/banner-event-en.webp" alt="banner" />
      <img class="mw-100 mt-4 banner-pre" src="/img/pre-order/banner-2-en.jpg" alt="banner" />
      <b-colxx xxs="12" class="px-0">
        <ol class="main-list mt-5">
          <li class="main-list_li">
            <span> Event time: </span>
            <div>
              <ul>
                <li>Now to 12/31/2022</li>
              </ul>
            </div>
          </li>
          <li class="main-list_li">
            <span> Eligibility: </span>
            <div>
              <ul>
                <li>
                  This event is open to all VLINKMETA distributors globally.
                </li>
              </ul>
            </div>
          </li>
          <li class="main-list_li">
            <span> Prize: </span>
            <table class="table table-product table-responsive d-block d-lg-table">
              <thead>
                <td></td>
                <td>Prizes</td>
                <td>Quantity</td>
                <td>Listed price</td>
                <td>Vlink's special price (sale 20%)</td>
                <td>Up-front deposit (VLG)</td>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="item.name">
                  <td>{{ index }}</td>
                  <td>{{ item.name }}<span class="text-bold d-inline"></span></td>
                  <td>{{ item.qty }}</td>
                  <td>{{ $t("pre-order.from") }} {{ item.price | toCurrency(true, 0) }}<span class="text-bold d-inline">*</span></td>
                  <td>{{ item.sale | toCurrency(true, 0) }}</td>
                  <td>{{ item.deposit }}</td>
                </tr>
              </tbody>
            </table>
            <p>
              (*) The value of the prize is the market price listed by the
              seller Offered as of September 2022. Actual value of the prize The
              bonus is subject to change according to the market price at the
              time of the event program.
            </p>
          </li>
          <li class="main-list_li">
            <span> Dial form:</span>
            <div>
              <ul>
                <li>
                  Direct dial at the event
                </li>
                <!-- <li>The event will be livestream on Zoom via an URL sent directly to your email.</li> -->
              </ul>
            </div>
          </li>
          <li class="main-list_li">
            <span>
              Conditions, methods and procedures for participating in the
              program:
            </span>
            <div>
              <ul>
                <li>Customers are users in VLINKMETA system.</li>
                <li>Items can only be paid for in VLG tokens.</li>
                <li>Each participating customer can deposit multiple items.</li>
                <li>Products cannot be changed or destroyed for any reason.</li>
                <li>Deposit will be paid 1 hour in advance for each dial.</li>
                <li>
                  The VLG that has been deposited for the items will be refunded
                  intact if the participant does not win the corresponding item,
                  the refund will take place within 24 hours from the end of the
                  drawing.
                </li>
              </ul>
            </div>
          </li>
          <li class="main-list_li">
            <span> Regulations on how to determine winnings: </span>
            <div>
              <ul>
                <li>
                  15 types of items will be rotated into 15 batches (each batch
                  will be dialed a number of times corresponding to the number
                  of items) to determine the lucky participant.
                </li>
                <li>
                  Customers who have won the previous draw will not be allowed
                  to spin next time.
                </li>
              </ul>
            </div>
          </li>
          <li class="main-list_li pb-5">
            <span> Method of awarding: </span>
            <div>
              <ul>
                <li>
                  The lottery program will be conducted directly at the event
                  and the list of lucky people will be displayed live to ensure
                  the transparency of the program.
                </li>
                <li>
                  After the dialing is over, the dialing result will be
                  published on VLINKMETA's homepage.
                </li>
                <li>
                  Within 24 hours since the list of lucky customers is published
                  on the VLINKMETA homepage, please pay the remaining amount of
                  the item in the Transaction History.
                </li>
                <li>
                  In case the payment has not been completed within 24 hours,
                  the customer will lose the deposit and the right to purchase
                  the product will be canceled.
                </li>
                <li>
                  Products cannot be exchanged for cash or any other cash asset.
                </li>
              </ul>
            </div>
          </li>
        </ol>
      </b-colxx>
    </div>
  </div>
</template>

<script>
import { preOrder } from "@/constants/packages";

export default {
  name: "EventProposal",
  data() {
    return {
      items: preOrder
    }
  }
};
</script>

<style lang="scss">
.event-detail {
  margin: 24px;

  @media only screen and (max-width: 768px) {
    margin: 0;
  }

  &__container {
    padding: 24px;

    @media only screen and (max-width: 768px) {
      padding: 12px;
    }

    background: #ffffff;
    border-radius: 8px;

    .cta-back {
      margin-bottom: 17px;

      a {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
      }

      img {
        width: 29px;
        margin-right: 16px;
      }

      span {
        font-size: 20px;
        line-height: 28px;
        color: #43464e;
      }
    }
  }

  .banner-pre {
    border-radius: 8px;
  }
}

.main-list {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #43464e;

  p {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: #43464e;
  }

  &_li {
    margin-bottom: 2rem;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #141822;
      display: block;
      margin-bottom: 8px;
    }

    ul li {
      list-style-type: disc;
    }
  }

  .table-product {
    text-align: center;
    // border-radius: 8px;
    border-collapse: separate;
    font-size: 0.9em;
    // overflow: hidden;
    border: 1px solid #132239;
    border-spacing: 0;

    thead {
      background: #132239;
      color: #ffffff;

      td {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }

    tbody {
      border: 2px solid #132239 !important;
    }

    td {
      border: 1px solid #132239 !important;
      border-color: #132239 !important;
      white-space: nowrap;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #43464e;
    }
  }

  .text-bold {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #43464e;
  }
}
</style>
