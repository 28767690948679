<template>
  <div class="change-pw">
    <div class="change-pw__title">{{$t('input.update-password')}}</div>
    <div class="change-pw__content mt-3">
      <div class="row gx-5">
        <div class="col">
          <BaseInputCustom
            class-name="login__username theme-light"
            :label="$t('update-password.current')"
            v-model="formData.Password"
            ref="current"
            type="password"
            name="current"
            required
            :max-length="50"
            :min-length="6"
            :limit-input="50"
          />
        </div>
      </div>
      <div class="row gx-5 mt-4">
        <div class="col">
          <BaseInputCustom
            class-name="login__username theme-light"
            :label="$t('update-password.new')"
            v-model="formData.NewPassword"
            :max-length="50"
            :min-length="6"
            :limit-input="50"
            ref="new"
            type="password"
            name="new"
            required
          />
        </div>
      </div>
      <div class="row gx-5 mt-4">
        <div class="col">
          <BaseInputCustom
            class-name="login__username theme-light"
            :label="$t('update-password.confirmation')"
            v-model="confirmPassword"
            ref="confirmation"
            type="password"
            name="confirmation"
            required
            :same-as="formData.NewPassword"
            :max-length="50"
            :min-length="6"
            :limit-input="50"
          />
        </div>
      </div>
    </div>
    <div class="text-right pr-0">
      <button class="btn btn-primary col-md-4 col-sm-12 align-self-center" @click="submit">{{ $t('action.update') }}</button>
    </div>
  </div>
</template>

<script>
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import validateMixin from '@/core/mixins/validate'
import {mapActions} from "vuex";
const {RepositoryFactory} = require("@apis/repositoryFactory")
const UserRepository = RepositoryFactory.get('user')
import {NOTIFICATION_ICON} from "@/constants";
export default {
  name: "Password",
  components: {
    BaseInputCustom
  },
  mixins: [validateMixin],
  data() {
    return {
      formData: {
        Password: '',
        NewPassword: ''
      },
      confirmPassword: ''
    }
  },
  methods: {
    ...mapActions('Auth', {
      signOut: 'signOut'
    }),
    signOutAndGoHome() {
      this.signOut()
      this.$router.push("/home")
    },
    async submit() {
      const refs = ['new', 'current', 'confirmation']
      const valid = this.$_validateMixin_refs(refs)
      if (!valid) {
        return
      }
      try {
        await UserRepository.updatePassword(this.formData)

        // this.$alertMsg('success',this.$t('swal.success.saved'))
        this.$notifyPopup(
          NOTIFICATION_ICON.SUCCESS,
          this.$t('swal.title.change-password'),
          this.$t('profile.change-password.login-again'),
          {
            action1Text: 'OK',
            action1Func: this.signOutAndGoHome,
            didCloseFunc: this.signOutAndGoHome,
          })
      } catch (e) {
        console.log(e)
        this.$alertMsg('fail', e.response.data.message)
      }
    }
  },
}
</script>

<style scoped lang="scss">
.change-pw {
  font-family: 'Overpass';
  font-style: normal;
  max-width: 756px;

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #43464E;
    margin-top: 24px;
  }
}
</style>
