const packages = [
  {
    name: 'KYC5',
  },
  {
    name: 'KYC10',
  },
  {
    name: 'KYC25',
  },
  {
    name: 'KYC50',
  },
  {
    name: 'KYC100',
  },
  {
    name: 'KYC500',
  },
  {
    name: 'Rookie',
    slug: 'rookie',
    detail: 'packages.rookie',
    direct: ['5%']
  },
  {
    name: 'STARTER',
    slug: 'starter',
    detail: 'packages.starter',
    direct: ['10%']
  },
  {
    name: 'SUPER STAR',
    slug: 'super-star',
    detail: 'packages.super-star',
    direct: ['12.5%']
  },
  {
    name: 'Bronze',
    slug: 'bronze',
    detail: 'packages.bronze',
    direct: ['15%']
  },
  {
    name: 'Silver',
    slug: 'silver',
    detail: 'packages.silver',
    direct: ['17.5%']
  },
  {
    name: 'Gold',
    slug: 'gold',
    detail: 'packages.gold',
    direct: ['20%']
  },
  {
    name: 'Platinum',
    slug: 'platinum',
    detail: 'packages.platinum',
    direct: ['25%']
  },
  {
    name: 'Diamond',
    slug: 'diamond',
    detail: 'packages.diamond',
    direct: ['30%']
  },
  {
    name: 'PRO BUSINESS',
    slug: 'pro-business',
    detail: 'packages.pro-business'
  },
  {
    name: 'PLATINUM BUSINESS',
    slug: 'platinum-business',
    detail: 'packages.platinum-business'
  },
  {
    name: 'BRONZE BUSINESS',
    slug: 'bronze-business',
    detail: 'packages.bronze-business'
  },

  {
    name: 'PACKAGE PLUS 500',
    slug: 'package-plus-500',
    detail: 'packages.package-plus-500'
  },
  {
    name: 'PACKAGE PLUS 200',
    slug: 'package-plus-200',
    detail: 'packages.package-plus-200'
  },
  {
    name: 'PACKAGE PLUS 100',
    slug: 'package-plus-100',
    detail: 'packages.package-plus-100'
  },
  {
    name: 'PACKAGE PLUS 50',
    slug: 'package-plus-50',
    detail: 'packages.package-plus-50'
  },
  {
    name: 'PACKAGE VLG 50K',
    slug: 'vlg50k',
    detail: 'packages.vlg50k',
    direct: ['30%']
  },
  {
    name: 'PACKAGE VLG 10K',
    slug: 'vlg10k',
    detail: 'packages.vlg10k',
    direct: ['25%']
  },
  {
    name: 'VLINK MERCHANT META',
    slug: 'vlink-merchant-meta',
    detail: 'packages.vlink-merchant-meta'
  },

  {
    name: 'VLG 1K META',
    slug: 'vlg-1k-meta',
    detail: 'packages.vlg-1k-meta',
    direct: ['15%']
  },
  {
    name: 'VUSD 1K',
    slug: 'vusd-1k',
    detail: 'packages.vusd-1k',
    direct: ['15%']
  },
  {
    name: 'VUSD 2K',
    slug: 'vusd-2k',
    detail: 'packages.vusd-2k',
    direct: ['17.5%']
  },

   {
    name: 'VUSD 5K',
    slug: 'vusd-5k',
    detail: 'packages.vusd-5k',
    direct: ['20%']
  },
   {
    name: 'VUSD 10K',
    slug: 'vusd-10k',
    detail: 'packages.vusd-10k',
    direct: ['25%']
  },
  {
    name: 'VUSD 20K',
    slug: 'vusd-20k',
    detail: 'packages.vusd-20k',
    direct: ['30%']
  },
  {
    name: 'VUSD 50K',
    slug: 'vusd-50k',
    detail: 'packages.vusd-50k',
    direct: ['30%']
  },
  {
    name: 'VUSD 100K',
    slug: 'vusd-100k',
    detail: 'packages.vusd-100k',
    direct: ['30%']
  },
  {
    name: '1L',
    slug: '1l',
    detail: 'packages.l1',
    direct: ['30%']
  },
  {
    name: '3EB',
    slug: '3eb',
    detail: 'packages.eb3',
    direct: ['30%']
  },
  {
    name: '5EB',
    slug: '5eb',
    detail: 'packages.eb5',
    direct: ['30%']
  },
  {
    name: 'VLG E-COMMERCE',
    slug: 'vlg-e-commerce',
    detail: 'packages.vlg-e-commerce'
  },
  {
    name: 'VLG GALA NIGHT',
    slug: 'vlg-gala-night',
    detail: 'packages.vlg-gala-night'
  },
  {
    name: 'VLG CONFERENCE',
    slug: 'vlg-conference',
    detail: 'packages.vlg-conference'
  },
  {
    name: 'Vlinkmeta Mall Founders',
    slug: 'vlink-merchant-meta',
    detail: 'packages.vlink-merchant-meta',
    direct: ['10%']
  },
  {
    name: 'VLINK MERCHANT META 555',
    slug: 'vlink-merchant-meta',
    detail: 'packages.vlink-merchant-meta',
    direct: ['25%']
  },
]

export const preOrder = [
  {
    "name": "Mercedes Maybach GLS 600 SUV                  ",
    "qty": 10,
    "price": 165100,
    "sale": 132080,
    "deposit": 200
  },
  {
    "name": "Tesla Model S",
    "qty": 10,
    "price": 142990,
    "sale": 114392,
    "deposit": 200
  },
  {
    "name": "Mercedes G 550 SUV ",
    "qty": 10,
    "price": 139900,
    "sale": 111992,
    "deposit": 200
  },
  {
    "name": "Tesla Model X",
    "qty": 10,
    "price": 127990,
    "sale": 102392,
    "deposit": 200
  },
  {
    "name": "BMW X7 M60i",
    "qty": 10,
    "price": 103100,
    "sale": 82480,
    "deposit": 200
  },
  {
    "name": "BMW 740i xDrive",
    "qty": 10,
    "price": 93300,
    "sale": 74640,
    "deposit": 200
  },
  {
    "name": "Tesla Model Y",
    "qty": 10,
    "price": 69490,
    "sale": 55592,
    "deposit": 200
  },
  {
    "name": "BMW X5 sDrive40i",
    "qty": 10,
    "price": 61600,
    "sale": 49280,
    "deposit": 200
  },
  {
    "name": "Vinfast VF9",
    "qty": 10,
    "price": 55500,
    "sale": 44400,
    "deposit": 200
  },
  {
    "name": "Tesla Model 3",
    "qty": 10,
    "price": 48490,
    "sale": 38792,
    "deposit": 200
  },
  {
    "name": "Vinfast VF8",
    "qty": 10,
    "price": 40700,
    "sale": 32560,
    "deposit": 200
  },
  {
    "name": "Macbook Pro",
    "qty": 10,
    "price": 1299,
    "sale": 1040,
    "deposit": 50
  },
  {
    "name": "iPhone 14 256GB",
    "qty": 10,
    "price": 899,
    "sale": 719,
    "deposit": 50
  },
  {
    "name": "iPhone 13 256GB",
    "qty": 10,
    "price": 799,
    "sale": 639,
    "deposit": 50
  },
  {
    "name": "iPad Air",
    "qty": 25,
    "price": 749,
    "sale": 599,
    "deposit": 40
  }
]

export default packages

export const dreamUsaPackageName = ['3EB', '5EB', '1L']
