<template>
  <div class="accounts">
    <div class="accounts__title">{{  $t('menu-items.profiles.add-account')}}</div>
    <div class="accounts__search">
      <BaseInputCustom v-model="searchText"
                       :placeholder="$t('account.search-placeholder')"
                       class-name="theme-light"
                       searchable
                       @search="filterMembers(searchText)"
      >
      </BaseInputCustom>
    </div>
    <BaseLoading v-show="loading" class="loading mt-5" :loading="loading" />
    <div class="accounts__list mt-3" v-show="!loading" v-if="affMembers && affMembers.length">
        <div v-for="mem in affMembers" :key="mem.Id" class="member">
          <div class="d-flex justify-content-center">
            <img :src="getPackageLogoPath(mem.PackageLogo)"
                 alt="package-logo"
                 class="member__package-logo"
            />
          </div>
          <div class="d-flex justify-content-between">
            <span class="member__info-label">{{$t('user.fullname')}}:</span>
            <span class="member__info-content">{{`${mem.FirstName} ${mem.LastName}`}}</span>
          </div>
          <div class="d-flex justify-content-between mt-2">
            <span class="member__info-label">{{$t('user.username')}}:</span>
            <span class="member__info-content">{{mem.Username}}</span>
          </div>
          <div class="d-flex justify-content-between mt-2">
            <span class="member__info-label">{{$t('user.email')}}:</span>
            <span class="member__info-content">{{mem.Email}}</span>
          </div>
          <button class="btn btn-outline-primary w-100 mt-3" @click="openShareBonusModal(mem.Id)">{{$t('setup-bonus.btn')}}</button>
        </div>
    </div>
    <div class="no-member mt-3" v-else>
      {{ $t('account.no-accounts')}}
    </div>
    <ShareBonusModal :id="childId" />
    <ModalCreateAccount @created="fetchAccountList"/>
  </div>
</template>

<script>
import BaseInputCustom from "@/components/Inputs/BaseInputCustom.vue"
import Card from "@/components/Cards/Card.vue"
import ModalCreateAccount from "@/containers/NetworkTree/modal-create-account.vue"
import { RepositoryFactory } from '@/apis/repositoryFactory'
const UserRepository = RepositoryFactory.get('user')
import BaseLoading from "@components/Inputs/BaseLoading";
import {apiImageUrl} from "@/constants/config";
import ShareBonusModal from "@containers/Profile/Accounts/ShareBonusModal";
import {MODAL} from "@/constants";
export default {
  name: "Account",
  components: {
    ShareBonusModal,
    BaseInputCustom,
    Card,
    BaseLoading,
    ModalCreateAccount
  },
  data() {
    return {
      affMembers: [],
      searchText: '',
      loading: false,
      childId: ''
    }
  },
   async mounted() {
     this.affMembers = await this.getAffMembers()
  },
  methods: {
    async fetchAccountList () {
      this.searchText = ""
      this.affMembers = await this.getAffMembers()
    },
    async filterMembers (query) {
      this.affMembers = await this.getAffMembers(query)
    },
    getPackageLogoPath(packageLogo) {
      if (!packageLogo) {
        return '/assets/img/icon/user-default.png'
      }
      return apiImageUrl + packageLogo
    },
    async getAffMembers(query='') {
      try {
        this.loading = true
        const data = await UserRepository.getChildAccounts(query)
        return data
      } catch (e) {
        this.$alertMsg('fail', e.response.data.message)
        return []
      } finally {
        this.loading = false
      }
    },
    openShareBonusModal(id) {
      this.childId = id
      this.$bvModal.show(MODAL.SHARE_BONUS)
    }
  },
}
</script>

<style scoped lang="scss">
  .accounts {
    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #43464E;
      margin-top: 24px;
      margin-bottom: 16px;
    }
    &__search {
      width: 488px;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
    }
    .member {
      background: #F7F7F7;
      border-radius: 8px;
      max-width: 288px;
      padding: 16px;
      position: relative;
      margin-right: 16px;
      margin-top: 16px;
      width: 260px;

      &__status {
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 8px 0px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        text-transform: capitalize;
        padding: 2px 15px;
        &.WAITING {
          background: #ffc107;
        }
        &.ACTIVE {
          background: #67B231;
        }
        &.LOCK {
          background: #c43d4b;
        }
      }
      &__info-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #43464E;
        min-width: 120px;
      }
      &__package-logo {
        padding: 0 36px 26px 36px;
        min-height: 180px;
        height: 180px;
        margin-top: 12px;
      }
      &__info-content {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: #141822;
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>
