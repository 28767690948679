import Swal from 'sweetalert2'
import countries from '@/constants/countriesArray.json'
import { RepositoryFactory } from '@apis/repositoryFactory'
const UserRepository = RepositoryFactory.post('user')
import {NOTIFICATION_ICON} from "@/constants";

export default {
  methods: {
    async requireUpdate(userId) {
      const message = this.$t('validate.seleted', [
        this.$t('users.infor.country'),
      ])
      await Swal.fire({
        imageUrl: '/img/icons/ico-success.png',
        imageHeight: 80,
        imageWidth: 80,
        title: this.$t('swal.title.success'),
        text: this.$t('success'),
        input: 'select',
        inputOptions: countries,
        inputPlaceholder: this.$t('select-country'),
        showCancelButton: false,
        allowOutsideClick: false,
        customClass: {
          container: 'dashboard',
        },
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value) {
              this.updateCountry(userId, value)
            } else {
              resolve(message)
            }
          })
        },
      })
    },
    async updateCountry(userId, value) {
      try {
        await UserRepository.updateCountry(userId, {
					Country: value
				})
        this.$notifyPopup(
          NOTIFICATION_ICON.SUCCESS,
					'',
          this.$t('swal.title.success'),
          {
            action1Text: 'OK',
            action1Func: () => ({}),
          },
          'dashboard',
        )
      } catch (error) {
        this.$notifyPopup(
          NOTIFICATION_ICON.ERROR,
          this.$t('swal.title.ops'),
          error.response.data.message,
          {
            action1Text: 'OK',
            action1Func: 'OK',
          },
          'dashboard',
        )
      }
    },
  },
}
