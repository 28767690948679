<template>
  <BaseModal :config="config">
    <template slot="content">
      <ModalContent />
    </template>
  </BaseModal>
</template>

<script>
import { RepositoryFactory } from "@/apis/repositoryFactory";
const WalletRepository = RepositoryFactory.get("wallet");
import { MODAL } from "@/constants";
import ModalContent from "./ModalContent/certificate.vue";

export default {
  name: "ModalCertificate",
  components: {
    ModalContent,
  },
  mixins: [],
  data() {
    return {
      config: {
        id: MODAL.CERTIFICATE,
        class: "modal--certificate",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light",
      },
    };
  },
};
</script>

<style scoped lang="scss">
</style>
