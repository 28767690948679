<template>
  <div class="col-12 tree">
    <template>
      <search @updatetree="updatetree" />
      <div class="position-relative">
        <div class="d-flex flex-wrap tree__action">
          <b-button
            variant="outline-primary"
            class="mb-2 default ml-2"
            @click="controlScale('bigger')"
            >+</b-button
          >
          <b-button
            variant="outline-primary"
            class="mb-2 default ml-2"
            @click="controlScale('smaller')"
            >-</b-button
          >
          <b-button
            variant="outline-primary"
            class="mb-2 default ml-2"
            @click="controlScale('restore')"
            >1:1</b-button
          >
          <b-button
            :disabled="loadingData || disabledRoot"
            variant="outline-primary"
            class="mb-2 default ml-2"
            @click="changeRoot('')"
            >Root</b-button
          >
          <b-button
            :disabled="loadingData || !data.ParentUserId"
            variant="outline-primary"
            class="mb-2 default ml-2"
            @click="changeRoot(data.ParentUserId)"
            >Up</b-button
          >
        </div>
        <vue-tree
          ref="scaleTree"
          style="width: 100%; height: 600px; border: 1px solid gray"
          :dataset="data"
          :config="treeConfig"
          :isShowInfo="isShow"
        >
          <template v-slot:node="{ node, collapsed }">
            <div
              class="rich-media-node"
              :class="{ active: isShow == node.Username }"
              :style="{ border: collapsed ? '2px solid grey' : '' }"
              v-show="node.Username"
            >
              <div class="tree-avatar-wraper">
                <div class="container-network-tree-avatar">
                  <div
                    class="
                      d-flex
                      justify-content-between
                      w-100
                      font-weight-bold
                      position-absolute
                      px-3
                    "
                  >
                    <span class="Fnumber"> F{{ node.Layer }}</span>
                    <b-badge
                      v-if="node.Layer"
                      @click="changeRoot(node.UserId)"
                      class="font-size-normal"
                      variant="light"
                    >
                      <img src="/img/network-tree/icon-tree.svg" alt="" />
                    </b-badge>
                  </div>
                  <img
                    v-if="node.PackageImage"
                    class="
                      vk-image-responsive
                      tree-avatar-default tree-avatar
                      logo
                    "
                    :src="apiImageUrl + node.PackageImage"
                  />
                  <img
                    v-else
                    class="
                      vk-image-responsive
                      tree-avatar-default tree-avatar
                      logo
                    "
                    src="/img/default-avatar.png"
                  />
                  <div class="tree-avatar-container-name-nickname">
                    <p class="tree-avatar-name">
                      {{ node.FullName || node.Username }}
                    </p>
                    <p class="tree-avatar-name">
                      ({{ $t("Downlines") }}: {{ node.ChildrenCount }})
                    </p>
                  </div>
                  <div class="package-tooltip-container position-relative">
                    <p class="tree-avatar-package">{{ node.PackageName }}</p>
                    <div
                      class="info-tooltip"
                      @click="toggleTooltip(node.Username)"
                    >
                      i
                    </div>
                    <div class="tree-avatar-pop">
                      <div
                        class="tree-avatar-pop-body"
                        v-show="isShow == node.Username"
                      >
                        <div class="tree-avatar-pop-body-left">
                          <p
                            class="tree-avatar-nickname font-weight-bold mb-1"
                            v-if="node.Title"
                          >
                            <img
                              :src="getUrlbyTitle(node.Title)"
                              class="tree-avatar-title-icon"
                            />
                            {{ $t("users.infor.title") }}:
                            <span class=""> {{ node.Title }} </span>
                          </p>
                          <p class="tree-avatar-nickname font-weight-bold mb-1">
                            {{ $t("users.infor.username") }}:
                            {{ node.Username }}
                          </p>
                          <a
                            class="tree-avatar-pop-sponsor-name"
                            target="_blank"
                            :href="getLinkAff(node.VLinkId)"
                          >
                            {{ getLinkAff(node.VLinkId) }}
                          </a>
                          <a href="javascript:void(0)">
                            <img
                              src="/img/network-tree/icon-copy.svg"
                              alt=""
                              @click="onClickCopy(getLinkAff(node.VLinkId))"
                          /></a>
                          <div class="tree-avatar-pop-country-wrap"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </vue-tree>
        <div class="tree-loading" v-if="loadingData">
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </div>
        <b-button
          v-b-tooltip.hover
          title="Previous"
          class="position-absolute btn-arrow__tree btn-arrow__tree-prev default"
          variant="light"
          :disabled="data.Page == 1"
          @click="gotoPage(data.Page - 1)"
        >
          <i class="fa fa-arrow-left"></i>
        </b-button>
        <b-button
          v-b-tooltip.hover
          title="Next"
          class="position-absolute btn-arrow__tree btn-arrow__tree-next default"
          variant="light"
          :disabled="!data.HasNextPage"
          @click="gotoPage(data.Page + 1)"
        >
          <i class="fa fa-arrow-right"></i>
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { homeUrl } from "@/constants/config";
import helpers from "@/core/mixins/helpers";
import { apiImageUrl } from "@/constants/config";
import { TITLE } from "@/constants";
import Search from "./search.vue";

export default {
  props: {
    loadingData: {
      type: Boolean,
      default: false,
    },
    data: {
      type: [Array, Object],
    },
  },
  data: () => ({
    isShow: null,
    apiImageUrl,
    treeConfig: {
      nodeWidth: 230,
      nodeHeight: 230,
      levelHeight: 300,
    },
  }),
  components: {
    QrcodeVue,
    Search,
  },
  mixins: [helpers],
  computed: {
    disabledRoot() {
      return this.data.UserId == this.$store.state.Auth.currentUser.Id;
    },
  },
  methods: {
    getUrlbyTitle(data) {
      const title = data.toLowerCase();
      const icon = TITLE.findIndex(
        (x) => x.title == title || x.title.replace(/\s+/g, "") == title
      );
      return icon < 0 ? "" : TITLE[icon].icon;
    },
    getLinkAff(vlinkId) {
      return homeUrl + "/user/register?vlinkId=" + vlinkId;
    },
    toggleTooltip(username) {
      this.isShow = this.isShow == username ? null : username;
    },
    gotoPage(page) {
      this.$emit(
        "updatetree",
        this.data.UserId,
        this.data.HasRanking,
        this.data.HasRedeem,
        page
      );
    },
    changeRoot(userId) {
      this.$emit(
        "updatetree",
        userId,
        this.data.HasRanking,
        this.data.HasRedeem
      );
    },
    updatetree(q, hasRanking, hasRedeem) {
      this.$emit("updatetree", q, hasRanking, hasRedeem);
    },
    controlScale(command) {
      switch (command) {
        case "bigger":
          this.$refs.scaleTree.zoomIn();
          break;
        case "smaller":
          this.$refs.scaleTree.zoomOut();
          break;
        case "restore":
          this.$refs.scaleTree.restoreScale();
          break;
      }
    },
    onClickCopy(link) {
      navigator.clipboard.writeText(link);
      this.$alertMsg("success", this.$t("action.copied-text"));
    },
  },
};
</script>
<style lang="scss">
.tree {
  margin-top: 24px;
  @media screen and (max-width: 991px) {
    padding: 0;
  }
  &__action {
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 2;
  }
}
.logo {
  border-color: rgb(110, 189, 228);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.btn-arrow__tree {
  top: 40%;
  bottom: 40%;
  // opacity: 0.7;
  z-index: 2;
  &-prev {
    left: 2px;
  }
  &-next {
    right: 2px;
  }
}
.tree-loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background: #0000001f;
}
.Fnumber {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #c99f4e;
}
</style>
