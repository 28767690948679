<template>
  <Profile />
</template>

<script>
import Profile from "@/containers/Profile/index.vue"
export default {
  components: {
    Profile
  }
}
</script>

<style>

</style>
