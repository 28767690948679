<template>
  <div class="our-partner">
    <div class="our-partner__header">
      <p class="title text-white">{{ $t('menu-items.our-partners') }}</p>
    </div>
    <div class="our-partner__content">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <div class="search">
            <div class="form-outline">
              <input
                v-model="keywords"
                type="search"
                id="form1"
                class="form-control"
                :placeholder="$t('partners.search-placeholder')"
                aria-label="Search"
                @keyup="debounceInput"
              />
            </div>
          </div>
          <div class="map-center">
            <Map />
          </div>
          <div class="row list" v-if="data && data.length && !isLoading" >
            <div
              v-for="(item, index) in data"
              class="item col-sm-12 col-md-6 col-lg-6"
              :key="index"
              @click="$router.push(`/details-partner?id=${item.Id}`)"
            >
              <div class="img-shop">
                <img :src="$apiImageUrl + item.BusinessLogo" :alt="item.BusinessName"
                     onerror="this.onerror=null;this.src='/assets/Default.jpg';"
                     class="image">
              </div>

              <p class="name">{{ item.BusinessName }}</p>
              <p class="description">{{ item.Address }}</p>
            </div>
          </div>
          <BaseLoading v-if="isLoading" class="loading" :loading="isLoading" />
          <div v-if="!data.length && !isLoading" class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <p>{{ $t('partners.no_partners_found')}}</p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 map-bottom">
          <Map />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLoading from "@components/Inputs/BaseLoading";
import {RepositoryFactory} from "@apis/repositoryFactory";
import Map from 'src/pages/Maps.vue'
import debounce from 'lodash.debounce'
const UserRepository = RepositoryFactory.get('user');
const SearchRepository = RepositoryFactory.post('user');
export default {
  components: {
    Map,
    BaseLoading
  },
  data () {
    return {
      data: [],
      searchData: [],
      keywords: '',
      isLoading: true
    }
  },
  watch: {
    keywords (value) {
      if (value === '') {
        this.searchOurPartners()
      }
    }
  },
  async mounted () {
    try {
      this.data = await UserRepository.getOurPartnersShowOnMap()
      this.addressItem = await UserRepository.GetGoogleMapLocationByAddress('9793 Westheimer Road')
      if (this.data && this.data.length) {
        this.isLoading = false
      }
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    async searchOurPartners() {
      try {
        this.isLoading = true
        this.data = await SearchRepository.searchOurPartners(this.keywords)
        if (this.data) {
          this.isLoading = false
        }
      } catch (error) {
        console.log(error)
      }
    },
    debounceInput: debounce(function () {
      this.searchOurPartners()
    }, 500),
  }
}
</script>

<style scoped lang="scss">
.our-partner {
  padding: 16px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 26px;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      color: #141822;
      margin-bottom: 0;
    }
  }
  &__content {
    padding: 24px;
    background: #FFFFFF;
    border-radius: 8px;
    .search {
      margin-bottom: 24px;
    }
    .map-center {
      display: none;
      @media only screen and (max-width: 1200px) {
        display: block;
        margin-bottom: 16px;
        /deep/#map {
          min-height: calc(100vh - 400px);
        }
      }
    }
    .list {
      height: 80vh;
      overflow-x: auto;
      @media only screen and (max-width: 1200px) {
        height: auto;
      }
    }
    .item {
      margin-bottom: 24px;
      .img-shop {
        position: relative;
        padding-top: 58%;
        margin-bottom: 12px;
        border-radius: 8px;
        background: #f7f7f7;
        border: 1px solid #dbdbdb;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          object-fit: contain;
        }
      }
      .name {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #141822;
        margin-bottom: 0;
      }
      .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #141822;
        margin-bottom: 0;
      }
      &:hover {
        cursor: pointer;
      }
      @media only screen and (max-width: 768px) {
        border-bottom: 1px solid #EAEAEA;
      }
    }
    .map-bottom {
      @media only screen and (max-width: 1200px) {
        display: none;
      }
    }
  }
}
#map {
  min-height: 80vh;
}
</style>
