<template>
  <div class="dashboard mh-100vh">
    <div class="dashboard__wrapper">
      <title-marquee class="text-white my-4" />
      <div class="dashboard__header">
        <p class="text-gradient mb-3">{{ $t("menu-items.dashboards") }}</p>
        <!-- <span class="text-white">{{ $t("total-value") }} (USD)</span>
        <span class="text-gradient d-block h3" v-if="dashBoard.WalletTotal">
          {{ dashBoard.WalletTotal | toCurrency(true) }}
        </span> -->
      </div>
      <div class="line-gradient mt-3 border-radius-none"></div>
      <div class="dashboard__content">
        <div class="text-center my-2" v-if="isLoading">
          <BaseLoading v-if="isLoading" class="loading" :loading="isLoading" />
        </div>
        <div v-else class="overview">
          <div class="row">

            <div class="col-sm-12 col-md-6 col-xl-4" v-for="item in items" :key="item.icon">
              <div class="item hover-shine" @click="goTo(item.link)">
                <div class="right">
                  <img :src="item.icon" alt="blue" class="img-chart" />
                </div>
                <div class="left d-flex flex-column w-100 pl-3 justify-content-center h5">
                  <span class="font-weight-bold">{{ $t(item.label) }}</span>
                  <template v-if="item.ref == 'share'">
                    <span> {{ $t('dashboards.price') }}:
                      <span class="h5">
                        {{ dashBoard.VLGSharePrice | toCurrency(true) }}</span>
                    </span>
                    <span> {{ $t('dashboards.nPrice') }}:
                      <span class="h5">
                        {{ dashBoard.VLGShareNextPrice | toCurrency(true) }}
                      </span>
                      {{ dashBoard.DateActiveNextShare | formatDate }}
                    </span>
                  </template>
                  <template v-if="item.ref == 'token'">
                    <span> {{ $t('dashboards.price') }}:
                      <span class="h5">
                        {{ dashBoard.VLGTokenBlockPrice | toCurrency(true) }}</span>
                    </span>
                  </template>
                  <template v-if="item.ref == 'netwoking'">
                    <span> {{ $t('dashboards.bonusEarn') }}:
                      <span class="h5">
                        {{ dashBoard.BonusThisWeek | toCurrency(true) }}</span>
                    </span>
                    <span> {{ $t('dashboards.totalBonus') }}:
                      <span class="h5">
                        {{ dashBoard.TotalBonus | toCurrency(true) }}</span>
                    </span>
                  </template>
                  <template v-if="item.ref == 'vmm'">
                    <span> {{ $t('dashboards.price') }}:
                      <span class="h5">
                        {{ dashBoard.VmmTokenPrice | toCurrency(true, 6) }}</span>
                    </span>
                    <span> {{ $t('dashboards.total') }}:
                      <span class="h5">
                        {{ dashBoard.TotalVmm | toCurrency }}</span>
                    </span>
                    <span> {{ $t('dashboards.value') }}:
                      <span class="h5">
                        {{ dashBoard.TotalVmmPrice | toCurrency(true) }}</span>
                    </span>
                  </template>
                  <template v-if="item.ref == 'vlgGold'">
                    <span> {{ $t('dashboards.total') }}:
                      <span class="h5">
                        {{ dashBoard.TotalGold | toCurrency }}</span>
                    </span>
                    <span> {{ $t('dashboards.value') }}:
                      <span class="h5">
                        {{ dashBoard.ValueGold | toCurrency(true) }}</span>
                    </span>
                  </template>
                  <template v-if="item.ref == 'vlg'">
                    <span> {{ $t('dashboards.total') }}:
                      <span class="h5">
                        {{ dashBoard.VlgTotalToken | toCurrency }}</span>
                    </span>
                    <span> {{ $t('dashboards.value') }}:
                      <span class="h5">
                        {{ dashBoard.VlgTotalTokenValue | toCurrency(true) }}</span>
                    </span>
                  </template>
                  <template v-if="item.ref == 'vmf'">
                    <span> {{ $t('dashboards.price') }}:
                      <span class="h5">
                        {{ dashBoard.VmfPackagePrice | toCurrency(true) }}</span>
                    </span>
                    <span> {{ $t('dashboards.sold') }}:
                      <span class="h5">
                        {{ dashBoard.VmfPackageSold | toCurrency(false, 0) }}%</span>
                    </span>
                    <span> {{ $t('dashboards.nPrice') }}:
                      <span class="h5">
                        {{ dashBoard.VmfPackageNextPrice | toCurrency(true) }}</span>
                    </span>
                  </template>
                  <template v-if="dashBoard[item.field]">
                    <span class="h5">
                      {{ dashBoard[item.field] | toCurrency(item.toCurrency) }}
                    </span>
                  </template>
                </div>
              </div>
            </div>

          </div>
        </div>
        <business :isDashboard="true" />
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from "@/core/mixins/global";
import { RepositoryFactory } from "@/apis/repositoryFactory";
import moment from "moment";
const DashboardRepository = RepositoryFactory.get("overview");
const AssetRepository = RepositoryFactory.get("asset");
import { IMAGE_TYPE } from "@/constants";
import "swiper/css/swiper.css";
import BaseLoading from "@components/Inputs/BaseLoading";
import Business from '../Home/business.vue';
import TitleMarquee from '@components/title-marquee.vue';
const CommissionRepository = RepositoryFactory.get("commission");

export default {
  components: {
    BaseLoading,
    Business,
    TitleMarquee
  },
  data() {
    return {
      dashBoard: [],
      images: null,
      photoIndex: null,
      swiperOptions: {
        spaceBetween: 24,
        slidesPerView: 3.5,
        loop: true,
        lazy: false,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
      },
      banners: [],
      items: [
        {
          label: 'VUSD',
          field: 'CashAmount',
          ref: 'cash',
          link: '/wallet/detail?code=VLGCash',
          toCurrency: true,
          icon: '/assets/img/icons/cash.png'
        },
        {
          label: this.$t('dashboards.items.vlg'),
          field: 'VLGToken',
          ref: 'token',
          link: '/wallet/detail?code=VLGTokenAvailable',
          icon: '/assets/img/icons/vlg.png?v=0.0.1'
        },
        {
          label: this.$t('dashboards.items.vlg'),
          field: '',
          ref: 'vlg',
          toCurrency: true,
          link: '/wallet/detail?code=VLGTokenAvailable',
          icon: '/assets/img/icons/vlg-token.png?v=0.0.1'
        },
        {
          label: this.$t('dashboards.items.tree'),
          field: '',
          ref: 'tree',
          link: '/network-tree',
          icon: '/assets/img/icons/tree.png'
        },
        {
          label: this.$t('dashboards.items.vmf'),
          field: '',
          ref: 'vmf',
          link: '/package-detail?PackageId=ddd89898-4632-11ed-ad9b-4e6b9e3ff844',
          icon: '/assets/img/icons/vmf.png?v=0.0.1'
        },
        {
          label: this.$t('dashboards.items.vmm'),
          field: '',
          ref: 'vmm',
          link: '/wallet/detail?code=VmmToken',
          icon: '/assets/img/icons/vmm.png?v=0.0.2'
        },
        {
          label: this.$t('dashboards.items.netwoking'),
          field: '',
          ref: 'netwoking',
          link: '/network',
          icon: '/assets/img/icons/netwoking.png'
        },
        {
          label: this.$t('dashboards.shopping'),
          link: '/shop',
          ref: 'shopping',
          icon: '/assets/img/icons/shopping.png'
        },
        {
          label: this.$t('dashboards.items.redeem'),
          link: '/shop',
          ref: 'redeem',
          icon: '/assets/img/icons/redeem.png'
        },
        {
          label: this.$t('dashboards.items.vlgGold'),
          field: '',
          ref: 'vlgGold',
          link: '/wallet/detail?code=VLGGold',
          icon: '/assets/img/icons/vlgGold.png?v=0.0.1'
        },
        {
          label: this.$t('dashboards.items.share'),
          field: '',
          ref: 'share',
          link: '/wallet/detail?code=VLGShare',
          icon: '/assets/img/icons/share.png?v=0.0.2'
        },
        {
          label: this.$t('dashboards.contribution'),
          field: 'TotalContribution',
          ref: 'contribute',
          toCurrency: true,
          link: '/shop?tab=MyPackage',
          icon: '/assets/img/icons/contribute.png?v=0.0.1'
        },
      ]
    };
  },
  mixins: [globalMixin],
  async mounted() {
    await this.getDashBoard()
  },
  async created() {
    try {
      this.banners = await AssetRepository.getBanners(IMAGE_TYPE.EVENT);
    } catch (error) { }
  },
  computed: {
    bannerIsUsing() {
      const lang = this.$i18n.locale.toUpperCase();
      return this.banners.filter((i) => i.Language.toUpperCase() === lang);
    },
    columnRender() {
      switch (true) {
        case this.bannerIsUsing.length == 1:
          return 12;
        case this.bannerIsUsing.length == 2:
          return 6;
        case this.bannerIsUsing.length == 3:
          return 4;
        case this.bannerIsUsing.length == 4:
          return 3;
        default:
          return 3;
      }
    },
  },
  methods: {
    goTo(link) {
      if (!link) return
      this.$router.push(link)
    },
    formatTimeDashboard(value) {
      const locale = this.$i18n.locale.toLocaleLowerCase();
      return locale === 'vi' ? value : moment(value).format('MMMM Do, YYYY');
    },
    onThumbClick(item) {
      this.$bvModal.show('modal1')
      this.images = item;
    },
    handleHide() {
      this.images = null;
      this.photoIndex = null;
    },
    async getDashBoard() {
      try {
        this.$_loading();
        this.dashBoard = await DashboardRepository.dashboard();
      } catch (error) {
        console.log(error);
      } finally {
        this.$_load();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard {
  padding: 16px;

  &__wrapper {
    max-width: 1360px;
    margin: 0 auto;
  }

  // background: #f5f7fa;
  &__header {
    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      color: #141822;
      margin-bottom: 0;
      text-align: left;
    }
  }

  &__content {
    margin-top: 24px;

    .overview {
      .item {
        // min-height: 150px;
        color: #ffffff;
        border-radius: 8px;
        // padding: 24px;
        position: relative;
        margin-bottom: 24px;
        border: 1px solid #F4CC59;
        cursor: pointer;
        display: grid;
        grid-template-columns: 0.9fr 1fr;

        @media only screen and (max-width: 600px) {
          grid-template-columns: 0.7fr 1.2fr;
        }

        .amount {
          font-weight: 700;
          font-size: 24px;
          line-height: 34px;
          color: #ffffff;
        }

        .time {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }
    }

    .right {

      .img-chart {
        width: 100%;
      }

    }


    .news-link {
      height: 100%;
      display: block;

      img {
        top: 0;
        bottom: 0;
        // height: 100%;
        object-fit: contain;
      }
    }
  }

  .announcement-popup {
    max-width: 800px;

    .modal-content {
      background: #30353F;
      border-radius: 8px;

      p {
        color: #ffffff;
      }
    }
  }

  @media (max-width: 769px) {
    .announcement-popup {
      max-width: 100%;

      .fixed-close-button {
        .modal-header {
          position: fixed;
          top: 10px;
          z-index: 999;
          right: 0px;
          border: none;
          background: #fff0;
          padding: 10px;
          border-radius: 0px;
        }
      }
    }
  }

  .close-btn {
    background: #333333c7;
    padding: 6px 8px;
    border-radius: 50%;
    top: 10px;
    right: 10px;
    cursor: pointer;

    img {
      width: 18px;
    }
  }
}
</style>
