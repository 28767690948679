var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-input-custom",class:[_vm.className, { 'base-input-custom--password': _vm.type === 'password' }]},[(_vm.inputType !== 'textarea')?_c('div',{staticClass:"base-input-custom__wrap",class:{
      focused: _vm.isFocus || _vm.currentValue,
      'input-error': _vm.$v.currentValue.$error,
      'focused-pointer': _vm.isFocus,
    }},[_c('div',{staticClass:"label-input"},[_c('label',[_vm._v(_vm._s(_vm.label))]),_c('span',[_vm._v(_vm._s(_vm.$t('wallet.avaible-balance'))+": "+_vm._s(_vm.maxValue))])]),_c('div',{staticClass:"input-group"},[_c('MaskedInput',{ref:"mark",attrs:{"mask":_vm.getMarkNumber(),"type":_vm.inputType,"name":_vm.name,"guide":false,"maxlength":_vm.limitInput,"disabled":_vm.disabled,"placeholder":_vm.formatPlaceholder},on:{"blur":function($event){return _vm.onBlur()},"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}}),_c('a',{staticClass:"btn-max",attrs:{"type":"button"},on:{"click":_vm.clickMax}},[_vm._v("\n        "+_vm._s(_vm.$t('Max'))+"\n      ")])],1),(_vm.type === 'password')?_c('i',{class:[_vm.isShowPassword ? 'fa fa-eye' : 'fa fa-eye-slash', 'icon eye'],on:{"click":function($event){return _vm.togglePassword()}}}):_vm._e(),(_vm.searchable)?_c('i',{staticClass:"icon search fa fa-search",on:{"click":_vm.search}}):_vm._e(),(_vm.$v.currentValue.$error)?_c('span'):_vm._e(),(_vm.$v.currentValue.$error)?_vm._t("error"):_vm._e(),_vm._t("default")],2):_c('div',{staticClass:"base-input-custom__wrap base-input-custom__wrap--reverse",class:{
      focused: _vm.isFocus || _vm.currentValue,
      'input-error': _vm.$v.currentValue.$error,
    }},[_c('label',[_vm._v(_vm._s(_vm.label))]),_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model.trim",value:(_vm.currentValue),expression:"currentValue",modifiers:{"trim":true}}],attrs:{"name":_vm.name,"placeholder":_vm.formatPlaceholder},domProps:{"value":(_vm.currentValue)},on:{"blur":[function($event){return _vm.onBlur()},function($event){return _vm.$forceUpdate()}],"focus":function($event){return _vm.onFocus()},"input":function($event){if($event.target.composing)return;_vm.currentValue=$event.target.value.trim()}}},'textarea',_vm.$attrs,false))]),(_vm.$v.currentValue.$error)?_c('p',{staticClass:"error",class:_vm.classError},[(!_vm.$v.currentValue.required)?[_vm._v("\n      "+_vm._s(_vm.formatErrorRequired)+"\n    ")]:(!_vm.$v.currentValue.minLength)?[_vm._v("\n      "+_vm._s(_vm.formatErrorMinLength)+"\n    ")]:(!_vm.$v.currentValue.maxLength)?[_vm._v("\n      "+_vm._s(_vm.formatErrorMaxLength)+"\n    ")]:(!_vm.$v.currentValue.minValue)?[_vm._v("\n      "+_vm._s(_vm.formatErrorMinValue)+"\n    ")]:(!_vm.$v.currentValue.maxValue)?[_vm._v("\n      "+_vm._s(_vm.formatErrorMaxValue)+"\n    ")]:(!_vm.$v.currentValue.email)?[_vm._v("\n      "+_vm._s(_vm.formatErrorEmail)+"\n    ")]:(!_vm.$v.currentValue.sameAs)?[_vm._v("\n      "+_vm._s(_vm.formatErrorSameAs)+"\n    ")]:(!_vm.$v.currentValue.notSameAs)?[_vm._v("\n      "+_vm._s(_vm.formatErrorNotSameAs)+"\n    ")]:(
        !_vm.$v.currentValue.specialCharacter ||
        !_vm.$v.currentValue.specialEmailCharacter
      )?[_vm._v("\n      "+_vm._s(_vm.formatErrorSpecicalCharacter)+"\n    ")]:(!_vm.$v.currentValue.notZeroFirst)?[_vm._v("\n      "+_vm._s(_vm.formatErrorNotZeroFirst)+"\n    ")]:(!_vm.$v.currentValue.fullNameCharacter)?[_vm._v("\n      "+_vm._s(_vm.formatErrorFullNameCharacter)+"\n    ")]:(!_vm.$v.currentValue.usernameValidate)?[_vm._v("\n      "+_vm._s(_vm.formatErrorUsernameCharacter)+"\n    ")]:(!_vm.$v.currentValue.normalizeCharacter)?[_vm._v("\n      "+_vm._s(_vm.formatErrorNormalizeCharacter)+"\n    ")]:(!_vm.$v.currentValue.password)?[_vm._v(_vm._s(_vm.formatErrorPassword))]:(!_vm.$v.currentValue.alphaNumericCharacter)?[_vm._v(_vm._s(_vm.formatErrorAlphaNumericCharacter))]:_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }