<template>
  <div
    class="item"
     :class="{'active' : paymentMethod === item.value, 'disable': !item.allow}"
     @click="setPaymentMethod(item.value)"
  >
    <template v-if="item.tag">
      <img class="payment_tag-left" :src="item.tag" alt="">
    </template>
    <div class="d-flex justify-content-center">
      <img :src="item.logo" alt="crypto" class="image-crypto icon">
    </div>
    <div class="information mt-2">
      <div class="name">{{ item.label }}</div>
      <span class="balance" v-if="item.showEquivalentBalance">
        {{ $t('payments.methods.cash.balance')}}: {{ wallet[item.value].Amount | toCurrency }}
             ≈ {{ wallet[item.value].Amount *  formatCurrentPrice(wallet[item.value].CurrentPrice || 1) | toCurrency(true) }}
      </span>
      <div v-if="item.value==='CreditCard' && item.allow" class="text-blue" @click="tabToEditCredit">
        {{item.hasInfo ? $t('payments.methods.creadit.edit') : $t('payments.methods.creadit.add')}}
      </div>
    </div>
    <div class="item__checkbox">
      <input
        :id="item.value"
        type="radio"
        :value="item.value"
        :checked="checked"
      >
      <label :for="item.value" class="checkbox__box" />
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'input',
    event: 'change'
  },
  props: {
    wallet: {
      type: Object,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {}
    },
    input: {
      type: String,
      default: ''
    }
  },
  computed: {
    checked () {
      return this.paymentMethod === this.item.value
    },
    paymentMethod: {
      set(val) {
        this.$emit('change', val)
      },
      get() {
        return this.input
      }
    }
  },
  methods: {
    formatCurrentPrice(value) {
      if (value) {
        return value
      }
      return 1
    },
    setPaymentMethod (val) {
      if (val === 'CreditCard') {
        this.$emit('selectCreditCard')
        return
      }
      this.paymentMethod = val
    },
    tabToEditCredit () {
      this.$emit('editCreditCard')
    }
  }
}
</script>

<style scoped lang="scss">
  .item {
    position: relative;
    padding: 24px 10px 16px 10px;
    margin-bottom: 14px;
    cursor: pointer;
    width: 150px;
    height: 150px;
    border: 1px solid #DADADA;
    border-radius: 8px;

    &.disable {
      pointer-events: none;
      cursor: default;
      background: #dadada;
    }
    &.active {
      border: 1px solid #007BFF;
    }
    img.icon {
      width: 32px;
      height: 32px;
      object-fit: contain
    }
    .information {
      width: 100%;
      .name {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #141822;
        text-align: center;
      }
      .balance {
        display: flex;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #898B91;
        b {
          color: #141822;
        }
      }
    }
    &__checkbox {
      position     : absolute;
      left: 8px;
      top: 8px;
      pointer-events: none;
      cursor       : pointer;
      &__label {
        padding-left : 9px;
        font-size    : 14px;
        line-height  : 17px;
        white-space  : nowrap;
      }
      input {
        transition   : all 0.2s;
        touch-action : manipulation;
        color        : #F2F2F2;
        cursor       : pointer;
      }
      input[type=radio] {
        position           : relative;
        width              : 20px;
        height             : 20px;
        border-radius      : 100%;
        outline            : none !important;
        display            : none;
        -webkit-appearance : none;
        cursor             : pointer;
        &:checked {
          & ~ .checkbox__box {
            &::before {
              background  :#007BFF;
              border      : none;
            }
            &::after {
              content     : "\2713";
              color       : #ffffff;
              font-size   : 15px;
              text-align  : center;
              font-weight : bold;
              top         : 50%;
              left        : 50%;
              width       : 19px;
              height      : 19px;
              transform   : translate(-50%, -50%);
              background  : transparent;
            }
          }
        }
      }
      .checkbox__box {
        cursor   : pointer;
        width    : 20px;
        height   : 20px;
        position : relative;
        &::before {
          content       : "";
          position      : absolute;
          top           : 0;
          left          : 0;
          display       : block;
          background    : transparent;
          border        : 1px solid #DADADA;
          border-radius : 100%;
          box-shadow    : none;
          width         : 20px;
          height        : 20px;
          transition    : all 300ms cubic-bezier(0.19, 1, 0.22, 1);
        }
        &::after {
          content       : none;
          position      : absolute;
          top           : 4px;
          left          : 4px;
          display       : block;
          background    : #e7ecf2;
          border-radius : 100%;
          width         : 14px;
          height        : 14px;
          transition    : all 300ms cubic-bezier(0.19, 1, 0.22, 1);
        }
      }
    }
    .text-blue {
      color: #007BFF;
      text-align: center;
    }
    .payment_tag-left {
      position: absolute;
      width: 100px !important;
      height: 100px !important;
      right: -6px;
      top: -6px;
    }
  }
</style>
