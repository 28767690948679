<template>
  <WalletDetail v-if="$route.query.code !== 'tron'"/>
  <WalletDetailTron v-else/>
</template>

<script>
import WalletDetail from '@containers/Wallet/detail.vue'
import WalletDetailTron from '@containers/Wallet/detail-tron.vue'

export default {
  name: "index",
  components: {
    WalletDetail,
    WalletDetailTron
  }
}
</script>

<style scoped>

</style>
